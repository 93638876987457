import React from 'react'
import { Link } from 'gatsby'

import { useUserData } from '~contexts/userDataContext'

import {
	removeMarketItem,
	updateMarketItem
} from './queries/MarketItem.queries'
import { TMarketItem } from 'src/types/MarketItemType'

import { useModalWithPayload } from '~components/basics/Modal/Modal'
import ModalConfirmation from '~components/basics/Modal/ModalConfirmation/ModalConfirmation'

import { useMarketItems, useMarketScrollEffect } from '~utils/misc'

import {
	duplicatMarketItem,
	enableOrDisableAllMarketItems
} from './MarketItemEditor/queries/marketItem.queries'
import { EnvelopIcon } from '~components/svg/EnvelopIcon'
import { EyeSlashIcon } from '~components/svg/EyeSlashIcon'
import EyeIcon from '~components/svg/EyeIcon'
import MarketItem from './MarketItem/MarketItem'

import './Market.scss'
import Spinner from '~components/basics/Icons/Spinner'

const Market = props => {
	const { token, activitiesId } = useUserData()
	const [items, setItems, loading] = useMarketItems()
	const confirmationModal = useModalWithPayload()

	useMarketScrollEffect(props.location)

	const handleToggleMarketItemEnable = (item: TMarketItem) => () => {
		const enabled = !item.enabled
		updateMarketItem(
			item.id,
			{
				enabled
			},
			token
		)
			.then(() => {
				setItems(
					items.map(_ =>
						_.id === item.id
							? {
									..._,
									enabled
							  }
							: _
					)
				)
			})
			.catch(err => {})
	}

	const handleRemoveMarketItem = itemId => () => {
		confirmationModal.open({
			title: `Confirmez-vous la suppression ?`,
			subtitle: null,
			handleLeftButtonClick: () => {},
			leftButtonlabel: 'Annuler',
			handleRightButtonClick: () => {
				removeMarketItem(itemId, token).then(() => {
					setItems(items.filter(_ => _.id !== itemId))
				})
			},
			rightButtonlabel: 'Oui'
		})
	}

	const handleEnableAll = (enable: boolean) => {
		enableOrDisableAllMarketItems(enable, token).then(res => {
			items.map(x => (x.enabled = enable))
			setItems([...items])
		})
	}

	const handleDuplicate = itemId => {
		duplicatMarketItem(itemId, token).then(res => {
			if (res.id) {
				setItems([res, ...items])
			}
		})
	}

	const itemsEnable = items.findIndex(x => x.enabled) >= 0
	if (loading)
		return (
			<div className="loader_">
				<Spinner color="dark" />
			</div>
		)
	return (
		<div className="Market">
			<ModalConfirmation {...confirmationModal} />
			<div className="headerbar">
				<div className="title">
					<div>Votre boutique</div>
					<span>
						{items.length} offre{items.length > 1 ? 's' : ''}
					</span>
				</div>
				<div className="butons">
					<a
						href={
							activitiesId?.length === 1
								? '/fiche/mails' + activitiesId[0]
								: '/fiche'
						}
					>
						<EnvelopIcon /> Modifier les mails de réservation
					</a>
					{itemsEnable ? (
						<button onClick={() => handleEnableAll(false)}>
							<EyeSlashIcon /> Désactivez temporairement toutes les offres
						</button>
					) : (
						<button onClick={() => handleEnableAll(true)}>
							<EyeIcon color="dark" /> Activez toutes les offres
						</button>
					)}
				</div>
			</div>
			<div className="items">
				<Link to="/boutique/nouveau" className="create">
					<span>+ Créer une nouvelle offre</span>
				</Link>
				{items.map(item => (
					<MarketItem
						key={item.id}
						item={item}
						handleRemove={handleRemoveMarketItem(item.id)}
						handleToggleEnable={handleToggleMarketItemEnable(item)}
						handleDuplicate={() => handleDuplicate(item.id)}
					/>
				))}
			</div>
		</div>
	)
}

export default Market
