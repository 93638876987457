import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Winky from '~svg/wink.svg'
import { useUserData } from '~contexts/userDataContext'

const EmptyBooking = ({ page }) => {
	const { shareLink } = useUserData()
	const [copied, setCopied] = useState(null)

	const handleCopy = act => {
		setCopied(null)

		setCopied(act)

		setTimeout(() => {
			setCopied(null)
		}, 2000)
	}

	const content = {
		booking: {
			title: 'Parlez de votre activité pour avoir des réservations !',
			text:
				'La liste de vos réservations sera affichée ici. Un petit message sur vos réseaux sociaux pour promouvoir l’url de votre page vous aidera.'
		},
		payment: {
			title: 'Aucun historique de réservation',
			text:
				'Pensez à promouvoir l’url de votre page sur vos réseaux sociaux pour avoir des réservations :)'
		}
	}

	return (
		<Wrapper>
			<Wink className="wink">
				<Winky />
			</Wink>
			<H2>{content[page].title}</H2>

			<Text>{content[page].text}</Text>

			{Object.keys(shareLink).filter((act)=>(shareLink[act].url)).map(act=>(
					<CopyToClipboard text={shareLink[act].url} onCopy={()=>handleCopy(act)} key={act}>
						<ShareBox>
							{shareLink[act].url}
							<Signal copied={copied} _id={act}>Lien copié dans le presse-papier</Signal>
						</ShareBox>
					</CopyToClipboard>
			)
      )}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	background-color: white;
	padding: 12% 18% 14%;
	width: 100%;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`
const Wink = styled.div`
	height: 100px;
	width: 100px;
	border-radius: 100%;
	background-color: ${props => props.theme.colors.yellow};
	margin-bottom: 30px;
`

const H2 = styled.h2`
	margin-bottom: 8px;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
`

const Text = styled.p`
	margin-top: 0;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	margin-bottom: 39px;
`

const ShareBox = styled.div`
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	text-align: center;
	width: 100%;
	height: 60px;
	padding: 25px 35px 0;
	background-color: ${props => props.theme.colors.boxBg};
	color: ${props => props.theme.colors.boxFg};
	word-break: break-all;
	box-shadow: 0 10px 0 ${props => props.theme.colors.boxBg};
	border-radius: 4px;
	cursor: pointer;
	position: relative;
	margin-bottom: 20px;
`

const Signal = styled.div`
	opacity: ${props => (props.copied === props._id ? 0.8 : 0)};
	pointer-events: none;
	position: absolute;
	font-size: 12px;
	background-color: ${props => props.theme.colors['grey-light']};
	color: ${props => props.theme.colors['grey-dark']};
	top: -20px;
	padding: 5px;
	border-radius: 6px;

	transition: opacity 400ms ease-in;
`

export default EmptyBooking
