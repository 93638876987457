import React from 'react'
import 'react-step-progress-bar/styles.css'
import { ProgressBar, Step } from 'react-step-progress-bar'

import { useUserData } from '~contexts/userDataContext'

import './StepBar.scss'

const StepBar = ({
	step,
	unlocked,
	enabledNavLinks = true,
	handleChangeStep = () => {}
}) => {
	// Context
	const { dispatch, userData } = useUserData()

	const {
		free,
		ticketing: { current_step: currentStep }
	} = userData

	const handleClick = goTo => {
		handleChangeStep(goTo)
		if (enabledNavLinks) dispatch({ type: 'step', payload: goTo })
	}

	return (
		<div className="stepper">
			<ProgressBar percent={unlocked ? 0 : (100 / 4) * (step - 1)}>
				<Step>
					{({ accomplished, index }) => (
						<div
							onClick={() => handleClick(1, accomplished)}
							data-title="Infos"
							className={`indexedStep ${
								unlocked || accomplished ? 'accomplished' : ''
							} ${unlocked ? 'unlocked' : ''} ${
								currentStep !== index + 1 ? 'inactive' : ''
							} ${step === 1 ? 'active' : ''}`}
						>
							{index + 1}
						</div>
					)}
				</Step>

				<Step>
					{({ accomplished, index }) => (
						<div
							onClick={() => handleClick(2, accomplished)}
							data-title="Contact"
							className={`indexedStep ${
								unlocked || accomplished ? 'accomplished' : ''
							} ${unlocked ? 'unlocked' : ''} ${
								currentStep !== index + 1 ? 'inactive' : ''
							} ${step === 2 ? 'active' : ''}`}
						>
							{index + 1}
						</div>
					)}
				</Step>
				<Step>
					{({ accomplished, index }) => (
						<div
							onClick={() => handleClick(3, accomplished)}
							data-title="Tarifs"
							className={`indexedStep ${
								unlocked || accomplished ? 'accomplished' : ''
							} ${unlocked ? 'unlocked' : ''} ${
								currentStep !== index + 1 ? 'inactive' : ''
							} ${step === 3 ? 'active' : ''}`}
						>
							{index + 1}
						</div>
					)}
				</Step>
				<Step>
					{({ accomplished, index }) => (
						<div
							onClick={() => handleClick(4, accomplished)}
							data-title="Horaires"
							className={`indexedStep ${
								unlocked || accomplished ? 'accomplished' : ''
							} ${unlocked ? 'unlocked' : ''} ${
								currentStep !== index + 1 ? 'inactive' : ''
							} ${step === 4 ? 'active' : ''}`}
						>
							{index + 1}
						</div>
					)}
				</Step>
				<Step>
					{({ accomplished, index }) => (
						<div
							onClick={() => handleClick(5, accomplished)}
							data-title="Créneaux"
							className={`indexedStep ${
								unlocked || accomplished ? 'accomplished' : ''
							} ${unlocked ? 'unlocked' : ''} ${
								currentStep !== index + 1 ? 'inactive' : ''
							} ${step === 5 ? 'active' : ''}`}
						>
							{index + 1}
						</div>
					)}
				</Step>
			</ProgressBar>
		</div>
	)
}

export default StepBar
