import { TAppDataAction } from './appData.types'

const fakeFetchAction = error =>
	new Promise((res, reject) => {
		setTimeout(() => {
			if (error) {
				reject()
			} else
				res([
					{
						name: 'A'
					},
					{
						name: 'B'
					},
					{
						name: 'C'
					}
				])
		}, 1000)
	})

export const loadActivitiesAction = (dispatch: React.Dispatch<TAppDataAction>) => {
	dispatch({
		type: 'GET_ACTIVITIES_FETCHING'
	})
	fakeFetchAction(true)
		.then((activities: any) => {
			dispatch({
				type: 'GET_ACTIVITIES_SUCCESS',
				payload: { activities }
			})
		})
		.catch(() => {
			dispatch({
				type: 'GET_ACTIVITIES_FAIL'
			})
		})
}

export const loadActivityAction = (
	activityId: string,
	dispatch: React.Dispatch<TAppDataAction>
) => {
	console.log({
		activityId
	})
	// todo
}
