import React, { useEffect, useMemo, useRef, useState } from 'react'
import './ReservationDetailsPopup.scss'
import ParameterIcon from '~svg/parameter.svg'
import CloseIcon from '~svg/close.svg'
import InformationIcon from '~svg/information.svg'
import RedCircleTrash from '~svg/redCircleTrash.svg'
import PersonIcon from '~svg/person.svg'
import GroupPersonsIcon from '~svg/group-persons.svg'
import GroupPersonsCircleIcon from '~svg/group-persons-circle.svg'
import InformationSymbolIcon from '~svg/information-symbol.svg'
import CalendarIcon from '~svg/calendar-circle.svg'
import CommentIcon from '~svg/comment.svg'
import Moment from 'moment'
import 'moment/locale/fr'
import PrestationBenefits from './prestation-benefits'
import PrestationOptions from './prestation-options'
import ReservationCancellationResult from './ReservationCancellationResult'
import { cancelReservationTicket } from '~requests/reservations/services/reservation.service'
import { set } from 'date-fns'
import { useUserData } from '~contexts/userDataContext'
import { TicketPricingsCancellationRefund } from './ticket-pricings-refund'
import {
	getTicketPricings,
	getTicketTva,
	getTicketOptions,
	getTicketDatesString,
	getDeliveryAddress
} from '@coq-trotteur/coq-core/dist'

function useOutsideAlerter(ref, setter, dropdownRef) {
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event) {
			if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
				event.stopPropagation()
				return
			} else if (ref.current && !ref.current.contains(event.target)) {
				setter(false)
			}
		}

		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [ref])
}

const ReservationMarketDetailsPopup = ({ reservation, onClose }) => {
	const { apiUrl, token } = useUserData()
	const [openDropdown, setOpenDropdown] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const [saveIsLoading, setSaveIsLoading] = useState(false)
	const [
		cancellationResultPopupTitle,
		setCancellationResultPopupTitle
	] = useState('')
	const [
		cancellationResultPopupDescription,
		setCancellationResultPopupDescription
	] = useState('')
	const [confirmDialog, setConfirmDialog] = useState(true)
	const cancelBtnRef = useRef(null)
	const cancelDropdownBtnRef = useRef(null)
	useOutsideAlerter(cancelBtnRef, setOpenDropdown, cancelDropdownBtnRef)
	
	const onClickAbort = () => {
		setIsOpen(false)
		setConfirmDialog(true)
	}
	const onClickCancel = () => {
		setIsOpen(false)
		setConfirmDialog(true)
	}
	const onClickSave = () => {
		setSaveIsLoading(true)
		cancelReservationTicket(
			reservation.reservation_ticket_number,
			apiUrl,
			token
		)
			.then(async res => {
				if (res.status !== 200 && res.status !== 201) {
					const response = await res.json()
					setCancellationResultPopupTitle('Annulation impossible')
					setCancellationResultPopupDescription(response.error)
				} else {
					setCancellationResultPopupTitle('Annulation confirmée')
					setCancellationResultPopupDescription(
						'Un mail a été envoyé au client pour le prévenir que sa réservation a été annulée.'
					)
				}
				setConfirmDialog(false)
			})
			.catch(error => {
				console.warn('Error when fetching pending reservations ', error)
				setCancellationResultPopupTitle('Annulation impossible')
				setCancellationResultPopupDescription(error?.error)
			})
			.finally(() => {
				setSaveIsLoading(false)
			})
	}
	const cancelReservation = () => {
		setCancellationResultPopupTitle('Êtes-vous sûr de vouloir annuler ?')
		setCancellationResultPopupDescription(
			'Si vous confirmez, vous ne pourrez plus changer d’avis, l’annulation sera définitive.'
		)
		setIsOpen(true)
	}
	useEffect(() => {
		if (reservation) {
			document.body.classList.add('no-scroll')

			return () => {
				document.body.classList.remove('no-scroll')
			}
		}
	}, [reservation])

	// To move in an utils file
	const sumReducer = (a, b) => a + b

	const [reservationMoment, phoneNumber, options, tva] = useMemo(() => {
		let phone = reservation?.reservation?.informations?.phone
		phone = phone?.replace('+', '')?.replace(' ', '')
		phone = phone?.match(/.{1,2}/g)
		phone = phone?.join(' ')
		let options = ''
		reservation?.options?.map(option => (options += ' - ' + option.value))
		// console.log({reservation})
		return [
			Moment(reservation?.createdAt),
			phone,
			options,
			calcTVA(reservation)
		]
	}, [reservation])

	function calcTVA(infos) {
		let tva = 0
		if (infos?.marketPricings?.uniquePrice) {
			tva = (
				(((infos?.marketPricings?.basePrice * infos?.marketPricings?.quantity) /
					(1 + (infos?.marketPricings?.tva || 0) / 100)) *
					(infos?.marketPricings?.tva || 0)) /
				100
			)
				.toFixed(2)
				.replace('.', ',')
		} else {
			tva = infos?.marketPricings?.list
				?.map(pricing => {
					return (
						(((pricing?.price * pricing?.quantity) /
							(1 + (infos?.marketPricings?.tva || 0) / 100)) *
							(infos?.marketPricings?.tva || 0)) /
						100
					)
						.toFixed(2)
						.replace('.', ',')
				})
				.reduce((a, b) => parseFloat(a) + parseFloat(b))
		}
		return tva
	}

	return isOpen ? (
		<ReservationCancellationResult
			saveIsLoading={saveIsLoading}
			isOpen={isOpen}
			title={cancellationResultPopupTitle}
			description={cancellationResultPopupDescription}
			confirmDialog={confirmDialog}
			onClickAbort={onClickAbort}
			onClickCancel={onClickCancel}
			onClickSave={onClickSave}
		/>
	) : (
		<div className={`reservation-details-popup ${reservation ? 'active' : ''}`}>
			<div className="overlay" />
			<div className="popup-content">
				<div className="popup-header">
					<div className="btn-group">
						<div className="btn" ref={cancelDropdownBtnRef}>
							<ParameterIcon onClick={() => setOpenDropdown(!openDropdown)} />
						</div>
						<CloseIcon className="btn" onClick={onClose} />
					</div>
					{openDropdown ? (
						<div className="line" ref={cancelBtnRef}>
							<div
								className="cancellation-btn"
								onClick={() => cancelReservation()}
							>
								<RedCircleTrash className="icon" /> Annuler et rembourser la
								réservation
							</div>
						</div>
					) : (
						<></>
					)}

					<div className="separator" />
				</div>

				<div className="popup-body first-popup-body-elem">
					<div className="note-form">
						<div className="label">
							Ajouter une note interne à cette réservation{' '}
							<InformationIcon className="information-icon" />
							<textarea className="note-control" />
						</div>
					</div>
				</div>

				<div className="separator" />

				<div className="reservation-details popup-body">
					<div className="details-group">
						<PersonIcon className="details-icon" />
						<div className="details">
							<div className="detail">
								<span className="detail-title">Nom : </span>
								<span>
									{reservation?.reservation?.informations?.firstname}{' '}
									{reservation?.reservation?.informations?.lastname} |{' '}
									{reservation?.reservation_ticket_number}
								</span>
							</div>
							<div className="detail">
								<span className="detail-title">Contact : </span>
								<span>
									{reservation?.reservation?.informations?.email} ou +
									{phoneNumber}
								</span>
							</div>
							<div className="detail">
								{reservation?.delivery?.type === 'takeaway' ? (
									<span className="detail-title">
										Retrait sur place{' '}
										{reservation?.giftPackage?.selected
											? ' + paquet cadeau '
											: ' '}
									</span>
								) : (
									''
								)}

								{reservation?.delivery?.type === 'owndelivery' ? (
									<span className="detail-title">
										Livraison par le vendeur{' '}
										{reservation?.giftPackage?.selected
											? ' + paquet cadeau '
											: ' '}
									</span>
								) : (
									''
								)}
								{reservation?.delivery?.type === 'shippingoption' ? (
									<span className="detail-title">
										Livraison par {reservation?.delivery?.shippingService || reservation?.delivery?.service}{' '}
										{reservation?.giftPackage?.selected
											? ' + paquet cadeau '
											: ' '}
									</span>
								) : (
									''
								)}
								<span>
									{reservation?.delivery?.type === 'owndelivery' ||
									reservation?.delivery?.type === 'shippingoption' || reservation?.delivery?.type === 'service'
										? ': ' +
										  getDeliveryAddress(reservation)
										: ''}
								</span>
							</div>
						</div>
					</div>

					<div className="details-group">
						<InformationSymbolIcon className="details-icon" />
						<div className="details">
							{reservation?.state?.canceled ? (
								<div className="danger">
									Annulé par le vendeur le{' '}
									{Moment(reservation?.state?.canceled_date).format('DD/MM/yy')}{' '}
									à{' '}
									{Moment(reservation?.state?.canceled_date).format('HH\\h:mm')}
									. Remboursement 100%.
								</div>
							) : (
								<></>
							)}
							<div>
								<span className="detail-title">
									Commande, {reservation?.total?.toFixed(2)}€ (dont TVA{' '}
									{reservation ? getTicketTva(reservation) : 0}€{' '}
									{reservation?.delivery?.price
										? 'et livraison ' + reservation?.delivery?.price + '€ TTC'
										: ''}
									) :{' '}
								</span>
							</div>
							{reservation && (
								<>
									{reservation.snapshot && <div>{getTicketDatesString(reservation)}</div>}
									{reservation.market_item && getTicketPricings(reservation).map(pricing => (
										<div>
											{pricing.quantity || 1} * {pricing.label}
										</div>
									))}
									{getTicketOptions(reservation).map(option => (
										<div>
											{option.quantity || 1} * {option.label}
										</div>
									))}
								</>
							)}
						</div>
					</div>

					<div className="details-group">
						<CalendarIcon className="details-icon" />
						<div className="details">
							<div>
								<span className="detail-title">
									Commandé le {reservationMoment.format('DD/MM')} à{' '}
									{reservationMoment.format('HH:mm')} pour un montant total de{' '}
									{reservation?.total?.toFixed(2)}€.{' '}
								</span>
								<span></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ReservationMarketDetailsPopup
