import React from 'react'
import { FormLine, Select } from '~components/Ticketing/FormToolbox'

const InfosEnvironment = ({ inDoorField }) => (
	<FormLine>
		<div>Votre activité est-elle en intérieur ou en extérieur ?</div>
		<div>
			<Select
				style={{
					width: 180
				}}
				{...inDoorField}
				options={[
					{
						label: 'En intérieur',
						value: true
					},
					{
						label: 'En extérieur',
						value: false
					}
				]}
			/>
		</div>
	</FormLine>
)

export default InfosEnvironment
