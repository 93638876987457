import React, { useEffect, useState } from 'react'
import './reservation-calendar.scss'
import SnapeIcon from '~svg/snape.svg'
import SnapeUpIcon from '~svg/snape-up.svg'
import VerifiedCircleIcon from '~svg/verified-circle.svg'
import VerifiedCircleReadIcon from '~svg/verified-circle-read.svg'
import {
	markPrestationAsRead,
	getReservationCalendar,
	markTicketAsRead
} from '~requests/reservations/services/reservation.service'
import { useUserData } from '~contexts/userDataContext'
import GroupPersonsIcon from '~svg/group-persons.svg'
import GroupPersonsReadIcon from '~svg/group-persons-read.svg'
import moment from 'moment'
import {
	getTicketSlot,
	countTotalTicketPricingsQuantity,
	getTicketOptions
} from '@coq-trotteur/coq-core'
import ReservationMarketDetailsPopup from './ReservationMarketDetailsPopup'
import { downloadWeeklyBill, downloadCalendar } from '~requests/reservations/services/pdf-generator.service'
import { capitalize } from '~utils/misc'

const ReservationCalendar = ({ ticketAccepted }) => {
	const { apiUrl, token } = useUserData()
	const [reservations, setReservations] = useState([])
	const [popupDetailsData, setPopupDetailsData] = useState(null)
	const [reservationDays, setReservationDays] = useState([])

	function getCalendarPdf(calendar) {
		downloadCalendar(token, calendar)
		.then(res => {
		  const data = res;
		  try {
			const blob = data;
			let url = process.env.GATSBY_APIV2_URL+'/pdf-generator/calendar?id='+data//window.URL.createObjectURL(new Blob(data, {type: "application/pdf"}));
			  let a = document.createElement('a');
			  document.body.appendChild(a);
			  a.setAttribute('style', 'display: none');
			  a.href = url;
			  a.download = 'cal.pdf';
			  a.click();
			  window.URL.revokeObjectURL(url);
			  a.remove();
		  } catch (error) {
			console.log(error)
			alert("Erreur lors du telechargement ")
			return null;
		  }
		})
		.catch(error => {
		  console.warn('Error download calendar ', error)
		})
	  }
	useEffect(() => {
		getReservationCalendar(apiUrl, token)
			.then(res => {
				// console.log({hey: res})
				// setReservations(res.reservations);
				setReservationDays(res)
			})
			.catch(error => {
				console.warn('Error when fetching pending reservations ', error)
			})
	}, [ticketAccepted])

	const markAsRead = (
		payload,
		dataIndex,
		reservationIndex,
		prestationIndex
	) => {
		markTicketAsRead(apiUrl, token, payload)
			.then(res => {
				const resa = reservationDays.slice()
				resa[dataIndex].reservations[reservationIndex].tickets[
					prestationIndex
				].isUsed = res
				const prestation =
					resa?.[dataIndex]?.reservations[reservationIndex]?.tickets[
						prestationIndex
					]
				setReservationDays(resa)
			})
			.catch(error => {
				console.warn('Error when mark prestation as read ', error)
			})
	}

	function calcTotalPrestationsDay(reservation) {
		let total = 0
		reservation.prestations.map(presta => {
			total += presta.numberOfPrestations
		})
		return total
	}

	function calclTotalReservationsDay(reservationsDay) {
		let total = 0
		reservationsDay.map(reservation => {
			total += calcTotalPrestationsDay(reservation)
		})
		return total
	}

	function replyDayCalendar(reservationIndex) {
		document.getElementById('calendar_day_'+reservationIndex).style.display = 'none';
		document.getElementById('snape_calendar_day_'+reservationIndex).style.display = 'none';
		document.getElementById('snape_up_calendar_day_'+reservationIndex).style.display = 'inline-block';
	}

	function deplyDayCalendar(reservationIndex) {
		document.getElementById('calendar_day_'+reservationIndex).style.display = 'block';
		document.getElementById('snape_up_calendar_day_'+reservationIndex).style.display = 'none';
		document.getElementById('snape_calendar_day_'+reservationIndex).style.display = 'inline-block';
	}

	return (
		<div className="block-info">
			<ReservationMarketDetailsPopup
				reservation={popupDetailsData}
				onClose={() => {
					setPopupDetailsData(null)
				}}
			/>
			<div className="calendar-title">Calendrier</div>
			{/*
                <div className='search-bar'>
                    <input placeholder="Rechercher" className="search-text mb" />
                    <div>
                        <select className="form-control-right mr mb"> 
                            <option>Toutes les prestations</option>
                        </select>
                        <input type="date" placeholder="Choisir une date" className="form-control-right mb" />
                    </div>
                </div>
            */}

			{!reservationDays || !reservationDays.length ? (
				<div className="center-h-v">Aucune réservation à venir.</div>
			) : (
				reservationDays?.map((day, dayIndex) => {
					return (
						<div className="reservation" key={`data_${dayIndex}`}>
							<div className="reservation-day">
								{capitalize(moment(day.date).format('dddd'))}{' '}
								{moment(day.date).format('DD/MM/YY')} :{' '}
								{day.numberOfPrestations} réservation
								{day.numberOfPrestations > 1 ? 's' : ''}
								<div className="pdf_button" onClick={() => getCalendarPdf(day)}>
									<svg
										width="14"
										height="14"
										viewBox="0 0 14 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M11.2051 7.61523L7 11.8203L2.79494 7.61523L3.56836 6.84181L6.45312 9.72658V0H7.54688V9.72658L10.4316 6.84181L11.2051 7.61523ZM14 13.4531C14 13.1511 13.7552 12.9062 13.4531 12.9062H0.546875C0.244844 12.9062 0 13.1511 0 13.4531C0 13.7552 0.244844 14 0.546875 14H13.4531C13.7552 14 14 13.7552 14 13.4531Z"
											fill="#172B49"
										/>
									</svg>
									&nbsp;Telecharger
								</div>
							</div>
							{day.reservations.map((reservation, reservationIndex) => {
								return (
									<div key={`reservation_${reservationIndex}`}>
										<div className="container-title">
											{reservation?._id?.marketItem?.pricing?.label ||
												reservation.tickets[0].market_item.title}{' '}
											{reservation?._id?.schedule?.slot
												? `- ${getTicketSlot(reservation.tickets[0])}`
												: ''}{' '}
											- Réservation : {reservation.numberOfPrestations}
											{/* {
                                                    reservation.pricing.max && reservation.pricing.max <= calcTotalPrestationsDay(reservation) ? <> - <span className="complet"> Complet</span> </> : <></>
                                                } */}
												
											<SnapeIcon className="float-right snape-collapse" onClick={() => replyDayCalendar(dayIndex+'_'+reservationIndex)} id={'snape_calendar_day_'+dayIndex+'_'+reservationIndex}/>
											<SnapeUpIcon className="float-right snape-collapse snape-up" onClick={() => deplyDayCalendar(dayIndex+'_'+reservationIndex)} id={'snape_up_calendar_day_'+dayIndex+'_'+reservationIndex}/>
											<div className="pdf_button float-right download-presta" onClick={() => getCalendarPdf({ ...day, reservations: [reservation], numberOfPrestations: reservation.numberOfPrestations })}>
												<svg
													width="14"
													height="14"
													viewBox="0 0 14 14"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M11.2051 7.61523L7 11.8203L2.79494 7.61523L3.56836 6.84181L6.45312 9.72658V0H7.54688V9.72658L10.4316 6.84181L11.2051 7.61523ZM14 13.4531C14 13.1511 13.7552 12.9062 13.4531 12.9062H0.546875C0.244844 12.9062 0 13.1511 0 13.4531C0 13.7552 0.244844 14 0.546875 14H13.4531C13.7552 14 14 13.7552 14 13.4531Z"
														fill="#172B49"
													/>
												</svg>
												&nbsp;Telecharger
											</div>
										</div>
										<div className="block-container" id={'calendar_day_'+dayIndex+'_'+reservationIndex}>
											<table>
												<tbody>
													{reservation.tickets?.sort((a, b) => a.isUsed > b.isUsed ? 1 : -1)?.map((ticket, ticketIndex) => {
														return (
															<tr
																key={`prestation_${ticketIndex}`}
																className={ticket.isUsed ? 'read' : ''}
															>
																<td>
																	{ticket.isUsed ? (
																		<VerifiedCircleReadIcon
																			onClick={() => {
																				markAsRead(
																					{
																						ticketId: ticket._id,
																						isUsed: false,
																						ticketNumber:
																							ticket.reservation_ticket_number
																					},
																					dayIndex,
																					reservationIndex,
																					ticketIndex
																				)
																			}}
																			className="check-icon"
																		/>
																	) : (
																		<VerifiedCircleIcon
																			onClick={() => {
																				markAsRead(
																					{
																						ticketId: ticket._id,
																						isUsed: true,
																						ticketNumber:
																						ticket.reservation_ticket_number
																					},
																					dayIndex,
																					reservationIndex,
																					ticketIndex
																				)
																			}}
																			className="check-icon"
																		/>
																	)}{' '}
																	<span
																		onClick={() => {
																			setPopupDetailsData(ticket)
																		}}
																	>
																		{ticket.reservation.informations.firstname}{' '}
																		{ticket.reservation.informations.lastname}
																	</span>
																</td>
																<td
																	onClick={() => {
																		setPopupDetailsData(ticket)
																	}}
																>
																	{countTotalTicketPricingsQuantity(ticket)}{' '}
																	{ticket.isUsed ? (
																		<GroupPersonsReadIcon className="group-person-icon" />
																	) : (
																		<GroupPersonsIcon className="group-person-icon" />
																	)}{' '}
																	{ticket.reservation_ticket_number}{' '}
																</td>
																<td
																	onClick={() => {
																		setPopupDetailsData(ticket)
																	}}
																>
																	{getTicketOptions(ticket).map(
																		option =>
																			' '+(option.quantity || 1) + ' * ' + option.label
																	)}
																</td>
															</tr>
														)
													})}
												</tbody>
											</table>
										</div>
									</div>
								)
							})}
						</div>
					)
				})
			)}
		</div>
	)
}

export default ReservationCalendar
