import React from 'react'

import { RemoveIcon } from '../StepSchedules'

import { HoursMinutesInput } from '../StepSchedules'

import './TimeSlotInput.scss'

const TimeSlotInput = ({ slotForm, clearCopy, stopAfterMinutes = false }) => {
	return (
		<div className="time-slot-input">
			<div className="content">
				<HoursMinutesInput
					timeForm={slotForm.get('start')}
					stopAfterMinutes={stopAfterMinutes}
				/>
				<button
					type="button"
					className="remove-button"
					onClick={() => {
						clearCopy()
						slotForm.remove()
					}}
				>
					<RemoveIcon />
				</button>
			</div>
		</div>
	)
}
export default TimeSlotInput
