import React from 'react'

import './Button.scss'

/**
 * Simple button.
 *
 * @component
 * @example
 *
 *   <Button theme='primary' size='large' roundish >
 * 	Next
 * 	</Button>
 * @param {{
 *   theme?:'underline-action'| 'colored-action'|'yellow'|'primary'|'primary-dark'|'blue'|'danger'|'outline-blue'
 *   children: any
 *   size?: 'large'
 *   style?: React.CSSProperties
 *   onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
 *   disabled?: boolean
 *   className?:string,
 *   roundish?: any
 * }} props
 */
const Button = ({ className = '', ...props }) => {
	const {
		theme,
		size,
		roundish = '',
		children,
		invisible,
		type = 'button',
		disabled = false,
		title = null
	} = props
	return (
		<button
			title={title}
			type={type}
			disabled={disabled}
			className={`button ${theme ? 'theme-' + theme : ''} ${
				size ? 'size-' + size : ''
			} ${roundish} ${invisible ? 'invisible' : ''} ${className}`.trim()}
			{...props}
		>
			{children}
		</button>
	)
}

export default Button
