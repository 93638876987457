import React from 'react'
import { FormLine, LabeledInput } from '../FormToolbox'

const FormPlaceOfSupport = ({ enabled = true, startingLocationForm }) => {
  return (
    <FormLine>
      <div style={{ flex: 2 }}>
        <LabeledInput
          label={<img src="/icons/pin.svg" />}
          placeholder="Adresse (optionnel)"
          {...startingLocationForm.get('address')}
        />
      </div>
      <div style={{ flex: 3 }}>
        <LabeledInput
          label={<img src="/icons/pencil.svg" />}
          placeholder="Ajouter une note écrite (optionnel)"
          {...startingLocationForm.get('note')}
        />
      </div>
    </FormLine>
  )
}
export default FormPlaceOfSupport
