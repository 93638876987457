import React from 'react'

export const EnvelopIcon = () => {
	return (
		<svg
			width="20"
			height="15"
			viewBox="0 0 20 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20 2.14286V12.1429C20 12.7112 19.7742 13.2562 19.3724 13.6581C18.9705 14.0599 18.4255 14.2857 17.8571 14.2857H2.14286C1.57454 14.2857 1.02949 14.0599 0.627628 13.6581C0.225765 13.2562 0 12.7112 0 12.1429V2.14286C0.0023174 1.89858 0.0482782 1.65668 0.135714 1.42857L8.81429 7.21429C9.16567 7.44771 9.57815 7.57223 10 7.57223C10.4219 7.57223 10.8343 7.44771 11.1857 7.21429L19.8643 1.42857C19.9517 1.65668 19.9977 1.89858 20 2.14286ZM10.3929 6.02143L18.9643 0.307143C18.6301 0.105889 18.2473 -0.000309778 17.8571 6.78737e-07H2.14286C1.75448 0.00314113 1.37427 0.111775 1.04286 0.314286L9.61429 6.02857C9.73084 6.10283 9.86644 6.14168 10.0046 6.14041C10.1428 6.13914 10.2777 6.09781 10.3929 6.02143Z"
				fill="#172B49"
			/>
		</svg>
	)
}
