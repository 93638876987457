export const getTransferToken = async (accessToken: string) => {
	const headers = new Headers()
	headers.append('x-access-token', accessToken)

	const url = `${process.env.GATSBY_API_URL}/backend_users/auth-transfer-token`

	const result = await fetch(url, {
		headers
	}).then(result => result.json())

	if (!result?.transferToken) throw ''

	return result.transferToken as string
}
