import React, { useEffect, useState } from 'react'

import { useInvalidSession, useUserData } from '~contexts/userDataContext'

// API Queries deps
import {
	getSchedulesPageData,
	saveSchedulesEnabled
} from './schedulesDataQueries'

import './FicheSchedulePage.scss'
import {
	transformSchedulesFromDatabase,
	handleSaveUtil,
	ficheChangUtil
} from './Schedules.utils'
import Schedules from './containers/Schedules/Schedules'
import { useCurrentFormStateContext } from '~contexts/currentFormContext'
import ScheduleCopier from './containers/ScheduleCopier/ScheduleCopier'
import { useModal } from '~components/basics/Modal/Modal'
import ScheduleModal from './containers/ScheduleModal/ScheduleModal'
import CloseScheduleBtn from './components/CloseScheduleBtn'
import FicheNavbar from '../common/FicheNavbar/FicheNavbar'
import { isFalse } from '~utils/misc'

const FicheSchedulePage: React.FC<any> = ({ id: activityId }) => {
	const { stateGetterRef, currentEnvironmentRef } = useCurrentFormStateContext()
	const [enabled, setEnabled] = useState(true)
	const { isOpen, setIsOpen } = useModal()
	const [hasSchedule, setHasSchedule] = useState(false)
	const [refresh, setRefresh] = useState(false)

	const { activitiesId, token, apiUrl } = useUserData()
	const [schedules, setSchedules] = useState(null)
	const [activitiesNames, setActivitiesNames] = useState(null)

	useEffect(() => {
		// Important
		currentEnvironmentRef.current = 'fiche'
		// ---

		getSchedulesPageData(activitiesId, token, apiUrl)
			.then(({ activities, activitiesNames }) => {
				const schedules = transformSchedulesFromDatabase(activities)
				// console.log('schedules',schedules);

				if (activitiesId.length === 1) {
					if (schedules.length === 0) {
						schedules.push({
							activities: activitiesId
						})
					} else {
						schedules.forEach(schedule => {
							schedule.activities = activitiesId
						})
					}
				}

				const activity = schedules.find(x => x.activities.includes(activityId))
				if (activity) {
					// console.log(`activity`, activity)
					setEnabled(isFalse(activity.enabled) ? false : true)
					setHasSchedule(true)
				}
				setSchedules(schedules.filter(x => x.activities.includes(activityId)))
				setActivitiesNames(activitiesNames)
			})
			.catch(() => {
				// invalidSession()
			})
	}, [])

	const handleFormSave = async () => {
		const state = await stateGetterRef.current()
		const success = await state.save()
		return success
	}

	const handleSave = formData => {
		console.log(`formData`, formData)
		return handleSaveUtil(formData, activitiesId, token, enabled)
	}

	const handleFicheChange = actiID => {
		const schdls = ficheChangUtil(schedules, actiID, activityId)

		setSchedules([...schdls])
		setRefresh(true)
		setTimeout(() => {
			setRefresh(false)
		}, 10)
	}

	const updateScheduleEnabled = async () => {
		setEnabled(!enabled)
		await saveSchedulesEnabled(activityId, !enabled, token, apiUrl)
	}

	if (!schedules || !activitiesNames) return null

	if (!activitiesNames[activityId])
		return (
			<div className="FicheSchedulePage">
				<div className="not_found">
					Activité inconnue, veuillez rafraîchir ou vérifier le lien.
				</div>
			</div>
		)
	return (
		<>
			<div className="FicheSchedulePage">
				<CloseScheduleBtn
					enabled={enabled}
					onClick={() => {
						if (enabled) {
							setIsOpen(true)
						} else updateScheduleEnabled()
					}}
				/>
				<div className="form">
					{!enabled && <div className="overlay" />}
					{Object.keys(activitiesNames).length > 1 && (
						<ScheduleCopier
							activitiesNames={activitiesNames}
							schedules={schedules}
							copyFicheFrom={handleFicheChange}
							hasSchedule={hasSchedule}
							activityId={activityId}
						/>
					)}
					{!refresh && (
						<Schedules
							schedules={{
								root: {
									enabled: true,
									schedules
								}
							}}
							handleSave={handleSave}
							updateScheduleEnabled={updateScheduleEnabled}
							activitiesNames={activitiesNames}
							activityId={activityId}
							enabled={enabled}
							hasSchedule={hasSchedule}
						></Schedules>
					)}
				</div>
			</div>
			<FicheNavbar handleSave={handleFormSave} error={false} />
			<ScheduleModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				updateEnabled={updateScheduleEnabled}
				activityId={activityId}
			/>
		</>
	)
}

export default FicheSchedulePage
