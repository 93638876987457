/*
 * Common function to call API.
 * As we migrate API to a new version, some queries are made
 * to new one and other to the last one. We'll migrate to
 * the new one step by step.
 */

import { ApolloClient, InMemoryCache } from '@apollo/client'

var jwt = require('jsonwebtoken')

// Remove double slash when api url environemnt variable has a slash at end.
export const cleanURL = url => url.replace(/([^:]\/)\/+/g, '$1')

type doGraphqlQueryPropsType = {
	query: import('graphql').DocumentNode
	accessToken: string
	variables?: object
	noCache?: Boolean
}

export const doGraphqlQuery = async ({
	query,
	accessToken,
	variables = {},
	noCache = false
}: doGraphqlQueryPropsType) => {
	const client = new ApolloClient({
		uri: cleanURL(`${process.env.GATSBY_APIV2_URL}/graphql`),
		headers: {
			'x-access-token': accessToken,
			'x-universe-token': process.env.GATSBY_CLIENT_TOKEN,
			'public-client-verification': jwt.sign(
				{
					sub: new Date().getTime()
				},
				process.env.GATSBY_JWT_SECRET
			)
		},
		cache: new InMemoryCache({
			addTypename: false
		})
	})

	const { data } = await client.query({
		query,
		variables,
		...(noCache ? { fetchPolicy: 'no-cache' } : {})
	})

	return data
}

type doGraphqlMutationPropsType = {
	mutation: any
	accessToken: string
	variables?: object
	noCache?: Boolean
}

export const doGraphqlMutation = async ({
	mutation,
	accessToken,
	variables = {},
	noCache = false
}: doGraphqlMutationPropsType) => {
	const client = new ApolloClient({
		uri: cleanURL(`${process.env.GATSBY_APIV2_URL}/graphql`),
		headers: {
			'x-access-token': accessToken,
			'x-universe-token': process.env.GATSBY_CLIENT_TOKEN,
			'public-client-verification': jwt.sign(
				{
					sub: new Date().getTime()
				},
				process.env.GATSBY_JWT_SECRET
			)
		},
		cache: new InMemoryCache({
			addTypename: false
		})
	})

	const { data } = await client.mutate({
		mutation,
		variables,

		...(noCache ? { fetchPolicy: 'no-cache' } : {})
	})

	return data
}
