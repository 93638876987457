import React, { useEffect, useState, useRef } from 'react'
import {
	FormSection,
	FormLine,
	Input,
	Select,
	PriceInput,
	MultipleSelect,
	CheckBox,
	AutoResizableTextarea
} from '../FormToolbox'
import InfoTooltip from '~components/basics/InfoTooltip'
import Button from '~components/basics/Button'
import { langData } from '~utils/objects'
import FormInclusions from '../Common/FormInclusions'
import FormPlaceOfSupport from '../Common/FormPlaceOfSupport'
import { useUserData } from '~contexts/userDataContext'

export const computeFinalPrice = soldPrice => {
	const percent = 3
	const percentTTC = percent * 1.2
	const tax = soldPrice * (percentTTC / 100)
	const taxFree = (soldPrice - tax).toFixed(2)
	return isNaN(taxFree) ? null : taxFree
}

const InformationIcon = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			id="Shape"
			d="M2 12C2 6.4875 6.4875 2 12 2C17.5125 2 22 6.4875 22 12C22 17.5125 17.5125 22 12 22C6.4875 22 2 17.5125 2 12Z"
			fill="#FFEDBD"
			stroke="#172B49"
			stroke-width="4"
		/>
		<path
			id="Vector"
			d="M21.5413 17.9161C22.5922 16.1993 23.2 14.1721 23.2 11.9996C23.2 5.81358 18.2755 0.800003 12.2 0.800003C6.12443 0.800003 1.19995 5.81358 1.19995 11.9996C1.19995 18.1873 5.79188 23.0133 12.2 23.2C14.3337 23.2 16.3252 22.5809 18.0116 21.5104L19.6642 19.4L21.5413 17.9161ZM12.2 6.02323C12.9625 6.02323 13.5811 6.65266 13.5811 7.42948C13.5811 8.20587 12.9629 8.83574 12.2 8.83574C11.437 8.83574 10.8188 8.20631 10.8188 7.42948C10.8188 6.65266 11.4374 6.02323 12.2 6.02323ZM14.6169 17.9772H9.78304V15.8676H10.8188V11.6488H9.78304V9.53959H13.5811V11.6488V15.8676H14.6169L14.6169 17.9772Z"
			fill="#172B49"
		/>
	</svg>
)

const PricingPrestationOption = ({ option }) => {
	return (
		<>
			<tr className="pricing-prestation-option">
				<td>
					<Input
						style={{
							width: '100%',

							border: `1px solid ${
								option.get('name').error ? '#e65264' : 'rgba(23, 43, 73, 0.1)'
							}`
						}}
						placeholder="Ex: Audio-guide"
						{...option.get('name')}
						className="name"
					/>
				</td>
				<td style={{ width: 160 }}>
					<Select
						fixedHeight={45}
						{...option.get('tva')}
						style={{
							width: 152
						}}
						options={[
							{
								label: '20%',
								value: 20
							},
							{
								label: '10%',
								value: 10
							},
							{
								label: '8.5%',
								value: 8.5
							},
							{
								label: '5.5%',
								value: 5.5
							},
							{
								label: '2.1%',
								value: 2.1
							},
							{
								label: (
									<div
										style={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											width: '100%'
										}}
									>
										Je ne collecte pas de TVA
									</div>
								),
								value: 0
							}
						]}
					/>
				</td>
				<td
					style={{
						width: 178
					}}
				>
					<PriceInput {...option.get('selling_price')} />
				</td>
				<td
					style={{
						width: 178
					}}
				>
					<PriceInput
						readOnly
						value={
							typeof option.get('selling_price').value === 'string'
								? computeFinalPrice(
										parseFloat(
											option.get('selling_price').value.replace(/,/g, '.')
										)
								  )
								: computeFinalPrice(option.get('selling_price').value)
						}
					/>
				</td>
				<td>
					<Button
						className="remove-option-button"
						theme="no-style"
						onClick={() => option.remove()}
						style={{ height: 45 }}
					>
						<img src="/icons/delete.svg" height={20} />
					</Button>
				</td>
			</tr>
		</>
	)
}

const PricingLine = ({
	item,
	onChange,
	name,
	error,
	id,
	hasEnabledPricing
}) => {
	const data = item
	const setData = data => {
		onChange(data)
	}

	return (
		<tr>
			<td
				style={{
					opacity: data.enabled || name === null ? 1 : 0.5,
					transform: 'opacity 200ms',
					width: 190
				}}
			>
				<PriceInput
					error={
						error &&
						(data.selling_price === null ||
							data.selling_price === undefined ||
							data.selling_price === '')
					}
					readOnly={!data.enabled && name !== null}
					value={data.selling_price === undefined ? null : data.selling_price}
					setValue={e => {
						onChange({
							...data,
							selling_price: e === '' || e === null ? null : e
						})
					}}
				/>
			</td>
			<td
				style={{
					opacity: data.enabled ? 1 : 0.7,
					transform: 'opacity 200ms',
					width: 190
				}}
			>
				<PriceInput
					readOnly={true}
					value={computeFinalPrice(parseFloat(data.selling_price)).replace(
						'.',
						','
					)}
				/>
			</td>
			{name !== null && (
				<td style={{}}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							outline: 'none',
							cursor: 'pointer',
							userSelect: 'none'
						}}
						role="button"
						tabIndex={0}
						onClick={() => setData({ ...data, enabled: !data.enabled })}
					>
						<div style={{ width: 30 }}>
							<CheckBox
								value={data.enabled}
								error={error && !data.enabled && !hasEnabledPricing}
							/>
						</div>
						<div>{name}</div>
					</div>
				</td>
			)}
		</tr>
	)
}

const PricingPrestationOptions = ({ optionsForm }) => {
	const rootRef = useRef(null)

	return (
		<div ref={rootRef}>
			{optionsForm.length > 0 && (
				<table>
					<thead>
						<tr>
							<th>
								Nom de l'option
								<InfoTooltip>
									Renseignez un titre clair. Par exemple : Panier repas pour 2
									personnes.
								</InfoTooltip>
							</th>
							<th
								style={{
									minWidth: 140
								}}
							>
								TVA associée
								<InfoTooltip>
									Cette valeur nous sert uniquement à vous fournir un
									récapitulatif de chiffre d’affaires sur le mois. Nous
									calculons automatiquement votre TVA, pour vous simplifier les
									choses.
								</InfoTooltip>
							</th>
							<th>
								Prix de vente
								<InfoTooltip>
									C’est le prix TTC affiché pour les clients. Nos CGUVP
									interdisent de mettre des prix plus importants que ceux que
									vous pratiquez ailleurs.
								</InfoTooltip>
							</th>
							<th colSpan={2}>
								Montant final reçu
								<InfoTooltip>
									C’est le montant que vous allez recevoir. Nous appliquons une
									commission de seulement 10%HT sur les réservations pour
									couvrir les frais bancaires et nos frais de fonctionnement.
									Environ la moitié de cette commission est redonnée au
									territoire pour aider à son développement.
								</InfoTooltip>
							</th>
						</tr>
					</thead>
					<tbody>
						{optionsForm.map((option, i, id) => (
							<PricingPrestationOption option={option} key={id} />
						))}
					</tbody>
				</table>
			)}

			<Button
				theme="underline-action"
				style={{
					marginTop: 4
				}}
				onClick={() => {
					optionsForm.push()

					// Can't find a better solution, for the moment.
					setTimeout(() => {
						try {
							const list = [
								...rootRef.current.querySelectorAll(
									'.pricing-prestation-option'
								)
							]

							if (list.length === 0) return

							list
								.slice(-1)[0]
								.querySelector('.name')
								.focus()
						} catch (err) {
							console.error(err)
						}
					}, 150)
				}}
			>
				+ Ajouter une option supplémentaire
			</Button>
			<span style={{ marginLeft: 4 }}>
				(ex : audio-guide, siège enfant, panier repas)
			</span>
		</div>
	)
}

const Quota = ({ pricingsForm }) => {
	const inputRef = useRef(null)

	return (
		<FormLine>
			<div>Ajouter un quota de place max. sur cette prestation</div>
			<InfoTooltip
				style={{
					marginLeft: 10
				}}
			>
				Si vous cochez oui, vous définirez un nombre maximum de réservations
				possibles simultanément.
			</InfoTooltip>
			<div>
				<Select
					buttonTitle={
						pricingsForm.get('schedule_type').value === 'time_duration'
							? 'Lorsque les billets sont vendus avec une durée de validité, vous ne pouvez pas mettre de quotas de place'
							: null
					}
					readOnly={pricingsForm.get('schedule_type').value === 'time_duration'}
					style={{
						width: 80
					}}
					{...pricingsForm.get('spots.set')}
					error={pricingsForm.get('spots.set').error}
					value={pricingsForm.get('spots.set').value}
					setValue={value => {
						pricingsForm.get('spots.set').setValue(value)

						if (value) {
							inputRef.current.focus()
						}
					}}
					options={[
						{
							label: 'Oui',
							value: true
						},
						{
							label: 'Non',
							value: false
						}
					]}
				/>
			</div>

			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					...(pricingsForm.get('spots.set').value
						? {}
						: {
								opacity: 0,
								pointerEvents: 'none'
						  })
				}}
			>
				<div>Nombre de place maximum</div>
				<InfoTooltip
					style={{
						marginLeft: 10
					}}
				>
					C’est le nombre de réservations maximum que vous prenez en même temps.
					Si votre prestation est disponible en plusieurs langues, ce quota sera
					valable pour chaque langue.
				</InfoTooltip>
				<div>
					<Input
						targetRef={inputRef}
						className="quota-input"
						error={pricingsForm.get('spots.max').error}
						value={pricingsForm.get('spots.max').value}
						setValue={value => {
							if (value.match(/[^0-9]/)) return

							pricingsForm.get('spots.max').setValue(value)
						}}
						placeholder="Ex : 15"
						style={{
							width: 70,
							marginLeft: 10
						}}
					/>
				</div>
			</div>

			{{ ...pricingsForm.get('spots.set') }.value && <></>}
		</FormLine>
	)
}

const PricingPrestation = ({
	pricingsForm,
	pricingsListForm,
	same_pricing,
	form,
	activitiesNames,
	addDeletedPricings
}) => {
	const { activitiesId } = useUserData()

	const [pricingsData, setPricingData] = useState(
		pricingsForm
			.get('pricings')
			.toJSON()
			.reduce((acc, cur, i) => {
				acc[cur.id] = cur
				return acc
			}, {})
	)

	const hasEnabledPricing =
		pricingsForm
			.get('pricings')
			.toJSON()
			.filter(({ enabled }) => enabled).length > 0

	const hasInclusions =
		form.get('activity.about.inclusions').value.enabled ===
		'prestation_dependent'

	const hasPlaceOfSupport =
		form.get('activity.accessibility.starting_location.enabled').value ===
		'prestation_dependent'

	const getPricingsList = () => {
		if (same_pricing) {
			return [
				{
					name: 'same',
					selling_price:
						Object.keys(pricingsData).length > 0
							? pricingsData[0]?.selling_price
							: null,
					id: 0,
					_id: 0
				}
			]
		}

		return pricingsListForm.toJSON().map(({ id, name }) => {
			if (!pricingsData[id])
				return {
					name,
					selling_price: '',
					enabled: true,
					id,
					_id: id
				}
			else {
				return {
					...pricingsData[id],
					name,
					id,
					_id: id
				}
			}
		})
	}

	useEffect(() => {
		if (same_pricing) {
			const _ = pricingsForm.get('pricings').toJSON()

			if (_.length > 0 && _[0].name === 'same') {
				setPricingData({
					'0': {
						..._[0],
						name: 'same'
					}
				})
			} else {
				setPricingData({
					'0': {
						name: null,
						selling_price: null,
						enabled: true
					}
				})
			}
		} else {
			setPricingData(
				pricingsForm
					.get('pricings')
					.toJSON()
					.reduce((acc, cur, i) => {
						if (pricingsListForm.toJSON().find(p => p.id === cur.id))
							acc[cur.id] = cur
						return acc
					}, {})
			)
		}
	}, [same_pricing])

	useEffect(() => {
		pricingsForm.get('pricings').set(getPricingsList())
	}, [pricingsData])

	return (
		<FormSection>
			{pricingsForm.canBeRemoved() && (
				<div
					style={{
						textAlign: 'right'
					}}
				>
					<Button
						onClick={() => {
							const json = pricingsForm.toJSON()

							if (json._id) {
								addDeletedPricings(json._id)
							}

							pricingsForm.remove()
						}}
						theme="no-style"
						className="delete-prestation-button"
						style={{
							textAlign: 'center'
						}}
					>
						<img
							src="/icons/delete-white.svg"
							style={{
								width: 16
							}}
						/>
					</Button>
				</div>
			)}

			<FormLine
				style={{
					alignItems: 'flex-start'
				}}
			>
				<div
					style={{
						width: 214 + 60
					}}
				>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							height: 24,
							paddingBottom: 10,
							boxSizing: 'content-box'
						}}
					>
						Nom de la prestation
						<InfoTooltip>
							Exemple pour un musée qui a 2 prestations différentes : Entrée
							coupe-file, Entrée avec guide. Exemple pour une excursion : Visite
							des coins insolites de Marseille ; Balade dans le centre
							historique de Marseille
						</InfoTooltip>
					</div>

					<div>
						<Input {...pricingsForm.get('name')} />
					</div>
				</div>

				<div>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							width: 140,
							height: 24,
							paddingBottom: 10,
							boxSizing: 'content-box'
						}}
					>
						TVA associée
						<InfoTooltip>
							Cette valeur nous sert uniquement à vous fournir un récapitulatif
							de chiffre d’affaires sur le mois. Nous calculons automatiquement
							votre TVA, pour vous simplifier les choses.
						</InfoTooltip>
					</div>

					<Select
						rootStyle={{ height: 45, width: 140 }}
						style={{ height: 46, width: 140 }}
						{...pricingsForm.get('tva')}
						options={[
							{
								label: '20%',
								value: 20
							},
							{
								label: '10%',
								value: 10
							},
							{
								label: '8.5%',
								value: 8.5
							},
							{
								label: '5.5%',
								value: 5.5
							},
							{
								label: '2.1%',
								value: 2.1
							},
							{
								label: (
									<div
										style={{
											whiteSpace: 'nowrap',
											overflow: 'hidden',
											textOverflow: 'ellipsis',
											width: '100%'
										}}
									>
										Je ne collecte pas de TVA
									</div>
								),
								value: 0
							}
						]}
						noWrap={true}
					/>
				</div>
				<div style={{ flex: 1 }}>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							height: 24,
							paddingBottom: 10,
							boxSizing: 'content-box'
						}}
					>
						Description (optionnel)
					</div>
					<AutoResizableTextarea
						{...pricingsForm.get('description')}
						paddingTop={12}
						paddingBottom={12}
					/>
				</div>
			</FormLine>

			<hr />

			{activitiesId.length > 1 && (
				<>
					<FormLine>
						Sur quelle(s) fiche(s) d’activité souhaitez-vous afficher cette
						prestation ?
						<InfoTooltip>
							La prestation apparaîtra sur chaque fiche d’activité que vous
							aurez coché.
						</InfoTooltip>
						<div>
							<MultipleSelect
								error={pricingsForm.get('activities').error}
								dropdownStyle={{
									width: 360
								}}
								style={{
									width: 248
								}}
								list={pricingsForm.get('activities').toJSON()}
								setValue={v => {
									pricingsForm.get('activities').set(v)
								}}
								label={
									pricingsForm.get('activities').toJSON().length === 0
										? 'Sélectionner des fiches'
										: `${
												pricingsForm.get('activities').toJSON().length
										  } activité${
												pricingsForm.get('activities').toJSON().length > 1
													? 's'
													: ''
										  }`
								}
								items={Object.keys(activitiesNames).map(id => ({
									value: id,
									name: activitiesNames[id].title
								}))}
							/>
						</div>
					</FormLine>

					<hr />
				</>
			)}

			<FormLine>
				<div>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							width: 260,
							height: 24,
							paddingBottom: 10,
							boxSizing: 'content-box'
						}}
					>
						Date associée à la réservation
						<InfoTooltip>
							Cette option vous permet de choisir si la réservation a une durée
							de validité ou si le visiteur doit choisir un jour ou un créneau
							particulier.{' '}
						</InfoTooltip>
					</div>
					<Select
						dropdownStyle={{
							maxHeight: 500
						}}
						rootStyle={{ height: 45, width: 260 }}
						style={{ height: 45, width: 260 }}
						dropdownHorizontalPosition={'right'}
						error={pricingsForm.get('schedule_type').error}
						value={pricingsForm.get('schedule_type').value}
						setValue={value => {
							if (value === 'time_duration') {
								const _data = {
									...pricingsForm.toJSON(),
									schedule_type: value,
									cancellation_type: 'none',
									cancellation_weather: false,
									spots: {
										set: false
									}
								}

								pricingsForm.set(_data)
							} else {
								pricingsForm.get('schedule_type').setValue(value)
							}
						}}
						options={[
							{
								label: (
									<div style={{ width: 340 }}>
										<div>Durée de validité</div>
									</div>
								),
								value: 'time_duration',
								details: (
									<div>
										Le visiteur achète un billet valable sur une durée à définir
										par vos soins. Il consultera vos horaires d’ouverture pour
										savoir quand venir.
									</div>
								)
							},
							{
								label: <div>Choix d’un jour</div>,
								value: 'opening_time',
								details: (
									<div>
										Lors de sa réservation, le visiteur choisit un jour parmi
										vos jours d’ouverture.
									</div>
								)
							},
							{
								label: <div>Choix d’un créneau spécifique</div>,
								value: 'time_slots',
								details: (
									<div>
										Lors de sa réservation, le visiteur doit choisir un créneau
										particulier parmi ceux renseignés (ex : lundi 12 septembre à
										10:30).
									</div>
								)
							}
						]}
						noWrap={true}
					/>
				</div>

				{pricingsForm.get('schedule_type').value === 'time_duration' && (
					<div>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								height: 24,
								paddingBottom: 10,
								boxSizing: 'content-box'
							}}
						>
							Durée validité
							<InfoTooltip>
								C’est la durée pendant laquelle le visiteur peut venir utiliser
								son bon d’achat à partir du moment où il a effectué sa
								réservation.
							</InfoTooltip>
						</div>

						<Select
							rootStyle={{ height: 45 }}
							style={{ height: 45, width: '100%' }}
							dropdownHorizontalPosition="right"
							{...pricingsForm.get('time_limit')}
							options={[
								{
									label: <div style={{ width: 70 }}>1 mois</div>,
									value: '1'
								},
								{
									label: '3 mois',
									value: '3'
								},
								{
									label: '6 mois',
									value: '6'
								},
								{
									label: '1 an',
									value: '12'
								}
							]}
						/>
					</div>
				)}

				<div>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							width: 202,
							height: 24,
							paddingBottom: 10,
							boxSizing: 'content-box'
						}}
					>
						Politique d’annulation
						<InfoTooltip>
							Les visiteurs attendent de plus en plus de flexibilité, c’est
							pourquoi nous recommandons d’appliquer les conditions d’annulation
							standard.
						</InfoTooltip>
					</div>

					<Select
						rootStyle={{ height: 45, width: 202 }}
						style={{ height: 45, width: 202 }}
						dropdownStyle={{
							maxHeight: 500
						}}
						{...pricingsForm.get('cancellation_type')}
						buttonTitle={
							pricingsForm.get('schedule_type').value === 'time_duration'
								? 'Lorsque les billets sont vendus avec une durée de validité, l’annulation par les visiteurs n’est pas possible.'
								: null
						}
						readOnly={
							pricingsForm.get('schedule_type').value === 'time_duration'
						}
						options={[
							{
								label: 'Standard (recommandé)',
								value: 'standard',
								details: (
									<div
										style={{
											width: 370
										}}
									>
										Un client qui annule peut bénéficier d’un remboursement
										intégral jusqu’à 24h avant le début de l’activité. À moins
										de 24h, aucun remboursement ne sera accordé.
									</div>
								)
							},
							{
								label: 'Flexible',
								value: 'partial',
								details: (
									<div>
										Remboursement intégral jusqu’à 4j avant le début de
										l’activité, remboursement de 50% entre 4j et 2j avant le
										début de l’activité et non remboursable à moins de 2j.
									</div>
								)
							},
							{
								label: 'Non remboursable',
								value: 'none',
								details: <div>Aucun remboursement possible.</div>
							}
						]}
						noWrap={true}
					/>
				</div>

				<div>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							width: 247,
							height: 24,
							paddingBottom: 10,
							boxSizing: 'content-box'
						}}
					>
						Confirmation de réservation
						<InfoTooltip>
							Nous vous conseillons vivement d’opter pour une confirmation
							instantanée. Les clients sont plus réticents à réserver lorsqu’ils
							doivent attendre une confirmation.
						</InfoTooltip>
					</div>

					<Select
						rootStyle={{ height: 45, width: 247 }}
						style={{ height: 45, width: 247 }}
						{...pricingsForm.get('confirmation_method')}
						options={[
							{
								label: (
									<div style={{ width: 370 }}>Instantanée (recommandé)</div>
								),

								details:
									'La réservation du client est automatiquement acceptée',
								value: 'instant'
							},
							{
								label: 'Manuelle',
								details: (
									<span>
										Vous devez valider chaque réservation. Passé 48h sans
										réponse, la réservation est automatiquement annulée. Un
										client peut annuler une réservation tant que vous ne l’avez
										pas validée.
									</span>
								),
								value: 'manual'
							}
						]}
						noWrap={true}
					/>
				</div>
			</FormLine>

			{pricingsForm.get('schedule_type').value === 'time_slots' && (
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<InformationIcon />
					<div style={{ paddingLeft: 10 }}>
						Vous renseignerez ces créneaux sur la Page 5 “Créneaux”
					</div>
				</div>
			)}

			<hr />

			<Quota pricingsForm={pricingsForm} />

			<hr />
			<FormLine>
				Annulation / report possible en cas de mauvais temps (facultatif)
				<InfoTooltip>
					Cette option peut être utile à des activités pour lesquelles le beau
					temps est nécessaire. En cas de conditions météos défavorables, vous
					proposerez au visiteur de reporter l’activité. Et si vous n’arrivez
					pas à vous mettre d’accord sur un créneau, il vous suffira d’annuler
					sa réservation et le visiteur sera remboursé.
				</InfoTooltip>
				<div>
					<Select
						style={{
							width: 108
						}}
						readOnly={
							pricingsForm.get('schedule_type').value === 'time_duration'
						}
						{...pricingsForm.get('cancellation_weather')}
						options={[
							{
								label: 'Oui',
								value: true
							},
							{
								label: 'Non',
								value: false
							}
						]}
					/>
				</div>
			</FormLine>

			{hasInclusions && (
				<>
					<hr />
					<div
						style={{
							marginBottom: 12
						}}
					>
						Voulez-vous préciser ce qui est inclus / non inclus dans la
						prestation ?
					</div>
					<FormInclusions inclusionsForm={pricingsForm.get('inclusions')} />
				</>
			)}

			{hasPlaceOfSupport && (
				<>
					<hr />
					<div
						style={{
							marginBottom: 12
						}}
					>
						Voulez-vous préciser un lieu de prise en charge particulier ?
					</div>
					<FormPlaceOfSupport
						startingLocationForm={pricingsForm.get('starting_location')}
					/>
				</>
			)}

			<hr />

			<FormLine>
				Le visiteur doit-il choisir une langue lors de sa réservation
				<InfoTooltip>
					Le client devra choisir une langue pour valider sa réservation. Cette
					option peut par exemple être activée dans le cas d’une visite proposée
					en plusieurs langues, par différents guides.
				</InfoTooltip>
				<div>
					<Select
						style={{
							width: 120
						}}
						{...pricingsForm.get('lang.force')}
						options={[
							{
								label: 'Oui',
								value: true
							},
							{
								label: 'Non',
								value: false
							}
						]}
					/>
				</div>
				{pricingsForm.get('lang.force').value && (
					<MultipleSelect
						style={{
							width: 120
						}}
						error={pricingsForm.get('lang.selection').error}
						list={pricingsForm.get('lang.selection').map(item => item.value)}
						setValue={v => {
							pricingsForm.get('lang.selection').set(v)
						}}
						items={langData}
						label={
							pricingsForm.get('lang.selection').length === 0
								? 'Langue'
								: `${pricingsForm.get('lang.selection').length} langue${
										pricingsForm.get('lang.selection').length > 1 ? 's' : ''
								  }`
						}
					/>
				)}
			</FormLine>
			<hr />

			<table>
				<thead>
					<tr>
						<th style={{ maxWidth: 150 }}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center'
								}}
							>
								Prix de vente TTC
								<InfoTooltip>
									C’est le prix TTC affiché pour les clients. Nos CGUVP
									interdisent de mettre des prix plus importants que ceux que
									vous pratiquez ailleurs.
								</InfoTooltip>
							</div>
						</th>
						<th style={{ maxWidth: 150 }}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center'
								}}
							>
								Montant final reçu
								<InfoTooltip>
									C’est le montant que vous allez recevoir. Nous appliquons une
									commission de seulement 10%HT sur les réservations pour
									couvrir les frais bancaires et nos frais de fonctionnement.
									Environ la moitié de cette commission est redonnée au
									territoire pour aider à son développement.
								</InfoTooltip>
							</div>
						</th>
						<th></th>
					</tr>
				</thead>

				<tbody>
					{!same_pricing ? (
						pricingsListForm.map((item, i, id) => (
							<PricingLine
								id={item.get('id').value}
								key={item.get('id').value}
								onChange={v => {
									setPricingData({
										...pricingsData,
										[item.get('id').value]: {
											...v,
											name: item.value
										}
									})
								}}
								hasEnabledPricing={hasEnabledPricing}
								error={pricingsForm.get('pricings').error ? true : false}
								item={pricingsData[item.get('id').value] || { enabled: true }}
								name={item.get('name').value}
							/>
						))
					) : (
						<PricingLine
							onChange={v => {
								setPricingData({
									[0]: {
										...v,
										name: 'same'
									}
								})
							}}
							hasEnabledPricing={hasEnabledPricing}
							error={pricingsForm.get('pricings').error ? true : false}
							item={pricingsData[0] || { enabled: true }}
							name={null}
						/>
					)}
				</tbody>
			</table>

			<hr />

			<PricingPrestationOptions optionsForm={pricingsForm.get('options')} />
		</FormSection>
	)
}
export default PricingPrestation
