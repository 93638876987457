import React from 'react'
import { FormLine, Select } from '~components/Ticketing/FormToolbox'
import InfoTooltip from '~components/basics/InfoTooltip'
import FormPlaceOfSupport from '~components/Ticketing/Common/FormPlaceOfSupport'

const BookingPlaceOfSupport = ({ startingLocationForm }) => {
	const enabled = startingLocationForm.get('enabled').value === 'yes'

	return (
		<>
			<FormLine>
				Voulez-vous préciser un lieu de prise en charge
				<InfoTooltip>
					Cette option concerne surtout des activités qui n’ont pas de lieu
					physique, comme par exemple un guide proposant des balades.
				</InfoTooltip>
				<div>
					<Select
						style={{
							width: 270
						}}
						{...startingLocationForm.get('enabled')}
						options={[
							{
								label: 'Non',
								value: 'no'
							},
							{
								label: 'Cela dépend des prestations',
								value: 'prestation_dependent'
							},
							{
								label: 'Oui',
								value: 'yes'
							}
						]}
					/>
				</div>
			</FormLine>

			<div
				style={{
					position: enabled ? 'static' : 'absolute',
					opacity: enabled ? 1 : 0,
					pointerEvents: enabled ? 'all' : 'none'
				}}
			>
				<FormPlaceOfSupport
					enabled={enabled}
					startingLocationForm={startingLocationForm}
				/>
			</div>
		</>
	)
}

export default BookingPlaceOfSupport
