import React, { useRef } from 'react'
import useToggle from '~hooks/useToggle'
import Button from '~components/basics/Button'
import { Textarea } from '~components/Ticketing/FormToolbox'

const FunFact = ({ storyField }) => {
	const [isOpen, toggleIsOpen] = useToggle(storyField.value.length > 0)
	const inputRef = useRef(null)

	const handleOpen = () => {
		toggleIsOpen()
		inputRef.current.focus()
	}

	return (
		<>
			<Button theme="underline-action" onClick={handleOpen}>
				+ Une anecdote à partager ou un mot pour redonner le sourire aux
				visiteurs ?
			</Button>{' '}
			(optionnel)
			<div
				style={{
					paddingTop: 8,
					opacity: isOpen ? 1 : 0,
					pointerEvents: isOpen ? 'all' : 'none',
					position: isOpen ? 'static' : 'absolute'
				}}
			>
				<Textarea
					targetRef={inputRef}
					placeholder="Écrivez votre anecdote"
					{...storyField}
					id="anecdote-textarea"
				/>
			</div>
		</>
	)
}

export default FunFact
