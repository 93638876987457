import React from 'react'
import Modal from '~components/basics/Modal/Modal'

import Spinner from '~basics/Icons/Spinner'

import './ClearSessionModal.scss'

const ClearSessionModal = ({ isOpen }) => {
	if (!isOpen) return null

	return (
		<div
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				zIndex: 1000000
			}}
		>
			<div
				style={{
					backgroundColor: '#1c1e38',
					opacity: 0.7,
					position: 'absolute',
					top: 0,
					left: 0,
					right: 0,
					bottom: 0
				}}
			/>
			<div
				style={{
					backgroundColor: 'white',
					padding: 40,
					textAlign: 'center',
					position: 'relative',
					zIndex: 1
				}}
			>
				<p>
					Par mesure de sécurité, nous allons vous déconnecter.
					<br />
					Il vous suffit de vous re-connecter.
				</p>

				<Spinner color="dark" />
			</div>
		</div>
	)
}
export default ClearSessionModal
