const isBrowser = () => typeof window !== 'undefined'

export const log = (obj, clear = false, isMapped) => {
	if (clear) {
		console.clear()
	}

	if (isMapped) {
		console.group(isMapped)
		obj.map(o => console.log(o))
		console.groupEnd(isMapped)
	} else if (Array.isArray(obj)) {
		console.log('%c User Log', 'font-size: 19px; color: #e65264')
		console.table(obj)
	} else if (typeof obj === 'object') {
		console.log('%c User Log', 'font-size: 19px; color: #e65264')
		console.log(obj)
	} else {
		console.log('%c User Log', 'font-size: 19px; color: #e65264')
		console.log(`%c ${obj}`, 'font-size: 14px; color: white')
	}
}

export const getLocalstorageJSON = key => {
	if (!isBrowser()) return null;
	const data = localStorage.getItem(key)

	if (!data) return false

	try {
		return JSON.parse(data)
	} catch {
		return null
	}
}

export const cleanGraphqlResult = data => {
	const _ = JSON.parse(JSON.stringify(data))

	const recursiveClean = _ => {
		if (Array.isArray(_)) {
			_.forEach(recursiveClean)
		} else if (typeof _ === 'object') {
			if (_.__typename) {
				delete _.__typename
			}
			Object.values(_)
				.filter(_ => !!_ && typeof _ === 'object')
				.forEach(sub => {
					recursiveClean(sub)
				})
		}
	}

	recursiveClean(_)

	return _
}

export const scrollToFirstError = () => {
	setTimeout(() => {
		if (!document.querySelector('.error')) return
		const firstErrorElementY = document
			.querySelector('.error')
			.getBoundingClientRect().top

		scrollTo(
			firstErrorElementY -
				70 +
				document.querySelector('.form-content')?.scrollTop
		)
	}, 200)
}

export const scrollTo = top => {
	if (document.querySelector('.form-content')?.scrollTo) {
		document.querySelector('.form-content')?.scrollTo({
			top,
			behavior: 'smooth'
		})
	} else {
		const elt = document.querySelector('.form-content');
		if (elt) elt.scrollTop = `${top}px`
	}
}

export const removeIds = object => {
	const recursiveRemoveIds = o => {
		if (Array.isArray(o)) {
			o.forEach(recursiveRemoveIds)
		} else if (typeof o === 'object') {
			delete o._id

			Object.values(o).forEach(recursiveRemoveIds)
		}
	}

	const _object = JSON.parse(JSON.stringify(object))
	return _object
}

export const getUrlParams = () => {
	if (!isBrowser()) return {}

	return document.location.search
		.substr(1)
		.split('&')
		.filter((param) => !!param)
		.map((param) => {
			const split = [...param.split('='), null]

			return {
				[split[0]]: split[1]
			}
		})
		.reduce(
			(acc, cur) => ({
				...acc,
				...cur
			}),
			{}
		)
}

export function isEmail(email) {
	if (!email) return false;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
}