export const getBackendUser = async (apiUrl, token, userId) => {
  const myHeaders = new Headers()
  myHeaders.append('x-access-token', token)

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }
  let res = await fetch(apiUrl + '/backend_users/' + userId, requestOptions)
  let data = await res.json()
  return data
}

export const getUserActivitiesApidaeForm = async (apiUrl, token) => {
  const myHeaders = new Headers()
  myHeaders.append('x-access-token', token)

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }
  let res = await fetch(process.env.GATSBY_APIV2_URL + '/activities-apidae-forms', requestOptions)
  let data = await res.json()
  return data
}

export const getActivityApidaeFormUrl = async (url, token) => {
  // const myHeaders = new Headers()
  // myHeaders.append('x-access-token', token)

  const requestOptions = {
    method: 'GET',
    // headers: myHeaders,
    redirect: 'follow',
  }
  let res = await fetch(url, requestOptions)
  let data = await res.json()
  return data.url
}

export const getActivityApidaeForm = async (url, token) => {
  // const myHeaders = new Headers()
  // myHeaders.append('x-access-token', token)

  const requestOptions = {
    method: 'GET',
    // headers: myHeaders,
    body: { url },
    redirect: 'follow',
  }
  let res = await fetch(process.env.GATSBY_APIV2_URL + '/fetch-form', requestOptions)
  let data = await res.json()
  return data
}

export const putRib = async (data, apiUrl, token, userId) => {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Accept', 'application/json, text/plain, */*')
  myHeaders.append('x-access-token', token)

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: 'follow',
  }

  let res = await fetch(apiUrl + '/backend_users/' + userId, requestOptions)
  let freshData = await res.json()
  return freshData
}

export const sendRecoveryMail = async (API_KEY, data) => {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('accept', 'application/json')
  myHeaders.append('api-key', API_KEY)

  // const urlencoded = new URLSearchParams()
  // urlencoded.append("new_mail", new_mail)
  // urlencoded.append("new_password", new_password)

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: 'follow',
  }

  let res = await fetch(
    'https://api.sendinblue.com/v3/smtp/email',
    requestOptions
  )
  let resp = await res.json()
  return resp
}

export const forgotMail = async (apiUrl, mail) => {
  const myHeaders = new Headers()

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }

  let res = await fetch(
    apiUrl + '/backend_users/forgot/' + mail,
    requestOptions
  )
  let data = await res.json()
  return data
}

export const recoverUser = async (apiUrl, data) => {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('accept', 'application/json')

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: 'follow',
  }

  let res = await fetch(apiUrl + '/backend_users/recover', requestOptions)
  let resp = await res.json()
  return resp
}
