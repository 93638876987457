import React, { useState, useEffect } from 'react'

import Cgu from './Cgu'
import { CheckBox } from './FormToolbox'
import Button from '~components/basics/Button'
import { useUserData } from '~contexts/userDataContext'
import {
	saveTicketingEnabled,
	saveCurrentStep,
	saveFormDone
} from './Common/ticketingCommonQueries'

//
import { useFormHandler } from '~contexts/currentFormContext'

import './Validate.scss'

const Validate = ({ handleChangeCurrentStep }) => {
	const [cguDisplay, setCguDisplay] = useState(false)

	const { apiUrl, token, activitiesId } = useUserData()

	const [accepted, setAccepted] = useState(false)
	const [hasError, setHasError] = useState(false)

	useFormHandler(
		{
			modified: false,
			isValid: accepted,
			save: () => {
				return accepted
			},
			checkErrors: () => {
				if (accepted) return true

				setHasError(true)
				return false
			}
		},
		() => {
			return accepted
		}
	)

	useEffect(() => {
		saveCurrentStep(5, activitiesId, token, apiUrl)
	}, [])

	const handleSubmit = async e => {
		e.preventDefault()

		if (accepted) {
			await saveCurrentStep(1, activitiesId, token, apiUrl)
			await saveTicketingEnabled(activitiesId, true, token, apiUrl)
			await saveFormDone(activitiesId, true, token, apiUrl)
			dispatch({ type: 'step', payload: '+' })
		} else {
			setHasError(true)
		}
	}

	return (
		<>
			<form
				className={`validate-container`}
				id="ticketing-5"
				onSubmit={handleSubmit}
			>
				<Cgu closer={() => setCguDisplay(false)} display={cguDisplay} />

				<div className="validate">
					<h1>Vous y êtes presque ! Il ne vous reste plus qu’à valider.</h1>

					<div className="accept-line">
						<div className="checkbox-container">
							<CheckBox
								className={hasError ? 'error' : ''}
								value={accepted}
								setValue={value => {
									setHasError(false)
									setAccepted(value)
								}}
							/>
						</div>

						<span>
							<span
								className={`text`}
								style={{
									cursor: 'pointer'
								}}
								onClick={() => {
									setHasError(false)
									setAccepted(!accepted)
								}}
							>
								J’ai lu et j’accepte les
							</span>{' '}
							<a
								href="https://www.coq-trotteur.com/cguvp"
								target="_blank"
								rel="noopener noreferer"
							>
								CGUVP
							</a>
						</span>

						{hasError && (
							<div className="error-message">Vous devez accepter les CGUVP</div>
						)}
					</div>

					<div>
						<Button
							roundish="roundish"
							size="large"
							theme="yellow"
							type="button"
							className="validate-button"
							onClick={() => {
								if (accepted) handleChangeCurrentStep(7)
								else {
									setHasError(true)
								}
							}}
						>
							Valider
						</Button>
					</div>
				</div>
			</form>
		</>
	)
}

export default Validate
