import React, { useEffect, useRef, useState } from 'react'
import { Link, navigate, useStaticQuery, graphql } from 'gatsby'

import OnBoardingScreen from '~components/OnBoarding'

import { useUserData } from '~contexts/userDataContext'
import { useBooking } from '~contexts/bookingContext'
import { usePricings } from '~contexts/pricingsContext'
import { useHorairesContext } from '~horairesMod/context/horaires'

import BigLogo from '~svg/bigLogo.svg'
import ResaIcon from '~svg/reservation.svg'
import FicheIcon from '~svg/fiche.svg'
import ShopIcon from '~svg/shop.svg'
import MyWebsiteIcon from '~svg/mywebsite.svg'
import ComptabiliteIcon from '~svg/comptabilite.svg'

import Button from '~components/basics/Button'
import useToggle from '~hooks/useToggle'
import HelpPanel from '~components/HelpPanel'
import { SuperLink } from '~contexts/currentFormContext'

import Dropdown from '~components/basics/Dropdown/Dropdown'
import BurgerIcon from '~components/svg/BurgerIcon'

import './DashboardTemplate.scss'

const NavigationItem = ({ children, to, active }) => (
	<SuperLink to={to} className={`item ${active ? 'active' : ''}`}>
		{children}
	</SuperLink>
)

const OptionMenu = ({ handleLogout }) => {
	const [isOpen, toggleIsOpen] = useToggle(false)
	const rootRef = useRef(null)
	const [helpPanelIsOpen, toggleHelpPanelIsOpen] = useToggle(false)

	useEffect(() => {
		const clickOutsideEvent = event => {
			if (rootRef.current && !rootRef.current.contains(event.target))
				toggleIsOpen()
		}

		if (isOpen) {
			window.addEventListener('click', clickOutsideEvent)

			return () => {
				window.removeEventListener('click', clickOutsideEvent)
			}
		}
	}, [isOpen, toggleIsOpen])

	return (
		<>
			<HelpPanel
				isOpen={helpPanelIsOpen}
				toggleIsOpen={toggleHelpPanelIsOpen}
			/>
			<div className={`option-menu ${isOpen ? 'active' : ''}`}>
				<Button onClick={toggleIsOpen}>
					<img src="/icons/option-menu/cog.svg" /> Options / aide
				</Button>

				<div className="dropdown" ref={rootRef}>
					<button
						className="item"
						onClick={() => {
							toggleHelpPanelIsOpen()
							toggleIsOpen()
						}}
					>
						<div className="icon">
							<img src="/icons/option-menu/question.svg" />
						</div>
						<div>Foire aux questions</div>
					</button>
					<div className="item">
						<div className="icon">
							<img src="/icons/option-menu/information.svg" />
						</div>
						<div>
							Besoin d'aide ?{' '}
							<span style={{ fontWeight: 400 }}>
								Ecrivez à:{' '}
								<a href="mailto:aide@coq-trotteur.com">aide@coq-trotteur.com</a>
							</span>
						</div>
					</div>
					<button
						className="item"
						onClick={handleLogout}
						style={{ cursor: 'pointer' }}
					>
						<div className="icon">
							<img src="/icons/option-menu/logout.svg" />
						</div>
						<div>Déconnexion</div>
					</button>
				</div>
			</div>
		</>
	)
}

const AccountName = ({ activities }) => {
	const [activityListOpen, setActivityListOpen] = useState(false)

	const rootRef = useRef(null)

	useEffect(() => {
		const clickOutsideEvent = event => {
			if (rootRef.current && !rootRef.current.contains(event.target))
				setActivityListOpen(false)
		}

		if (activityListOpen) {
			window.addEventListener('click', clickOutsideEvent)

			return () => {
				window.removeEventListener('click', clickOutsideEvent)
			}
		}
	}, [activityListOpen, setActivityListOpen])

	return (
		<div className="account-name" ref={rootRef}>
			<button
				style={{}}
				onClick={() => {
					if (activities.length > 1) {
						setActivityListOpen(!activityListOpen)
					}
				}}
			>
				{activities.length === 1 ? (
					<a href={activities[0].url} target="_blank" rel="noopener noreferer">
						{activities[0].title}
					</a>
				) : (
					`${activities.length} fiches`
				)}
			</button>
			{activityListOpen && (
				<Dropdown
					style={{
						top: 'unset',
						bottom: 'calc(100% + 12px)',
						width: 300,
						left: 0,
						right: 'unset'
					}}
				>
					<div className="activity-list">
						{activities.map(activity => (
							<div key={activity.id} className="activity">
								<a href={activity.url} target="_blank" rel="noopener noreferer">
									{activity.title}
								</a>
							</div>
						))}
					</div>
				</Dropdown>
			)}
		</div>
	)
}

const DashboardTemplate = ({ children, currentPage }) => {
	// Context
	const {
		name,
		setLogged,
		apiUrl,
		token,
		dispatch,
		shareLink,
		onboarding
		// _onboarding
	} = useUserData()

	const {
		resetPeriods,
		resetClosingPeriods,
		resetOpeningPeriods
	} = useHorairesContext()

	const { setBookingData } = useBooking()
	const { setPricings } = usePricings()
	const [mobileIsOpen, setMobileIsOpen] = useState(false)

	// Query
	const data = useStaticQuery(graphql`
		{
			site {
				siteMetadata {
					mainMenu {
						title
						path
						icon
					}
				}
			}
		}
	`)

	// ***** Events handlers
	const handleLogout = () => {
		const _id = localStorage.getItem('userId')
		const myHeaders = new Headers()
		myHeaders.append('x-access-token', token)

		const requestOptions = {
			method: 'GET',
			redirect: 'follow'
		}

		fetch(apiUrl + '/auth/logout', requestOptions).then(() => {
			sessionStorage.clear()
			localStorage.clear()
			setPricings({ type: 'reset' })
			setBookingData?.({ type: 'reset' })
			dispatch({ type: 'reset' })
			resetPeriods()
			resetClosingPeriods()
			resetOpeningPeriods()
			setLogged(null)
			document.location.reload()
		})
	}

	const activities = shareLink ? Object.values(shareLink) : []

	return (
		<div className={`dashboard-template ${mobileIsOpen ? 'active' : ''}`}>
			<button
				className="burger"
				onClick={() => {
					setMobileIsOpen(!mobileIsOpen)
				}}
			>
				<BurgerIcon open={mobileIsOpen} />
			</button>

			<div
				className={`mobile-overlay ${mobileIsOpen ? 'active' : ''}`}
				onClick={() => {
					setMobileIsOpen(false)
				}}
			/>
			<div className="side-menu">
				<a className="brand">
					<BigLogo height={50} />
					<span>coq-trotteur</span>
				</a>
				<nav className={`navigation ${onboarding ? 'nav-disabled' : ''}`}>
					<div className="menu-el">
						<NavigationItem
							to="/reservations"
							active={currentPage === 'reservations' && !onboarding}
						>
							<div className="icon" style={{ marginTop: 3 }}>
								<ResaIcon />
							</div>
							Réservations
						</NavigationItem>
					</div>

					{/* {!_onboarding && ( */}
					<div className="menu-el">
						<NavigationItem
							to="/fiche"
							active={currentPage === 'fiche' && !onboarding}
						>
							<div className="icon">
								<FicheIcon />
							</div>
							Fiche
						</NavigationItem>
					</div>
					{/* )} */}

					<div className="menu-el">
						<NavigationItem
							to="/boutique"
							active={currentPage === 'boutique' && !onboarding}
						>
							<div className="icon">
								<ShopIcon />
							</div>
							Boutique
						</NavigationItem>
					</div>

					{[
						'5fb9cb6bedddc808dc3a715e',
						'5fbd9aeeeb218508dc66f93a',
						'6006e870870eda08fdaa7b91',
						'5eff4af48789b0084e1296a1',
						'5fb3f32dfd54c208dbfb6189',
						'5eec802afb3697069d55c751',
						'614c6f26921d8408800a6fd6',
						'5fca4f7bcd5a2c08e2388322',
						'5f03499194170e084d4845f3'
					].includes(localStorage.getItem('userId')) && (
						<div className="menu-el">
							<NavigationItem
								to="/votre-site-internet"
								active={currentPage === 'websites' && !onboarding}
							>
								<div
									className="icon"
									style={{
										margin: '-3px 10px -3px 1px'
									}}
								>
									<MyWebsiteIcon />
								</div>
								Votre site internet
							</NavigationItem>
						</div>
					)}

					<div className="menu-el">
						<NavigationItem
							to="/mouvements"
							active={currentPage === 'mouvements' && !onboarding}
						>
							<div
								className="icon"
								style={{
									margin: '0 9px 0 2px'
								}}
							>
								<ComptabiliteIcon />
							</div>
							Comptabilité
						</NavigationItem>
					</div>
				</nav>

				<div>
					<AccountName activities={activities} />
				</div>
				<OptionMenu handleLogout={handleLogout} />
			</div>
			<div className="content form-content">
				{onboarding ? <OnBoardingScreen /> : children}
			</div>
		</div>
	)
}
export default DashboardTemplate
