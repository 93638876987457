import { gql } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import Spinner from '~components/basics/Icons/Spinner'
import InfoTooltip from '~components/basics/InfoTooltip'
import { useUserData } from '~contexts/userDataContext'
import { doGraphqlQuery } from '~requests/common'

import './ReservationsEmptyHeader.scss'

type TUserStats = {
	totalSales: number
	uniqueViews: number
	views: number
}

const FormatNumber = ({ value }: { value: number }) => {
	const formatted = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
	return <span>{formatted}</span>
}

const loadStoredUserStats = () => {
	const key = `user-stats`

	const data = sessionStorage.getItem(key)

	if (!data) return null

	try {
		return JSON.parse(data)
	} catch {
		return null
	}
}

const ReservationsEmptyHeader = () => {
	const { token } = useUserData()
	const [userStats, setUserStats] = useState<TUserStats>(loadStoredUserStats())

	useEffect(() => {
		getUserStats(token)
			.then(userStats => {
				sessionStorage.setItem(`user-stats`, JSON.stringify(userStats))
				setUserStats(userStats)
			})
			.catch(() => {
				setUserStats(undefined)
			})
	}, [])

	return (
		<div className="ReservationsEmptyHeader">
			{userStats === undefined ? null : userStats ? (
				<div>
					<h1>
						<FormatNumber value={userStats.uniqueViews + userStats.views} />{' '}
						vues
						<InfoTooltip
							direction="bottom"
							style={{
								marginLeft: 8,
								height: 18,
								width: 18,
								transform: 'translateY(2px)'
							}}
						>
							Ce chiffre représente le nombre de fois où votre page / vos offres
							ont été vues, que ce soit sur la page des résultats, le moteur
							d’inspirations ou directement sur votre page.
						</InfoTooltip>
					</h1>
					<p>
						Autant de personnes qui vous ont découverts et viennent vous
						rencontrer au jour le jour :)
					</p>
				</div>
			) : (
				<div
					style={{
						backgroundColor: 'white',
						padding: 10
					}}
				>
					<Spinner color="dark" />
				</div>
			)}
		</div>
	)
}

const getUserStats = async (accessToken): Promise<TUserStats> => {
	const query = gql`
		query userStats {
			userStats {
				uniqueViews
				views
				ca
				totalSales
			}
		}
	`

	const result = await doGraphqlQuery({
		query,
		accessToken
	})

	return result.userStats
}

export default ReservationsEmptyHeader
