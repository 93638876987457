import React, { useEffect, useRef } from 'react'

// import CloudinaryImage from '~basics/CloudinaryImage'
// import { useDeviceDetection } from '~hooks/useDeviceDetection'

import useToggle from '~hooks/useToggle'
import Button from './basics/Button'

import './HelpPanel.scss'

const HelpPanelSection = ({ title, children }) => {
	const [isOpen, toggleIsOpen] = useToggle(false)

	return (
		<>
			<dt role="button" onClick={toggleIsOpen} tabIndex={0}>
				<div className="text">{title}</div>

				<div className="button-container">
					<Button
						theme="no-style"
						className="arrow-button"
						style={{
							cursor: 'pointer',
							border: 0,
							backgroundColor: 'transparent',
							height: 28,
							boxSizing: 'border-box',
							padding: 0
						}}
						className={`close-button ${isOpen ? 'active' : ''}`}
					>
						<img src="/icons/option-menu/fleche-bas.svg" />
					</Button>
				</div>
			</dt>
			<dd>{isOpen && children}</dd>
		</>
	)
}

const HelpPanel = ({ isOpen, toggleIsOpen }) => {
	const selectRef = useRef(null)
	useEffect(() => {
		if (isOpen) {
			document.body.classList.add('no-scroll')

			return () => {
				document.body.classList.remove('no-scroll')
			}
		}
	}, [isOpen])

	useEffect(() => {
		const clickOutsideEvent = event => {
			if (selectRef.current && !selectRef.current.contains(event.target))
				toggleIsOpen()
		}

		if (isOpen) {
			window.addEventListener('click', clickOutsideEvent)

			return () => {
				window.removeEventListener('click', clickOutsideEvent)
			}
		}
	}, [isOpen, toggleIsOpen])

	// const { isMobile } = useDeviceDetection()

	const isMobile = false

	if (!isOpen) return null

	return (
		<div className="help-panel" ref={selectRef}>
			<header>
				{!isMobile && `Aide et questions`}
				<Button
					theme="no-style"
					className="close-button"
					onClick={toggleIsOpen}
				>
					<img src="/icons/cross.svg" style={{ height: 14 }} />
				</Button>
			</header>

			<div className="content">
				<dl className="faq">
					<HelpPanelSection title="Qu’est-ce que le service boutique va m’apporter ?">
						L’espace marché de Noël est une réponse au reconfinement. Il est
						conçu pour permettre à tous de continuer de vendre des prestations
						et des produits malgré la fermeture. Cela vous permettra d’avoir de
						la trésorerie, de répondre aux attentes de vos habitués et d’attirer
						de nouveaux visiteurs.
					</HelpPanelSection>

					<HelpPanelSection title="Comment ça marche ?">
						Le principe est simple : les utilisateurs découvrent l’ensemble de
						l’offre du territoire ( activités et produits) et commandent celle
						de leur choix. De notre côté, nous vous transférons chaque semaine
						l’argent des réservations.
					</HelpPanelSection>

					<HelpPanelSection title="Ce service est-il gratuit ?">
						Nous avons pris l’engagement fort de faire de cette action une
						démarche solidaire. La solution est entièrement gratuite pour vous.
						Le taux de commission sur les ventes est placé jusqu’à Noël à 5%,
						afin de couvrir les frais bancaires et une partie des frais de
						fonctionnement.
					</HelpPanelSection>

					<HelpPanelSection title="Comment suis-je au courant d’une commande ?">
						Vous recevrez un mail à chaque nouvelle commande. Dans votre espace
						“Réservations”, vous pourrez visualiser l’ensemble de vos commandes.
						Vous pouvez également spécifier que la commande est à archiver.
					</HelpPanelSection>

					<HelpPanelSection title="Quand suis-je payé ?">
						Nous vous faisons un virement toutes les semaines. Le virement coûte
						0,15€HT, soit le montant facturé par notre partenaire bancaire.
					</HelpPanelSection>

					<HelpPanelSection title="J‘ai déjà un système de réservation sur mon site, quelle est la différence avec vous ?">
						Ce sont deux choses bien différentes. Votre système de réservation
						permet uniquement de réserver votre activité. C’est à vous de faire
						venir la personne sur votre site internet. Notre service est
						complémentaire : il consiste à augmenter votre visibilité et à vous
						apporter des réservations supplémentaires. En plus de prendre la
						réservation, nous vous apportons les clients.
					</HelpPanelSection>

					<HelpPanelSection title="Le client peut-il annuler son achat ?">
						Cette action est une démarche de soutien. Nous ne permettons donc
						pas pour l’instant l’annulation d’un achat. A fortiori parce que de
						nombreux produits seront personnalisés (taille, couleur etc.). Dans
						le cas où vous souhaiteriez rembourser un achat, vous rembourserez
						le client et nous vous rembourserons les 5%.
					</HelpPanelSection>

					<HelpPanelSection title="Je suis un prestataire d’activité, un hébergeur, un cinéma, un musée etc… Ce service est-il également fait pour moi ?">
						Evidemment ! Un bon cadeau pour une nuit en amoureux avec pétales
						sur le lit, un billet pour une visite guidée à thème, un bon pour
						une demi journée de randonnée littéraire etc. Ce service permet de
						vendre des prestations sur une durée de validité que vous aurez
						choisie. Créez et vendez des prestations dès maintenant, les
						visiteurs pourront profiter de ce bon dès votre réouverture. Vous
						pouvez même être inventif sur vos offres…
					</HelpPanelSection>

					<HelpPanelSection title="Quelle est la démarche de coq-trotteur ?">
						Coq-trotteur est conçu avec les territoires comme une solution
						d’avenir. Nous fédérons l’écosystème local afin de promouvoir la
						destination et ses acteurs. Une solution puissante, qui apporte
						visibilité et revenus supplémentaires aux acteurs de la destination.
						Une solution vertueuse, à impact social et environnemental. Derrière
						cette plateforme se trouve également un enjeu de souveraineté. Celle
						de disposer d’une solution locale forte et de ne pas avoir à
						dépendre des GAFA.
					</HelpPanelSection>

					<HelpPanelSection title="Je vends des produits, comment le client récupère-t-il son achat ? ">
						Vous avez la liberté de proposer au choix le retrait sur place (vous
						pourrez préciser des indications) et / ou la livraison par voie
						postale (vous préciserez le service et le coût) et / ou la livraison
						par vos moyens. Vous pourrez modifier ce choix au jour le jour,
						notamment en fonction de l’évolution de la situation.
					</HelpPanelSection>

					<HelpPanelSection title="Je vends des services, comment les visiteurs vont-ils utiliser leur réservation ?">
						Après chaque réservation, l’acheteur et vous-même recevez un mail.
						lors de sa venue, il vous suffira de demander au visiteur son nom ou
						son n° de réservation. Sur votre plateforme d’administration, vous
						pourrez retrouver sa réservation en recherchant son nom ou son n° de
						commande. En un clic, vous pourrez retranscrire que le billet a été
						utilisé. Et même laisser une note interne, par exemple : si les 2
						autres personnes de la réservation ne sont pas encore arrivées.
					</HelpPanelSection>

					<HelpPanelSection title="Dois-je signer un contrat ?">
						Lors de la première connexion sur la boutique en ligne, ou à la fin
						du formulaire de billetterie, vous devrez accepter les conditions
						générales d’utilisation et de vente.
					</HelpPanelSection>

					<HelpPanelSection title="Est-ce que je peux vendre tous mes produits ? ">
						Le temps est court, privilégiez donc des produits de saison et vos
						incontournables. Vous pouvez aussi être créatif(ve) en proposant des
						paniers gourmands, box etc.
					</HelpPanelSection>

					<HelpPanelSection title="Comment se passe l’écriture comptable ?">
						Nous vous donnons chaque semaine un récapitulatif des ventes et
						réservations réalisées. Pour faciliter la gestion, nous émettons des
						factures en votre nom et pour votre compte (mandat de facturation).
					</HelpPanelSection>

					<HelpPanelSection title="Des questions ? Des problèmes pour remplir votre fiche ?">
						Aucun souci ! Notre équipe est là pour vous accompagner.
						Contactez-nous à{' '}
						<a href="mailto:aide@coq-trotteur.com">aide@coq-trotteur.com</a>.
						Nous vous répondrons dans les plus brefs délais.
					</HelpPanelSection>
				</dl>
			</div>
		</div>
	)
}
export default HelpPanel
