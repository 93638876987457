import React from 'react'

export const BackIcon = ({ height = 22, width = 37, color="grey"}) => {
    return (
        <svg width={width || height} height={height} viewBox="0 0 22 37" version="1.1">
		<g id="Group-4" transform="translate(0.5 0.5)">
			<path
				d="M19.783 19.7822C19.783 19.7822 4.30358 35.2611 4.30358 35.2611C3.3189 36.2463 1.72241 36.2463 0.73821 35.2611C-0.24607 34.2769 -0.24607 32.6804 0.73821 31.6962L13.0216 19.4132C13.8023 18.6326 13.8023 17.3669 13.0216 16.5863C9.52258 13.0874 0.738608 4.30372 0.738608 4.30372C-0.245672 3.31904 -0.245672 1.72279 0.738608 0.738509C1.72289 -0.24617 3.3193 -0.24617 4.30397 0.738509C4.30397 0.738509 19.7834 16.2177 19.7834 16.2177C20.2755 16.7101 20.5213 17.3547 20.5213 17.9997C20.5213 18.6449 20.2751 19.29 19.783 19.7822Z"
				transform="matrix(-1 0 0 1 20.52136 0)"
				id="Shape"
				fill={color}
				stroke="none"
			/>
		</g>
	</svg>
    )
}
