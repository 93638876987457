import { getActivities } from '../Common/ticketingCommonQueries'

const getQueryOptions = token => {
	const headers = new Headers()
	headers.append('x-access-token', token)

	return {
		method: 'GET',
		headers,
		redirect: 'follow'
	}
}

const getPostQueryOptions = (activity, token) => {
	const headers = new Headers()
	headers.append('x-access-token', token)
	headers.append('Content-Type', 'application/json')
	headers.append('Accept', 'application/json, text/plain, */*')

	return {
		method: 'POST',
		headers,
		body: JSON.stringify(activity),
		redirect: 'follow'
	}
}

export const getPricings = async (id, token, api) => {
	await fetch(`${api}/pricings/${id}`, getQueryOptions(token)).then(data =>
		data.json()
	)
}

export const getActivity = async (id, token, api) => {
	const activity = await fetch(
		`${api}/activities/${id}`,
		getQueryOptions(token)
	).then(data => data.json())

	return activity
}

export const createNewPricings = async (data, token, api) => {
	const result = await fetch(
		`${api}/pricings`,
		getPostQueryOptions(data, token)
	).then(data => {
		return data.json()
	})

	return result
}
export const saveActivityFree = async (activityID, free, token, api) => {
	if (typeof free !== 'boolean') {
		console.warn(`free should be a boolean but found '${free}'`)
		return
	}

	const activity = {
		// free: free,
		'pricings.enabled': !free
		// 'ticketing.enabled': !free
	}

	await fetch(
		`${api}/activities/step/${activityID}`,
		getPostQueryOptions(activity, token)
	).then(data => data.json())
}

export const saveActivitiesFree = async (activitiesId, free, token, api) => {
	for (let i = 0; i < activitiesId.length; i++) {
		await saveActivityFree(activitiesId[i], free, token, api)
	}
}

export const updatePricings = async (data, _id, token, api) => {
	return await fetch(
		`${api}/pricings/${_id}`,
		getPostQueryOptions(data, token)
	).then(data => {
		return data.json()
	})
}

/*
 * TODO : May be move some of the actions in transformPricingsDataFromForm
 */
export const savePricingsPageData = async (
	activity,
	activitiesId,
	token,
	api,
	deletedPricings
) => {
	// const origin_activity = await getActivity(activityID, token)

	const pricings_id = []
	const pricings = [...activity.pricings.pricings_table]

	// Update / Create pricings
	for (let i = 0; i < pricings.length; i++) {
		const pricing = pricings[i]

		if (!pricing._id || pricing._id.length === 0) {
			// delete pricing._id

			const returnedData = await createNewPricings(pricing, token, api)

			pricing._id = returnedData._id
		} else {
			const { _id, ..._pricing } = pricing

			await updatePricings(_pricing, _id, token, api)
		}
	}

	// Update activities
	// activity.pricings.pricings_table = pricings_id.filter(e => e !== null)

	for (let i = 0; i < activitiesId.length; i++) {
		const activityID = activitiesId[i]
		const pricings_table = []

		pricings.forEach(pricing => {
			if (pricing.activities.includes(activityID)) {
				pricings_table.push(pricing._id)
			}
		})

		await fetch(
			`${api}/activities/step/${activityID}`,
			getPostQueryOptions(
				{
					min_price: activity.min_price,
					pricings: {
						...activity.pricings,
						pricings_table
					}
				},
				token
			)
		).then(data => data.json())
	}

	// Delete pricings unused
	for (let i = 0; i < deletedPricings.length; i++) {
		const pricing_id = deletedPricings[i]

		// TODO
		// await deletePricing(api, token, pricing_id)
	}
}

export const getPricingsPageData = async (activitiesId, token, api) => {
	// const activities = []

	const activities = await getActivities(activitiesId, token, api)

	const activity = activities[0]

	const pricings = {
		...activity.pricings,
		pricings_table: []
	}

	activities.forEach(activity => {
		activity.pricings.pricings_table.forEach(p => {
			const find = pricings.pricings_table.find(({ _id }) => _id === p._id)

			if (find) {
				find.activities.push(activity._id)
			} else {
				pricings.pricings_table.push({
					...p,
					activities: [activity._id]
				})
			}
		})
	})
	return {
		activity: {
			...activity,
			pricings
		},
		activitiesNames: activities.reduce((acc, cur) => {
			acc[cur._id] = {
				title: cur.title
			}

			return acc
		}, {})
	}
}
