import React, { useState, useEffect, useRef } from 'react'
import {
	FormSection,
	Input,
	Select,
	FormLine,
	FormGrid,
	Textarea
} from '../FormToolbox'
import InfoTooltip from '~components/basics/InfoTooltip'
import Button from '~components/basics/Button'

const uuid = require('uuid')

const PricingsInformations = ({ pricingsForm }) => {
	const [showTextarea, setShowTextarea] = useState(
		pricingsForm.get('informations').value.length > 0
	)
	const inputRef = useRef(null)
	const toggleShowTextarea = () => {
		if (showTextarea) return

		setShowTextarea(!showTextarea)

		inputRef.current.focus()
	}

	useEffect(() => {
		if (!showTextarea) {
			pricingsForm.get('informations').setValue(null)
		}
	}, [showTextarea])

	return (
		<div>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Button theme="underline-action" onClick={toggleShowTextarea}>
					+ Ajouter des informations générales sur vos tarifs
				</Button>
				<span
					style={{
						marginLeft: 4
					}}
				>
					(optionnel)
				</span>
				<InfoTooltip>
					Cela peut vous être utile pour donner des informations générales. Par
					exemple, pour demander aux visiteurs de penser à prendre des
					justificatifs en cas de réduction.
				</InfoTooltip>
			</div>
			<div
				style={{
					marginTop: 10,
					opacity: showTextarea ? 1 : 0,
					pointerEvents: showTextarea ? 'all' : 'none',
					position: showTextarea ? 'static' : 'absolute'
				}}
			>
				<Textarea
					targetRef={inputRef}
					style={{ minHeight: 70 }}
					{...pricingsForm.get('informations')}
				/>
			</div>
		</div>
	)
}

const DeleteIcon = () => (
	<svg
		height="17px"
		viewBox="0 0 20 22"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title>Supprimer</title>
		<defs></defs>
		<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g
				id="5-bis"
				transform="translate(-1254.000000, -961.000000)"
				fill="#172B49"
				fillRule="nonzero"
			>
				<g id="Group-15" transform="translate(349.000000, 451.000000)">
					<g id="Group-10" transform="translate(0.000000, 29.000000)">
						<g id="Group-5">
							<g id="Group-3">
								<g id="Group-2" transform="translate(30.000000, 290.000000)">
									<g
										id="delete-(3)"
										transform="translate(875.000000, 191.000000)"
									>
										<g id="Group" transform="translate(0.000000, 3.179916)">
											<polygon
												id="Shape"
												points="14.0825941 4.20610879 12.4056904 4.14460251 12.0421339 14.0728033 13.7190377 14.1340586"
											></polygon>
											<rect
												id="Rectangle-path"
												x="9.16100418"
												y="4.17535565"
												width="1.67799163"
												height="9.92824268"
											></rect>
											<polygon
												id="Shape"
												points="7.95757322 14.0725523 7.59401674 4.14430962 5.91711297 4.20585774 6.2807113 14.1340586"
											></polygon>
											<path
												d="M0.0158577406,0.0362761506 L0.0158577406,1.71426778 L1.76435146,1.71426778 L3.15146444,17.4772385 C3.18949791,17.9104184 3.55225941,18.2426778 3.98711297,18.2426778 L15.9846025,18.2426778 C16.4194979,18.2426778 16.7825105,17.9101674 16.820251,17.4769456 L18.2074059,1.71426778 L19.9841423,1.71426778 L19.9841423,0.0362761506 L0.0158577406,0.0362761506 Z M15.2161088,16.5646862 L4.75539749,16.5646862 L3.44878661,1.71426778 L16.5230126,1.71426778 L15.2161088,16.5646862 Z"
												id="Shape"
											></path>
										</g>
										<path
											d="M12.7407531,0 L7.25924686,0 C6.48820084,0 5.8609205,0.627280335 5.8609205,1.39832636 L5.8609205,4.05518828 L7.53891213,4.05518828 L7.53891213,1.67799163 L12.4610879,1.67799163 L12.4610879,4.05518828 L14.1390795,4.05518828 L14.1390795,1.39832636 C14.1390795,0.627280335 13.5117992,0 12.7407531,0 Z"
											id="Shape"
										></path>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
)

const PricingsList = ({ pricingsListForm }) => {
	return (
		<div className="pricings-list">
			<hr />
			Écrivez le nom de vos différents tarifs :
			<FormGrid className="pricings-list-items">
				{pricingsListForm.map((pricings, i, id) => (
					<div className="pricings-list-item" key={id}>
						<div style={{ display: 'flex', flex: 1, position: 'relative' }}>
							<Input
								style={{
									flex: 1,
									boxSizing: 'border-box',
									width: '100%',
									padding: '0 0 0 12px',
									height: 44
								}}
								{...pricings.get('name')}
								key={i}
								placeholder={
									i === 0
										? 'Ex: Plein tarif'
										: i === 1
										? 'Ex: Tarif Jeune (-12 ans)'
										: ''
								}
							/>
						</div>
						{pricings.canBeRemoved() && (
							<div
								style={{
									width: 47
								}}
							>
								<Button
									className="remove-button"
									onClick={() => pricings.remove()}
								>
									<DeleteIcon />
								</Button>
							</div>
						)}
					</div>
				))}
				<Button
					theme="transparent"
					style={{ textAlign: 'left', color: '#66A3FF' }}
					onClick={() => {
						pricingsListForm.push({
							id: uuid.v4()
						})

						setTimeout(() => {
							document
								.querySelector('.pricings-list-items>:nth-last-child(2) input')
								?.focus()
						}, 300)
					}}
				>
					+ Ajouter un tarif
				</Button>
			</FormGrid>
		</div>
	)
}

const PricingsHeader = ({ form }) => {
	const same_pricing = form.get('pricings.same_pricing').value

	return (
		<FormSection
			style={{
				marginBottom: 40
			}}
		>
			<FormLine>
				Avez-vous un prix unique pour tout le monde
				<InfoTooltip>
					La prestation apparaîtra sur chaque fiche d’activité que vous aurez
					coché.
				</InfoTooltip>
				<div>
					<Select
						dropdownStyle={{
							width: 280
						}}
						style={{
							width: 250
						}}
						value={form.get('pricings.same_pricing').value}
						setValue={same_pricing => {
							if (form.get('pricings.same_pricing').value === same_pricing)
								return

							if (same_pricing) {
								const _ = form.get('pricings').toJSON()
								form.get('pricings').set({
									..._,
									pricings_list: [
										{
											name: ''
										},
										{
											name: ''
										}
									],
									pricings_table: _.pricings_table.map(p => {
										return {
											...p,
											pricings: _.pricings_list.map(({ id, name }) => {
												return {
													enabled: true,
													name,
													_id: id,
													id,
													selling_price: ''
												}
											})
										}
									}),
									same_pricing
								})
							} else {
								form.get('pricings').set({
									...form.get('pricings').toJSON(),
									pricings_list: [
										{
											name: ''
										}
									],
									same_pricing
								})
							}

							document.querySelector('.pricings-list-item input').focus()
						}}
						options={[
							{
								label: 'Oui, tarif unique',
								value: true
							},
							{
								label: 'Non, j’ai des tarifs différents',
								value: false
							}
						]}
					/>
				</div>
			</FormLine>

			<div
				style={{
					position: same_pricing ? 'absolute' : 'static',
					opacity: same_pricing ? 0 : 1,
					pointerEvents: same_pricing ? 'none' : 'all'
				}}
			>
				<PricingsList pricingsListForm={form.get('pricings.pricings_list')} />
			</div>

			<hr />

			<PricingsInformations pricingsForm={form.get('pricings')} />
		</FormSection>
	)
}
export default PricingsHeader
