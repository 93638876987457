import { useEffect, useState } from 'react'

import {
	TService,
	TProduct,
	TMarketItem,
	TMarketItemGeneric
} from '@coq-trotteur/coq-types'


import { TMarketService, TMarketProduct, TMarketItemType } from 'src/types/MarketItemType'
import { getUserMarketItems } from '~components/Market/queries/MarketItem.queries'
import { useUserData } from '~contexts/userDataContext'
import { useAppContext } from '~contexts/appContext/appContext'

export const isBrowser = () => typeof window !== 'undefined'

export const CopyToClipboard = (container, unselectAfter = false) => {
	if (!isBrowser()) return

	try {
		container.select()
		container.setSelectionRange(0, 1000)
		document.execCommand('copy')
		if (unselectAfter) container.setSelectionRange(0, 0)
	} catch (err) {
		console.log(err);	
	}
	
}

export const timeout = ms => new Promise(res => setTimeout(res, ms))

export const apiUrl = process.env.GATSBY_API_URL || ''

export const getCachedData = key => {
	const item = sessionStorage.getItem(key)

	if (item) {
		try {
			return JSON.parse(item)
		} catch { }
	}
	return null
}

export const generateNewMarketItem = (type: TMarketItemType): TMarketItem => (
	type === "product" ? generateNewProduct() : generateNewService()
)

const marketItemCommonFields: TMarketItemGeneric = {
	enabled: false,
	creation: { complete: false, currentStep: 0 },

	miniature: null,
	gallery: [],

	title: '',
	description: '',
	note: '',
	informations: {
		gender: 'unisex',
		moods: [],
		labels: [],
		who: []
	},
	about: null,
	tags: null,
	pricing: {
		unique: true
	},
	quotas: {
		enabled: false
	},
	options: [],
	reservation_policy: null,

	activities: [],
	category: null
}

const generateNewService = (): TService => ({
	...marketItemCommonFields,
	type: "service",
	languages: {
		enabled: false,
		values: []
	},
	private_group: {
		enabled: false,
		min_visitor_count: null,
		max_visitor_count: null
	},
	service_availability: {
		// TODO correct values
		type: null,
		global_closed_period: false,
		schedules: [],
		unique_schedule: true
	},
	service_duration: {
		days: null,
		hours: null,
		minutes: null
	},
	service_location: {
		address: {
			full_address: null,
			location: null,
		},
		note: '',
		enabled: false
	},
	time_limit: null,
})

const generateNewProduct = (): TProduct => {

	const delay = getCachedInteger('last-cancellationPolicy.delay')

	return ({
		...marketItemCommonFields,
		type: "product",
		delivery: {
			ownDelivery: {
				enabled: false,
				freeAfterOrderPrice: null,
				note: '',
				preparationDelay: null,
				price: null,
				pricing: null
			},
			shippingOptions: [],
			takeAway: {
				enabled: false,
				preparationDelay: null,
				note: ''
			}
		},
		giftPackage: {
			available: 'none',
			price: null
		},
		characteristics: {
			height: null,
			length: null,
			volume: null,
			weight: null,
			width: null
		},
		reservation_policy: {
			weather_cancellation: false,
			cancellationPolicy: {
				enabled: delay===null ? null : delay===0?false:true,
				delay: getCachedInteger('last-cancellationPolicy.delay') || null
			},
			refundPolicy: {
				enabled: getCachedBoolean('last-refundPolicy.enabled'),
				delay: getCachedInteger('last-refundPolicy.delay')
			},
			exchangePolicy: {
				enabled: getCachedBoolean('last-exchangePolicy.enabled'),
				delay: getCachedInteger('last-exchangePolicy.delay')
			}
		}
	})
}

const getCachedBoolean = (key) => {

	const find = localStorage.getItem(key)

	if (!find) return false

	return find === "true"
}


const getCachedInteger = (key) => {

	const find = localStorage.getItem(key)

	if (!find) return null

	return parseInt(find, 10)
}


export const useMarketScrollEffect = location => {
	useEffect(() => {
		const div = document.querySelector('.dashboard-template>.content')

		if (location.state?.loadLastPosition) {
			const lastPosition = sessionStorage.getItem('market-last-position')

			if (lastPosition) {
				try {
					div.scrollTo({
						top: JSON.parse(lastPosition) || 0
					})
				} catch { }
			}
		}

		const handleScroll = () => {
			sessionStorage.setItem(
				'market-last-position',
				JSON.stringify(div.scrollTop)
			)
		}

		div.addEventListener('scroll', handleScroll)
		return () => {
			div.removeEventListener('scroll', handleScroll)
		}
	}, [])
}

export const useMarketItems: TUseMarketItems = () => {
	const { userId, token } = useAppContext().auth

	const cachedDataKey = `user-market-items-${userId}`


	const [marketItems, setMarketItems] = useState<(TMarketProduct | TMarketService)[]>(
		getCachedData(cachedDataKey) || []
	)
	const [loading, setLoading] = useState(true)

	useEffect(() => {

		// console.log({userId, token})
		if(userId && token){
			
			getUserMarketItems(userId, token)
			.then(result => {
				sessionStorage.setItem(cachedDataKey, JSON.stringify(result))
				setMarketItems(result)
			})
			.catch(err => {
				console.log(err)
			}).finally(() => setLoading(false))
		}
	}, [userId, token])

	return [marketItems, setMarketItems, loading]
}

type TUseMarketItems = () => [
	marketItems: (TMarketProduct | TMarketService)[],
	setMarketItems: (marketItems: (TMarketProduct | TMarketService)[]) => void,
	loading: boolean
]

export const capitalize = (text: string) => `${text[0].toUpperCase()}${text.substr(1)}`

export const countArray = (n: number) => Array.from(new Array(n)).map((_, i) => i)

export const isFalse = (val: any): val is false => {
	if ([true, false].includes(val) && !val) return true
	return false
}