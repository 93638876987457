import { text_field, bool_field, number_field } from 'react-super-useform'

const uuid = require('uuid')

const dayHoursFieldValidation = (isPause = false) => (value, get) => {
	// const isClosed = get('parent.parent.options.closed').value
	// if (isClosed) return true

	// const has_pause = get('parent.parent.options.has_pause').value
	// if (isPause && !has_pause) return true
	// if (value === '') return false
	// return value > 0 && value < 24

	// TODO
	return true
}

const dayMinutesFieldValidation = (isPause = false) => (value, get) => {
	// const isClosed = get('parent.parent.options.closed').value
	// if (isClosed) return true
	// const has_pause = get('parent.parent.options.has_pause').value
	// if (isPause && !has_pause) return true
	// if (value === '') return false
	// return value >= 0 && value < 60
	// TODO
	return true
}

export const dayTimeSchema = {
	hours: text_field({ validation: dayHoursFieldValidation() }),
	minutes: text_field({ validation: dayMinutesFieldValidation() })
}

export default {
	activity: {
		pricings: {
			// closed_periods_type: { type: String, default: 'global' },
			pricings_table: {
				type: Array,
				min: 1,
				constrainMinMax: true,
				children: {
					name: text_field(),
					description: text_field({ required: false }),
					cancellation_type: text_field({ default: 'standard' }),
					cancellation_weather: bool_field({ default: false }),
					confirmation_method: text_field({ default: 'instant' }),
					schedule_type: text_field({ default: '' }),
					spots: {
						set: bool_field({ default: false }),
						max: number_field({
							validation: (value, get) => {
								if (!get('parent.set').value) return true

								return value > 0
							}
						})
					},

					schedules: {
						_id: text_field({ required: false }),
						same: bool_field(),
						list: {
							type: Array,
							children: {
								lang: text_field({ required: false }),
								unique_period: bool_field(),
								closed_periods: {
									type: Array,
									children: {
										start_date: text_field({
											validation: (value, get) => {
												if (
													get('parent.parent.parent').toJSON()
														.use_global_closed_periods
												)
													return true

												return !!value
											}
										}),
										end_date: text_field({
											validation: (value, get) => {
												if (
													get('parent.parent.parent').toJSON()
														.use_global_closed_periods
												)
													return true
												return !!value
											}
										})
									}
								},
								use_global_closed_periods: bool_field({ default: null }),
								periods: {
									type: Array,
									min: 1,
									constrainMinMax: true,
									children: {
										dates: {
											type: Array,
											children: {
												start_date: text_field({
													validation: (value, get) => {
														const same = get(
															'parent.parent.parent.parent.parent.parent.parent.same'
														).value

														if (same) {
															const currentLang = get(
																'parent.parent.parent.parent.parent'
															).toJSON().lang

															const schedule_list = get(
																'parent.parent.parent.parent.parent.parent'
															).toJSON()

															const currentIndex = schedule_list.findIndex(
																({ lang }) => lang === currentLang
															)

															if (currentIndex !== 0) return true
														}

														if (
															get('parent.parent.parent.parent.parent').toJSON()
																.unique_period === true
														)
															return true
														return value.length > 0
													}
												}),
												end_date: text_field({
													validation: (value, get) => {
														const same = get(
															'parent.parent.parent.parent.parent.parent.parent.same'
														).value

														if (same) {
															const currentLang = get(
																'parent.parent.parent.parent.parent'
															).toJSON().lang

															const schedule_list = get(
																'parent.parent.parent.parent.parent.parent'
															).toJSON()

															const currentIndex = schedule_list.findIndex(
																({ lang }) => lang === currentLang
															)

															if (currentIndex !== 0) return true
														}

														if (
															get('parent.parent.parent.parent.parent').toJSON()
																.unique_period === true
														)
															return true
														return value.length > 0
													}
												})
											},
											min: 1,
											constrainMinMax: true
										},

										// start_date: text_field(),
										// end_date: text_field(),
										global: bool_field(),
										days: {
											type: Array,
											min: 7,
											max: 7,
											constrainMinMax: true,
											// validation: (value, get) => {
											// 	console.log(value)
											// TODO check is empty. BECAREFUL, hidden periods (same lang schedule) should not be error checked
											// 	return false
											// },
											children: {
												name: text_field(),
												slots: {
													type: Array,
													max: 50,
													children: {
														start: dayTimeSchema
													}
												}
											},
											default: [
												{ name: 'monday' },
												{ name: 'tuesday' },
												{ name: 'wednesday' },
												{ name: 'thursday' },
												{ name: 'friday' },
												{ name: 'saturday' },
												{ name: 'sunday' }
												// { name: 'lundi' },
												// { name: 'mardi' },
												// { name: 'mercredi' },
												// { name: 'jeudi' },
												// { name: 'vendredi' },
												// { name: 'samedi' },
												// { name: 'dimanche' }
											]
										}
									}
								}
							}
						}
					},

					tva: number_field({
						default: 0
					}),
					inclusions: {
						has: {
							type: Array,
							children: text_field({ validation: () => true }),
							min: 1,
							max: 8,
							constrainMinMax: true
						},
						hasnt: {
							type: Array,
							children: text_field({ validation: () => true }),
							min: 1,
							max: 8,
							constrainMinMax: true
						}
					},
					starting_location: {
						enabled: bool_field({ required: false, default: true }),
						note: text_field({ required: false }),
						address: text_field({ required: false })
					},
					time_limit: number_field({ required: false }),
					lang: {
						force: bool_field(),
						selection: {
							type: Array,
							children: text_field()
						}
					},
					options: {
						type: Array,
						children: {
							name: text_field(),
							selling_price: number_field({
								default: null,
								validation: value => {
									return value !== null && value >= 0
								}
							}),
							tva: number_field()
						}
					},
					pricings: {
						type: Array,
						children: {
							name: text_field({ required: false }),
							selling_price: number_field({
								required: false,
								default: null,
								validation: (value, get) => {
									if (!get('parent.enabled').value) {
										return true
									}

									if (value === null || value === '') return false
									return value >= 0
								}
							}),
							enabled: bool_field({ default: true }),
							id: text_field({ required: false })
						}
					}
				}
			}
		}
	}
}
