import React from 'react'
import { FormLine, LabeledInput } from '~components/Ticketing/FormToolbox'

const BookingContact = ({ activityForm }) => (
	<FormLine>
		<div>
			<div>
				En cas de contact par un administrateur, non transmis aux clients :
			</div>

			<FormLine>
				<div
					style={{
						flex: 1
					}}
				>
					<LabeledInput
						label={<img src="/icons/phone.svg" height={16} width={16} />}
						placeholder="(+33)"
						{...activityForm.get('administration.phone')}
						value={activityForm
							.get('administration.phone')
							.value.replace(/[^0-9+]/g, '')
							.replace(/(.{2})/g, '$1 ')
							.trim()}
						setValue={value => {
							activityForm
								.get('administration.phone')
								.setValue(value.replace(/[^0-9+]/g, ''))
						}}
					/>
				</div>
				<div
					style={{
						marginLeft: 4,
						flex: 1
					}}
				>
					<LabeledInput
						label={<img src="/icons/envelope.svg" width={16} />}
						placeholder="E-mail"
						{...activityForm.get('administration.mail')}
					/>
				</div>
			</FormLine>
		</div>

		<div
			style={{
				paddingLeft: 4
			}}
		>
			<div>Mail pour clients et réservations :</div>

			<FormLine>
				<LabeledInput
					label={<img src="/icons/envelope.svg" width={16} />}
					placeholder="E-mail"
					{...activityForm.get('booking.informations.mail')}
				/>
			</FormLine>
		</div>
	</FormLine>
)

export default BookingContact
