import React from 'react'
import Button from '~components/basics/Button'

import './WebsiteCreationError.scss'

const WebsiteCreationError: TWebsiteCreationError = () => {
	return (
		<div className="WebsiteCreationError">
			<h2>Il semble qu'il y ait un problème.</h2>

			<p>
				Veuillez réessayer plus tard
				<br />
				ou nous contacter à{' '}
				<a href="mailto:aide@coq-trotteur.com">aide@coq-trotteur.com</a>.
			</p>

			<div
				style={{
					marginTop: 20
				}}
			>
				<Button
					theme="blue"
					onClick={() => {
						document.location.reload()
					}}
				>
					Retour
				</Button>
			</div>
		</div>
	)
}

export default WebsiteCreationError

type TWebsiteCreationError = React.FC
