import React, { useState } from 'react'
import Loader from 'react-loader-spinner'
import styled from 'styled-components'
import Save from '~svg/Shape.svg'
import { useUserData } from '~contexts/userDataContext'
import { log } from '~utils/helpers'


/**
 * 
 * @param {
 *  saveRib: Function
 * } props 
 */
const SaveRibButton = ({ saveRib, setErrors }) => {
  const [loading, setLoading] = useState(false)
  const { saved, setSaved } = useUserData()

  const handleSave = () => {
    if (saveRib()) {
      setLoading(true)
      setSaved(true)
      setTimeout(() => {
        setLoading(false)
      }, 500)
      
    } else {
      setErrors(true)
    }
  
  }
  
  const textButton = saved ? 'Enregistré' : 'Enregistrer'

  return (
    <div onClick={handleSave}>
      <Button
        roundish={'roundish'}
        size="medium"
        theme="blue"
        type="submit"
      >
        {loading ? (
          <StyledLoader
            type="TailSpin"
            color="#FFF"
            height={20}
            width={20}
            timeout={5000}
          />
        ) : (
          <>
            {saved && <Done saved={saved} />}
            {textButton}
          </>
        )}
      </Button>
    </div>
  )
}

export default SaveRibButton

const Button = styled.button`
	border: none;
	border-radius: 6px;
	height: 47px;
	width: 175px;
	padding: 13px 20px;
	background-color: #66a3ff;
	color: white;
	font-family: 'Montserrat', sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	cursor: pointer;
`

const StyledLoader = styled(Loader)`
	display: inline;
	margin-right: 8px;
`

const Done = styled(Save)`
	margin-right: 8px;
	height: 20px;
	width: 20px;
	g {
		fill: #172b49;
		fill-opacity: ${props => (props.saved ? 1 : 0)};
		transition: color 200ms;
	}
`