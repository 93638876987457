import { Router } from '@reach/router'
import React from 'react'
import { withAuth } from '~components/HOC/withAuth'
import FicheMailPage from '~components/pages/Fiche/FicheMailPage/FicheMailPage'

const schedules = () => {
	return (
		<Router>
			<FicheMailPage path="/fiche/mails/:id/*" />
		</Router>
	)
}

export default withAuth(schedules)
