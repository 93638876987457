import React, { useRef } from 'react'
import moment from 'moment'

import Button from '~components/basics/Button'
import TimeSlotInput from './TimeSlotInput'

import './TimeSlots.scss'

const TimeSlotsDay = ({
	dayForm,
	i,
	copiedDay,
	setCopiedDay,
	clearCopy,
	periodID
}) => {
	const rootRef = useRef(null)

	return (
		<div className="day">
			<div className="day-name">
				{moment()
					.day((i + 1) % 7)
					.format('dddd')}
			</div>

			<div className="slots" ref={rootRef}>
				{dayForm.get('slots').map((slotForm, _, id) => (
					<TimeSlotInput
						slotForm={slotForm}
						key={id}
						clearCopy={clearCopy}
						stopAfterMinutes={true}
					/>
				))}

				<Button
					theme="colored-action"
					style={{ height: 42 }}
					onClick={() => {
						clearCopy()
						dayForm.get('slots').push()

						setTimeout(() => {
							const _ = [
								...rootRef.current.querySelectorAll('.time-slot-input')
							].slice(-1)

							if (_.length === 0) return // in case of computer is very slow

							_[0].querySelector('.hours-input').focus()
						}, 200)
					}}
				>
					+ Ajouter un créneau
				</Button>
			</div>
			<div>
				{copiedDay &&
				copiedDay.periodID === periodID &&
				copiedDay.dayI === i ? (
					<>
						<img
							src="/icons/checked.svg"
							style={{ height: 12, marginRight: 4 }}
						/>
						<span>Copié</span>
					</>
				) : (
					<>
						{dayForm.get('slots').length > 0 && (
							<Button
								theme="underline-action"
								onClick={() => {
									setCopiedDay({
										data: dayForm.toJSON(),
										dayI: i
									})
								}}
							>
								Copier
							</Button>
						)}

						{copiedDay !== null && (
							<>
								{' | '}

								<Button
									theme="underline-action"
									onClick={() => {
										dayForm.set({
											...copiedDay.data,
											name: dayForm.toJSON().name
										})
									}}
								>
									Coller
								</Button>
							</>
						)}
					</>
				)}
			</div>
		</div>
	)
}

const TimeSlots = ({ daysForm, ...props }) => {
	return (
		<div className="timeslots">
			<div className="title">
				Renseignez uniquement l’heure de début de la prestation
			</div>
			{daysForm.map((dayForm, i) => (
				<TimeSlotsDay dayForm={dayForm} key={i} i={i} {...props} />
			))}
		</div>
	)
}
export default TimeSlots
