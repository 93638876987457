import React, { useEffect, useState, useRef, useMemo } from 'react'
import { theme, GlobalStyle } from '~horairesMod/theme'
import { ThemeProvider } from 'styled-components'

import { FormSection, FormLine, Select, FormErrorMessage } from '../FormToolbox'
import InfoTooltip from '~components/basics/InfoTooltip'

import useForm from 'react-super-useform'

import pricingScheduleFormSchema from './pricingScheduleFormSchema'

import {
	getPricingSchedulePageData,
	savePricingSchedulePageData
} from './pricingScheduleDataQueries'
import { useInvalidSession, useUserData } from '~contexts/userDataContext'

import TimeSlots from './TimeSlots'
import Button from '~components/basics/Button'

import { ClosePeriodItem, TimesIcon } from '../StepSchedules'
import DatePicker from '../StepSchedules/DatePicker'

import { useFormHandler } from '~contexts/currentFormContext'

import moment from 'moment'

import './PricingSchedule.scss'
import EmptyPricingSchedule from './sections/EmptyPricingSchedule'

const SchedulesItemPeriod = ({
	periodForm,
	copiedDay,
	setCopiedDay,
	clearCopy,
	periodID,
	unique_period,
	otherPeriodRanges
}) => {
	const pickerRootRef = useRef(null)

	const _id = periodForm.toJSON()._id

	const dates = periodForm.get('dates').toJSON()

	const allDateArePassed = useMemo(() => {
		const findAfter = dates.find(({ end_date }) => {
			if (!end_date) return true

			if (moment().diff(moment(end_date)) < 0) return true
		})

		return !findAfter
	}, [dates])

	return (
		<div className="schedules-item-period">
			{!unique_period && (
				<>
					{allDateArePassed && (
						<div className="color-danger">Période passée</div>
					)}
					<FormLine
						style={{
							alignItems: 'flex-start'
						}}
					>
						<div style={{ paddingTop: 8 }}>Créneaux du :</div>

						<div style={{ flex: 1 }} ref={pickerRootRef}>
							<div>
								{periodForm.get('dates').map((date, i) => (
									<span
										className="schedule-date-range"
										style={{
											position: 'relative',
											verticalAlign: 'top',
											marginBottom: 8
										}}
									>
										<DatePicker
											dateRangeForm={date}
											disabledRanges={[
												...periodForm
													.get('dates')
													.toJSON()
													.filter((_, _i) => _i !== i),
												...otherPeriodRanges
											]}
										/>
										{date.canBeRemoved() && (
											<button
												type="button"
												onClick={() => {
													date.remove()
												}}
												className="remove-button"
											>
												<TimesIcon
													style={{
														width: 12
													}}
												/>
											</button>
										)}
									</span>
								))}

								<button
									style={{
										width: 340,
										verticalAlign: 'top',
										height: 42
									}}
									type="button"
									className="add-period"
									onClick={() => {
										periodForm.get('dates').push()

										setTimeout(() => {
											;[
												...pickerRootRef.current.querySelectorAll(
													'.open-button'
												)
											]
												.slice(-1)[0]
												.click()
										}, 500)
									}}
								>
									+ Ajouter période avec mêmes créneaux
								</button>
							</div>
						</div>

						<div>
							{periodForm.canBeRemoved() && (
								<Button
									style={{
										height: 42,
										width: 49
									}}
									onClick={() => {
										periodForm.remove()
									}}
								>
									<img src="/icons/delete.svg" width={15} />
								</Button>
							)}
						</div>
					</FormLine>
				</>
			)}

			<TimeSlots
				daysForm={periodForm.get('days')}
				copiedDay={copiedDay}
				setCopiedDay={setCopiedDay}
				clearCopy={clearCopy}
				periodID={periodID}
			/>
		</div>
	)
}

const SchedulesItem = ({
	schedulesForm,
	copiedDay,
	setCopiedDay,
	clearCopy,
	copiedClosedPeriods,
	setCopiedClosedPeriods,
	schedules_enabled
}) => {
	const closedPeriodsRootRef = useRef(null)

	const { _id } = schedulesForm.toJSON()

	return (
		<>
			{schedulesForm.get('lang').value && (
				<div className="schedules-item-title">
					Langue : {schedulesForm.get('lang').value}
				</div>
			)}

			<div className="sub-section">
				<>
					{schedules_enabled && (
						<>
							<FormLine>
								Quelles sont les périodes de fermeture (vacances, jours fériés)
								?
								<InfoTooltip>
									Les périodes de fermeture viennent automatiquement placer des
									périodes en indisponible. Vous devez uniquement indiquer les
									périodes de vacances, de jours fériés, de jours fermés
									exceptionnellement. Si vous êtes fermé·e les samedi et
									dimanche, vous renseignerez cela dans vos horaires.{' '}
								</InfoTooltip>
								<div>
									<Select
										{...schedulesForm.get('use_global_closed_periods')}
										rootStyle={{ height: 45 }}
										style={{ height: 45, width: 341 }}
										dropdownStyle={{
											maxHeight: 400,
											width: 450
										}}
										options={[
											{
												label: 'Les mêmes que celles des horaires d’ouverture.',
												value: true,
												details: `Les périodes de fermeture pour cette prestation sont
												les mêmes que celles que vous avez renseignées sur
												la page des horaires d’ouverture.`
											},
											{
												label:
													'Des périodes de fermeture différentes des horaires d’ouverture.',
												value: false,
												details: `Les périodes de fermeture pour cette prestation ne
												sont pas les mêmes que celles que vous avez
												renseigné sur la page des horaires d’ouverture.`
											}
										]}
										noWrap={true}
									/>
								</div>
							</FormLine>
							<hr />
						</>
					)}

					{schedulesForm.get('use_global_closed_periods').value === false && (
						<>
							<FormLine>
								Période de fermeture (vacances, jours fériés){' '}
								<InfoTooltip>
									Les périodes de fermeture viennent automatiquement placer des
									périodes en indisponible. Vous devez uniquement indiquer
									dedans les périodes de vacances, de jours fériés, de jours
									fermés exceptionnellement. Si vous êtes fermé·e les samedi et
									dimanche, vous renseignerez cela dans vos horaires.
								</InfoTooltip>
								<div
									style={{
										flex: 1
									}}
								/>
								<div>
									{/* {copiedClosedPeriods && copiedClosedPeriods._id === _id && (
										<Button
											theme="underline-action"
											style={{ cursor: 'default' }}
										>
											<img
												src="/icons/checked.svg"
												style={{
													height: 12,
													marginRight: 4
												}}
											/>
											Copié
										</Button>
									)} */}
									{/* {(!copiedClosedPeriods ||
										copiedClosedPeriods._id !== _id) && (
										<Button
											theme="underline-action"
											onClick={() => {
												setCopiedClosedPeriods({
													data: schedulesForm.get('closed_periods').toJSON(),
													_id
												})
											}}
										>
											Copier
										</Button>
									)} */}
									{/* {copiedClosedPeriods && copiedClosedPeriods._id !== _id && (
										<Button
											theme="underline-action"
											onClick={() => {
												schedulesForm
													.get('closed_periods')
													.set(copiedClosedPeriods.data)
											}}
										>
											Coller
										</Button>
									)} */}
								</div>
							</FormLine>

							<div ref={closedPeriodsRootRef}>
								<div>
									{schedulesForm
										.get('closed_periods')
										.map((closePeriod, i, id) => (
											<div
												key={id}
												style={{
													display: 'inline-block',
													verticalAlign: 'top'
												}}
											>
												<ClosePeriodItem
													closePeriodForm={closePeriod}
													onChange={() => {
														if (
															copiedClosedPeriods &&
															copiedClosedPeriods._id === _id
														) {
															setCopiedClosedPeriods(null)
														}
													}}
												/>
											</div>
										))}

									<Button
										style={{
											height: 40
										}}
										theme="colored-action"
										onClick={() => {
											schedulesForm.get('closed_periods').push()

											if (
												copiedClosedPeriods &&
												copiedClosedPeriods._id === _id
											) {
												setCopiedClosedPeriods(null)
											}

											setTimeout(() => {
												;[
													...closedPeriodsRootRef.current.querySelectorAll(
														'.open-button'
													)
												]
													.slice(-1)[0]
													.click()
											}, 300)
										}}
									>
										+ Ajouter une période de fermeture
									</Button>
								</div>
							</div>

							<hr />
						</>
					)}
				</>

				<FormLine>
					Hormis vos périodes de fermeture, avez-vous les mêmes créneaux toute
					l’année ?{' '}
					<InfoTooltip>
						Si vous répondez non, vous pourrez enregistrer des créneaux
						différents dans l’année. Sinon, vous aurez les mêmes créneaux toute
						l’année.
					</InfoTooltip>
					<div>
						<Select
							rootStyle={{ height: 45 }}
							style={{ height: 45, width: 105 }}
							value={schedulesForm.get('unique_period').value}
							setValue={unique_period => {
								if (unique_period) {
									const schedulesJSON = schedulesForm.toJSON()

									schedulesForm.set({
										...schedulesJSON,
										unique_period: true,
										periods: [
											{
												...schedulesJSON.periods[0],
												dates: [
													{
														start_date: null,
														end_date: null
													}
												]
											}
										]
									})
								} else {
									schedulesForm.get('unique_period').setValue(false)
								}
							}}
							options={[
								{
									label: 'Oui',
									value: true
								},
								{
									label: 'Non',
									value: false
								}
							]}
							noWrap={true}
						/>
					</div>
				</FormLine>
				<hr />

				{schedulesForm.get('periods').map((periodForm, i, id) => (
					<SchedulesItemPeriod
						periodForm={periodForm}
						key={id}
						copiedDay={copiedDay}
						setCopiedDay={data => {
							setCopiedDay({ ...data, periodID: id })
						}}
						clearCopy={clearCopy}
						periodID={id}
						unique_period={schedulesForm.get('unique_period').value}
						otherPeriodRanges={(() => {
							const ranges = []

							schedulesForm
								.get('periods')
								.toJSON()
								.forEach((period, _i) => {
									if (_i === i) return

									period.dates.forEach(({ start_date, end_date }) => {
										if (
											start_date &&
											end_date &&
											start_date !== '' &&
											end_date !== ''
										) {
											ranges.push({
												start_date,
												end_date
											})
										}
									})
								})

							return ranges
						})()}
					/>
				))}

				{schedulesForm.get('unique_period').value === false && (
					<Button
						theme="blue"
						className="add-schedule-period"
						onClick={() => {
							schedulesForm.get('periods').push()
						}}
					>
						+ Ajouter une période d'horaire
					</Button>
				)}
			</div>
		</>
	)
}

const PricingSchedule = ({
	activity,
	handleSave,
	registerValidationFunction,
	handleChangeCurrentStep
}) => {
	const [copiedDay, setCopiedDay] = useState(null)
	const [copiedClosedPeriods, setCopiedClosedPeriods] = useState(null)
	const [errorNotification, setErrorNotification] = useState(null)

	const [sameClosedPeriods, setSameClosedPeriods] = useState(true)
	const [allSameClosedPeriods, setAllSameClosedPeriods] = useState(false)

	const clearCopy = () => {
		setCopiedDay(null)
	}

	const form = useForm(pricingScheduleFormSchema, {
		activity
	})

	const hasTimeSlotsPrestation =
		form
			.get('activity.pricings.pricings_table')
			.toJSON()
			.filter(e => e.schedule_type === 'time_slots').length > 0

	useFormHandler(
		hasTimeSlotsPrestation
			? form
			: {
					modified: false,
					isValid: true,
					save: () => true
			  },
		hasTimeSlotsPrestation ? handleSave : () => true
	)

	const beforeUnload = e => {
		var e = e || window.event

		if (e) {
			e.returnValue = 'Des données ne sont pas sauvegardées'
		}

		return 'Des données ne sont pas sauvegardées'
	}

	useEffect(() => {
		if (form.modified) {
			window.addEventListener('beforeunload', beforeUnload)
			return () => {
				window.removeEventListener('beforeunload', beforeUnload)
			}
		}
	}, [form.modified])

	form.logErrors()

	const schedules_enabled = activity.schedules.enabled

	if (!hasTimeSlotsPrestation) return <EmptyPricingSchedule />

	return (
		<>
			<form
				style={{
					position: 'relative'
				}}
				className="pricing-schedule"
			>
				<FormErrorMessage show={errorNotification} />
				{form.get('activity.pricings.pricings_table').map((pricingForm, i) => {
					if (pricingForm.toJSON().schedule_type !== 'time_slots') {
						return null
					}

					const langs = pricingForm.get('lang.selection').toJSON()

					return (
						<FormSection className="pricing-section">
							<h2 style={{ textAlign: 'center' }}>
								{pricingForm.toJSON().name}
							</h2>
							<hr />

							{pricingForm.get('schedules.list').map((scheduleForm, i) =>
								pricingForm.get('schedules.same').value === true &&
								i > 0 ? null : (
									<>
										<SchedulesItem
											schedulesForm={scheduleForm}
											copiedDay={copiedDay}
											setCopiedDay={setCopiedDay}
											clearCopy={clearCopy}
											copiedClosedPeriods={copiedClosedPeriods}
											setCopiedClosedPeriods={setCopiedClosedPeriods}
											sameClosedPeriods={sameClosedPeriods}
											allSameClosedPeriods={allSameClosedPeriods}
											schedules_enabled={schedules_enabled}
										/>
										{i === 0 &&
											pricingForm.get('lang.force').value &&
											pricingForm.get('schedules.list').length > 1 && (
												<FormLine
													style={{
														margin: '30px 0 0 0'
													}}
												>
													<div>
														{langs.length > 2
															? `Les autres langues (${langs
																	.slice(1)
																	.join(', ')}) ont-elles les mêmes horaires ?`
															: `La langue ${langs[1]} a-t-elle les mêmes horaires ?`}{' '}
													</div>
													<div>
														<Select
															rootStyle={{ height: 45 }}
															style={{ height: 45, width: 105 }}
															{...pricingForm.get('schedules.same')}
															options={[
																{
																	label: 'Oui',
																	value: true
																},
																{
																	label: 'Non',
																	value: false
																}
															]}
															noWrap={true}
														/>
													</div>
												</FormLine>
											)}
									</>
								)
							)}
						</FormSection>
					)
				})}
			</form>
		</>
	)
}

const removePeriodIds = ({ _id, start_date, end_date, dates, ...period }) => {
	return {
		...period,
		days: period.days.map(({ _id, ...day }) => ({
			...day,
			slots: day.slots.map(({ _id, ...slot }) => ({
				start: {
					hours: `00${slot.start.hours}`.substr(-2),
					minutes: `00${slot.start.minutes}`.substr(-2)
				}
			}))
		}))
	}
}

export const transformActivityFromDatabase = activity => {
	const schedules_enabled = activity.schedules.enabled

	const _activity = {
		...activity,
		pricings: {
			...activity.pricings,
			pricings_table: activity.pricings.pricings_table
				.map(pricings_item => {
					let schedules = pricings_item.schedules

					if (pricings_item.lang.force) {
						const list = [
							...new Array(pricings_item.lang.selection.length)
						].map((_, i) => {
							const lang = pricings_item.lang.selection[i]
							const item = schedules.list.find(item => item.lang === lang) || {}

							const periods = []

							if (item.periods)
								item.periods
									.filter(({ lang }) => lang !== '')
									.forEach(({ start_date, end_date, ...period }) => {
										const findDuplicate = periods.find(
											_period =>
												JSON.stringify(removePeriodIds(_period)) ===
												JSON.stringify(removePeriodIds(period))
										)

										if (findDuplicate) {
											findDuplicate.dates.push({
												start_date,
												end_date
											})
										} else {
											periods.push({
												...period,
												dates: [
													{
														start_date,
														end_date
													}
												],
												days: period.days.map(day => ({
													...day,
													slots: day.slots.map(slot => {
														return {
															...slot,
															start: {
																hours: `00${slot.start.hours || 0}`.substr(-2),
																minutes: `00${slot.start.minutes || 0}`.substr(
																	-2
																)
															}
														}
													})
												}))
											})
										}
									})

							return {
								lang: pricings_item.lang.selection[i],
								...item,
								use_global_closed_periods: !schedules_enabled
									? false
									: item.use_global_closed_periods,
								periods
							}
						})

						schedules.list = list
					} else {
						// If there is no lang, just need to have 1 schedule table
						if (schedules.list.length === 0) {
							schedules.list = [
								{
									lang: ''
								}
							] // TODO test this shit
						} else {
							if (schedules.list[0].lang !== '') {
								schedules.list = [
									{
										lang: ''
									}
								]
							} else {
								const schedule = schedules.list[0]

								const periods = []

								schedule.periods.forEach(
									({ start_date, end_date, ...period }) => {
										const findDuplicate = periods.find(
											_period =>
												JSON.stringify(removePeriodIds(_period)) ===
												JSON.stringify(removePeriodIds(period))
										)

										if (findDuplicate) {
											findDuplicate.dates.push({
												start_date,
												end_date
											})
										} else {
											periods.push({
												...period,

												dates: [
													{
														start_date,
														end_date
													}
												]
											})
										}
									}
								)

								schedules.list = [
									{
										...schedule,
										periods
									}
								]
							}
						}
					}

					schedules.list.forEach(schedule => {
						schedule.periods?.forEach(period => {
							period.days.forEach(day => {
								day.slots.forEach(slot => {
									slot.start.minutes = `00${slot.start.minutes}`.substr(-2)
								})
							})
						})
					})

					return {
						...pricings_item,
						schedules
					}
				})
				.sort((a, b) => {
					// TODO do it in a better way.
					// principle : empty schedules displayed first.
					return JSON.stringify(a).length - JSON.stringify(b).length
				})
		}
	}

	return _activity
}

export const transformActivityDataFromForm = activity => {
	activity.pricings.pricings_table.forEach(pricing => {
		pricing.schedules.list.forEach(schedule => {
			const _periods = []

			schedule.periods.forEach(({ dates, ...period }) => {
				dates.forEach(date => {
					_periods.push({
						...period,
						...date
					})
				})
			})

			schedule.periods = _periods
		})
	})

	return activity
}

const PricingScheduleWrapper = ({ setCanGoNext, ...props }) => {
	const { activitiesId, token, apiUrl } = useUserData()

	const [activity, setActivity] = useState(null)

	const { invalidSession } = useInvalidSession()

	// Preload activity data before displaying page
	useEffect(() => {
		getPricingSchedulePageData(activitiesId, token, apiUrl)
			.then(({ activity }) => {
				setActivity(transformActivityFromDatabase(activity))
			})
			.catch(err => {
				invalidSession(err)
			})
	}, [])

	const handleSave = async formData => {
		await savePricingSchedulePageData(
			transformActivityDataFromForm(formData.activity),
			token,
			apiUrl
		)
	}

	if (activity === null) return null

	return (
		<ThemeProvider theme={theme}>
			<PricingSchedule activity={activity} handleSave={handleSave} {...props} />
			<GlobalStyle />
		</ThemeProvider>
	)
}

export default PricingScheduleWrapper
