import React from 'react'
import moment from 'moment'

export const TicketPricingsCancellationRefund = ({ ticket }) => {
    if(!ticket || !ticket.state || !ticket.state.canceled) {
        return <></>
    }
    const canceledBy = ticket.state.canceled_by === 'client' ? 'client' : 'Prestataire';
    
    return (
        <>
            <div className="danger">Annulé par le {canceledBy} le {moment(ticket.state.canceled_date).format('DD/MM/yy')} à {moment(ticket.state.canceled_date).format('HH\\h:mm')}.</div>
            {
                ticket.items?.map((item) => {
                    const refundPeercent = calcPricingCancellationRefundPeercent(item, ticket);
                    return (
                        <ul>
                            {
                                item.benefits.map((benefit) => {
                                    return <li><span className='danger'>Remboursement : {refundPeercent}%</span> {benefit.count}* {item.name} ({benefit.name}) - {benefit.count*benefit.price}€</li>
                                })
                            }
                            {
                                item.options.map((option) => {
                                    return <li><span className='danger'>Remboursement : {refundPeercent}%</span> {option.count}* {option.name} - {option.count*option.price}€</li>
                                })
                            }
                        </ul>
                    )
                })
            }
        </>
    )
}

export const calcPricingCancellationRefundPeercent = (itemPricing, ticket) => {
    if (ticket.state.canceled_by !== 'client') {
        return 100;
    }
    const pricingDateTime = getPricingDateTime(itemPricing, ticket.date);
    const oneDayBefore = moment(pricingDateTime).add(-1, "days");
    const twoDaysBefore = moment(pricingDateTime).add(-2, "days");
    const fourDaysBefore = moment(pricingDateTime).add(-4, "days");
    const canceledDate = ticket.state.canceled_date;
    if (itemPricing.cancellation.type === 'standard' && moment(canceledDate).isBefore(oneDayBefore)) {
        return 100;
    } else if (itemPricing.cancellation.type === 'partial' && moment(canceledDate).isBefore(fourDaysBefore)) {
        return 100;
    } else if (itemPricing.cancellation.type === 'partial' && moment(canceledDate).isBetween(fourDaysBefore, twoDaysBefore)) {
        return 50;
    } else if (itemPricing.cancellation.type === 'none' && !ticket.state.accepted) {
        return 100;
    } else {
        return 0;
    }
}

export function getPricingTime(item, date) {
    date = new Date(date);
    return item.schedule_type !== 'time_slots' || !item.time_slot ? { hours: date.getHours(), minutes: date.getMinutes(), seconds: date.getSeconds() } : { ...item.time_slot, seconds: 0 };
}

export function getPricingDateTime(item, date) {
    const pricingTime = getPricingTime(item, date);
    const pricingDate = new Date(date);
    pricingDate.setHours(pricingTime.hours);
    pricingDate.setMinutes(pricingTime.minutes);
    pricingDate.setSeconds(pricingTime.seconds);
    return pricingDate;
}
