import React from 'react'

export const FicheClosedIcon = () => {
	return (
		<svg
			width="30"
			height="26"
			viewBox="0 0 30 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M27.9492 10.8179H25.0832L17.5404 3.32461C17.6027 3.10137 17.6367 2.86646 17.6367 2.62371C17.6367 1.17703 16.4539 0 15 0C13.5461 0 12.3633 1.17703 12.3633 2.62371C12.3633 2.86646 12.3973 3.10131 12.4596 3.32461L4.91678 10.8179H2.05078C0.91998 10.8179 0 11.7379 0 12.8687V23.3203C0 24.4511 0.91998 25.3711 2.05078 25.3711H27.9492C29.08 25.3711 30 24.4511 30 23.3203V12.8687C30 11.7379 29.08 10.8179 27.9492 10.8179ZM28.2422 23.3203C28.2422 23.4819 28.1108 23.6133 27.9492 23.6133H2.05078C1.88924 23.6133 1.75781 23.4819 1.75781 23.3203V12.8687C1.75781 12.7072 1.88924 12.5757 2.05078 12.5757H27.9492C28.1108 12.5757 28.2422 12.7072 28.2422 12.8687V23.3203ZM15 3.48967C14.5154 3.48967 14.1211 3.10119 14.1211 2.62377C14.1211 2.14635 14.5154 1.75781 15 1.75781C15.4846 1.75781 15.8789 2.14629 15.8789 2.62371C15.8789 3.10113 15.4846 3.48967 15 3.48967ZM13.4934 4.77516C13.9208 5.0724 14.4401 5.24748 15 5.24748C15.5599 5.24748 16.0791 5.0724 16.5066 4.77516L22.5943 10.8179H7.40566L13.4934 4.77516Z"
				fill="#172B49"
			/>
			<path
				d="M9.61943 19.4389C9.35927 19.4403 9.08523 19.4414 8.85449 19.4419V16.1609C8.85449 15.8373 8.59217 15.575 8.26855 15.575C7.94494 15.575 7.68262 15.8373 7.68262 16.1609V20.0248C7.68262 20.3102 7.88822 20.5541 8.16953 20.6023C8.2116 20.6095 9.18373 20.6132 9.62593 20.6107C9.94954 20.6089 10.2104 20.3451 10.2086 20.0215C10.2068 19.6979 9.9414 19.4393 9.61943 19.4389Z"
				fill="#172B49"
			/>
			<path
				d="M6.89779 19.0332C6.64982 18.8253 6.28027 18.8577 6.07232 19.1058C6.0418 19.1421 6.00898 19.1761 5.97471 19.2066C5.79922 19.3629 5.57328 19.4421 5.30316 19.4421C4.56008 19.4421 3.95551 18.8376 3.95551 18.0945C3.95551 17.3513 4.56008 16.7468 5.30316 16.7468C5.57322 16.7468 5.83367 16.8263 6.05633 16.9767C6.32445 17.1578 6.68873 17.0873 6.86984 16.8191C7.05096 16.5509 6.98041 16.1867 6.71229 16.0056C6.29521 15.7238 5.80795 15.575 5.30322 15.575C3.91396 15.575 2.78369 16.7052 2.78369 18.0945C2.78369 19.4837 3.91396 20.614 5.30322 20.614C5.86139 20.614 6.36313 20.43 6.75418 20.0817C6.83076 20.0135 6.90348 19.9385 6.97039 19.8587C7.17822 19.6107 7.14576 19.2412 6.89779 19.0332Z"
				fill="#172B49"
			/>
			<path
				d="M12.752 15.575C11.3627 15.575 10.2324 16.7052 10.2324 18.0945C10.2324 19.4837 11.3627 20.614 12.752 20.614C14.1412 20.614 15.2715 19.4837 15.2715 18.0945C15.2715 16.7052 14.1413 15.575 12.752 15.575ZM12.752 19.4421C12.0089 19.4421 11.4043 18.8376 11.4043 18.0945C11.4043 17.3513 12.0089 16.7468 12.752 16.7468C13.495 16.7468 14.0996 17.3513 14.0996 18.0945C14.0996 18.8376 13.495 19.4421 12.752 19.4421Z"
				fill="#172B49"
			/>
			<path
				d="M22.3148 16.7488C22.6384 16.7488 22.9007 16.4864 22.9007 16.1628C22.9007 15.8393 22.6384 15.5769 22.3148 15.5769H20.7002C20.3766 15.5769 20.1143 15.8393 20.1143 16.1628V19.9947C20.1143 20.3183 20.3766 20.5806 20.7002 20.5806H22.3148C22.6384 20.5806 22.9007 20.3183 22.9007 19.9947C22.9007 19.6711 22.6384 19.4088 22.3148 19.4088H21.2861V18.6647H22.1957C22.5194 18.6647 22.7817 18.4024 22.7817 18.0788C22.7817 17.7552 22.5194 17.4929 22.1957 17.4929H21.2861V16.7488H22.3148Z"
				fill="#172B49"
			/>
			<path
				d="M25.1146 15.6162H24.0518C23.8962 15.6162 23.747 15.6781 23.637 15.7882C23.5271 15.8984 23.4655 16.0477 23.4658 16.2033V16.206V16.206V19.8939C23.4658 19.9295 23.4695 19.9643 23.4756 19.9982C23.486 20.1385 23.5461 20.271 23.6466 20.3707C23.7564 20.4797 23.9047 20.5408 24.0594 20.5408H24.0616C24.0937 20.5407 24.8524 20.5378 25.156 20.5324C26.3496 20.5116 27.216 19.4795 27.216 18.0784C27.216 16.6057 26.3715 15.6162 25.1146 15.6162ZM25.1354 19.3608C25.0199 19.3628 24.831 19.3645 24.6434 19.3659C24.642 18.9326 24.6404 18.3692 24.6404 18.0744C24.6404 17.8268 24.6395 17.2396 24.6387 16.7881H25.1146C25.9771 16.7881 26.0442 17.7758 26.0442 18.0785C26.0442 18.7088 25.7632 19.3498 25.1354 19.3608Z"
				fill="#172B49"
			/>
			<path
				d="M18.165 17.5751C17.7094 17.407 17.2854 17.2254 17.1199 17.1532C17.062 17.1007 17.0621 17.0309 17.0674 16.9937C17.0747 16.9427 17.1132 16.8177 17.3116 16.7579C17.8124 16.6071 18.279 16.9731 18.2982 16.9885C18.5437 17.1922 18.9105 17.1593 19.1159 16.915C19.2159 16.7962 19.2635 16.6452 19.2501 16.49C19.2367 16.3349 19.1638 16.1943 19.045 16.0944C19.0066 16.0621 18.0923 15.3066 16.9757 15.6426C16.4012 15.8157 15.9945 16.27 15.9144 16.8281C15.8389 17.3542 16.067 17.8603 16.5099 18.149C16.5361 18.166 16.5636 18.1809 16.5918 18.1934C16.6494 18.2191 17.1725 18.4505 17.762 18.668C17.8447 18.6985 18.2549 18.8623 18.2135 19.0942C18.1821 19.2702 17.948 19.4602 17.6246 19.4602C17.2629 19.4602 16.9157 19.3154 16.6959 19.0729C16.4802 18.835 16.1111 18.8167 15.8731 19.0324C15.6352 19.2481 15.6171 19.6171 15.8327 19.8552C16.2697 20.3373 16.9395 20.6251 17.6246 20.6251C18.4931 20.6251 19.2231 20.0673 19.3603 19.299C19.464 18.717 19.2047 17.9585 18.165 17.5751Z"
				fill="#172B49"
			/>
		</svg>
	)
}
