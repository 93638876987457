import React, { useEffect, useState } from 'react'

import './FormToolbox.scss'
import SuperSelect from './SuperSelect'
import Button from '~components/basics/Button'
import { CaretDownIcon } from './SuperSelect'
import useToggle from '~hooks/useToggle'
import { useRef } from 'react'

export const FormContainer = ({ children }) => (
	<div className="form-container">{children}</div>
)

export const FormSection = ({ children, style = {}, className = '' }) => {
	return (
		<div className={`form-section ${className}`} style={style}>
			{children}
		</div>
	)
}

export const FormSectionButton = ({
	children,
	style = {},
	className = '',
	onClick = () => {}
}) => {
	return (
		<button
			className={`form-section ${className}`}
			type="button"
			onClick={onClick}
		>
			{children}
		</button>
	)
}

export const FormLine = ({ children, style = {} }) => (
	<div className="form-line" style={style}>
		{children}
	</div>
)

export const FormGrid = ({ children, cols = 3, className = '' }) => (
	<div
		className={`form-grid ${className}`}
		style={{
			gridTemplateColumns: `repeat(${cols}, 1fr)`
		}}
	>
		{children}
	</div>
)

export const IntInput = ({
	value,
	setValue,
	error,
	className = '',
	targetRef = null,
	min = 0,
	...props
}) => (
	<input
		className={`input ${error ? 'error' : ''} ${className}`}
		ref={targetRef}
		value={value}
		onKeyDown={e => {
			const delta = e.shiftKey ? 10 : 1

			if (e.key === 'ArrowDown') {
				e.preventDefault()
				setValue(Math.max(min, value - delta))
			} else if (e.key === 'ArrowUp') {
				e.preventDefault()
				setValue(value + delta)
			}
		}}
		onChange={e => {
			setValue(e.target.value)
		}}
		{...props}
	/>
)

export const Input = ({
	value,
	setValue,
	error = '',
	targetRef = null,
	className = '',
	...props
}) => {
	return (
		<input
			autoComplete="off"
			ref={targetRef}
			{...props}
			className={`input ${error ? 'error' : ''} ${className}`}
			value={value === null ? '' : value}
			onChange={e => {
				setValue(e.target.value, e)
			}}
		/>
	)
}

export const PriceInput = ({
	value,
	setValue,
	error,
	readOnly = false,
	...props
}) => {
	return (
		<>
			<div style={{ display: 'flex', width: 178 }}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						backgroundColor: '#f4f5fd',
						borderRadius: '4px 0 0 4px',
						fontSize: 12,
						padding: '0 8px'
					}}
				>
					EUR
				</div>
				<div style={{ flex: 1 }}>
					<input
						autoComplete="off"
						min={0}
						type={'text'}
						readOnly={readOnly}
						{...props}
						onKeyDown={e => {
							// console.log(e.keyCode)
							// if (e.keyCode >= 49 && e.keyCode <= 58) return
							// if (e.keyCode === 69) {
							// 	e.preventDefault()
							// }
							// e.preventDefault()
						}}
						value={
							value === null
								? ''
								: `${value}`.includes('.')
								? `${value}`.replace('.', ',')
								: value
						}
						style={{
							borderRadius: '0 4px 4px 0',
							opacity: readOnly ? 0.5 : 1,
							border: `1px solid ${error ? '#e65264' : 'rgba(23, 43, 73, 0.1)'}`
						}}
						className={`input ${error ? 'error' : ''}`}
						onChange={e => {
							if (e.target.value === '') {
								setValue('')
								return
							}
							const value = parseFloat(e.target.value)

							if (
								e.target.value.match(/[,]/g) &&
								e.target.value.match(/[,]/g).length > 1
							) {
								return null
							}

							if (e.target.value.match(/[^0-9,]/)) {
								return
							}

							if (!e.target.value.match(/^[0-9]*(,[0-9]?[0-9]?)?$/)) return
							if (parseFloat(e.target.value + '0') == NaN) {
								return
							}
							setValue(e.target.value.replace(',', '.'))
						}}
					/>
				</div>
			</div>
		</>
	)
}

export const FormErrorMessage = ({ show }) => (
	<div className={`form-error-message ${show ? 'show' : ''}`}>
		Certains champs semblent incomplets
	</div>
)

export const Textarea = ({
	value,
	setValue,
	error = false,
	targetRef = null,
	...props
}) => {
	return (
		<textarea
			className={`Textarea ${error ? 'error' : ''}`}
			ref={targetRef}
			{...props}
			value={value || ''}
			onChange={e => {
				setValue(e.target.value)
			}}
		/>
	)
}

export const FormColumns = ({ children }) => {
	return <div className="form-columns">{children}</div>
}

/**
 *
 * @param {{
 *   value: boolean
 *   setValue?:(value:boolean) => void
 *   className?: string
 *   readOnly?: boolean
 *   error?: boolean
 * }} props
 */
export const CheckBox = ({
	value,
	setValue = () => {},
	className = '',
	readOnly = false,
	error = false
}) => {
	return (
		<span
			className={`checkbox ${value ? 'checked' : ''} ${className} ${
				error ? 'error' : ''
			}`}
			onClick={e => {
				e.preventDefault()

				if (!readOnly) setValue(!value)
			}}
		>
			<div>
				<img src="/icons/checked-white.svg" />
			</div>
		</span>
	)
}

export const LabeledInput = ({
	label,
	rightLabel = null,
	value,
	setValue,
	error,
	onClickRightLabel = () => {},
	...props
}) => {
	return (
		<div className={`labeled-input ${error ? 'error' : ''}`}>
			<div className="label">{label}</div>
			<input
				autoComplete="off"
				className={`input `}
				value={value === null ? '' : value}
				{...props}
				onChange={e => {
					setValue(e.target.value)
				}}
			/>

			{rightLabel && (
				<div
					className="delete-action"
					role="button"
					tabIndex={0}
					onClick={onClickRightLabel}
				>
					{rightLabel}
				</div>
			)}
		</div>
	)
}

/**
 *
 * @param {{
 * 	list: [string]
 *  setValue: (value:string) => void
 *  error?: any
 *  items?: {
 *     name: string
 *     value: string
 * 	   title?: string
 *     disables?: boolean
 *  }[]
 *  label?: any
 *  style?: React.CSSProperties
 *  dropdownStyle?: React.CSSProperties
 *  dropdownVerticalPosition?: 'bottom'|'top'
 *  dropdownHeader?: any
 * }} props
 */
export const MultipleSelect = ({
	list,
	setValue,
	items,
	label = '',
	style = {},
	dropdownStyle = {},
	dropdownVerticalPosition = 'bottom',
	dropdownHeader = null,
	error = false
}) => {
	const [isOpen, toggleIsOpen, setIsOpen] = useToggle(false)
	const dropdonwRef = useRef(null)

	useEffect(() => {
		const clickOutsideEvent = event => {
			if (!dropdonwRef.current.contains(event.target)) setIsOpen(false)
		}

		const closeDropdown = () => {
			setIsOpen(false)
		}

		if (isOpen) {
			window.addEventListener('click', clickOutsideEvent)
			window.addEventListener('blur', closeDropdown)

			return () => {
				window.removeEventListener('click', clickOutsideEvent)
				window.removeEventListener('blur', closeDropdown)
			}
		}
	}, [isOpen, setIsOpen])

	return (
		<div
			className={`multi-select ${
				isOpen ? 'active' : ''
			} position-${dropdownVerticalPosition} ${error ? 'error' : ''}`}
			ref={dropdonwRef}
		>
			<Button onClick={toggleIsOpen} style={style}>
				<span>{label}</span>
				<span className="caret">
					<CaretDownIcon />
				</span>
			</Button>

			<div
				className="dropdown"
				style={{
					...(dropdownVerticalPosition === 'top'
						? {
								bottom: '100%',
								marginBottom: -1
						  }
						: {}),
					...dropdownStyle
				}}
			>
				{dropdownHeader && <header>{dropdownHeader}</header>}
				{items.map(
					({ name, value, behavior = null, disabled = false, title }) => (
						<Button
							key={name}
							className={`item ${disabled ? 'disabled' : ''}`}
							title={title}
							onClick={() => {
								if (disabled) return
								if (list.includes(value)) {
									setValue(list.filter(v => v !== value))
								} else {
									if (behavior === 'all') {
										setValue([value])
									} else {
										setValue([
											...list.filter(
												_ =>
													items.find(__ => __.value === _)?.behavior !== 'all'
											),
											value
										])
									}
								}
							}}
						>
							<div>
								<CheckBox value={list.includes(value)} readOnly={true} />
							</div>
							<span>{name}</span>
						</Button>
					)
				)}
			</div>
		</div>
	)
}

export const Select: TSelectComponent = ({
	value,
	setValue,
	options,
	fixedHeight = null,
	emptyLabel = false,
	noWrap = false,
	dropdownVerticalPosition = 'bottom',
	buttonTitle = null,
	dropdownStyle,
	...props
}) => {
	return (
		<SuperSelect
			value={value}
			onChange={setValue}
			{...props}
			emptyLabel={emptyLabel}
			noWrap={noWrap}
			buttonTitle={buttonTitle}
			dropdownVerticalPosition={dropdownVerticalPosition}
			style={{
				width: '100%',
				...(fixedHeight ? { height: fixedHeight } : {}),
				...(props.style || {})
			}}
			rootStyle={{
				width: '100%',
				...(fixedHeight ? { height: fixedHeight } : {})
			}}
			dropdownStyle={dropdownStyle}
		>
			{options.map(({ label, value, details }) => (
				<option value={value} key={value}>
					<div className={`title ${details ? 'bold' : ''}`}>{label}</div>
					{details && (
						<div className="details">
							<small
								style={{ fontWeight: 400, lineHeight: 1.2, display: 'block' }}
							>
								{details}
							</small>
						</div>
					)}
				</option>
			))}
		</SuperSelect>
	)
}

type TSelectComponent = React.FC<{
	value: any
	setValue: (value: any) => void
	fixedHeight?: number
	emptyLabel?: any
	noWrap?: boolean
	dropdownVerticalPosition?: 'bottom' | 'top'
	buttonTitle?: string
	error?: boolean
	options: {
		label: any
		value: any
		details?: any
	}[]
	style?: React.CSSProperties
	dropdownStyle?: React.CSSProperties
}>

export const InputRemoveButton = ({ onClick }) => (
	<button
		className="input-remove-button"
		role="button"
		onClick={e => {
			e.preventDefault()
			onClick()
		}}
	>
		×
	</button>
)

/**
 *
 * @param {{
 *   value: string
 *   setValue: (value:string) => void
 *   error: boolean
 *   maxLineHeight?: number
 *   lineHeight?: number
 *   paddingTop?: number
 *   paddingBottom?: number
 * }} props
 */
export const AutoResizableTextarea = ({
	value,
	setValue,
	error,
	maxLineHeight = 5,
	lineHeight = 20,
	paddingTop = 4,
	paddingBottom = 4
}) => {
	const textareaRef = useRef(null)
	const [focused, setFocused] = useState(false)

	const handleChange = e => {
		setValue(e.target.value)
	}

	const autoResize = () => {
		textareaRef.current.style.height = '1px'

		const { scrollHeight } = textareaRef.current

		const nLines = (scrollHeight - (paddingTop + paddingBottom)) / lineHeight

		if (nLines > maxLineHeight) {
			textareaRef.current.style.height = `${paddingTop +
				paddingBottom +
				maxLineHeight * lineHeight +
				2}px`
		} else {
			textareaRef.current.style.height = `${scrollHeight + 2}px`
		}
	}

	useEffect(() => {
		autoResize()
	}, [])

	useEffect(() => {
		if (!focused) return

		autoResize()
	}, [value, focused])

	return (
		<textarea
			onFocus={() => {
				setFocused(true)
			}}
			onBlur={() => {
				setFocused(false)
			}}
			ref={textareaRef}
			className={`autoresizable-textarea ${error ? 'error' : ''}`}
			value={value}
			onChange={handleChange}
			style={{
				lineHeight: `${lineHeight}px`
			}}
		></textarea>
	)
}
