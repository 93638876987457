import React, { useState, useEffect } from 'react'

import './Alert.scss'

export const useAlert = () => {
  const [isOpen, setIsOpen] = useState(true)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return {
    isOpen,
    toggle,
    setIsOpen,
  }
}

const Alert = ({ children, isOpen, toggle, className = '', blocked }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll')

      return () => {
        document.body.classList.remove('no-scroll')
      }
    }
  }, [isOpen])

  return (
    <div className={`alert ${isOpen ? 'active' : ''} ${className}`}>
      <div className="content">{children}</div>
      <div className="alert-overlay" onClick={blocked ? null : toggle} />
    </div>
  )
}
export default Alert
