import { pendingReservationsUrl, providerReservationsUrl, reservationCalendarUrl, providerHasReservationUrl, ticketCancellationUrl, markPrestationAsReadUrl, ticketAcceptationUrl, ticketRefusalUrl, marketReservationsUrl, markTicketAsReadUrl } from "../routes/reservations.routes"

export const getPendingReservations = async (apiUrl, token) => {
    const myHeaders = new Headers()
    myHeaders.append('x-access-token', token)

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
    }
    // console.log({url: process.env.GATSBY_APIV2_URL})
    let res = await fetch(process.env.GATSBY_APIV2_URL + `/${pendingReservationsUrl}`, requestOptions)
    let data = await res.json()
    return data
}

export const getProviderReservations = async (apiUrl, token, searchQuery = "") => {
    const myHeaders = new Headers()
    myHeaders.append('x-access-token', token)

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
    }

    let res = await fetch(apiUrl + `/${providerReservationsUrl}?search=${searchQuery}`, requestOptions)
    let data = await res.json()
    return data
}

export const getReservationCalendar = async (apiUrl, token) => {
    const myHeaders = new Headers()
    myHeaders.append('x-access-token', token)

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
    }

    let res = await fetch(process.env.GATSBY_APIV2_URL + `/${reservationCalendarUrl}`, requestOptions)
    let data = await res.json()
    return data
}

export const providerHasReservation = async (apiUrl, token) => {
    const myHeaders = new Headers()
    myHeaders.append('x-access-token', token)

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
    }

    let res = await fetch(apiUrl + `/${providerHasReservationUrl}`, requestOptions)
    let data = await res.json()
    return data
}

export const cancelReservationTicket = (ticketNumber, apiUrl, token) => {
    const myHeaders = new Headers()
    myHeaders.append('x-access-token', token)
    myHeaders.append('x-universe-token', process.env.GATSBY_UNIVERSE_TOKEN)
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
    }

    return fetch(process.env.GATSBY_APIV1_URL + `/reservation/${ticketNumber}/${ticketCancellationUrl}`, requestOptions)
}

export const refusalReservationTicket = (ticketNumber, apiUrl, token) => {
    const myHeaders = new Headers()
    myHeaders.append('x-access-token', token)
    myHeaders.append('x-universe-token', process.env.GATSBY_UNIVERSE_TOKEN)
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
    }

    return fetch(process.env.GATSBY_APIV1_URL + `/reservation/${ticketNumber}/${ticketRefusalUrl}`, requestOptions)
}

export const markPrestationAsRead = async (apiUrl, token, payload) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Accept', 'application/json, text/plain, */*')
    myHeaders.append('x-access-token', token)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify(payload),
    }

    let res = await fetch(apiUrl + `/${markPrestationAsReadUrl}`, requestOptions)
    let data = await res.json()
    return data
}

export const markTicketAsRead = async (apiUrl, token, payload) => {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    myHeaders.append('Accept', 'application/json, text/plain, */*')
    myHeaders.append('x-access-token', token)

    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify(payload),
    }

    let res = await fetch(apiUrl + `/${markTicketAsReadUrl}`, requestOptions)
    let data = await res.json()
    return data
}

export const acceptReservationTicket = (ticketId, apiUrl, token) => {
    const myHeaders = new Headers()
    myHeaders.append('x-access-token', token)
    myHeaders.append('x-universe-token', process.env.GATSBY_UNIVERSE_TOKEN)
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
    }

    return fetch(process.env.GATSBY_APIV1_URL + `/reservation/${ticketId}/${ticketAcceptationUrl}`, requestOptions)
}

export const getMarketReservations = async (apiUrl, token) => {
    const myHeaders = new Headers()
    myHeaders.append('x-access-token', token)

    const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
    }

    let res = await fetch(apiUrl + `/${marketReservationsUrl}`, requestOptions)
    let data = await res.json()
    return data
}
