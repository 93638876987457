// @ts-check

import { computeFinalPrice } from './PricingPrestation'

const uuid = require('uuid')

/**
 * Convert database activities to form format.
 *
 * @param {*} activity
 */
export const transformPricingsDataFromDatabase = activity => {
	const pricings_list = activity.pricings.pricings_list.map(name => ({
		name,
		id: uuid.v4()
	}))
	// console.log('----------', activity.pricings.pricings_table)

	const pricings = {
		...activity.pricings,
		pricings_list,
		pricings_table: activity.pricings.pricings_table
			.map(p => {
				const pricings = activity.pricings.same_pricing
					? [{ ...p.pricings[0], id: 0 }]
					: p.pricings.map(_ => {
							if (!pricings_list.find(item => item.name === _.name)) {
								return null
							}
							return {
								..._,
								id: pricings_list.find(item => item.name === _.name).id,
								_id: pricings_list.find(item => item.name === _.name).id
							}
					  })

				return {
					...p,
					pricings
				}
			})
			.filter(_ => _ !== null)
	}

	// pricings.pricings_table.forEach(p => {
	// 	console.log('XXX', p)
	// })

	// console.log('YYY', pricings.pricings_table[0])
	// console.log('OOO', pricings.pricings_table)
	// console.log('OOO', pricings.pricings_table.length)

	return { ...activity, pricings }
}

/**
 * Convert form data to data ready to be saved in database.
 *
 * @param {{
 *   pricings:{
 * 	   same_pricing: boolean
 *     pricings_list:[{
 *       name: string
 *       id: string
 *     }]
 *     pricings_table:[{
 *        pricings:[{
 *          id: string
 *          name: string
 *          enabled: boolean
 *          selling_price: string
 *        }]
 * 	      options:[{
 * 			selling_price: string
 *        }]
 *        inclusions:{
 *          has: [string]
 *          hasnt: [string]
 *        }
 *     }]
 *   }
 * }} activity
 */
export const transformPricingsDataFromForm = activity => {
	const pricings = {
		...activity.pricings,
		enabled: true,
		pricings_list: activity.pricings.pricings_list.map(({ name }) => name),
		pricings_table: activity.pricings.pricings_table.map((table, i) => {
			const pricings = table.pricings
				.filter(({ id, name }) => {
					return (
						name === 'same' ||
						!!activity.pricings.pricings_list.find(p => p.id === id)
					)
				})
				.map((pricing, i) => {
					if (i >= activity.pricings.pricings_list.length) {
						return null
					}

					const name = activity.pricings.same_pricing
						? 'same'
						: activity.pricings.pricings_list[i].name
					return {
						enabled: pricing.enabled,
						selling_price: parseFloat(pricing.selling_price),
						name,
						final_price: computeFinalPrice(pricing.selling_price)
					}
				})
				.filter(_ => _ !== null)

			const options = table.options.map(option => ({
				...option,
				selling_price: parseFloat(option.selling_price),
				final_price: computeFinalPrice(option.selling_price)
			}))

			return {
				...table,
				pricings,
				options,
				inclusions: {
					...table.inclusions,
					has: table.inclusions.has.filter(item => !!item),
					hasnt: table.inclusions.hasnt.filter(item => !!item)
				}
			}
		})
	}

	return {
		...activity,
		pricings
	}
}
