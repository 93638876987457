import { getQueryOptions } from '../Common/ticketingCommonQueries'
import { updatePricings } from '../StepPricings/pricingsDataQueries'

export const getPricingSchedulePageData = async (activitiesId, token, api) => {
	const pricings_table = []

	let schedulesEnabled = false
	let pricingEnabled = false
	for (let i = 0; i < activitiesId.length; i++) {
		const activityID = activitiesId[i]

		const activity = await fetch(
			`${api}/activities/${activityID}`,
			getQueryOptions(token)
		).then(data => data.json())

		if (activity) {
			if (activity.pricings.enabled) {
				pricingEnabled = true
			}

			if (activity.schedules.enabled) {
				schedulesEnabled = true
			}

			for (let j = 0; j < activity.pricings.pricings_table.length; j++) {
				const p = activity.pricings.pricings_table[j]

				if (!pricings_table.find(({ _id }) => _id === p._id)) {
					if (p.schedule_type === 'time_slots') pricings_table.push(p)
					else {
					}
				}
			}
		}
	}

	return {
		activity: {
			schedules: {
				enabled: schedulesEnabled
			},
			pricings: {
				enabled: pricingEnabled,
				pricings_table
			}
		}
	}
}

export const savePricingSchedulePageData = async (activity, token, api) => {
	for (let i = 0; i < activity.pricings.pricings_table.length; i++) {
		const { _id, ...pricing } = activity.pricings.pricings_table[i]

		await updatePricings(pricing, _id, token, api)
	}
}
