import React from 'react'

const CheckIcon = ({
	color = '#FFEDBD',
	style = {},
	height = 9,
	className = ''
}) => {
	return (
		<span className={className}>
			<svg
				height={height}
				viewBox="0 0 11 9"
				xmlns="http://www.w3.org/2000/svg"
				style={style}
			>
				<path
					d="M4.21211 8.3768C4.10711 8.48242 3.96385 8.54135 3.81503 8.54135C3.66622 8.54135 3.52296 8.48242 3.41796 8.3768L0.246821 5.20515C-0.0822737 4.87606 -0.0822737 4.34241 0.246821 4.01393L0.643895 3.61675C0.973093 3.28766 1.50612 3.28766 1.83522 3.61675L3.81503 5.59667L9.16478 0.246821C9.49398 -0.0822737 10.0275 -0.0822737 10.3561 0.246821L10.7532 0.643998C11.0823 0.973093 11.0823 1.50664 10.7532 1.83522L4.21211 8.3768Z"
					id="Shape"
					fill={color}
					stroke="none"
				/>
			</svg>
		</span>
	)
}

export default CheckIcon
