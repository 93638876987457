/*
 * Send mail to coq-trotteur for help / comments.
 */

import { gql } from '@apollo/client'
import { doGraphqlMutation } from '~requests/common'

type TSendAssistanceMail = {
	token: string
	message: string
	subject: string
	name: string
	email: string
	phone: string
}

export const sendAssistanceMail = async ({
	token,
	message,
	subject,
	name,
	email,
	phone
}: TSendAssistanceMail) => {
	const mutation = gql`
		mutation sendAssistanceMail($assistanceMailInput: AssistanceMailInput!) {
			sendAssistanceMail(assistanceMailInput: $assistanceMailInput) {
				id
				email
				message
				name
				phone
				subject
			}
		}
	`
	const variables = {
		assistanceMailInput: {
			message,
			subject,
			name,
			email,
			phone
		}
	}

	const result = await doGraphqlMutation({
		mutation,
		accessToken: token,
		variables
	})

	// console.log({ result })

	return true
}
