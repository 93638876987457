// @ts-check

import React, { useState, useRef, useContext, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import FormModifiedPopup from '~components/Ticketing/FormModifiedPopup'
import { scrollToFirstError } from '~utils/helpers'
import { FormErrorMessage } from '~components/Ticketing/FormToolbox'

const currentFormStateContext = React.createContext(null)

export const useCurrentFormStateContext = () => {
	return useContext(currentFormStateContext)
}

export const CurrentFormStateProvider = ({ children }) => {
	const stateGetterRef = useRef(null)
	const pendingFunctionRef = useRef(null)
	const currentStateRef = useRef(null)
	const currentEnvironmentRef = useRef(null)
	const [modifiedPopupOpen, setModifiedPopupOpen] = useState(false)
	const [errorNotification, setErrorNotification] = useState(null)
	const [saveIsLoading, setSaveIsLoading] = useState(false)

	const registerStateGetter = stateGetter => {
		stateGetterRef.current = stateGetter
	}

	const showErrorNotification = () => {
		setErrorNotification(true)

		setTimeout(() => {
			setErrorNotification(false)
		}, 2000)
	}

	const openModifiedPopup = (state, pendingFunction) => {
		setModifiedPopupOpen(true)
		currentStateRef.current = state
		pendingFunctionRef.current = pendingFunction
	}

	const launchPendingFunction = () => {
		if (
			pendingFunctionRef.current &&
			typeof pendingFunctionRef.current === 'function'
		)
			pendingFunctionRef.current()
	}

	return (
		<currentFormStateContext.Provider
			value={{
				currentEnvironmentRef,
				currentStateRef,
				stateGetterRef,
				openModifiedPopup,
				registerStateGetter,
				showErrorNotification
			}}
		>
			<div>
				<FormErrorMessage show={errorNotification} />
				<FormModifiedPopup
					saveIsLoading={saveIsLoading}
					isOpen={modifiedPopupOpen}
					onClickAbort={() => {
						setModifiedPopupOpen(false)
						launchPendingFunction()
					}}
					onClickCancel={() => {
						setModifiedPopupOpen(false)
					}}
					onClickSave={async () => {
						setSaveIsLoading(true)
						if (!currentStateRef.current) return
						const { save } = currentStateRef.current
						const success = await save()

						setTimeout(() => {
							setSaveIsLoading(false)
							if (success) launchPendingFunction()
							setModifiedPopupOpen(false)
						}, 1000)
					}}
				/>
			</div>

			{children}
		</currentFormStateContext.Provider>
	)
}

export const useFormHandler = (form, handleSave) => {
	const {
		registerStateGetter,
		showErrorNotification,
		currentEnvironmentRef
	} = useContext(currentFormStateContext)

	const formStateGetter = async () => {
		return {
			modified: form.modified,
			isValid: form.isValid,
			save: async (force = false) => {
				if (!form.isValid && force === false) {
					// console.log(`xxxxxxxxxxxxx`)
					console.log(`form.formData`, form.formData)
					form.checkErrors()
					form.logErrors()
					scrollToFirstError()
					showErrorNotification()
				} else {
					await handleSave(form.toJSON ? form.toJSON() : null)
					if (form.setModified) form.setModified(false)
				}
				return form.isValid
			},
			disableForcedSave: form.disableForcedSave || false
		}
	}

	useEffect(() => {
		registerStateGetter(formStateGetter)

		return () => {
			registerStateGetter(null)
		}
	}, [form])
}

export const useNavigation = () => {
	const {
		stateGetterRef,
		openModifiedPopup,
		currentEnvironmentRef
	} = useContext(currentFormStateContext)

	/**
	 *
	 * @param {()=>void | string} param
	 */
	const _navigate = param => {
		const go = () => {
			if (typeof param === 'function') {
				param()
			} else {
				navigate(`${param}`)
			}
		}

		const stateGetter = stateGetterRef.current
		if (stateGetter && typeof stateGetter === 'function') {
			stateGetter().then(state => {
				if (!state.modified && state.isValid) {
					go()
				} else {
					openModifiedPopup(state, go)
				}
			})
		} else go()
	}

	return _navigate
}

/**
 * {{
 *   to?: string
 *   children: any
 *   className?: string
 * }} props
 */
export const SuperLink = ({ to, children, className = '' }) => {
	const { stateGetterRef, openModifiedPopup } = useContext(
		currentFormStateContext
	)

	return (
		<Link
			to={to}
			className={className}
			onClick={e => {
				e.preventDefault()
				const stateGetter = stateGetterRef.current

				if (stateGetter && typeof stateGetter === 'function') {
					stateGetter().then(state => {
						if (!state.modified && state.isValid) {
							navigate(to)
						} else {
							openModifiedPopup(state, () => {
								navigate(to)
							})
						}
					})
				} else {
					navigate(to)
				}
			}}
		>
			{children}
		</Link>
	)
}

export const useCurrentFormState = () => {
	const { stateGetter, registerStateGetter } = useContext(
		currentFormStateContext
	)

	return {
		stateGetter,
		registerStateGetter
	}
}
