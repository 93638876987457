import React from 'react'
import DatePicker from './DatePicker'
import { FicheRemoveIcon } from './icons/FicheRemoveIcon'

export const FicheClosePeriodItem = ({ closePeriodForm, onChange = () => {} }) => {
	return (
		<div className="close-period-item">
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					color: 'white',
					width: 140
				}}
			>
				<div style={{ flex: 1, height: '100%' }}>
					<DatePicker dateRangeForm={closePeriodForm} onChange={onChange} />
				</div>

				<button
					type="button"
					className="remove-button"
					onClick={() => {
						closePeriodForm.remove()
						onChange()
					}}
				>
					<FicheRemoveIcon style={{ marginBottom: 2 }} />
				</button>
			</div>
		</div>
	)
}