export const bookingObject = [
  {
    activity: null,

    canceled: false,

    // politique annulation
    cancellation: {
      type: 'none',
    },
    date: '',

    enabled_date: false,

    items: [
      {
        accepted: null,

        benefits: [
          {
            name: '',
            count: 0,
            price: 0,
            canceled: false,
          },
        ],

        date_limit: '',

        enabled_date: false,

        id: null,

        lang: '',

        name: '',

        options: [
          {
            name: '',
            count: 0,
            price: 0,
            canceled: false,
          },
        ],

        schedule: {
          hours: 0,
          minutes: 0,
        },

        state: {
          status: 'pending_validation',
        },
      },
    ],

    message: '',

    reservation_id: null,

    reservation_ticket_number: '',

    total: 0,

    visitors: [
      {
        firstname: '',
        lastname: '',
      },
    ],
  },
]
