import React from 'react'

export const ClockIcon = () => {
	return (
		<svg
			width="70"
			height="70"
			viewBox="0 0 70 70"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="35" cy="35" r="35" fill="white" />
			<path
				d="M35 16C24.5382 16 16 24.5388 16 35C16 45.4618 24.5388 54 35 54C45.4618 54 54 45.4611 54 35C54 24.5382 45.4612 16 35 16ZM35 51.7734C25.7511 51.7734 18.2266 44.2489 18.2266 35C18.2266 25.7511 25.7511 18.2266 35 18.2266C44.2489 18.2266 51.7734 25.7511 51.7734 35C51.7734 44.2489 44.2489 51.7734 35 51.7734Z"
				fill="#172B49"
			/>
			<path
				d="M25.5557 45.5596C26.1705 45.5596 26.6689 45.0611 26.6689 44.4463C26.6689 43.8314 26.1705 43.333 25.5557 43.333C24.9408 43.333 24.4424 43.8314 24.4424 44.4463C24.4424 45.0611 24.9408 45.5596 25.5557 45.5596Z"
				fill="#172B49"
			/>
			<path
				d="M44.4443 26.667C45.0592 26.667 45.5576 26.1686 45.5576 25.5537C45.5576 24.9389 45.0592 24.4404 44.4443 24.4404C43.8295 24.4404 43.3311 24.9389 43.3311 25.5537C43.3311 26.1686 43.8295 26.667 44.4443 26.667Z"
				fill="#172B49"
			/>
			<path
				d="M24.7638 24.7665C24.3291 25.2013 24.3291 25.9061 24.7638 26.3409C25.1986 26.7757 25.9034 26.7757 26.3382 26.3409C26.773 25.9061 26.773 25.2013 26.3382 24.7665C25.9035 24.3317 25.1986 24.3317 24.7638 24.7665Z"
				fill="#172B49"
			/>
			<path
				d="M43.6618 43.6591C43.227 44.0939 43.227 44.7987 43.6618 45.2335C44.0966 45.6683 44.8014 45.6683 45.2362 45.2335C45.671 44.7987 45.671 44.0939 45.2362 43.6591C44.8014 43.2243 44.0965 43.2243 43.6618 43.6591Z"
				fill="#172B49"
			/>
			<path
				d="M35 24.9805C35.6148 24.9805 36.1133 24.482 36.1133 23.8672V21.6406C36.1133 21.0258 35.6148 20.5273 35 20.5273C34.3852 20.5273 33.8867 21.0258 33.8867 21.6406V23.8672C33.8867 24.482 34.3852 24.9805 35 24.9805Z"
				fill="#172B49"
			/>
			<path
				d="M35 45.0195C34.3852 45.0195 33.8867 45.518 33.8867 46.1328V48.3594C33.8867 48.9742 34.3852 49.4727 35 49.4727C35.6148 49.4727 36.1133 48.9742 36.1133 48.3594V46.1328C36.1133 45.518 35.6148 45.0195 35 45.0195Z"
				fill="#172B49"
			/>
			<path
				d="M24.9805 35C24.9805 34.3852 24.482 33.8867 23.8672 33.8867H21.6406C21.0258 33.8867 20.5273 34.3852 20.5273 35C20.5273 35.6148 21.0258 36.1133 21.6406 36.1133H23.8672C24.482 36.1133 24.9805 35.6148 24.9805 35Z"
				fill="#172B49"
			/>
			<path
				d="M45.0195 35C45.0195 35.6148 45.518 36.1133 46.1328 36.1133H48.3594C48.9742 36.1133 49.4727 35.6148 49.4727 35C49.4727 34.3852 48.9742 33.8867 48.3594 33.8867H46.1328C45.518 33.8867 45.0195 34.3852 45.0195 35Z"
				fill="#172B49"
			/>
			<path
				d="M36.1133 34.5389V28.3203C36.1133 27.7055 35.6148 27.207 35 27.207C34.3852 27.207 33.8867 27.7055 33.8867 28.3203V35C33.8867 35.2952 34.0041 35.5784 34.2128 35.7872L40.8925 42.4669C41.3272 42.9016 42.0322 42.9017 42.4669 42.4669C42.9017 42.0322 42.9017 41.3273 42.4669 40.8925L36.1133 34.5389Z"
				fill="#172B49"
			/>
		</svg>
	)
}
