import React, { useState } from 'react'

import './MessageBar.scss'

/**
 *
 * @param {{
 *  type?: 'info' | 'danger'
 *  trigger: Boolean
 *  message: String
 *  onClose: Function
 * }} props
 */
const MessageBar = ({ type = 'info', trigger, message, onClose }) => {
  return (
    <div className={`messageBar ${type} ${trigger ? 'is-visible' : ''}`}>
      {message}
      <button className="cross" onClick={onClose}>
        ×
      </button>
    </div>
  )
}

export default MessageBar
