import React, { useEffect, useRef } from 'react'

import Info from '../svg/information.svg'

import './InfoTooltip.scss'

//direction: 'top' || 'bottom' so far
const InfoTooltip = ({
	children,
	direction = 'top',
	horizontal = 'center',
	style = {}
}) => {
	const rootRef = useRef(null)

	return (
		<div
			ref={rootRef}
			className={`info-tooltip direction-${direction} horizontal-${horizontal}`}
			style={style}
		>
			<Info />
			{children && <div className="value">{children}</div>}
		</div>
	)
}

export default InfoTooltip
