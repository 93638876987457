import React, { useState, useReducer, useContext } from 'react'

import { setObject, removeInArray, addInArray } from '~utils/forms'
import { userDataObject } from './userDataObject'
import { steps } from '~requests/post'
import { getData } from '~requests/get'
import { getLocalstorageJSON } from '~utils/helpers'
import ClearSessionModal from './userDataContext/ClearSessionModal'
import { useModalWithPayload } from '~components/basics/Modal/Modal'
import { TUserData } from './userDataContext.types'

const userDataContext = React.createContext<TUserData>(null)
export const useUserData = () => React.useContext<TUserData>(userDataContext)

const sessionValidityContext = React.createContext({ invalidSession: () => {} })

export const useInvalidSession = () => useContext(sessionValidityContext)

const getCachedActivitiesId = userId => {
	const data = localStorage.getItem(`activities-${userId}`)

	if (!data) return null

	try {
		return JSON.parse(data)
	} catch {
		return null
	}
}

/*
 * Called in wrapRootElement (gatsby-browser.js)
 */
export const UserDataProvider = ({ children }) => {
	const apiUrl = process.env.GATSBY_API_URL

	// States

	const [userId, setUserId] = useState(localStorage.getItem('userId') || null)

	const [name, setName] = useState(
		localStorage.getItem(`name-${userId}`) || null
	)
	const [activityId, setId] = useState(
		localStorage.getItem(`activity-${userId}`) || null
	)
	const [activitiesId, setActivitiesId] = useState(
		getCachedActivitiesId(userId)
	)
	const [logged, setLogged] = useState(false)
	const [token, setToken] = useState(
		localStorage.getItem(`token-${userId}`) || null
	)

	const [onboarding, setOnboarding] = useState(
		localStorage.getItem(`onboarding-${userId}`) || null
	)

	const [tempMail, setTempMail] = useState(
		getLocalstorageJSON(`tempMail-${userId}`) || null
	)

	const [tempPwd, _setTempPwd] = useState(
		getLocalstorageJSON(`tempPwd-${userId}`) || null
	)

	const setTempPwd = data => {
		localStorage.setItem(`tempPwd-${userId}`, JSON.stringify(data))
		_setTempPwd(data)
	}

	const [shareLink, setShareLink] = useState('')

	const [saved, setSaved] = useState(false)

	// ***** REDUCER *****

	const initialState =
		localStorage.getItem(`userData-${userId}`) || userDataObject

	const reducer = (state, { payload, type }) => {
		let newState
		let obj
		let { i } = payload || {}
		switch (type) {
			case 'refreshData':
				getData(apiUrl, token, activityId).then(result => {
					dispatch({ type: 'get', payload: result })
					dispatch({ type: 'makeShareLink', payload: null })
					localStorage.setItem(`userData-${userId}`, JSON.stringify(result))
					setLogged(true)
				})
				return state

			case 'reset':
				setLogged(false)
				setUserId(null)
				setName(null)
				setId(null)
				setToken(null)
				setTempMail(null)
				setTempPwd(null)
				setShareLink(null)
				setActivitiesId(null)
				setOnboarding(null)
				return userDataObject

			case 'getFromLocal':
				return payload || null

			case 'makeShareLink':
				return state

			case 'get':
				newState = { ...state, ...payload }
				localStorage.setItem(`userData-${userId}`, JSON.stringify(newState))
				return newState

			case 'post':
				const result = { ...state, ...payload }
				localStorage.setItem(`userData-${userId}`, JSON.stringify(result))
				return result

			// TODO delete, useless
			case 'removeInclusions':
				newState = JSON.parse(JSON.stringify(state))
				newState.about.inclusions.has.splice(i, 1)
				newState.about.inclusions.hasnt.splice(i, 1)
				localStorage.setItem(`userData-${userId}`, JSON.stringify(newState))
				return newState

			case 'addInclusions':
				newState = JSON.parse(JSON.stringify(state))
				newState.about.inclusions.has.push('')
				newState.about.inclusions.hasnt.push('')
				localStorage.setItem(`userData-${userId}`, JSON.stringify(newState))
				return newState

			case 'removeStrongPoint':
				newState = JSON.parse(JSON.stringify(state))
				newState.about.strong_points.splice(i, 1)
				localStorage.setItem(`userData-${userId}`, JSON.stringify(newState))
				return newState

			case 'addStrongPoint':
				newState = JSON.parse(JSON.stringify(state))
				newState.about.strong_points.push('')
				localStorage.setItem(`userData-${userId}`, JSON.stringify(newState))
				return newState

			case 'updateOne':
				const { field, value, index } = payload
				obj = setObject({ ...state }, field, value, index)
				newState = { ...state, ...obj }
				localStorage.setItem(`userData-${userId}`, JSON.stringify(newState))
				return newState

			case 'remover':
				const { path, id } = payload
				obj = removeInArray({ ...state }, path, id)
				newState = { ...state, obj }
				localStorage.setItem(`userData-${userId}`, JSON.stringify(newState))
				return newState

			case 'adder':
				const { addTo, max } = payload
				obj = addInArray({ ...state }, addTo, max)
				newState = { ...state, obj }
				localStorage.setItem(`userData-${userId}`, JSON.stringify(newState))
				return newState

			case 'addPricing':
				newState = JSON.parse(JSON.stringify(state))
				if (newState.pricings.pricings_list.length === 8) {
					return state
				}
				newState.pricings.pricings_list.push('')
				localStorage.setItem(`userData-${userId}`, JSON.stringify(newState))
				return newState

			case 'reEnablePricings':
				newState = JSON.parse(JSON.stringify(state))
				newState.pricings.pricings_list = ['']
				localStorage.setItem(`userData-${userId}`, JSON.stringify(newState))
				return newState

			case 'deletePricings':
				newState = JSON.parse(JSON.stringify(state))
				newState.pricings.pricings_list = ['Plein tarif']
				localStorage.setItem(`userData-${userId}`, JSON.stringify(newState))
				return newState

			case 'step':
				let step
				if (typeof payload === 'number') {
					step = payload
				} else {
					step =
						payload === '+'
							? state.ticketing.current_step + 1
							: state.ticketing.current_step - 1
				}
				const ticket = { ...state.ticketing }
				const newTicketing = { ticketing: { ...ticket, current_step: step } }
				newState = { ...state, ...newTicketing }
				localStorage.setItem(`userData-${userId}`, JSON.stringify(newState))
				return newState

			case 'scheduling':
				newState = JSON.parse(JSON.stringify(state))
				newState.schedules.scheduling_offer = payload
				localStorage.setItem(`isScheduling-${userId}`, JSON.stringify(payload))
				steps(newState, apiUrl, token, activityId)
				return newState

			default:
				break
		}
	}

	const [userData, dispatch] = useReducer(reducer, initialState)
	const clearSessionModal = useModalWithPayload()

	const invalidSession = err => {
		console.error(`INVALID SESSION`, err)

		clearSessionModal.open({})

		setTimeout(() => {
			localStorage.clear()
			sessionStorage.clear()
			document.location.reload()
		}, 4000)
	}

	return (
		<sessionValidityContext.Provider
			value={{
				invalidSession
			}}
		>
			<userDataContext.Provider
				value={{
					name,
					setName,
					activityId,
					setId,
					logged,
					setLogged,
					apiUrl,
					token,
					setToken,
					userId,
					setUserId,
					userData,
					dispatch,
					tempMail,
					setTempMail,
					tempPwd,
					setTempPwd,
					shareLink,
					saved,
					setSaved,
					activitiesId,
					setActivitiesId,
					setShareLink,
					onboarding: false,
					setOnboarding,
					_onboarding: onboarding
				}}
			>
				<ClearSessionModal {...clearSessionModal} />
				{children}
			</userDataContext.Provider>
		</sessionValidityContext.Provider>
	)
}
