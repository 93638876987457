import React from 'react';
import moment from 'moment';
import 'moment/locale/fr';

const PrestationOptions = ({ options, prestation, ticket }) => {
    return (
        options?.map((option) => {
            return (<li key={option._id}>
                <div>{option.count}* {option.name} (Option {prestation.name}) - {option.price}€</div>
                <div>
                    {
                        prestation.schedule_type === 'time_duration'
                            ?
                            prestation.time_limit ? `Durée de validité ${prestation.time_limit} mois` : ''
                            :
                            prestation.schedule_type === 'time_slots'
                                ?
                                `Pour le ${moment(ticket?.date).format('DD/MM/yy')} à ${prestation?.time_slot?.hours}:${prestation?.time_slot?.minutes ? prestation?.time_slot?.minutes : '00'}`
                                :
                                `Pour le ${moment(ticket?.date).format('DD/MM/yy')}`
                    }
                    
                </div>
                </li>)
        })
    )
}

export default PrestationOptions;