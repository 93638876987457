import React, { useState, useRef } from 'react'
import { FormLine, Select, Input } from '~components/Ticketing/FormToolbox'
import InfoTooltip from '~components/basics/InfoTooltip'

const InfosGift = ({ giftForm }) => {
	const [acceptGift, setAcceptGift] = useState(null)
	const [giftCode, setGiftCode] = useState('')

	const codeInputRef = useRef(null)

	const enabled = giftForm.get('enabled').value

	return (
		<FormLine>
			Acceptez-vous le paiement en Chèque-Vacances Connect (CVCo) ?
			<div
				style={{
					width: 90,
					paddingLeft: 8
				}}
			>
				<Select
					emptyLabel=""
					options={[
						{ label: 'Oui', value: true },
						{ label: 'Non', value: false }
					]}
					value={enabled}
					setValue={value => {
						giftForm.get('enabled').setValue(value)

						if (value === true) {
							codeInputRef.current.focus()
						}
					}}
				/>
			</div>
			<div style={{ flex: 1 }} />
			<div
				style={{
					opacity: enabled ? 1 : 0,
					pointerEvents: enabled ? 'all' : 'none',
					position: enabled ? 'static' : 'absolute',
					display: 'flex',
					alignItems: 'center'
				}}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						width: 140
					}}
				>
					Votre Shop Id
					<InfoTooltip>
						Vous pouvez retrouver votre identifiant Shop Id dans votre espace
						ANCV, dans l’espace “Mon Compte” puis “Liste des points d’accueil”.
						En cas de question, contactez l’ancv sur www.ancv.com.
					</InfoTooltip>
				</div>
				<div>
					<Input
						targetRef={codeInputRef}
						style={{
							width: 120,
							marginLeft: 16,
							height: 41,
							border: `1px solid ${
								giftForm.get('shop_id').error
									? '#e65264'
									: 'rgba(23, 43, 73, 0.1)'
							}`
						}}
						placeholder="Ex : B32493"
						{...giftForm.get('shop_id')}
					/>
				</div>
			</div>
		</FormLine>
	)
}
export default InfosGift
