import React, { useRef, useState } from 'react'
import { useUserData } from '~contexts/userDataContext'
import TimesIcon from '~components/svg/TimesIcon'
import { getTransferToken } from '~queries/auth.queries'
import { createWebsite, updateWebsiteDomain } from '~queries/website.queries'

import WebsiteCreationDomain from './WebsiteCreationDomain/WebsiteCreationDomain'
import WebsiteCreationActivities from './WebsiteCreationActivities/WebsiteCreationActivities'
import WebsiteCreationMainInformations from './WebsiteCreationMainInformations/WebsiteCreationMainInformations'

import { waitWebsiteIsAvailable } from './WebsiteCreation.utils'

import './WebsiteCreation.scss'
import WebsiteCreationError from './WebsiteCreationError/WebsiteCreationError'

const WebsiteCreation = ({ handleCancel }) => {
	const { token } = useUserData()
	const [data, setData] = useState({
		name: '',
		activitiesId: []
	})
	const lastDirectionRef = useRef<'right' | 'left'>('right')
	const [currentStep, setCurrentStep] = useState(0)
	const [showCreationErrorScreen, setShowCreationErrorScreen] = useState(false)

	const steps = [
		WebsiteCreationMainInformations,
		WebsiteCreationActivities,
		WebsiteCreationDomain
	]

	const Step = steps[currentStep]

	const handleCreateWebsite = ({ domain, ...data }) => {
		createWebsite(
			{
				...data
			},
			token
		)
			.then(async website => {
				await updateWebsiteDomain(website.netlify.siteId, domain, token)
				waitWebsiteIsAvailable(website.netlify.siteId, domain, token)
					.then(async () => {
						const transferToken = await getTransferToken(token)
						const url = `//${domain}/session?token=${transferToken}`
						document.location.href = url
					})
					.catch(() => {
						setShowCreationErrorScreen(true)
					})
			})
			.catch(() => {
				setShowCreationErrorScreen(true)
			})
	}

	if (showCreationErrorScreen)
		return (
			<div className="WebsiteCreation">
				<WebsiteCreationError />
			</div>
		)

	return (
		<div className="WebsiteCreation">
			<button
				className="close-button"
				onClick={() => {
					handleCancel()
				}}
			>
				<TimesIcon color="dark" />
			</button>
			<Step
				data={data}
				onChange={(update = {}) => {
					lastDirectionRef.current = 'right'
					setData({
						...data,
						...update
					})

					if (currentStep === steps.length - 1) {
						handleCreateWebsite({
							...data,
							...update
						})
					} else {
						setCurrentStep(currentStep + 1)
					}
				}}
				handlePrevious={(update = {}) => {
					lastDirectionRef.current = 'left'
					setData({
						...data,
						...update
					})
					setCurrentStep(currentStep - 1)
				}}
				handleByPass={(update = {}) => {
					setData({
						...data,
						...update
					})

					if (lastDirectionRef.current === 'left') {
						setCurrentStep(currentStep - 1)
					} else if (lastDirectionRef.current === 'right') {
						setCurrentStep(currentStep + 1)
					}
				}}
			/>
		</div>
	)
}

export default WebsiteCreation
