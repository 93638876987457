import React, { useState, useEffect } from 'react'
import { FormSection, FormErrorMessage } from '../FormToolbox'
import useForm from 'react-super-useform'
import infosFormSchema from './infosFormSchema'
import { useInvalidSession, useUserData } from '~contexts/userDataContext'
import { getInfosPageData, saveActivityData } from './infosDataQueries'

import InfosNote from './sections/InfosNote'
import InfosGift from './sections/InfosGift'
import InfosHealth from './sections/InfosHealth'
import InfosFunFact from './sections/InfosFunFact'
import InfosInclusions from './sections/InfosInclusions'
import InfosStrongPoint from './sections/InfosStrongPoint'
import InfosEnvironment from './sections/InfosEnvironment'
import InfosTransportAccessibility from './sections/InfosTransportAccessibility'

import ActivitiesLinks from '../Common/ActivitiesLinks'
import { useFormHandler } from '~contexts/currentFormContext'

import './Infos.scss'

const Infos = ({ activity, handleSave, activitiesNames }) => {
	const [errorNotification, setErrorNotification] = useState(null)
	const form = useForm(infosFormSchema, { activity })
	const { setSaved } = useUserData()

	useFormHandler(form, handleSave)

	useEffect(() => {
		form.logErrors()
	}, [form.formData])

	const formChanged = e => {
		setSaved(false)
	}

	useEffect(() => {
		history.pushState('', 'infos', '?p=1') // TODO make it properly
		form.addEventListener('change', formChanged)
		setSaved(false)
	}, [])

	useEffect(() => {
		const beforeUnload = e => {
			var e = e || window.event

			if (e) {
				e.returnValue = 'Des données ne sont pas sauvegardées'
			}

			return 'Des données ne sont pas sauvegardées'
		}

		if (form.modified) {
			window.addEventListener('beforeunload', beforeUnload)
			return () => {
				window.removeEventListener('beforeunload', beforeUnload)
			}
		}
	}, [form.modified])

	return (
		<form
			style={{
				marginBottom: -90
			}}
		>
			<ActivitiesLinks activitiesNames={activitiesNames} />
			<FormErrorMessage show={errorNotification} />

			<FormSection>
				<InfosFunFact storyField={form.get('activity.story_telling.story')} />
				<hr />
				<InfosStrongPoint
					strongPointForm={form.get('activity.about.strong_points')}
				/>
				<hr />
				<InfosNote
					descriptionField={form.get('activity.about.note.description')}
				/>
				<hr />
				<InfosGift giftForm={form.get('activity.gift_coupon')} />
				<hr />
				<InfosEnvironment
					inDoorField={form.get('activity.accessibility.indoor')}
				/>
				<hr />
				<InfosHealth
					restrictionsForm={form.get('activity.accessibility.restrictions')}
				/>
				<hr />
				<InfosTransportAccessibility
					publicTransportField={form.get(
						'activity.accessibility.public_transports'
					)}
				/>
				<hr />
				<InfosInclusions
					inclusionsForm={form.get('activity.about.inclusions')}
				/>
			</FormSection>
		</form>
	)
}

const transformPricingsDataFromForm = activity => {
	return {
		...activity,
		about: {
			...activity.about,
			inclusions: {
				...activity.about.inclusions,
				has: activity.about.inclusions.has.filter(item => !!item),
				hasnt: activity.about.inclusions.hasnt.filter(item => !!item)
			}
		}
	}
}

const InfosWrapper = ({ setCanGoNext, ...props }) => {
	const { activitiesId, token, dispatch, apiUrl, userData } = useUserData()
	const [activity, setActivity] = useState(null)
	const [activitiesNames, setActivitiesNames] = useState(null)
	const { done: ticketingDone } = userData?.ticketing

	const { invalidSession } = useInvalidSession()

	useEffect(() => {
		getInfosPageData(activitiesId, token, apiUrl)
			.then(({ activity, activitiesNames }) => {
				setActivity(activity)
				setActivitiesNames(activitiesNames)
			})
			.catch(err => {
				console.log(err)
				invalidSession(err)
			})
	}, [])

	const handleSave = async ({ activity }) => {
		await saveActivityData(
			transformPricingsDataFromForm(activity),
			activitiesId,
			token,
			apiUrl
		)

		if (!ticketingDone) {
			dispatch({ type: 'step', payload: '+' })
		}
	}

	if (!activity || !activitiesNames) return null

	return (
		<Infos
			activity={activity}
			handleSave={handleSave}
			setCanGoNext={setCanGoNext}
			activitiesNames={activitiesNames}
			{...props}
		/>
	)
}

export default InfosWrapper
