import React from 'react'

const CameraIcon = () => (
	<svg
		width="72"
		height="72"
		viewBox="0 0 72 72"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M51.2132 54.2132C55.1185 50.3079 55.1185 43.9763 51.2132 40.071C47.308 36.1658 40.9763 36.1658 37.0711 40.071C33.1658 43.9763 33.1658 50.3079 37.0711 54.2132C40.9763 58.1184 47.308 58.1184 51.2132 54.2132Z"
			fill="#B2F0FB"
		/>
		<path
			d="M44.4775 29.9624C42.1788 29.9624 39.946 30.4082 37.8416 31.2872C37.1449 31.5783 36.8161 32.379 37.107 33.0758C37.3981 33.7726 38.1989 34.101 38.8956 33.8103C40.6645 33.0714 42.5424 32.6968 44.4775 32.6968C52.4686 32.6968 58.9697 39.198 58.9697 47.189C58.9697 55.1799 52.4686 61.6812 44.4775 61.6812C36.4865 61.6812 29.9854 55.1799 29.9854 47.189C29.9854 45.2569 30.3589 43.3813 31.0956 41.6149C31.3863 40.918 31.057 40.1175 30.3601 39.8268C29.6637 39.5365 28.8626 39.8655 28.572 40.5623C27.6954 42.6641 27.251 44.8936 27.251 47.189C27.251 56.6878 34.9787 64.4155 44.4775 64.4155C53.9764 64.4155 61.7041 56.6878 61.7041 47.189C61.7041 37.6902 53.9764 29.9624 44.4775 29.9624Z"
			fill="#172B49"
			stroke="#172B49"
		/>
		<path
			d="M44.4775 58.3999C50.6593 58.3999 55.6885 53.3707 55.6885 47.189C55.6885 41.0072 50.6593 35.978 44.4775 35.978C38.2958 35.978 33.2666 41.0072 33.2666 47.189C33.2666 53.3707 38.2959 58.3999 44.4775 58.3999ZM44.4775 38.7124C49.1516 38.7124 52.9541 42.5151 52.9541 47.189C52.9541 51.8628 49.1516 55.6655 44.4775 55.6655C39.8035 55.6655 36.001 51.863 36.001 47.189C36.001 42.515 39.8035 38.7124 44.4775 38.7124Z"
			fill="#172B49"
			stroke="#172B49"
		/>
		<path
			d="M64.0662 23.3999H59.9072L55.1337 15.3216C54.8877 14.9053 54.4401 14.6499 53.9566 14.6499H34.9983C34.5147 14.6499 34.0672 14.9053 33.8213 15.3216L29.0477 23.3999H21.2344V21.7593C21.2344 19.0453 19.0265 16.8374 16.3125 16.8374C13.5985 16.8374 11.3906 19.0453 11.3906 21.7593V23.3999H7.93383C4.11049 23.3999 1 26.5104 1 30.3339V64.0441C1 67.8675 4.11049 70.978 7.93383 70.978H64.066C67.8894 70.978 70.9999 67.8675 70.9999 64.0441V30.3339C71 26.5104 67.8894 23.3999 64.0662 23.3999ZM35.7784 17.3843H53.1764L56.7311 23.3999H32.2237L35.7784 17.3843ZM14.125 21.7593C14.125 20.5531 15.1064 19.5718 16.3125 19.5718C17.5186 19.5718 18.5 20.5531 18.5 21.7593V23.3999H14.125V21.7593ZM68.2656 64.0441C68.2656 66.3598 66.3818 68.2436 64.0662 68.2436H7.93383C5.61822 68.2436 3.73438 66.3597 3.73438 64.0441V30.3339C3.73438 28.0181 5.61822 26.1343 7.93383 26.1343H64.066C66.3816 26.1343 68.2655 28.0181 68.2655 30.3339V64.0441H68.2656Z"
			fill="#172B49"
			stroke="#172B49"
		/>
		<path
			d="M44.4775 44.5229C45.9477 44.5229 47.1436 45.719 47.1436 47.189C47.1436 47.9441 47.7556 48.5562 48.5107 48.5562C49.2658 48.5562 49.8779 47.9441 49.8779 47.189C49.8779 44.2112 47.4553 41.7886 44.4775 41.7886C43.7224 41.7886 43.1104 42.4008 43.1104 43.1558C43.1104 43.9107 43.7224 44.5229 44.4775 44.5229Z"
			fill="#172B49"
			stroke="#172B49"
		/>
		<path
			d="M44.4775 11.0529C45.2326 11.0529 45.8447 10.4407 45.8447 9.68576V2.38867C45.8447 1.63371 45.2326 1.02148 44.4775 1.02148C43.7224 1.02148 43.1104 1.63357 43.1104 2.38867V9.68576C43.1104 10.4409 43.7224 11.0529 44.4775 11.0529Z"
			fill="#172B49"
			stroke="#172B49"
		/>
		<path
			d="M50.9829 11.053C51.3327 11.053 51.6827 10.9194 51.9496 10.6525L56.966 5.63617C57.4999 5.10229 57.4999 4.23658 56.9658 3.70256C56.4319 3.16881 55.5662 3.16881 55.0324 3.70256L50.0161 8.71905C49.4823 9.25293 49.4823 10.1186 50.0161 10.6527C50.2832 10.9195 50.633 11.053 50.9829 11.053Z"
			fill="#172B49"
			stroke="#172B49"
		/>
		<path
			d="M37.0051 10.6525C37.2721 10.9193 37.622 11.0529 37.9718 11.0529C38.3217 11.0529 38.6717 10.9193 38.9386 10.6525C39.4724 10.1186 39.4726 9.25288 38.9387 8.71885L33.9223 3.70251C33.3885 3.16889 32.5228 3.16876 31.9889 3.70251C31.455 4.23639 31.4548 5.1021 31.9887 5.63612L37.0051 10.6525Z"
			fill="#172B49"
			stroke="#172B49"
		/>
		<path
			d="M19.8672 34.6108H12.7578C12.0027 34.6108 11.3906 35.2231 11.3906 35.978C11.3906 36.7331 12.0027 37.3452 12.7578 37.3452H19.8672C20.6223 37.3452 21.2344 36.7331 21.2344 35.978C21.2344 35.2229 20.6223 34.6108 19.8672 34.6108Z"
			fill="#172B49"
			stroke="#172B49"
		/>
		<path
			d="M33.2603 37.3447C33.6198 37.3447 33.9726 37.1984 34.2269 36.9441C34.4812 36.6898 34.6274 36.3383 34.6274 35.9775C34.6274 35.618 34.4812 35.2652 34.2269 35.0109C33.9726 34.7568 33.6198 34.6104 33.2603 34.6104C32.9007 34.6104 32.5479 34.7566 32.2937 35.0109C32.038 35.2652 31.8931 35.6181 31.8931 35.9775C31.8931 36.3371 32.038 36.6898 32.2937 36.9441C32.5479 37.1984 32.8993 37.3447 33.2603 37.3447Z"
			fill="#172B49"
			stroke="#172B49"
		/>
	</svg>
)
export default CameraIcon
