import TPicture from 'src/types/PictureType'
import { isBrowser } from './misc'
import {getPictureURL as aws_getPictureURL} from './aws.image'

/**
 *
 * @param {import("src/types/PictureType").TPicture} image
 * @param {*} params
 */
export const getImageUrl = (image, params = 'f_auto') => {
	if (!image) return null

	const { public_id, format, url } = image

	if (public_id)
		return `https://res.cloudinary.com/coq-trotteur/image/upload/${params}/${public_id}${
			format ? `.${format}` : ''
		}`

	if (url) return url

	return null
}

const CLOUDINARY_BASE_URL = `https://res.cloudinary.com/coq-trotteur/image/upload`

type TGetPictureURLParams = {
	w?: number
	h?: number
	c?: 'fill' | 'limit'
	q?: 'auto' | number
	f?: 'auto'
}

const defaultParams: TGetPictureURLParams = {
	f: 'auto',
	q: 'auto'
}

const generateCloudinaryURLParamsString = (params: TGetPictureURLParams) => {
	if (!isBrowser()) return '';
	const list = []

	Object.keys(params).forEach(key => {
		const value = params[key]

		if (['w', 'h'].includes(key)) {
			list.push(`${key}_${Math.ceil(value * window.devicePixelRatio)}`)
		} else {
			if (value !== null) list.push(`${key}_${value}`)
		}
	})

	return list.join(',')
}

const generateCloudinaryURL = (public_id, params: TGetPictureURLParams) => {
	const paramsString = generateCloudinaryURLParamsString({
		...defaultParams,
		...params
	})
	return `${CLOUDINARY_BASE_URL}/${paramsString}/${public_id}`
}

/*
 * New function. TODO : migrate to this function everywhere.
 * Take a picture (TPicture) and generate URL.
 */
export const getPictureURL = (
	picture: TPicture,
	params: TGetPictureURLParams
) => {
	if (picture?.url) return aws_getPictureURL(picture, params as any)

	if (picture?.public_id) {
		return generateCloudinaryURL(picture.public_id, params)
	}

	if (picture?.url) {
		return picture.url
	}

	return null
}
