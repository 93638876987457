import React, { useState, useRef, useEffect } from 'react'

import DashboardTemplate from '~components/templates/DashboardTemplate'

import { withAuth } from '~HOC/withAuth'
import { useUserData } from '~contexts/userDataContext'
import OnboardingNavbar from '~components/Ticketing/OnboardingNavbar'

// Steps
import Infos from '~components/Ticketing/StepInfos'
import Booking from '~components/Ticketing/StepBooking'
import Pricings from '~components/Ticketing/StepPricings'
import Schedules from '~components/Ticketing/StepSchedules'
import PricingSchedule from '~components/Ticketing/StepPricingSchedule'

import { saveCurrentStep } from '~components/Ticketing/Common/ticketingCommonQueries'

import {
	useNavigation,
	useCurrentFormStateContext
} from '~contexts/currentFormContext'

import '../../style/fiche.scss'

/**
 *
 * @param {{currentStep: number}} props
 */
const FicheStep = ({ currentStep, ...props }) => {
	const StepComponents = [
		null,
		Infos,
		Booking,
		Pricings,
		Schedules,
		PricingSchedule
	]

	const CurrentStep = StepComponents[currentStep]

	return <CurrentStep {...props} />
}

const FichePage = () => {
	const { token, apiUrl, activitiesId } = useUserData()
	const [currentStep, setCurrentStep] = useState(1)
	const validationFunctionRef = useRef(null)

	const { stateGetterRef, currentEnvironmentRef } = useCurrentFormStateContext()
	const navigate = useNavigation()

	useEffect(() => {
		currentEnvironmentRef.current = 'fiche'
	}, [])

	if (stateGetterRef.current) stateGetterRef.current().then(console.log)

	const handleChangeCurrentStep = async step => {
		if (step > 5) {
			return
		}

		// Save button
		if (currentStep === step) {
			if (
				stateGetterRef.current &&
				typeof stateGetterRef.current === 'function'
			) {
				const state = await stateGetterRef.current()
				const success = await state.save()
				return success
			}
			return false
		}

		navigate(async () => {
			await saveCurrentStep(step, activitiesId, token, apiUrl)
			setCurrentStep(step)
		})
	}

	const registerValidationFunction = func => {
		validationFunctionRef.current = func
	}

	return (
		<div className="onboarding">
			<div className="onboarding-steps-template">
				<OnboardingNavbar
					currentStep={currentStep}
					handleChangeCurrentStep={handleChangeCurrentStep}
					showSaveButton={true}
				/>
				<div className="content">
					<FicheStep
						currentStep={currentStep}
						registerValidationFunction={registerValidationFunction}
						handleChangeCurrentStep={handleChangeCurrentStep}
						bypassStep={() => {
							handleChangeCurrentStep(currentStep + 1)
						}}
					/>
				</div>
			</div>
		</div>
	)
}

const FichePageContainer = (...props) => (
	<DashboardTemplate currentPage="fiche">
		<FichePage {...props} />
	</DashboardTemplate>
)

export default withAuth(FichePageContainer)
