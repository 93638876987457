const AWS_PICTURE_BASE_URL = 'https://d3nib4pa45io4l.cloudfront.net'

export type TGetPictureURLParams = {
	w?: number
	h?: number
	fill?: string // Hexadecimal (#fff)
	q?: number
	f?: 'auto' | 'jpg'
	fit?: 'cover' | 'stretch'
	format?: 'jpeg' | 'png'
}

const defaultParams = {
	w: 0,
	h: 0,
	q: 10
}

const getUrlParamsString = (params_: TGetPictureURLParams) => {
	const params = { ...defaultParams, ...params_ }
	let pString = `/${Math.round(
		params.w // * window.devicePixelRatio
	)}x${Math.round(
		params.h // * window.devicePixelRatio
	)}/filters:format(${params.format || 'png'})`

	if (params.fill) {
		pString += `/filters:fill(${params.fill})`
	}

	return pString
}

export const getPictureURL = (picture: any, params: TGetPictureURLParams) => {
	if (!picture) return null
	let pictureId = ''

	if (!picture) return null

	if (picture.cover || picture.avatar || picture.url) {
		let url = picture.avatar || picture.cover || picture.url || ''
		if (typeof url === 'string' && params && Object.keys(params).length > 0) {
			url = url.split('?')[0]
			// return url

			if (url.includes('.s3.eu-west-3.amazonaws.com/')) {
				pictureId = url?.split('.s3.eu-west-3.amazonaws.com/')[1]
			} else if (url.includes('cellar-c2.services.clever-cloud.com/')) {
				pictureId = url?.split('cellar-c2.services.clever-cloud.com/')[1]
			} else return url
		} else return url
	} else return null

	const filter = getUrlParamsString(params)

	return AWS_PICTURE_BASE_URL + filter + '/' + pictureId
}
