import React, {  useReducer } from 'react'

import { useUserData } from '~contexts/userDataContext'
import { pricingsObject } from '~contexts/pricingsObject'
import { getLocalstorageJSON } from '~utils/helpers'

const pricingsContext = React.createContext({})

export const usePricings = () => React.useContext(pricingsContext)

/*
 * Called in wrapRootElement (gatsby-browser.js)
 */
export const PricingsProvider = ({ children }) => {
	const apiUrl = process.env.GATSBY_API_URL

	// ***** Context *****
	const { token, activityId, userData } = useUserData()

	// ***** Reducer *****
	const _id = localStorage.getItem('userId')

	const initialState = getLocalstorageJSON(`pricings-${_id}`) || [
		pricingsObject
	]

	const reducer = (state, { payload, type }) => {
		let newState
		let newArr = []
		switch (type) {
			case 'reset':
				return [pricingsObject]

			case 'getAll':
				localStorage.setItem(`pricings-${_id}`, JSON.stringify(payload))
				newArr = payload && payload.length
					? JSON.parse(JSON.stringify(payload))
					: [pricingsObject]
				return newArr 

			case 'addPresta':
				// const { callback, data } = payload
				newArr = JSON.parse(JSON.stringify(state))
				newArr.push(pricingsObject)
				localStorage.setItem(`pricings-${_id}`, JSON.stringify(newArr))
				return newArr

			case 'removePresta':
				newArr = JSON.parse(JSON.stringify(state))
				newArr.splice(payload, 1)
				localStorage.setItem(`pricings-${_id}`, JSON.stringify(newArr))
				return newArr

			case 'updateAll':
				let { obj, position } = payload
				newArr = JSON.parse(JSON.stringify(state))
				newArr[position] = obj
				localStorage.setItem(`pricings-${_id}`, JSON.stringify(newArr))
				return newArr

			case 'strikeOne':
				let { field, pos, index } = payload
				let clone = JSON.parse(JSON.stringify(state))
				let newObj = [...state[pos][field]]
				newObj[index].name = ''
				newObj[index].selling_price = ''
				newObj[index].final_price = ''
				newObj[index].tva = ''
				newArr = { ...state[pos], options: newObj }
				clone[pos] = newArr
				localStorage.setItem(`pricings-${_id}`, JSON.stringify(clone))
				return clone

			// case 'ticketingDone':

			default:
				break
		}
	}

	const [pricings, setPricings] = useReducer(reducer, initialState)

	return (
		<pricingsContext.Provider
			value={{
				pricings,
				setPricings
			}}
		>
			{children}
		</pricingsContext.Provider>
	)
}
