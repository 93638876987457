import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { log } from '~utils/helpers'
import { getTicketPricings, getTicketOptions, getTicketTva } from '@coq-trotteur/coq-core/dist'

const Total = ({ FEE, bill }) => {
	const [total, setTotal] = useState({})

	useEffect(() => {
		let subTTC = bill.reduce((acc, curr) => {
			return acc + curr.total
		}, 0)

		let optionsHT = 0
		let subHT = 0

		bill.forEach(obj => {
			
			let ticket = obj
			if (ticket.marketPricings) {
				let total = 0
					getTicketPricings(ticket).map(pricing => {
						total += pricing.quantity * pricing.price
					})
					getTicketOptions(ticket).filter(option => option.type === 'required').map(option => {
						total += option.quantity || 1 * option.price
					})
				
				subHT +=
					total - getTicketTva(ticket) +
					(ticket.delivery ? ticket.delivery.price || 0 : 0)
			}
			
		})

		subHT += optionsHT
		subHT = subHT - (subTTC * FEE) / 100 - 0.15

		subTTC =
			subTTC -
			((subTTC * FEE) / 100 + (((subTTC * FEE) / 100) * 20) / 100) -
			0.18

		log(`HT: ${subHT}`)

		// const tva = bill.reduce((acc, curr) => {
		//   const opt = curr.items.reduce((accOpt, currOpt) =>)
		//   return curr.items.tva
		// })

		const total = {
			ttc: subTTC,
			tva: subTTC - subHT,
			ht: subHT
		}

		log(`subTTC ${subTTC}`)

		setTotal(total)
	}, [])
	return (
		<div>
			{/*<HT>
        <div>Total HT</div>
        <div>{total.ht?.toFixed(2).replace('.', ',')}€</div>
      </HT>

      <TVA>
        <div>Total TVA</div>
        <div>{total.tva?.toFixed(2).replace('.', ',')}€</div>
      </TVA>

      <TTC>
        <div>Total TTC</div>
        <div>{total.ttc?.toFixed(2).replace('.', ',')}€</div>
        </TTC>*/}
		</div>
	)
}

export default Total

const HT = styled.div`
	width: 100%;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
`
const TVA = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
`

const TTC = styled.div`
	width: 100%;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	display: flex;
	justify-content: space-between;
`
