import React, { useState, useRef, useEffect } from 'react'
import FichePage from '~components/pages/Fiche/FichePage/FichePage'

import DashboardTemplate from '~components/templates/DashboardTemplate'

import { withAuth } from '~HOC/withAuth'

import '../../style/fiche.scss'

const FichePageContainer = (...props) => (
	<DashboardTemplate currentPage="fiche">
		<FichePage />
	</DashboardTemplate>
)

export default withAuth(FichePageContainer)
