import React from 'react'

const TrashIcon = ({
	color,
	height = 22,
	width = 20
}: {
	color: 'dark' | 'white'
	height?: number
	width?: number
}) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 20 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14.0825 8.03144L12.4055 7.97119L12.042 17.6966L13.7189 17.7566L14.0825 8.03144Z"
			fill={color === 'dark' ? '#172B49' : 'white'}
		/>
		<rect
			x="9.16113"
			y="8.00122"
			width="1.67799"
			height="9.72544"
			fill={color === 'dark' ? '#172B49' : 'white'}
		/>
		<path
			d="M7.95745 17.6961L7.5939 7.9707L5.91699 8.03099L6.28059 17.7564L7.95745 17.6961Z"
			fill={color === 'dark' ? '#172B49' : 'white'}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0.015625 5.59025V3.94653H19.9839V5.59025H18.2072L16.82 21.0309C16.7823 21.4553 16.4193 21.781 15.9844 21.781H3.98688C3.55203 21.781 3.18927 21.4556 3.15123 21.0312L1.76412 5.59025H0.015625ZM4.75516 20.1377H15.2159L16.5228 5.59062H3.44855L4.75516 20.1377Z"
			fill={color === 'dark' ? '#172B49' : 'white'}
		/>
		<path
			d="M12.7402 0.795898H7.25868C6.48763 0.795898 5.86035 1.41037 5.86035 2.16566V4.76825H7.53834V2.43961H12.4605V4.76825H14.1385V2.16566C14.1385 1.41037 13.5112 0.795898 12.7402 0.795898Z"
			fill={color === 'dark' ? '#172B49' : 'white'}
		/>
	</svg>
)
export default TrashIcon
