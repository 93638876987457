import React from 'react';
import { prestationIsOnDate } from './reservations.service';
import moment from 'moment';
import 'moment/locale/fr';

const PrestationBenefits = ({ benefits, prestation, ticket }) => {
    return (
        benefits?.map((benefit) => {
            return (<li key={benefit._id}>
                <div>{benefit.count}* {prestation.name} ({benefit.name}) - {benefit.price}€</div>
                <div>
                {
                    prestation.schedule_type === 'time_duration'
                    ?
                        prestation.time_limit ? `Durée de validité ${prestation.time_limit} mois` : ''
                    :
                    prestation.schedule_type === 'time_slots'
                    ?
                            `Pour le ${moment(ticket?.date).format('DD/MM/yy')} à ${prestation?.time_slot?.hours}:${prestation?.time_slot?.minutes ? prestation?.time_slot?.minutes : '00'}`
                    :
                                `Pour le ${moment(ticket?.date).format('DD/MM/yy')}`
                }
                {prestation.lang ? ' - ' +prestation.lang : ''}
                </div>
                </li>)
        })
    )
}

export default PrestationBenefits;