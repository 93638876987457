import React from 'react'
import { FicheClosedIcon } from './icons/FicheClosedIcon'

const CloseScheduleBtn = ({ enabled, onClick }) => {
	return (
		<div
			className="CloseScheduleBtn"
		>
			<div
			role="button"
			tabIndex={0}
			onClick={onClick}>
				<FicheClosedIcon />
				{enabled
					? `Placer la fiche en “Fermé temporairement”`
					: `Votre fiche est “Fermée” - Cliquez ici pour passer en “Ouvert”`}
			</div>
		</div>
	)
}

export default CloseScheduleBtn
