import gql from 'graphql-tag'
import { useUserData } from '~contexts/userDataContext'
import { doGraphqlQuery } from '~requests/common'
import { timeout } from '~utils/misc'

const getSiteTLSCertificate = async (siteId: string, accessToken: string) => {
	const query = gql`
		query($siteId: String!) {
			getSiteTLSCertificate(siteId: $siteId) {
				state
				domains
			}
		}
	`

	const variables = {
		siteId
	}

	const { getSiteTLSCertificate } = await doGraphqlQuery({
		query,
		accessToken,
		variables
	})
	return getSiteTLSCertificate
}

export const waitWebsiteIsAvailable = async (
	siteId: string,
	domain: string,
	accessToken: string
) => {
	for (let i = 0; i < 100; i++) {
		const TLSCertificate = await getSiteTLSCertificate(siteId, accessToken)
		// console.log(TLSCertificate?.state)

		if (TLSCertificate?.state === 'issued') return

		await timeout(2000)
	}

	throw 'Error while waiting for domain TLS certificate'
}
