import React, { useState, useEffect } from 'react'
import useForm from 'react-super-useform'

// Form schema & queries
import bookingFormSchema from './bookingFormSchema'
import { getBookingPageData } from './bookingDataQueries'
import { saveActivityData } from '../StepInfos/infosDataQueries'

// Form sections
import BookingContact from './sections/BookingContact'
import BookingPlaceOfSupport from './sections/BookingPlaceOfSupport'
import BookingReservationInformation from './sections/BookingReservationInformation'

// Utils
import { FormSection, FormErrorMessage } from '../FormToolbox'
import { useInvalidSession, useUserData } from '~contexts/userDataContext'
import { useFormHandler } from '~contexts/currentFormContext'

const BookingPage = ({ activity, handleSave, registerValidationFunction }) => {
	const [errorNotification, setErrorNotification] = useState(null)
	const form = useForm(bookingFormSchema, { activity })

	useFormHandler(form, handleSave)

	form.logErrors()
	const { setSaved } = useUserData()
	const beforeUnload = e => {
		var e = e || window.event

		if (e) {
			e.returnValue = 'Des données ne sont pas sauvegardées'
		}

		return 'Des données ne sont pas sauvegardées'
	}

	const formChanged = e => {
		setSaved(false)
	}

	useEffect(() => {
		history.pushState('', 'booking', '?p=2')
		form.addEventListener('change', formChanged)
		setSaved(false)
	}, [])

	useEffect(() => {
		if (form.modified) {
			window.addEventListener('beforeunload', beforeUnload)
			return () => {
				window.removeEventListener('beforeunload', beforeUnload)
			}
		}
	}, [form.modified])

	const handleSaveWithCheckErrors = e => {
		e.preventDefault()
		form.checkErrors()
		if (form.isValid) {
			handleSave(form.toJSON())
			form.setModified(false)
		} else {
			setErrorNotification(true)

			setTimeout(() => {
				setErrorNotification(false)
			}, 4000)

			document.querySelector('.content').scrollTo(0, 0)
		}
	}

	return (
		<>
			<form id="ticketing-2" onSubmit={handleSaveWithCheckErrors}>
				<FormErrorMessage show={errorNotification} />
				<FormSection>
					<BookingPlaceOfSupport
						startingLocationForm={form.get(
							'activity.accessibility.starting_location'
						)}
					/>
					<hr />

					<BookingContact activityForm={form.get('activity')} />

					<hr />

					<BookingReservationInformation
						BookingForm={form.get('activity.booking')}
					/>
				</FormSection>
			</form>
		</>
	)
}

const BookingPageWrapper = ({ ...props }) => {
	const { activitiesId, token, dispatch, apiUrl, userData } = useUserData()
	const { done: ticketingDone } = userData?.ticketing
	const [activity, setActivity] = useState(null)

	const { invalidSession } = useInvalidSession()
	useEffect(() => {
		getBookingPageData(activitiesId[0], token, apiUrl)
			.then(({ activity }) => {
				setActivity(activity)
			})
			.catch(err => {
				invalidSession(err)
			})
	}, [])

	const handleSave = async (formData, previous = false) => {
		const { activity } = formData

		activity.contacts.mail = activity.booking.informations.mail

		await saveActivityData(activity, activitiesId, token, apiUrl)
		if (!ticketingDone) {
			dispatch({ type: 'step', payload: previous ? '-' : '+' })
		}
	}

	if (!activity) return null

	return <BookingPage activity={activity} handleSave={handleSave} {...props} />
}

export default BookingPageWrapper
