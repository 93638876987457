import { Router } from '@reach/router'
import React, { useEffect, useState } from 'react'
import { useUserData } from '~contexts/userDataContext'
import MarketItemEditor from '~components/Market/MarketItemEditor/MarketItemEditor'
import { getUserMarketItem } from '~components/Market/queries/MarketItem.queries'

const Editer = ({ marketItem, id }) => {
	return (
		<div
			style={{
				paddingTop: 50
			}}
		>
			<MarketItemEditor
				marketItem={marketItem}
				basePath={`/boutique/editer/${id}`}
				isInsideRouter={true}
			/>
		</div>
	)
}

const EditerContainer = ({ id }) => {
	const [marketItem, setMarketItem] = useState(null)

	const { token } = useUserData()

	useEffect(() => {
		getUserMarketItem(id, token).then(result => {
			setMarketItem({
				...result,
				activities: result.activities?.map(({ id }) => id) || [],
				category: result.category?.id || null,
				informations: {
					...result.informations,
					moods: result.informations.moods.map(({ id }) => id)
				}
			})
		})
	}, [])

	if (!marketItem) return null
	return <Editer marketItem={marketItem} id={id} />
}

const EditerRouter = () => {
	return (
		<Router>
			<EditerContainer path="/boutique/editer/:id/*" />
		</Router>
	)
}

export default EditerRouter
