import { text_field, bool_field, number_field } from 'react-super-useform'

const uuid = require('uuid')

export default {
	activity: {
		pricings: {
			same_pricing: bool_field({ default: false }),
			informations: text_field({ required: false }),
			enabled: bool_field({ default: false }),
			pricings_list: {
				type: Array,
				children: {
					name: text_field({
						validation: (value, get) => {
							if (get('parent.parent.parent').toJSON().same_pricing === true)
								return true

							return value.length > 0
						}
					}),
					id: text_field({
						default: () => uuid.v4()
					})
				},
				min: 2,
				constrainMinMax: true
			},
			pricings_table: {
				type: Array,
				min: 1,
				constrainMinMax: true,
				children: {
					name: text_field(),
					activities: {
						type: Array,
						min: 1,
						children: text_field() // Activities id, used to dispatch save btw activities
					},
					description: text_field({ required: false }),
					cancellation_type: text_field({ default: 'standard' }),
					cancellation_weather: bool_field({ default: false }),
					confirmation_method: text_field({ default: 'instant' }),
					schedule_type: text_field({ default: null }),
					spots: {
						set: bool_field({ default: false }),
						max: number_field({
							validation: (value, get) => {
								if (!get('parent.set').value) return true

								return value > 0
							}
						})
					},
					tva: number_field({
						default: 0
					}),
					inclusions: {
						has: {
							type: Array,
							children: text_field({ validation: () => true }),
							min: 1,
							max: 8,
							constrainMinMax: true
						},
						hasnt: {
							type: Array,
							children: text_field({ validation: () => true }),
							min: 1,
							max: 8,
							constrainMinMax: true
						}
					},
					starting_location: {
						enabled: bool_field({ required: false, default: true }),
						note: text_field({ required: false }),
						address: text_field({ required: false })
					},
					time_limit: number_field({
						validation: (value, get) => {
							if (get('parent.schedule_type').value !== 'time_duration')
								return true

							return value !== null
						}
					}),
					lang: {
						force: bool_field(),
						selection: {
							type: Array,
							validation: (value, get) => {
								if (!get('parent.force').value) return true

								return value.length > 0
							},
							children: text_field()
						}
					},
					options: {
						type: Array,
						children: {
							name: text_field(),
							selling_price: number_field({
								default: null,
								validation: value => {
									return value !== null && parseFloat(value) >= 0
								}
							}),
							tva: number_field()
						}
					},
					pricings: {
						type: Array,
						validation: list => {
							return list.filter(({ enabled }) => enabled === true).length > 0
						},
						children: {
							name: text_field({ required: false }),
							selling_price: number_field({
								default: null,
								validation: (value, get) => {
									if (!get('parent.enabled').value) {
										return true
									}

									if (value === null || value === '') return false
									return parseFloat(value) >= 0
								}
							}),
							enabled: bool_field({ default: true }),
							id: text_field({ required: false })
						}
					}
				}
			}
		}
	}
}
