import React, { useEffect, useState } from 'react'
import useForm from 'react-super-useform'

import { theme, GlobalStyle } from '~horairesMod/theme'
import { ThemeProvider } from 'styled-components'
import Button from '~components/basics/Button'
import pricingsFormSchema from './pricingsFormSchema'
import { useInvalidSession, useUserData } from '~contexts/userDataContext'
import {
	getPricingsPageData,
	savePricingsPageData,
	saveActivitiesFree
} from './pricingsDataQueries'
import PricingsHeader from './PricingsHeader'
import PricingPrestation from './PricingPrestation'

import { FormErrorMessage, FormSection } from '../FormToolbox'
import { ModalPricings } from '../modalPricings'

import {
	transformPricingsDataFromForm,
	transformPricingsDataFromDatabase
} from './PricingsDataTransformation'

import ActivitiesLinks from '../Common/ActivitiesLinks'

import { useFormHandler } from '~contexts/currentFormContext'

import './Pricings.scss'

const Pricings = ({
	activity,
	handleSave,
	setActivityFree,
	handleChangeCurrentStep,
	activitiesNames,
	addDeletedPricings
}) => {
	const [errorNotification, setErrorNotification] = useState(null)
	const [ticketingEnabled, setTicketingEnabled] = useState(
		activity.pricings.enabled
	)
	const { setSaved, activitiesId } = useUserData()

	/**
	 *
	 * @param {any} e
	 */
	const formChanged = e => {
		setSaved(false)
		return null
	}

	useEffect(() => {
		form.addEventListener('change', formChanged)
		setSaved(false)
	}, [])

	const form = useForm(pricingsFormSchema, {
		activity
	})

	const hasPrestationWithoutActivities =
		form
			.toJSON()
			.activity.pricings.pricings_table.filter(
				({ activities }) => activities.length === 0
			).length > 0

	const computeMinPrice = () => {
		const pricing_table = form.get('activity.pricings.pricings_table').toJSON()

		const prices = pricing_table.reduce((acc, cur) => {
			return [
				...acc,
				...cur.pricings.reduce((_acc, cur) => {
					if (!cur.selling_price && cur.selling_price !== 0) return _acc

					return [..._acc, parseInt(cur.selling_price, 10)]
				}, [])
			]
		}, [])

		const min_price = prices.reduce((min, price) => {
			if (min === null || price < min) return price
			return min
		}, null)

		return min_price
	}

	useFormHandler(
		ticketingEnabled
			? {
					...form,
					disableForcedSave: hasPrestationWithoutActivities,
					toJSON: () => {
						return {
							activity: {
								...form.toJSON().activity,
								min_price: computeMinPrice()
							}
						}
					}
			  }
			: {
					modified: false,
					isValid: true,
					save: () => true
			  },
		ticketingEnabled ? handleSave : () => true
	)

	form.logErrors()

	useEffect(() => {
		setTimeout(() => {
			form.setModified(false) // Just a little trick to avoid beforeunload (bug is because of pricings, TODO)
		}, 150)
	}, [])

	const beforeUnload = ev => {
		var e = ev || window.event

		if (e) {
			e.returnValue = 'Des données ne sont pas sauvegardées'
		}

		return 'Des données ne sont pas sauvegardées'
	}
	useEffect(() => {
		if (form.modified && ticketingEnabled) {
			window.addEventListener('beforeunload', beforeUnload)
			setSaved(false)
			return () => {
				window.removeEventListener('beforeunload', beforeUnload)
			}
		}
	}, [form.modified, ticketingEnabled])

	if (!ticketingEnabled)
		return (
			<>
				<div
					style={{
						padding: '30px',
						boxSizing: 'border-box',
						width: '100%',
						position: 'relative'
					}}
				>
					<ModalPricings
						goPricing={() => {
							setTicketingEnabled(true)
							setActivityFree(false)
						}}
						onClickNo={() => {
							setActivityFree(true)
							handleChangeCurrentStep(4)
						}}
					/>
				</div>
			</>
		)

	return (
		<>
			<form
				style={{
					position: 'relative'
				}}
			>
				{activitiesId.length > 1 && (
					<ActivitiesLinks activitiesNames={activitiesNames} />
				)}
				<div
					style={{
						marginBottom: 45,
						textAlign: 'center',
						outline: 'none',
						cursor: 'pointer',
						userSelect: 'none'
					}}
					role="button"
					tabIndex={0}
					onClick={() => {
						setTicketingEnabled(false)
						setActivityFree(true)
						form.setModified(false)
					}}
				>
					<FormSection
						style={{
							padding: 21
						}}
					>
						Si vous ne souhaitez plus vendre de prestations, cliquez ici
					</FormSection>
				</div>

				<FormErrorMessage show={errorNotification} />
				<PricingsHeader form={form.get('activity')} />
				<div className="empty-section">
					<h3>Liste des prestations réservables : </h3>
				</div>
				{form.get('activity.pricings.pricings_table').map((pricings, i, id) => (
					<>
						<PricingPrestation
							pricingsForm={pricings}
							pricingsListForm={form.get('activity.pricings.pricings_list')}
							same_pricing={form.get('activity.pricings.same_pricing').value}
							key={i}
							form={form}
							activitiesNames={activitiesNames}
							addDeletedPricings={addDeletedPricings}
						/>
					</>
				))}
				<div className="empty-section">
					<div className="grid-1" style={{ padding: '0 0 2em' }}>
						<Button
							theme="primary"
							size="large"
							style={{
								width: '100%',
								textAlign: 'center',
								fontSize: 15,
								fontWeight: 700
							}}
							onClick={() => {
								form
									.get('activity.pricings.pricings_table')
									.push(
										activitiesId.length === 1 && { activities: activitiesId }
									)
							}}
						>
							+ Ajouter une prestation
						</Button>
					</div>
				</div>
			</form>
		</>
	)
}

const PricingsWrapper = ({ setCanGoNext, ...props }) => {
	const { activitiesId, token, dispatch, apiUrl, userData } = useUserData()
	const { done: ticketingDone } = userData?.ticketing

	const [activity, setActivity] = useState(null)
	const [activitiesNames, setActivitiesNames] = useState(null)
	const [pricings, setPricings] = useState(null)
	const [deletedPricings, setDeletedPricings] = useState([])

	const { invalidSession } = useInvalidSession()

	const addDeletedPricings = id => {
		setDeletedPricings([...deletedPricings, id])
	}

	// Preload activity data before displaying page
	useEffect(() => {
		getPricingsPageData(activitiesId, token, apiUrl)
			.then(({ activity, activitiesNames }) => {
				const _transformedActivity = transformPricingsDataFromDatabase(activity)

				if (activitiesId.length === 1) {
					if (_transformedActivity.pricings.pricings_table.length === 0) {
						_transformedActivity.pricings.pricings_table.push({
							activities: activitiesId
						})
					} else {
						_transformedActivity.pricings.pricings_table.forEach(p => {
							p.activities = activitiesId
						})
					}
				}

				setActivity(_transformedActivity)
				setPricings(_transformedActivity.pricings)
				setActivitiesNames(activitiesNames)
			})
			.catch(err => {
				invalidSession(err)
			})
	}, [])

	const setActivityFree = free => {
		saveActivitiesFree(activitiesId, free, token, apiUrl)
		setActivity({
			...activity
			// free
		})
	}

	const handleSave = async formData => {
		const transformedData = transformPricingsDataFromForm(formData.activity)

		await savePricingsPageData(
			{
				...transformedData
				// free: false
			},
			activitiesId,
			token,
			apiUrl,
			deletedPricings
		)
	}

	const byPassStep = () => {}

	if (activity === null || pricings === null || activitiesNames === null)
		return null

	return (
		<ThemeProvider theme={theme}>
			<Pricings
				activity={activity}
				handleSave={handleSave}
				setCanGoNext={setCanGoNext}
				setActivityFree={setActivityFree}
				byPassStep={byPassStep}
				activitiesNames={activitiesNames}
				addDeletedPricings={addDeletedPricings}
				{...props}
			/>
			<GlobalStyle />
		</ThemeProvider>
	)
}

export default PricingsWrapper
