import React, { useRef } from 'react'

import {
	FormSection,
	FormErrorMessage,
	Select,
	FormContainer,
	FormLine,
	FormGrid,
	Input,
	CheckBox,
	MultipleSelect,
	FormSectionButton
} from '~components/Ticketing/FormToolbox'
import InfoTooltip from '~components/basics/InfoTooltip'
import Button from '~components/basics/Button'
import { FicheClosePeriodItem } from './FicheClosePeriodItem'


export const FicheClosePeriods = ({ closePeriodsForm }) => {
	const closePeriodsItemsRef = useRef(null)

	return (
		<>
			<FormContainer>
				<div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
					<span className="form-section-title">
						Période de fermeture (vacances, jours fériés)
					</span>
					<InfoTooltip>
						Les périodes de fermeture viennent automatiquement placer des
						périodes en indisponible. Vous devez uniquement indiquer dedans les
						périodes de vacances, de jours fériés, de jours fermés
						exceptionnellement. Si vous êtes fermé·e les samedi et dimanche,
						vous renseignerez cela dans vos horaires.
					</InfoTooltip>
				</div>
			</FormContainer>
			<div className="closed-periods-container">
				{closePeriodsForm.map((closePeriod, i, id) => (
					<div
						key={id}
						style={{
							display: 'inline-block',
							verticalAlign: 'top'
						}}
						ref={closePeriodsItemsRef}
					>
						<FicheClosePeriodItem closePeriodForm={closePeriod} />
					</div>
				))}

				<AddClosePeriodButton
					onClick={() => {
						closePeriodsForm.push()

						setTimeout(() => {
							closePeriodsItemsRef.current
								.querySelector(':nth-last-child(1) button')
								.click()
						}, 200)
					}}
				/>
			</div>
		</>
	)
}

const AddClosePeriodButton = ({ onClick }) => {
	return (
		<div
			style={{
				position: 'relative',
				display: 'inline-block'
			}}
		>
			<Button style={{ width: 290 }} theme="colored-action" onClick={onClick}>
				<div
					style={{
						height: 40,
						display: 'flex',
						alignItems: 'center',
						fontWeight: 600,
						position: 'relative'
					}}
				>
					+ Ajouter une période de fermeture
				</div>
			</Button>
		</div>
	)
}