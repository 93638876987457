import React, { useEffect, useState } from 'react'
import InfoTooltip from '~components/basics/InfoTooltip'
import { Select } from '~components/Ticketing/FormToolbox'

import './ScheduleCopier.scss'

const ScheduleCopier = ({
	schedules,
	activitiesNames,
	copyFicheFrom,
	hasSchedule,
	activityId
}) => {
	const [options, setOptions] = useState([])
	const [copyfrom, setCopyfrom] = useState('')
	const isCopiedSchedules =
		!hasSchedule ||
		schedules.findIndex(
			x => x.activities.includes(activityId) && x.activities.length > 1
		) >= 0
	const [answer, setAnswer] = useState(isCopiedSchedules)
	useEffect(() => {
		if (!schedules) return
		const tab: any[] = [
			{
				value: '',
				label: 'Choix'
			}
		]

		let value = ''
		const actNames = { ...activitiesNames }
		delete actNames?.[activityId]

		schedules.map(schedule => {
			let label = ''
			
			if (schedule.activities.length === 1) {
				if (schedule.activities[0] !== activityId){
					const id = schedule.activities[0]
					tab.push({
						value: id,
						label: actNames[id].title
					})
				}
			} else if (schedule.activities.length > 1) {
				schedule.activities.map((id, i) => {
					if (id === activityId) {
						
						value = schedule.activities.find(x => x !== activityId)
						return
					}
					label +=
						actNames[id].title +
						(i + 1 < schedule.activities.length ? ', ' : '')
				})
				tab.push({
					value: schedule.activities.find(x => x !== activityId),
					label
				})
			}
			// console.log(`tab`, tab)
			
		})
		setOptions([...(!value ? tab : tab.filter(x => x.value !== ''))])
		if (value !== '') {
			setCopyfrom(value)
		}
	}, [schedules])
	const yesOrNo = [
		{
			value: false,
			label: 'Non'
		},
		{
			value: true,
			label: 'Oui'
		}
	]
	return (
		<div className="ScheduleCopier">
			<div>
				Souhaitez-vous utiliser des horaires d’ouverture déjà remplis sur une
				autre fiche ? <InfoTooltip> </InfoTooltip>
				<Select
					setValue={val => {
						setAnswer(val)
						if (!val) {
							copyFicheFrom(null)
						} else if (val && copyfrom) {
							copyFicheFrom(copyfrom)
						}
					}}
					value={answer}
					options={schedules?.length > 0 ? yesOrNo : [yesOrNo[0]]}
				/>
			</div>
			{answer && (
				<>
					<div>
						Quels horaires d’ouverture souhaitez-vous utiliser pour cette fiche
						?<InfoTooltip> </InfoTooltip>
						<Select
							setValue={val => {
								setCopyfrom(val)
								setOptions(options.filter(x => x.value !== ''))
								copyFicheFrom(val)
							}}
							value={copyfrom}
							options={options}
						/>
					</div>

					{copyfrom === '' && !hasSchedule && (
						<i>
							Rappel : chaque modification sur vos horaires d’ouverture sera
							automatiquement répercutée sur toutes vos fiches qui utilisent ces
							horaires.
						</i>
					)}
				</>
			)}
		</div>
	)
}

export default ScheduleCopier
