import React from 'react'
import { FormLine, Select } from '~components/Ticketing/FormToolbox'
import FormInclusions from '~components/Ticketing/Common/FormInclusions'

const InfosInclusions = ({ inclusionsForm }) => {
	return (
		<>
			<FormLine>
				<div>
					Voulez-vous écrire quelques éléments sur ce que votre activité inclus
					/ n'inclus pas
				</div>
				<div>
					<Select
						dropdownVerticalPosition="top"
						noWrap={true}
						style={{
							width: 180
						}}
						{...inclusionsForm.get('enabled')}
						options={[
							{
								label: (
									<span
										style={{
											maxWidth: '100%',
											whiteSpace: 'nowrap',
											textOverflow: 'ellipsis',
											overflow: 'hidden'
										}}
									>
										Oui, sur l’activité en générale
									</span>
								),
								value: 'yes'
							},
							{
								label: (
									<span
										style={{
											maxWidth: '100%',
											whiteSpace: 'nowrap',
											textOverflow: 'ellipsis',
											overflow: 'hidden'
										}}
									>
										Oui, sur chaque prestation différente
									</span>
								),
								value: 'prestation_dependent'
							},
							{
								label: 'Non',
								value: 'no'
							}
						]}
					/>
				</div>
			</FormLine>

			<FormInclusions
				inclusionsForm={inclusionsForm}
				enabled={inclusionsForm.get('enabled').value === 'yes'}
			/>
		</>
	)
}

export default InfosInclusions
