export const getData = async (apiUrl, token, activityId) => {
	const myHeaders = new Headers()
	myHeaders.append('x-access-token', token)

	const requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow'
	}

	let res = await fetch(apiUrl + '/activities/' + activityId, requestOptions)
	let data = await res.json()
	return data
}

const getQueryOptions = token => {
	const headers = new Headers()
	headers.append('x-access-token', token)

	return {
		method: 'GET',
		headers,
		redirect: 'follow'
	}
}

export const getAllPricings = async (apiUrl, token, activityId) => {
	const myHeaders = new Headers()
	myHeaders.append('x-access-token', token)

	const requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow'
	}

	let res = await fetch(
		apiUrl + '/pricings?activity=' + activityId,
		requestOptions
	)
	let data = await res.json()
	return data
}

export const getActivities = async (id_list, token, api) => {
	const activities = []

	for (let i = 0; i < id_list.length; i++) {
		const activity = await fetch(
			`${api}/activities/${id_list[i]}`,
			getQueryOptions(token)
		).then(data => data.json())

		activities.push(activity)
	}

	return activities
}

const getDestination = async (apiUrl, token, destinationId) => {
	const myHeaders = new Headers()
	myHeaders.append('x-access-token', token)

	const requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow'
	}

	let res = await fetch(
		apiUrl + '/destinations/' + destinationId,
		requestOptions
	)
	let data = await res.json()
	return data
}
