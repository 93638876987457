import React, { useState, useEffect } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import Header from '~components/basics/virements/Rib/Header'
import LineCheck from '~components/basics/LineCheck'
import FormGroup from '~components/basics/FormGroup'
import { useUserData } from '~contexts/userDataContext'
import { useBooking, BookingProvider } from '~contexts/bookingContext'
import { withAuth } from '~components/HOC/withAuth'
import DashboardTemplate from '~components/templates/DashboardTemplate'
import { theme, GlobalStyle } from '~horairesMod/theme'
import { getBackendUser, putRib } from '~requests/back_user'
import Spinner from '~components/basics/Icons/Spinner'

const formatIBAN = txt => {
	const cleanFormat = txt.replace(/ /g, '').substr(0, 27)
	return cleanFormat.match(/.{1,4}/g).join(' ')
}

const Rib = () => {
	const [ribInfos, setRibInfos] = useState(null)

	const { userId, token, setSaved } = useUserData()

	const { rib, account, billing } = ribInfos || {}

	const [errors, setErrors] = useState(false)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		// console.log('getBackendUser', process.env.GATSBY_API_URL, token, userId)
		getBackendUser(process.env.GATSBY_API_URL, token, userId)
			.then(res => {
				// console.log(`res mouve- rib`, res)
				if (res) setRibInfos(res.payment)
			})
			.finally(() => setLoading(false))
	}, [])

	const handleChangeRib = e => {
		const { name, value, checked } = e.target

		setSaved(false)

		let formatedName

		if (name.search(/rib\.rib\./) !== -1) {
			formatedName = name.replace(/rib\.rib\./, '')

			if (formatedName === 'iban') {
				let formatedValue = formatIBAN(value)

				setRibInfos(prev => ({
					...prev,
					rib: {
						...prev.rib,
						[formatedName]: formatedValue
					}
				}))
			} else {
				setRibInfos(prev => ({
					...prev,
					rib: {
						...prev.rib,
						[formatedName]: value
					}
				}))
			}
		} else if (name.search(/rib\.account\./) !== -1) {
			formatedName = name.replace(/rib\.account\./, '')
			setRibInfos(prev => ({
				...prev,
				account: {
					...prev.account,
					[formatedName]: value
				}
			}))
		} else if (name.search(/rib\.billing\./) !== -1) {
			formatedName = name.replace(/rib\.billing\./, '')
			if (formatedName === 'enabled') {
				setRibInfos(prev => ({
					...prev,
					billing: {
						...prev.billing,
						[formatedName]: checked
					}
				}))
			} else {
				setRibInfos(prev => ({
					...prev,
					billing: {
						...prev.billing,
						[formatedName]: value
					}
				}))
			}
		}
		const newObj = { payment: ribInfos }
	}

	const saveRib = () => {
		const newObj = { payment: ribInfos }

		const { name, bank, iban } = ribInfos.rib
		const { name: accName, address } = ribInfos.account
		if (!name || !bank || !iban || !accName || !address) {
			return false
		} else {
			putRib(newObj, process.env.GATSBY_API_URL, token, userId)
				.then(res => {
					console.log(res)
				})
				.catch(err => {
					console.log(err)
				})
			return true
		}
	}

	if (loading)
		return (
			<div className="loader_">
				<Spinner color="dark" />
			</div>
		)

	if (!ribInfos) return null

	return (
		<div className={`virements-page`}>
			<RibWrapper>
				<H1>Rib et autres informations comptables</H1>
				<SubTitle>
					Pour vous envoyer de l’argent et éditer vos factures pour la compta,
					nous avons besoin de quelques infos.
				</SubTitle>

				<Form>
					<H4>RIB</H4>
					<Row>
						<div>
							<Label>Nom du titulaire du compte</Label>
							<Input
								onChange={e => handleChangeRib(e)}
								name="rib.rib.name"
								defaultValue={rib?.name}
								className={`${errors && !rib.name ? 'has-error' : ''}`}
							/>
						</div>

						<div>
							<Label>Nom de la banque</Label>
							<Input
								onChange={e => handleChangeRib(e)}
								name="rib.rib.bank"
								defaultValue={rib?.bank}
								className={`${errors && !rib?.bank ? 'has-error' : ''}`}
							/>
						</div>

						<div>
							<Label>IBAN</Label>
							<Input
								onChange={e => handleChangeRib(e)}
								name="rib.rib.iban"
								defaultValue={rib?.iban}
								value={rib?.iban}
								className={`${errors && !rib?.iban ? 'has-error' : ''}`}
							/>
						</div>

						<div>
							<Label>BIC (si virement à l’étranger)</Label>
							<Input
								onChange={e => handleChangeRib(e)}
								name="rib.rib.bic"
								defaultValue={rib?.bic}
							/>
						</div>
					</Row>

					<H4>Informations comptables</H4>
					<Row2>
						<div>
							<Label>Nom / Dénomination sociale</Label>
							<Input
								onChange={e => handleChangeRib(e)}
								name="rib.account.name"
								defaultValue={account?.name}
								className={`${errors && !account?.name ? 'has-error' : ''}`}
							/>
						</div>

						<div>
							<Label>N°Siren (facultatif)</Label>
							<Input
								onChange={e => handleChangeRib(e)}
								name="rib.account.siren"
								defaultValue={account?.siren}
							/>
						</div>

						<div>
							<Label>N°TVA (facultatif)</Label>
							<Input
								onChange={e => handleChangeRib(e)}
								name="rib.account.tva_num"
								defaultValue={account?.tva_num}
							/>
						</div>

						<Span2>
							<div>
								<Label>Adresse du siège</Label>
								<Input
									onChange={e => handleChangeRib(e)}
									name="rib.account.address"
									defaultValue={account?.address}
									className={`${
										errors && !account?.address ? 'has-error' : ''
									}`}
								/>
							</div>
						</Span2>
					</Row2>

					<LineCheck
						name="rib.billing.enabled"
						defaultValue={billing?.enabled}
						onChange={e => {
							handleChangeRib(e)
						}}
						data="+ Adresse de facturation (si différente de l’adresse du siège)"
					/>
					<FormGroup
						group="block"
						trigger={billing?.enabled}
						style={{ marginTop: 14 }}
					>
						<div className="grid-2">
							<div>
								<Label>Nom de facturation</Label>
								<Input
									onChange={e => handleChangeRib(e)}
									name="rib.billing.name"
									defaultValue={billing?.name}
								/>
							</div>
							<div>
								<Label>Adresse de facturation</Label>
								<Input
									onChange={e => handleChangeRib(e)}
									name="rib.billing.address"
									defaultValue={billing?.address}
								/>
							</div>
						</div>
					</FormGroup>
				</Form>

				<Header saveRib={saveRib} setErrors={setErrors} />
			</RibWrapper>
		</div>
	)
}

const RibContext = ({ data }) => {
	return (
		<DashboardTemplate currentPage="mouvements">
			<BookingProvider>
				<ThemeProvider theme={theme}>
					<Rib data={data} />
					<GlobalStyle />
				</ThemeProvider>
			</BookingProvider>
		</DashboardTemplate>
	)
}

export default withAuth(RibContext)

const RibWrapper = styled.div`
	width: 100%;
	* {
		line-height: 19px;
	}
`

const H1 = styled.h1`
	width: 100%;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 2px;
`
const SubTitle = styled.div`
	font-weight: normal;
	font-size: 15px;
	line-height: 18px;
	margin-bottom: 25px;
`

const Form = styled.form`
	width: 100%;
	/* max-width: 1080px; */
	background-color: white;
	padding: 60px 103px 60px 86px;
`

const H4 = styled.h4`
	padding-bottom: 3px;
	border-bottom: 2px solid ${props => props.theme.colors.dark};
	line-height: 20px;
`

const Row = styled.div`
	display: grid;
	grid-template-columns: 212px 158px 240px 214px;
	gap: 20px;
	margin-bottom: 14px;

	@media screen and (max-width: 1450px) {
		grid-template-columns: 1fr 1fr;
	}
`
const Row2 = styled(Row)`
	row-gap: 14px;
	grid-template-columns: 1fr 1fr 1fr;
`

const Label = styled.label`
	/* margin-bottom: 4px; */
`
const Input = styled.input`
	margin-top: 4px;
	width: 100%;
	border: 3px solid #f5f6fa;
`

const Span2 = styled.div`
	grid-column: span 2;
	& > div {
		width: 75%;
	}

	@media screen and (max-width: 1450px) {
		grid-column: span 1;
		& > div {
			width: 100%;
		}
	}
`
