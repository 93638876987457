// @ts-check
import React, { useMemo } from 'react'
import { Link, withPrefix } from 'gatsby'


// SCSS
import './Header.scss'
import LogoIcon from '~components/svg/LogoIcon'

const uniqid = require('uniqid')

/**
 *
 * @param {{
 * 	simplified?:boolean,
 *  extendedBrandName?:boolean,
 *  color?: 'white'|'dark'|'primary'|'light',
 *  destination?: any,
 *  showPhone?: boolean,
 * 	showBasket?:boolean
 *  desktopOnly?: boolean
 *  disableAll?: boolean
 *  brandColor?: 'dark'|'white'
 *  title?: string
 *  subtitle?: string
 *  showBurger?: boolean
 *  basePath?: string
 *  theme?: 'normal'|'with-separator'
 *  showInspirationLink?: boolean
 * }} [props]
 */

const HeaderLogo = ({ brandColor, color }) => {
	return (
		<>
			<LogoIcon height={30} color={brandColor || color} />
			<strong>coq-trotteur</strong>
		</>
	)
}

const Header = ({
	color = 'white',
	desktopOnly = false,
	disableAll = false,
	brandColor = null,
	theme = 'normal'
}) => {
	const globalProximity = null

	const _basePath = '/cguv'

	return useMemo(
		() => (
			<>
				<header
					className={`layout-header navbar-top-absolute color-${color} ${
						desktopOnly ? 'desktop-only' : ''
					} ${disableAll ? 'disable-all' : ''} theme-${theme} `}
				>
					<div>
						<Link
							to={
								/^\/[a-z]*\/marche-de-noel$/.test(document.location.pathname)
									? document.location.href
									: withPrefix(
											`${_basePath}${
												globalProximity
													? `?id=${uniqid()}&proximity=${globalProximity}`
													: ''
											}`
									  )
							}
							className={`brand color-${brandColor || color}`}
							title={`Page d'accueil de la destination touristique `}
						>
							<HeaderLogo brandColor={brandColor} color={color} />
						</Link>
					</div>

					<div style={{ flex: 1 }} />

					

					
				</header>
			</>
		),
		[]
	)
}

export default Header
