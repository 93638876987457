import React from 'react'

export const EyeSlashIcon = () => {
	return (
		<svg
			width="20"
			height="14"
			viewBox="0 0 20 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.99994 9.06985C8.29461 9.06985 6.90722 7.68243 6.90722 5.97703C6.90722 5.11967 7.25807 4.34284 7.82356 3.78209L4.81868 1.64764C4.81773 1.64826 4.81665 1.64875 4.8156 1.64937L2.76193 0.190543C2.29768 -0.139164 1.65418 -0.0302093 1.32448 0.434008C0.994768 0.898159 1.10372 1.54176 1.56794 1.87146L2.992 2.883C2.98687 2.88702 2.98171 2.89088 2.97658 2.89486C1.23163 4.25484 0.156561 5.59997 0.111692 5.65654C-0.0372306 5.84419 -0.0372306 6.10974 0.111692 6.29739C0.156594 6.35396 1.23163 7.69906 2.97654 9.05906C5.31368 10.8806 7.74235 11.8435 10 11.8435C11.3512 11.8435 12.7635 11.4983 14.1808 10.8309L17.2381 13.0027C17.4193 13.1314 17.6278 13.1932 17.8343 13.1932C18.1569 13.1932 18.4746 13.0422 18.6756 12.7592C19.0053 12.2951 18.8963 11.6515 18.4321 11.3217L16.1474 9.6988C16.1483 9.69818 16.1492 9.69753 16.1502 9.69684L12.7903 7.31024C12.2914 8.35004 11.2282 9.06985 9.99994 9.06985Z"
				fill="#172B49"
			/>
			<path
				d="M19.8878 6.29743C20.0367 6.10978 20.0367 5.84423 19.8878 5.65658C19.8429 5.60001 18.7679 4.25491 17.023 2.8949C14.6858 1.07329 12.2571 0.110474 9.99942 0.110474C9.01674 0.110474 8.00144 0.294494 6.97559 0.650999L17.8499 8.37548C19.1021 7.28044 19.8505 6.34442 19.8878 6.29743Z"
				fill="#172B49"
			/>
		</svg>
	)
}
