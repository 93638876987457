import React, { useEffect, useState } from 'react'
import format from 'date-fns/format'
import fr from 'date-fns/locale/fr'

import { withAuth } from '~components/HOC/withAuth'
import DashboardTemplate from '~components/templates/DashboardTemplate'
import { theme, GlobalStyle } from '~horairesMod/theme'
import styled, { ThemeProvider } from 'styled-components'
import { useBooking, BookingProvider } from '~contexts/bookingContext'
import Block from '~components/basics/factures/block'
import { log } from '~utils/helpers'
import { getAllReservationTickets } from '~requests/booking'
import { useUserData } from '~contexts/userDataContext'
import Spinner from '~components/basics/Icons/Spinner'

const FacturesPage = () => {
	const { emptyBooking, bookingData, setBookingData, apiUrl } = useBooking()
	const { token, activityId } = useUserData()
	const [monthlyBills, setMonthlyBills] = useState(null)
	const [loading, setLoading] = useState(true)
	useEffect(() => {
		const billsByMonth = {}
		getAllReservationTickets(apiUrl, token, activityId)
			.then(res => {
				if (res.length) {
					try {
						setBookingData({ type: 'getAll', payload: res })
					} catch (error) {
						console.log(error)
					}
					if (res.length) {
						res.sort(compare)
						res?.forEach((resa, i) => {
							if (
								resa.total === 0 ||
								resa?.state?.refused ||
								(!resa?.state?.accepted && resa?.state?.canceled) ||
								(resa?.state?.canceled && resa?.state?.canceled_by !== 'client')
							) {
								return
							}
							let month = format(
								new Date(resa.date || new Date()),
								'MMMM yyyy',
								{
									locale: fr,
									weekStartsOn: 1
								}
							)

							if (month in billsByMonth) {
								billsByMonth[month].push(resa)
							} else {
								billsByMonth[month] = []
								billsByMonth[month].push(resa)
							}
						})
						setMonthlyBills(billsByMonth)
					}

					// log({monthlyBills})
				}
			})
			.finally(() => setLoading(false))
	}, [])

	function compare(a, b) {
		if (a.date < b.date) {
			return -1
		}
		if (a.date > b.date) {
			return 1
		}
		return 0
	}

	if (loading)
		return (
			<div className="loader_">
				<Spinner color="dark" />
			</div>
		)

	return (
		<div className={`virements-page ${emptyBooking ? 'no-scroll' : ''}`}>
			<FacturesWrapper>
				<H1>Liste des virements</H1>
				<SubTitle>
					C’est l’heure des comptes ? On espère que le succès a été au
					rendez-vous !
				</SubTitle>
				<Table>
					{monthlyBills ? (
						Object.entries(monthlyBills)
							.reverse()
							.map(month => (
								<Block month={month[0]} bill={monthlyBills[month[0]]} />
							))
					) : (
						<span> Vous n'avez pas encore de facture</span>
					)}
				</Table>
			</FacturesWrapper>
		</div>
	)
}

const FacturesContext = ({ data }) => {
	return (
		<DashboardTemplate currentPage="mouvements">
			<ThemeProvider theme={theme}>
				<BookingProvider>
					<FacturesPage data={data} />
					<GlobalStyle />
				</BookingProvider>
			</ThemeProvider>
		</DashboardTemplate>
	)
}

export default withAuth(FacturesContext)

const FacturesWrapper = styled.div`
	width: 100%;
	* {
		line-height: 19px;
	}
`

const H1 = styled.h1`
	width: 100%;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 2px;
`
const SubTitle = styled.div`
	font-weight: normal;
	font-size: 15px;
	line-height: 18px;
	margin-bottom: 25px;
`

const Table = styled.div`
	margin-top: 31px;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 20px;
`
