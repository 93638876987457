import React from 'react'

const ArrowIcon = ({
	direction,
	color
}: {
	direction: 'left' | 'right'
	color: 'white' | 'dark' | 'blue'
}) => (
	<svg
		style={{
			display: 'inline-block',
			margin: direction === 'left' ? '0 6px 0 0' : '0 0 0 6px',
			verticalAlign: 'middle'
		}}
		width="21"
		height="10"
		viewBox="0 0 21 10"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		{direction === 'left' ? (
			<path
				d="M19.8387 4.19355H2.75977L5.58897 1.37806C5.90466 1.06387 5.90587 0.553264 5.59167 0.237578C5.27748 -0.078148 4.76683 -0.0793173 4.45115 0.234836L0.237273 4.42839C0.236991 4.42863 0.23679 4.42891 0.236548 4.42915C-0.0783315 4.74335 -0.0793396 5.2556 0.236467 5.57085C0.236749 5.57109 0.236951 5.57137 0.237193 5.57161L4.45107 9.76516C4.76671 10.0793 5.27736 10.0782 5.59159 9.76242C5.90578 9.44674 5.90458 8.93613 5.58889 8.62194L2.75977 5.80645H19.8387C20.2841 5.80645 20.6452 5.4454 20.6452 5C20.6452 4.5546 20.2841 4.19355 19.8387 4.19355Z"
				fill={
					color === 'dark' ? '#172B49' : color === 'blue' ? '#66a3ff' : 'white'
				}
			/>
		) : (
			<path
				fill={
					color === 'dark' ? '#172B49' : color === 'blue' ? '#66a3ff' : 'white'
				}
				d="M0.4,5c0,0.4,0.4,0.8,0.8,0.8h17.1l-2.8,2.8c-0.3,0.3-0.3,0.8,0,1.1c0.3,0.3,0.8,0.3,1.1,0l4.2-4.2c0,0,0,0,0,0
c0.3-0.3,0.3-0.8,0-1.1c0,0,0,0,0,0l-4.2-4.2c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1l2.8,2.8H1.2C0.7,4.2,0.4,4.6,0.4,5z"
			/>
		)}
	</svg>
)

export default ArrowIcon
