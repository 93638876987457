import React from 'react'

const CogIcon = () => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M15.6448 6.60794L13.8095 6.15659C13.6992 5.80869 13.5573 5.47131 13.3861 5.14859C13.6616 4.68991 14.2407 3.7245 14.2407 3.7245C14.3515 3.54003 14.3222 3.30381 14.1702 3.15184L12.8445 1.82616C12.6926 1.67372 12.4568 1.64444 12.2719 1.75566L10.8455 2.61031C10.5246 2.44047 10.189 2.29994 9.84344 2.19053L9.39209 0.35525C9.33988 0.1465 9.15266 0 8.9375 0H7.0625C6.84734 0 6.65966 0.1465 6.60794 0.355219C6.60794 0.355219 6.29528 1.63616 6.15703 2.19006C5.78853 2.30678 5.43241 2.45831 5.09319 2.64325L3.61416 1.75566C3.42922 1.64578 3.19394 1.67419 3.0415 1.82616L1.71581 3.15184C1.56384 3.30381 1.53453 3.54003 1.64531 3.7245L2.55994 5.25206C2.41253 5.54275 2.28894 5.84531 2.19053 6.15522L0.35525 6.60794C0.1465 6.66012 0 6.84734 0 7.0625V8.9375C0 9.15266 0.1465 9.34034 0.355219 9.39206L2.19097 9.84341C2.30222 10.1963 2.44684 10.5392 2.62219 10.8683L1.80372 12.2325C1.69294 12.4169 1.72225 12.6532 1.87422 12.8051L3.19991 14.1308C3.35144 14.2823 3.58763 14.3112 3.77256 14.2013C3.77256 14.2013 4.69038 13.6511 5.13991 13.3819C5.46537 13.5554 5.80594 13.6987 6.15706 13.8099L6.60797 15.6447C6.65966 15.8535 6.84734 16 7.0625 16H8.9375C9.15266 16 9.33987 15.8535 9.39206 15.6448L9.84297 13.8099C10.2042 13.6955 10.552 13.5476 10.883 13.3682C11.3449 13.6451 12.2718 14.2013 12.2718 14.2013C12.4563 14.3116 12.6925 14.2832 12.8445 14.1308L14.1702 12.8051C14.3222 12.6532 14.3515 12.4169 14.2407 12.2325L13.3979 10.8285C13.5641 10.5131 13.7019 10.1831 13.8095 9.84341L15.6447 9.39206C15.8535 9.33987 16 9.15266 16 8.9375V7.0625C16 6.84734 15.8535 6.66012 15.6448 6.60794ZM8 11.2812C6.19091 11.2812 4.71875 9.80909 4.71875 8C4.71875 6.19091 6.19091 4.71875 8 4.71875C9.80909 4.71875 11.2812 6.19091 11.2812 8C11.2812 9.80909 9.80909 11.2812 8 11.2812Z"
			fill="#172B49"
		/>
	</svg>
)
export default CogIcon
