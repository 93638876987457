import React from 'react'
import MinusIcon from '~svg/booking/minus.svg'
import styled from 'styled-components'

const Charges = ({date}) => {
  return (
    <ChargesWrapper>
      {/*<div><MinusIcon /></div>

      <Date>{date}</Date>

      <div>Virement bancaire HT</div>

  <Price>-0,15€ (0,18€ TTC)</Price>*/}
    </ChargesWrapper>
  )
}

export default Charges

const ChargesWrapper = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr 50% 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 20px;
  justify-items: center;
  margin-bottom: 25px;
`;

const Date = styled.div`
  justify-self: start;
`;

const Price = styled.div`
  justify-self: end;
`;