import React, { useState } from 'react'
import Button from '~components/basics/Button'
import Spinner from '~components/basics/Icons/Spinner'
import { useUserData } from '~contexts/userDataContext'
import { DecoratedH2 } from '~components/reservations/ReservationsEmpty/ReservationsEmpty'

import './WebsiteEmpty.scss'

const WebsiteEmpty = ({ handleCreateWebsite }) => {
	const { token } = useUserData()
	const [isLoading, setIsLoading] = useState(false)

	return (
		<div className="WebsiteEmpty">
			<DecoratedH2>
				Créez votre site internet en un clic, gratuitement !
			</DecoratedH2>

			<p>
				Multipliez votre impact en ligne en créant facilement et gratuitement
				votre site internet ! Grâce aux données de votre fiche coq-trotteur,
				votre site est généré automatiquement et adapté à vos besoin !
			</p>

			<Button
				theme="blue"
				style={{
					height: 43,
					width: 260
				}}
				disabled={isLoading}
				onClick={() => {
					setIsLoading(true)

					handleCreateWebsite()
						.then(() => {
							setIsLoading(false)
						})
						.catch(err => {})
				}}
			>
				{isLoading ? <Spinner color="light" /> : 'Créer mon site internet'}
			</Button>
		</div>
	)
}

export default WebsiteEmpty
