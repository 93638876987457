import React from 'react'

const PencilIcon = ({ color }: { color: 'dark' | 'light' }) => (
	<svg
		width="18"
		height="18"
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0 14.2474V17.9974H3.75L14.815 6.93237L11.065 3.18237L0 14.2474Z"
			fill={color === 'dark' ? '#172B49' : 'white'}
		/>
		<path
			d="M17.705 2.62748L15.37 0.2925C14.98 -0.0975 14.345 -0.0975 13.955 0.2925L12.125 2.1225L15.875 5.8725L17.705 4.0425C18.095 3.6525 18.095 3.01748 17.705 2.62748Z"
			fill={color === 'dark' ? '#172B49' : 'white'}
		/>
	</svg>
)
export default PencilIcon
