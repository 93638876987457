import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import React, { useEffect, useRef, useState } from 'react'
import Button from '~components/basics/Button'
import Dropdown from '~components/basics/Dropdown/Dropdown'
import CogIcon from '~components/svg/CogIcon'
import EuroIcon from '~components/svg/EuroIcon'
import EyeIcon from '~components/svg/EyeIcon'
import PencilIcon from '~components/svg/PencilIcon'
import TrashIcon from '~components/svg/TrashIcon'
import { getPictureURL } from '~utils/cloudinary'

import './MarketItem.scss'
import { CopyIcon } from '~components/svg/CopyIcon'

const MarketItem = ({ item, handleRemove, handleToggleEnable, handleDuplicate }) => {
	const miniature =  item.miniature?.url ? item.miniature : item.gallery?.[0]
	const cover = getPictureURL(miniature, {
		w: 224,
		h: 140,
		f: 'auto',
		c: 'fill',
		q: 'auto'
	})
	
	if (!cover) {
		console.log(`item.miniature`, item.miniature, item.gallery)
	}

	const allQuotaEmpty =
		item.optionsQuotas.length > 0 &&
		!item.optionsQuotas?.find(({ quota }) => quota > 0)
	const hasQuotaEmpty =
		item.optionsQuotas.length === 0
			? false
			: !!item.optionsQuotas?.find(({ quota }) => quota === 0)

	return (
		<Link className={`MarketItem`} to={`/boutique/editer/${item.id}`}>
			<div className="toolbar">
				{!item.enabled && item.creation.complete && (
					<button className="disabled toolbar-button">
						<span>Offre désactivée</span>
					</button>
				)}
				<MarketItemParam
					item={item}
					handleRemove={handleRemove}
					handleToggleEnable={handleToggleEnable}
					handleDuplicate={handleDuplicate}
				/>
			</div>
			<div className="picture">
				{allQuotaEmpty ? (
					<div className="has-quota-empty">Stocks épuisés</div>
				) : hasQuotaEmpty ? (
					<div className="has-quota-empty">Certains stocks épuisés</div>
				) : null}
				<img
					src={
						cover
						// || item.gallery.find(picture => picture.isCover)?.url
					}
				/>
			</div>
			<div className="informations">
				<Link to={`/boutique/editer/${item.id}`} className="title">
					<span>{item.title}</span>
				</Link>

				{item.creation.complete ? (
					<div className="price">
						<EuroIcon />
						<span>
							{item.pricing.unique ? (
								<>{item.pricing.basePrice} €</>
							) : (
									<>
										A partir de {Math.min(...item.pricing.list.map(_ => _.price))}
									€
								</>
								)}
						</span>
					</div>
				) : (
						<div className="uncompleted">
							Offre non active.
							<Button
								theme="underline-action"
								onClick={() => {
									navigate(`/boutique/editer/${item.id}`)
								}}
							>
								Terminer pour publier
						</Button>
						</div>
					)}
			</div>
		</Link>
	)
}

export default MarketItem

const MarketItemParam = ({ item, handleRemove, handleToggleEnable, handleDuplicate }) => {
	const [isOpen, setIsOpen] = useState(false)
	const rootRef = useRef(null)

	useEffect(() => {
		const clickOutsideEvent = event => {
			if (!rootRef.current.contains(event.target)) setIsOpen(false)
		}

		if (isOpen) {
			window.addEventListener('click', clickOutsideEvent)

			return () => {
				window.removeEventListener('click', clickOutsideEvent)
			}
		}
	}, [isOpen])

	return (
		<div
			className="MarketItemParam"
			style={{ position: 'relative' }}
			ref={rootRef}
			onClick={e => {
				e.stopPropagation()
				e.preventDefault()
			}}
		>
			{isOpen && (
				<Dropdown>
					<button
						className={`dropdown-item`}
						onClick={() => {
							navigate(`/boutique/editer/${item.id}`)
						}}
					>
						<span className="icon-container">
							<PencilIcon color="dark" />
						</span>
						{item.creation.complete ? (
							<span>Modifier</span>
						) : (
								<span>Reprendre</span>
							)}
					</button>

					{item.creation.complete && (
						<>
							<button
								className="dropdown-item"
								onClick={() => {
									setIsOpen(false)
									handleToggleEnable()
								}}
							>
								<span className="icon-container">
									<EyeIcon color="dark" />
								</span>
								{item.enabled ? (
									<span>Désactiver l’offre</span>
								) : (
										<span>Ré-activer l’offre</span>
									)}
							</button>
							<button className="dropdown-item" onClick={() => {
								setIsOpen(false)
								handleDuplicate()
							}}>
								<span className="icon-container">
									<CopyIcon />
								</span>
								<span>Dupliquer l’offre</span>
							</button>
						</>
					)}

					<button
						className="dropdown-item"
						onClick={() => {
							setIsOpen(false)
							handleRemove()
						}}
					>
						<span className="icon-container trash">
							<TrashIcon color="dark" />
						</span>
						<span>Supprimer définitivement</span>
					</button>
				</Dropdown>
			)}
			<button
				className="toolbar-button"
				onClick={e => {
					e.preventDefault()
					setIsOpen(!isOpen)
				}}
			>
				<CogIcon />
			</button>
		</div>
	)
}
