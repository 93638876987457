import React, { useEffect } from 'react'

import Button from '~components/basics/Button'

import './ReservationCancellationResult.scss'
import Spinner from '~components/basics/Icons/Spinner'

const ReservationCancellationResult = ({
    isOpen,
    onClickAbort,
    onClickCancel,
    onClickSave,
    saveIsLoading,
    confirmDialog,
    title,
    description
}) => {
    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('no-scroll')

            return () => {
                document.body.classList.remove('no-scroll')
            }
        }
    }, [isOpen])

    return (
        <div className={`reservation-cancellation-popup ${isOpen ? 'active' : ''}`}>
            <div className="overlay" />

            <div className="content">
                <Button
                    theme="underline-action"
                    className="close-button"
                    disabled={saveIsLoading}
                    onClick={saveIsLoading ? () => { } : onClickCancel}
                >
                    <img src="/icons/cross.svg" />
                </Button>
                <h2>
                    <span>{title}</span>
                </h2>

                <p>
                    {description}
				</p>

                <div className="buttons">
                    {
                        confirmDialog
                        ?
                        <>
                                <div>
                                    <Button
                                        disabled={saveIsLoading}
                                        style={{ marginRight: 6 }}
                                        roundish={'roundish'}
                                        onClick={saveIsLoading ? () => { } : onClickAbort}
                                        theme="white"
                                    >
                                        Retour
                            </Button>
                                </div>
                                <div>
                                    <Button
                                        onClick={saveIsLoading ? () => { } : onClickSave}
                                        roundish={'roundish'}
                                        theme="red"
                                        style={{ marginLeft: 6 }}
                                        className="confirm"
                                    >
                                        {saveIsLoading ? <Spinner color="dark" /> : `Confirmer l'annulation`}
                                    </Button>
                                </div>
                        </>
                        :
                        <div>
                            <Button
                                onClick={saveIsLoading ? () => { } : onClickCancel}
                                roundish={'roundish'}
                                theme="yellow"
                                style={{ marginLeft: 6 }}
                            >
                                C'est compris
                            </Button>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
export default ReservationCancellationResult
