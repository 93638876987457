import { withAuth } from "~components/HOC/withAuth"

import React, { useEffect, useState } from 'react'
import { useUserData } from "~contexts/userDataContext"
import { useBooking } from "~contexts/bookingContext"
import { getUserActivitiesApidaeForm, getActivityApidaeForm, getActivityApidaeFormUrl } from "~requests/back_user"
import { MD5 } from "crypto-js"
const { default: DashboardTemplate } = require("~components/templates/DashboardTemplate")
const { ThemeProvider } = require("styled-components")
const { GlobalStyle, theme } = require("~horairesMod/theme")

const Settings = () => {
    const [activitiesForms, setActivitiesForms] = useState([]);
    const [formUrl, setFormUrl] = useState(null);
    const [culturalAgenda, setCulturalAgenda] = useState(false);
    const {
		apiUrl,
	} = useBooking()

    const { token } = useUserData()
    
    useEffect(() => {
		getUserActivitiesApidaeForm(apiUrl, token).then(res => {
            if (res && res.length) {
                setActivitiesForms(res);
                getActivityForm(res[0]?.apidae?.editable ? res[0]?.apidae?.id : null)
            }
		})
    }, [])

    function getAllUrlParams(url) {

        // get query string from url (optional) or window
        var queryString = url ? url.split('?')[1] : '' ///window.location.search.slice(1);
      
        // we'll store the parameters here
        var obj = {};
      
        // if query string exists
        if (queryString) {
      
          // stuff after # is not part of query string, so get rid of it
          queryString = queryString.split('#')[0];
      
          // split our query string into its component parts
          var arr = queryString.split('&');
      
          for (var i = 0; i < arr.length; i++) {
            // separate the keys and the values
            var a = arr[i].split('=');
      
            // set parameter name and value (use 'true' if empty)
            var paramName = a[0];
            var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];
      
            // (optional) keep case consistent
            paramName = paramName.toLowerCase();
            if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();
      
            // if the paramName ends with square brackets, e.g. colors[] or colors[2]
            if (paramName.match(/\[(\d+)?\]$/)) {
      
              // create key if it doesn't exist
              var key = paramName.replace(/\[(\d+)?\]/, '');
              if (!obj[key]) obj[key] = [];
      
              // if it's an indexed array e.g. colors[2]
              if (paramName.match(/\[\d+\]$/)) {
                // get the index value and add the entry at the appropriate position
                var index = /\[(\d+)\]/.exec(paramName)[1];
                obj[key][index] = paramValue;
              } else {
                // otherwise add the value to the end of the array
                obj[key].push(paramValue);
              }
            } else {
              // we're dealing with a string
              if (!obj[paramName]) {
                // if it doesn't exist, create property
                obj[paramName] = paramValue;
              } else if (obj[paramName] && typeof obj[paramName] === 'string'){
                // if property does exist and it's a string, convert it to an array
                obj[paramName] = [obj[paramName]];
                obj[paramName].push(paramValue);
              } else {
                // otherwise add the property
                obj[paramName].push(paramValue);
              }
            }
          }
        }
      
        return obj;
      }
    
    function getActivityForm(activityApidaeId) {
        setFormUrl(null);
        if(!activityApidaeId || activityApidaeId === "0") {
          setCulturalAgenda(true);
          return;
        }
        getActivityApidaeFormUrl(process.env.GATSBY_APIV2_URL+'/fetch-form-url/'+activityApidaeId).then(res => {
            if(res) {
                setFormUrl(res);
                
            }
        })
    }

    function handleSelectChange(e) {
        getActivityForm(e.target.value);
    }

    return (
    <div style={{ margin: "3%" }}>
        <select style={{ width: "230px", height: "40px", padding: "10px", marginBottom: "3%" }} onChange={handleSelectChange}>
            {
                activitiesForms.map((activity) => {
                return <option key={activity._id} value={activity?.apidae?.editable ? activity?.apidae?.id : 0}>{activity.title}</option>
                })
            }
        </select>
        {
          culturalAgenda
          ?
          <div>
            Vous ne pouvez pas modifier cette fiche ici. Vous devez contacter votre organisme de référence pour la modifier.
          </div>
          :
          <></>
        }
        {
            formUrl
            ?
            <iframe id="myframe" style={{textAlign: "center"}} src={formUrl} width="100%" height="1000px"></iframe>
            :
            <></>
        }
    </div>
    )
}

const SettingsContext = ({ data }) => {
	return (
		<DashboardTemplate currentPage="mouvements">
			<ThemeProvider theme={theme}>
				<Settings data={data} />
				<GlobalStyle />
			</ThemeProvider>
		</DashboardTemplate>
	)
}

export default withAuth(SettingsContext)
