import React, { useEffect, useState } from 'react'
import Row from './Row'
import styled from 'styled-components'
import { log } from '~utils/helpers'
import Hr from '../Hr'
import { getTicketPricings, getTicketTva } from '@coq-trotteur/coq-core/dist'

const PositiveRows = ({ rows, FEE}) => {
  const [subTotal, setSubTotal] = useState(0)
  const [subTaxFree, setSubTaxFree] = useState(0)
  const [shippingCosts, setShippingCosts] = useState(0);
  

  useEffect(() => {
    let subTF = 0
    let totalShippingCosts = 0;

    rows.forEach(row => {
      
        totalShippingCosts += row?.delivery?.price || 0;
        
          // subTF += getTicketPricings(row).reduce(
          //   (acc, curr) => acc + curr.quantity * (curr.price / (1 + row?.marketPricings?.tva / 100)),
          //   0
          // )
          subTF += getTicketTva(row);
          // console.log(row.reservation_ticket_number+ " => "+getTicketTva(row));
        // subTF += row.total / (1 + (row?.marketPricings?.tva || 0) / 100);
       
      
    })
    const total = rows.reduce((prev, curr) => prev + curr.total, 0);
    setSubTaxFree(subTF)

    setSubTotal(total)
    setShippingCosts(totalShippingCosts);
  }, [])

  return (
    <div>
      {rows.map((resa, index) => (
        <Row infos={resa} key={`${index}-${resa._id}`} />
      ))}
      <Hr />
      <TTC>
      <div>Total du chiffre d'affaires</div>
      <div>
        <Price>{`${(subTotal - shippingCosts)
          .toFixed(2)
          .replace('.', ',')}€TTC (TVA : ${(subTaxFree).toFixed(2)}€. ${(subTotal - shippingCosts - subTaxFree)
          .toFixed(2)
          .replace('.', ',')}€HT)`}
        </Price>
      </div>
    </TTC>
    <TTC>
      <div>Total des frais de port</div>
      <div>
        <Price>{`${shippingCosts
          .toFixed(2)
          .replace('.', ',')}€TTC`}
        </Price>
      </div>
    </TTC>
      {/*<SubTotal>
        <div>Sous total TTC</div>
        <Price>{`${subTotal
          .toFixed(2)
          .replace('.', ',')}€ (${subTaxFree
          .toFixed(2)
          .replace('.', ',')}€HT)`}</Price>
          </SubTotal>*/}

      <SubTotal>
        <div>Commission : {FEE}% HT</div>
        <Price>{`${(
          (subTotal * FEE) / 100 +
          (((subTotal * FEE) / 100) * 20) / 100
        )
          .toFixed(2)
          .replace('.', ',')}€TTC
          (TVA 20% : ${((
            (subTotal * FEE) / 100 +
            (((subTotal * FEE) / 100) * 20) / 100
          ) - ((subTotal * FEE) / 100)).toFixed(2).replace('.', ',')}€. ${((subTotal * FEE) / 100).toFixed(2).replace('.', ',')}€HT)
          `}
          
          </Price>
      </SubTotal>

      <SubTotal>
        <div>Virement bancaire</div>
        <Price>0,18€TTC (TVA 20% : 0,03€. 0,15€HT)</Price>
      </SubTotal>

      <Hr />

      <TTC>
      <div>Total des frais</div>
      <div>
        <Price>
        {`${((
          (subTotal * FEE) / 100 +
          (((subTotal * FEE) / 100) * 20) / 100
        ) + 0.18).toFixed(2).replace('.', ',')}€TTC (TVA ${((((
          (subTotal * FEE) / 100 +
          (((subTotal * FEE) / 100) * 20) / 100
        ) + 0.18) / 1.2) * 0.20).toFixed(2).replace('.', ',')}€. ${(((
          (subTotal * FEE) / 100 +
          (((subTotal * FEE) / 100) * 20) / 100
        ) + 0.18) / 1.2).toFixed(2).replace('.', ',')}€HT)`
        }
        </Price>
      </div>
    </TTC>
    <NET>
      <div>Net à encaisser</div>
      <div>
        <Price>
        {`${(subTotal - ((
          (subTotal * FEE) / 100 +
          (((subTotal * FEE) / 100) * 20) / 100
        ) + 0.18)).toFixed(2).replace('.', ',')}€`
        }
        </Price>
      </div>
    </NET>
    </div>
  )
}

export default PositiveRows

const SubTotal = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(50% - 10px);
  column-gap: 20px;
  margin-bottom: 13px;
`

const Price = styled.div`
  justify-self: end;
`

const TTC = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
`;

const NET = styled.div`
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  margin-top: 44px;
`;
