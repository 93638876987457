export const pricingsObject = {
  name: '',
  tva: 0,
  description: '',
  // If activity is using the "no-date-booking" path
  time_limit: '',
  lang: {
    force: false,
    selection: [],
  },
  pricings: [
    {
      name: 'Plein tarif',
      selling_price: '',
      final_price: '',
      // _id:                        { type: String, default: shortid.generate },
    },
    // {
    //   name: true,
    //   selling_price: '',
    //   final_price: '',
    //   // _id:                        { type: String, default: shortid.generate },
    // },
  ],
  options: [
    {
      name: '',
      selling_price: '',
      final_price: '',
      tva: '',
      // _id:                        { type: String, default: shortid.generate },
    },
    {
      name: '',
      selling_price: '',
      final_price: '',
      tva: '',
      // _id:                        { type: String, default: shortid.generate },
    },
    {
      name: '',
      selling_price: '',
      final_price: '',
      tva: '',
      // _id:                        { type: String, default: shortid.generate },
    },
    {
      name: '',
      selling_price: '',
      final_price: '',
      tva: '',
      // _id:                        { type: String, default: shortid.generate },
    },
    {
      name: '',
      selling_price: '',
      final_price: '',
      tva: '',
      // _id:                        { type: String, default: shortid.generate },
    },
    {
      name: '',
      selling_price: '',
      final_price: '',
      tva: '',
      // _id:                        { type: String, default: shortid.generate },
    },
    {
      name: '',
      selling_price: '',
      final_price: '',
      tva: '',
      // _id:                        { type: String, default: shortid.generate },
    },
    {
      name: '',
      selling_price: '',
      final_price: '',
      tva: '',
      // _id:                        { type: String, default: shortid.generate },
    },
  ],
  inclusions: {
    has: [],
    hasnt: [],
  },
  starting_location: {
    enabled: false,
    address: '',
    note: '',
  },
  // cancellation_type:            { type: String, default: '' },
  // confirmation_method:          { type: String, default: '' },
  // schedule_type:                { type: String, default: '' },
  // line_cutting:                 { type: Boolean, default: false },
  // spots: {
  //   set:                        { type: Boolean, default: false },
  //   max:                        { type: Number, default: 0 }
  // },
  // activity:                     { type: mongoose.Schema.Types.ObjectId, ref: 'Activity' },

  // // only if schedule_type is set to 'specific'
  // schedules: {

  //   // we use the first list item for all languages
  //   same:                       { type: Boolean, default: true },

  //   list: [{
  //     lang:                     { type: String, default: '' },
  //     closed_periods:           { type: Array },
  //     periods: [{
  //       start_date:             { type: String, default: '' },
  //       end_date:               { type: String, default: '' },
  //       global:                 { type: Boolean, default: true },
  //       days: [{
  //         name:                 { type: String, default: '' },
  //         slots: [{
  //           start: {
  //             hours:            { type: Number, default: '' },
  //             minutes:          { type: Number, default: '' },
  //           }
  //         }]
  //       }],
  //     }]
  //   }]
  // },
  // created:                      { type: Date, default: Date.now },
}
