import React, { useEffect, useState } from 'react'
import DashboardTemplate from '~components/templates/DashboardTemplate'
import { withAuth } from '~HOC/withAuth'
import FormGroup from '~components/basics/FormGroup'
import { theme, GlobalStyle } from '~horairesMod/theme'

import Select from '~components/basics/Select'
import InputIcon from '~components/basics/InputIcon'
import Row from '~basics/virements/Row'
import { useBooking, BookingProvider } from '~contexts/bookingContext'

import BillsIcon from '~svg/booking/facture.svg'
import RibIcon from '~svg/booking/infos.svg'
import '~style/virements.scss'
// import Rib from 'src/pages/virements/Rib'
import { ThemeProvider } from 'styled-components'
import Notif from '~svg/booking/notif.svg'
import { useUserData } from '~contexts/userDataContext'
import { getBackendUser, putRib } from '~requests/back_user'
import EmptyBooking from '~components/basics/booking/EmptyBooking'
import { getAllReservationTickets } from '~requests/booking'
import { log } from '~utils/helpers'
import { Link } from 'gatsby'
import Spinner from '~components/basics/Icons/Spinner'

const Payments = () => {
	// ***** State / Context ****
	const [search, setSearch] = useState('')
	const [find, setFind] = useState([])
	const [ribDisplay, setRibDisplay] = useState(false)
	const [ribStatus, setRibStatus] = useState(null)
	const [loading, setLoading] = useState(true)

	const {
		apiUrl,
		bookingData,
		setBookingData,
		ribInfos,
		setRibInfos,
		emptyBooking
	} = useBooking()
	// console.log({ booking: useBooking() })
	const { userData, userId, token, activityId, saved, setSaved } = useUserData()

	const initData = async () => {
		const resTickets = await getAllReservationTickets(apiUrl, token, activityId)
		const backEndUser = await getBackendUser(apiUrl, token, userId)
		return { resTickets, backEndUser }
	}
	// ***** LifeCycle *****
	useEffect(() => {
		setSaved(false)
		initData()
			.then(res => {
				// console.log(`res --- booking data`, res)
				if (res.resTickets?.length) {
					setBookingData({ type: 'getAll', payload: res.resTickets })
				}

				if (res.backEndUser) setRibInfos(res.backEndUser.payment)
			})
			.finally(() => setLoading(false))
	}, [])

	useEffect(() => {
		const { name, bank, iban } = ribInfos?.rib || {}
		const { name: accName, address } = ribInfos?.account || {}
		if (!name || !bank || !iban || !accName || !address) {
			setRibStatus(false)
		} else {
			setRibStatus(true)
		}
	}, [ribInfos])

	// ***** Events handlers *****

	// ***** Events handlers *****
	const handleChange = e => {
		const { value } = e.target
		const val = value.toLowerCase()
		setSearch(value)
		if (value) {
			let found = []
			if (val === '*') {
				bookingData.forEach((_, i) => {
					found.push(i)
				})
			} else {
				bookingData.filter((o, i) => {
					const firstname = o.visitors[0]?.firstname.toLowerCase().includes(val)
					const lastname = o.visitors[0]?.lastname.toLowerCase().includes(val)
					const ticket = o.reservation_ticket_number.toLowerCase().includes(val)
					if (firstname || lastname || ticket) {
						found.push(i)
					}
					return firstname || lastname || ticket
				})
			}
			setFind(found)
		} else {
			setFind([])
		}
	}

	// ***** Functions *****
	const totalAmount = bookingData
		?.reduce((acc, curr) => acc + curr.price * curr.quantity, 0)
		.toFixed(2)
	
	if (loading) return <div className="loader_"><Spinner color="dark" /></div>
	
	return (
		<div
			className={`virements-page ${
				emptyBooking && !ribDisplay ? 'no-scroll' : ''
			}`}
		>
			<header className="header">
				<div className="row">
					<div className="grid-2">
						<Link
							className="button rib-button"
							onClick={() => setRibDisplay(true)}
							to="/mouvements/rib"
						>
							<div className="grid-2">
								<RibIcon />
								<div className="col j-center a-start">
									<span>Rib et autres informations comptables </span>
									{!ribStatus && (
										<span className="rib-notif notif">
											<Notif /> Vous devez renseigner votre Rib
										</span>
									)}
								</div>
							</div>
						</Link>

						<Link className="button bills-button" to="/mouvements/factures">
							<div className="grid-2">
								<BillsIcon />
								<div className="col j-center a-start">
									<span>Virements</span>
									<span className="bills-notif notif"></span>
								</div>
							</div>
						</Link>
					</div>
				</div>
			</header>

			{/*emptyBooking ? (
				<EmptyBooking page="payment" />
			) : (
				<div className="content">
					<h2>Historique des réservations</h2>
					<InputIcon
						icon="fa fa-search"
						placeholder="Recherche"
						changes={handleChange}
						value={search}
					/>
					<div className="main-table">
						{bookingData?.map((resa, index) => (
							<React.Fragment key={`${index}-${resa._id}`}>
								{resa.items
									.filter(
										item =>
											item.state.status === 'payed' ||
											item.state.status === 'done'
									)
									.map((prestation, i) => (
										<React.Fragment key={`${resa._id}-${name}-${i}`}>
											{!search ? (
												<Row
													index={index}
													item={i}
													infos={resa}
													search={search}
												/>
											) : (
												find.indexOf(index) !== -1 && (
													<Row
														index={index}
														item={i}
														infos={resa}
														search={search}
													/>
												)
											)}
										</React.Fragment>
									))}
							</React.Fragment>
						))}
					</div>
				</div>
												)*/}
			<a
				href="/settings"
				style={{
					background: '#f4f5fc',
					border: '1px solid lightgray',
					width: '16px',
					height: '16px',
					position: 'absolute',
					bottom: '1%',
					right: '1%'
				}}
			></a>
		</div>
	)
}

const PaymentsContext = ({ data }) => {
	return (
		<DashboardTemplate currentPage="mouvements">
			<BookingProvider>
				<ThemeProvider theme={theme}>
					<Payments data={data} />
					<GlobalStyle />
				</ThemeProvider>
			</BookingProvider>
		</DashboardTemplate>
	)
}

export default withAuth(PaymentsContext)
