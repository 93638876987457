import React from 'react'

const TimesIcon = ({ color }: { color: 'dark' | 'white' }) => (
	<svg
		width="12"
		height="12"
		viewBox="0 0 12 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1.35915 11.5789C1.11905 11.5789 0.878952 11.4879 0.696533 11.3041C0.330322 10.9379 0.330322 10.3441 0.696533 9.97792L9.97769 0.696533C10.3441 0.330322 10.9379 0.330322 11.3041 0.696533C11.6703 1.06274 11.6703 1.65646 11.3041 2.0229L2.02267 11.3041C1.83774 11.4879 1.59787 11.5789 1.35915 11.5789Z"
			fill={color === 'dark' ? '#172B49' : 'white'}
		/>
		<path
			d="M10.6402 11.5777C10.4004 11.5777 10.1603 11.4867 9.97786 11.3029L0.696705 2.02285C0.330265 1.65664 0.330265 1.06292 0.696705 0.696705C1.06292 0.330265 1.65664 0.330265 2.02285 0.696705L11.304 9.97786C11.6704 10.3441 11.6704 10.9378 11.304 11.304C11.1204 11.4867 10.8803 11.5777 10.6402 11.5777Z"
			fill={color === 'dark' ? '#172B49' : 'white'}
		/>
	</svg>
)
export default TimesIcon
