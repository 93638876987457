import React from 'react'
import { withAuth } from '~components/HOC/withAuth'
import Market from '~components/Market/Market'
import DashboardTemplate from '~components/templates/DashboardTemplate'

const BoutiquePage = props => {
	return (
		<div className="boutique">
			<Market {...props} />
		</div>
	)
}

const BoutiqueContainer = props => (
	<DashboardTemplate currentPage="boutique">
		<BoutiquePage {...props} />
	</DashboardTemplate>
)

export default withAuth(BoutiqueContainer)
