import React from 'react'

const StatIcon = () => (
	<svg
		width="72"
		height="64"
		viewBox="0 0 72 64"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M36.5131 28.2617L47.6142 9.60449L26.1953 11.9539L29.4029 17.0234L3.32324 33.8697L7.22615 40.0385L33.3058 23.1921L36.5131 28.2617Z"
			fill="#B2F0FB"
		/>
		<path
			d="M69.44 7.96459L70.9464 2.74631C71.0656 2.33342 70.9837 1.88867 70.7255 1.54537C70.4672 1.20193 70.0625 1 69.6328 1H55.3833C54.6282 1 54.0161 1.61223 54.0161 2.36719V24.2043H49.9303C49.1752 24.2043 48.5631 24.8164 48.5631 25.5715V31.2265H44.7801C44.025 31.2265 43.4129 31.8386 43.4129 32.5937V39.7904H23.5736C22.8185 39.7904 22.2064 40.4026 22.2064 41.1576V48.3542H2.36719C1.61209 48.3542 1 48.9664 1 49.7214V61.789C1 62.5439 1.61209 63.1562 2.36719 63.1562H65.9867C66.7418 63.1562 67.3538 62.5439 67.3538 61.789V32.5937C67.3538 31.8387 66.7418 31.2265 65.9867 31.2265H62.2038V25.5715C62.2038 24.8164 61.5917 24.2043 60.8366 24.2043H56.7506V14.9293H69.6328C70.0625 14.9293 70.4672 14.7274 70.7255 14.3839C70.9837 14.0406 71.0656 13.5958 70.9464 13.183L69.44 7.96459ZM3.73438 51.0884H22.2064V60.4217H3.73438V51.0884ZM24.9408 42.5246H43.4129V60.4215H24.9408V42.5246ZM64.6195 60.4217H46.1473V33.9608H64.6193V60.4217H64.6195ZM59.4693 31.2265H51.2975V26.9387H59.4693V31.2265ZM66.7035 8.34385L67.8151 12.1949H56.7505V3.73438H67.8151L66.7035 7.58547C66.632 7.8332 66.632 8.09598 66.7035 8.34385Z"
			fill="#172B49"
			stroke="#172B49"
			stroke-width="0.4"
		/>
	</svg>
)
export default StatIcon
