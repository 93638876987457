import React, { useRef } from 'react'
import { LabeledInput, FormLine, Select, FormColumns } from '../FormToolbox'
import Button from '~components/basics/Button'

const InclusionInput = ({ included = true, item, name = '' }) => (
	<LabeledInput
		className={`inclusion-${name}`}
		style={{
			height: 42
		}}
		label={
			included ? (
				<img src="/icons/checked.svg" style={{ height: 15, opacity: 0.5 }} />
			) : (
				<img src="/icons/cancel.svg" style={{ height: 12, opacity: 0.5 }} />
			)
		}
		{...item}
		placeholder={included ? 'Inclus...' : 'N’inclus pas…'}
		onClickRightLabel={() => {
			item.remove()
		}}
		rightLabel={
			item.canBeRemoved() && (
				<img src="/icons/delete.svg" style={{ height: 17, opacity: 0.5 }} />
			)
		}
	/>
)

const FormInclusions = ({ inclusionsForm, enabled = true }) => {
	const rootRef = useRef(null)

	return (
		<>
			{enabled && (
				<div ref={rootRef}>
					<FormColumns>
						<div>
							{inclusionsForm.get('has').map((item, i) => (
								<div
									style={{
										marginBottom: 8
									}}
								>
									<InclusionInput item={item} included={true} name="has" />
								</div>
							))}

							{inclusionsForm.get('has').canPush() && (
								<Button
									style={{
										height: 42
									}}
									theme="colored-action"
									onClick={() => {
										inclusionsForm.get('has').push()
										setTimeout(() => {
											const inputs = rootRef.current.querySelectorAll(
												'.inclusion-has'
											)
											if (inputs.length > 0) {
												;[...inputs].slice(-1)[0].focus()
											}
										}, 100)
									}}
								>
									+ Ajouter une ligne
								</Button>
							)}
						</div>

						<div>
							{inclusionsForm.get('hasnt').map((item, i) => (
								<div
									style={{
										marginBottom: 8
									}}
								>
									<InclusionInput item={item} included={false} name="hasnt" />
								</div>
							))}

							{inclusionsForm.get('hasnt').canPush() && (
								<Button
									style={{
										height: 42
									}}
									theme="colored-action"
									onClick={() => {
										inclusionsForm.get('hasnt').push()
										setTimeout(() => {
											const inputs = rootRef.current.querySelectorAll(
												'.inclusion-hasnt'
											)
											if (inputs.length > 0) {
												;[...inputs].slice(-1)[0].focus()
											}
										}, 100)
									}}
								>
									+ Ajouter une ligne
								</Button>
							)}
						</div>
					</FormColumns>
				</div>
			)}
		</>
	)
}
export default FormInclusions
