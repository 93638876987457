import React from 'react'

export const MailIcon = () => {
	return (
		<svg
			width="70"
			height="70"
			viewBox="0 0 70 70"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="35" cy="35" r="35" fill="white" />
			<path
				d="M47.8191 21H22.1809C21.0724 21.0012 20.0097 21.4421 19.2259 22.2259C18.4421 23.0097 18.0012 24.0724 18 25.1809V44.0191C18.0012 45.1276 18.4421 46.1903 19.2259 46.9741C20.0097 47.7579 21.0724 48.1988 22.1809 48.2H47.8191C48.9276 48.1988 49.9903 47.7579 50.7741 46.9741C51.5579 46.1903 51.9988 45.1276 52 44.0191V25.1809C51.9988 24.0724 51.5579 23.0097 50.7741 22.2259C49.9903 21.4421 48.9276 21.0012 47.8191 21ZM22.1809 23.2667H47.8191C48.3266 23.2673 48.8132 23.4691 49.172 23.828C49.5309 24.1868 49.7327 24.6734 49.7333 25.1809V26.0479L35 35.5191L20.2667 26.0479V25.1809C20.2673 24.6734 20.4691 24.1868 20.828 23.828C21.1868 23.4691 21.6734 23.2673 22.1809 23.2667ZM47.8191 45.9333H22.1809C21.6734 45.9327 21.1868 45.7309 20.828 45.372C20.4691 45.0132 20.2673 44.5266 20.2667 44.0191V28.7429L34.3869 37.8198C34.5697 37.9374 34.7826 38 35 38C35.2174 38 35.4303 37.9374 35.6131 37.8198L49.7333 28.7429V44.0191C49.7327 44.5266 49.5309 45.0132 49.172 45.372C48.8132 45.7309 48.3266 45.9327 47.8191 45.9333Z"
				fill="#172B49"
			/>
		</svg>
	)
}
