export const userDataObject = {
  title: '',
  // slug:                                      { type: String, default: '' },
  description: '',
  // search_terms:                              { type: Array },
  // address:                                   { type: mongoose.Schema.Types.Mixed },
  // end_address:                               { type: mongoose.Schema.Types.Mixed },
  administration: {
    mail: '',
    phone: '',
  },
  schedules: {
    same_schedule: '',
    closed_periods: [
      {
        start_date: '',
        end_date: '',
      },
    ],
    schedules_table: [],
  },
  // preset:                                    { type: mongoose.Schema.Types.ObjectId, ref: 'Preset' },
  // rating:                                    { type: mongoose.Schema.Types.ObjectId, ref: 'Rating' },
  // gallery:                                   [{ type: mongoose.Schema.Types.ObjectId, ref: 'Picture' }],
  // categories: {
  //   main:                                    { type: mongoose.Schema.Types.ObjectId, ref: 'Category' },
  //   optionals:                               [{ type: mongoose.Schema.Types.ObjectId, ref: 'Category' }],
  // },
  // informations:                              [{ type: mongoose.Schema.Types.ObjectId, ref: 'DataBlock' }],
  // destinations: [{
  //   data:                                    { type: mongoose.Schema.Types.ObjectId, ref: 'Destination' },
  //   sections: [{
  //     name:                                  { type: String, enum:['day', 'evening', 'cultural_agenda', 'restaurants', 'stays'] },
  //     categories: {
  //       main:                                { type: mongoose.Schema.Types.ObjectId, ref: 'Category' },
  //       optionals:                           [{ type: mongoose.Schema.Types.ObjectId, ref: 'Category' }],
  //     }
  //   }]
  // }],
  // folders:                                   [{ type: mongoose.Schema.Types.ObjectId, ref: 'Destination' }],
  // favorites:                                 [{ type: mongoose.Schema.Types.ObjectId, ref: 'Destination' }],
  // highlights: [{
  //   destination:                             { type: mongoose.Schema.Types.ObjectId, ref: 'Destination' },
  //   section:                                 { type: String, enum:['day', 'evening', 'cultural_agenda', 'restaurants', 'stays'] },
  //   category:                                { type: mongoose.Schema.Types.ObjectId, ref: 'Category' }
  // }],
  // constraints:                               [{ type: mongoose.Schema.Types.ObjectId, ref: 'Constraints' }],
  // free:                                      { type: Boolean, default: true },
  // duration: {
  //   days:                                    { type: Number, default: 0 },
  //   hours:                                   { type: Number, default: 0 },
  //   minutes:                                 { type: Number, default: 0 },
  // },
  // contacts: {
  //   mail:                                    { type: String, default: '' },
  //   website:                                 { type: String, default: '' },
  //   phone:                                   { type: String, default: '' },
  //   socials: {
  //     facebook:                              { type: String, default: '' },
  //     instagram:                             { type: String, default: '' },
  //     twitter:                               { type: String, default: '' }
  //   }
  // },
  // apidae: {
  //   id:                                      { type: String, default: '' },
  //   project_id:                              { type: String, default: '' },
  //   api_key:                                 { type: String, default: '' },
  //   updated:                                 { type: Date, default: ''},
  //   last_sync:                               { type: Date, default: ''},
  // },
  accessibility: {
    indoor: '',
    restrictions: [],
    public_transports: {
      accessibility: '',
      informations: {
        bus: '',
        tram: '',
        subway: '',
      },
    },
    starting_location: {
      enabled: '',
      address: '',
      note: '',
    },
  },
  about: {
    strong_points: ['', ''],
    note: {
      description: '',
    },
    inclusions: {
      enabled: '',
      has: [],
      hasnt: [],
    },
  },
  booking: {
    // confirmation_method:                     { type: String, enum:['instant', 'manual', 'prestation_dependent'], default: 'instant' },
    // confirmation_mail:                       { type: Boolean, default: true },
    // ticket: {
    //   type:                                  { type: String, enum:['any', 'paper_only'], default: 'any' },
    //   line_cutting:                          { type: String, enum:['yes', 'prestation_dependent', 'no'] }
    // },
    // cancellation: {
    //   unique:                                { type: Boolean, default: true },
    //   type:                                  { type: String, enum:['standard', 'partial', 'none'], default: 'standard' },
    //   weather:                               { type: Boolean, default: false },
    // },
    informations: {
      phone: '',
      mail: '',
    },
    each_person_identity: false,
    allow_message: false,
  },
  pricings: {
    same_pricing: null,
    informations: '',
    pricings_list: [],
    pricings_table: [],
  },
  // auto:                                      { type: Boolean, default: false },
  // created:                                   { type: Date, default: Date.now },
  // seen:                                      { type: Number, default: 0 },
  // selected:                                  { type: Number, default: 0 },
  ticketing: {
    enabled: false,
    done: false,
    current_step: 0,
    enabled_date: false,
  },
  story_telling: {
    enabled: false,
    story: '',
  },
}
