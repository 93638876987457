import React from 'react'
import Modal from '~components/basics/Modal/Modal'
import { Select } from '~components/Ticketing/FormToolbox'
import { DeleteIcon } from '../Icons'

import './DeleteEmailModal.scss'

const DeleteEmailModal = ({ setIsOpen, isOpen, deleteItem, canDelete }) => {
	return (
		<Modal
			className="DeleteEmailModal"
			setIsOpen={setIsOpen}
			isOpen={isOpen}
			close={() => setIsOpen(false)}
		>
			<DeleteIcon />
			<h3 style={{ marginBottom: 0 }}>Supprimer un mail</h3>
			{canDelete ? <div>Êtes-vous sûr de vouloir faire cela ?</div>: <div>Êtes-vous sûr de vouloir faire cela ?</div>}
			<hr />
			{/* <div style={{ marginBottom: '22px' }}>
				Il y a des offres qui ont “contact@atelier.fr” comme seule adresse de
				réservation. Pour la supprimer, vous devez choisir l’adresse sur
				laquelle vous recevrez vos mails de réservations issus de ces offres :
			</div>
			
			<div className="select_dropdown" style={{ marginBottom: '50px' }}>
				<Select
					value={1}
					setValue={() => {}}
					options={[
						{ value: 1, label: 'Non, ne pas désactiver toutes les offres' },
						{ value: 2, label: 'Désactiver toutes les offres' }
					]}
				/>
			</div> */}
			<div className={"buttons" + (!canDelete ? ' no_del' : '')}>
				<button
					onClick={() => {
						setIsOpen(false)
					}}
				>
					Annuler
				</button>
				{canDelete && <button
					className="confirm"
					onClick={() => {
						deleteItem()
						setIsOpen(false)
					}}
				>
					Confirmer
				</button>}
			</div>
		</Modal>
	)
}

export default DeleteEmailModal
