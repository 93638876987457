import React from 'react'
import { withAuth } from '~components/HOC/withAuth'
import MarketItemEditor from '~components/Market/MarketItemEditor/MarketItemEditor'

const NouveauPage = () => {
	return (
		<div
			className="nouveau"
			style={{
				paddingTop: 50
			}}
		>
			<MarketItemEditor />
		</div>
	)
}
export default withAuth(NouveauPage)
