import { useState } from 'react'
const { v4 } = require('uuid')

export function usePeriods() {
	const [periods, setPeriods] = useState([...defaultState])

	const handleRemovePeriod = uuid => () => {
		setPeriods(prev => prev.filter(pr => pr.uuid !== uuid))
	}

	const handleAddPeriod = () => {
		const days = JSON.parse(JSON.stringify(defaultDays))
		setPeriods(prev => [
			...prev,
			{ uuid: v4(), start_date: '', end_date: '', days }
		])
	}

	const resetPeriods = () => {
		const freshPeriod = JSON.parse(JSON.stringify(defaultState))
		setPeriods(freshPeriod)
	}

	const handleUpdateTime = period => day => type => time => {
		const oldPeriods = periods
		const oldWeek = oldPeriods[period].days
		oldWeek[day] = {
			...oldWeek[day],
			[type]: {
				hours: time.split(':')[0],
				minutes: time.split(':')[1]
			}
		}

		setPeriods([...oldPeriods])
	}

	const handleUpdateOptions = period => day => type => value => {
		const oldPeriods = periods
		const oldWeek = oldPeriods[period].days

		if (type === 'has_pause') {
			oldWeek[day] = {
				...oldWeek[day],
				options: {
					...oldWeek[day].options,
					[type]: !oldWeek[day].options[type]
				}
			}
		} else {
			oldWeek[day] = {
				...oldWeek[day],
				options: {
					...oldWeek[day].options,
					[type]: value
				}
			}
		}

		oldPeriods[period].days = oldWeek

		setPeriods([...oldPeriods])
	}

	const handlePaste = (period, parent, child) => {
		const oldPeriods = periods
		const oldWeek = oldPeriods[period].days
		oldWeek[child] = oldWeek[parent]
		setPeriods([...oldPeriods])
	}

	const handleGetPeriod = period => {
		setPeriods(prev => [...period])
	}

	return {
		periods,
		handleRemovePeriod,
		handleAddPeriod,
		handleUpdateOptions,
		resetPeriods,
		handleUpdateTime,
		handlePaste,
		handleGetPeriod
	}
}

const defaultDays = [
	{
		name: 'Lundi',
		start: {
			hours: '',
			minutes: ''
		},
		end: {
			hours: '',
			minutes: ''
		},
		start_pause: {
			hours: '',
			minutes: ''
		},
		end_pause: {
			hours: '',
			minutes: ''
		},
		options: {
			closed: false,
			has_pause: false
		}
	},
	{
		name: 'Mardi',
		start: {
			hours: '',
			minutes: ''
		},
		end: {
			hours: '',
			minutes: ''
		},
		start_pause: {
			hours: '',
			minutes: ''
		},
		end_pause: {
			hours: '',
			minutes: ''
		},
		options: {
			closed: false,
			has_pause: false
		}
	},
	{
		name: 'Mercredi',
		start: {
			hours: '',
			minutes: ''
		},
		end: {
			hours: '',
			minutes: ''
		},
		start_pause: {
			hours: '',
			minutes: ''
		},
		end_pause: {
			hours: '',
			minutes: ''
		},
		options: {
			closed: false,
			has_pause: false
		}
	},
	{
		name: 'Jeudi',
		start: {
			hours: '',
			minutes: ''
		},
		end: {
			hours: '',
			minutes: ''
		},
		start_pause: {
			hours: '',
			minutes: ''
		},
		end_pause: {
			hours: '',
			minutes: ''
		},
		options: {
			closed: false,
			has_pause: false
		}
	},
	{
		name: 'Vendredi',
		start: {
			hours: '',
			minutes: ''
		},
		end: {
			hours: '',
			minutes: ''
		},
		start_pause: {
			hours: '',
			minutes: ''
		},
		end_pause: {
			hours: '',
			minutes: ''
		},
		options: {
			closed: false,
			has_pause: false
		}
	},
	{
		name: 'Samedi',
		start: {
			hours: '',
			minutes: ''
		},
		end: {
			hours: '',
			minutes: ''
		},
		start_pause: {
			hours: '',
			minutes: ''
		},
		end_pause: {
			hours: '',
			minutes: ''
		},
		options: {
			closed: false,
			has_pause: false
		}
	},
	{
		name: 'Dimanche',
		start: {
			hours: '',
			minutes: ''
		},
		end: {
			hours: '',
			minutes: ''
		},
		start_pause: {
			hours: '',
			minutes: ''
		},
		end_pause: {
			hours: '',
			minutes: ''
		},
		options: {
			closed: false,
			has_pause: false
		}
	}
]

const defaultState = [
	{
		uuid: v4(),
		start_date: '',
		end_date: '',
		days: [...defaultDays]
	}
]
