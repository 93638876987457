import { pricingsObject } from '~contexts/pricingsObject'
import { TUserData } from '~contexts/userDataContext.types'
import { getActivities } from '~requests/get'
import { auth } from '~requests/post'
import { apiUrl } from '~utils/misc'

export const signInAction = (
	username,
	password,
	dispatchAuth: React.Dispatch<any>,
	dispatchData: React.Dispatch<any>,
	useData: TUserData
) => {
	dispatchAuth({
		type: `SIGN_IN_FETCHING`,
		payload: null
	})
	auth(apiUrl, username, password)
		.then(async result => {
			// console.log(result)
			const userId = result.user._id
			const activitiesIds = result.user.activities
			const activitiesData = await getActivities(
				result.user.activities,
				result.token,
				apiUrl
			)

			const shareLink = activitiesData.reduce((acc, activity) => {
				const url =
					activity.destinations.length && activity.destinations[0].data
						? `https://coq-trotteur.com/fr/a/${activity.slug}`
						: null

				acc[activity._id] = {
					url,
					title: activity.title
				}

				return acc
			}, {})
			dispatchData({
				type: 'SET_BASIC_DATA',
				payload: {
					shareLink,
					activitiesIds
				}
			})
			// do it save user token and data in localstorage
			localStorage.setItem('userId', userId)
			// localStorage.setItem(`userData-${userId}`, JSON.stringify(result.user))
			localStorage.setItem(`token-${userId}`, result.token)
			localStorage.setItem(`sharelink-${userId}`, JSON.stringify(shareLink))
			localStorage.setItem(
				`activities-${userId}`,
				JSON.stringify(activitiesIds)
			)

			// Legacy storage --------- START
			const onbording = !activitiesData[0].ticketing.done
			// @ts-ignore
			localStorage.setItem(`onboarding-${userId}`, onbording)
			useData.setToken(result.token)
			useData.setActivitiesId(activitiesIds)
			useData.setId(activitiesIds[0])
			useData.setShareLink(shareLink)
			useData.setUserId(userId)
			
			localStorage.setItem(`name-${userId}`, result.user.username)
			localStorage.setItem(`temp_mail-${userId}`, result.user.temp_mail)
			localStorage.setItem(`activity-${userId}`, activitiesIds[0])
			localStorage.setItem(
				`pricings-${userId}`,
				JSON.stringify([pricingsObject])
			)

			// Legacy storage --------- END

			// window.location.reload()

			dispatchAuth({
				type: `SIGN_IN_SUCCESS`,
				payload: {
					...result,
					userId
				}
			})
		})
		.catch(err => {
			dispatchAuth({
				type: `SIGN_IN_FAIL`,
				payload: null
			})
			console.log(err)
		})
}

export const signOutAction = (dispatch: React.Dispatch<any>) => {
	dispatch({
		type: 'SIGN_OUT'
	})
}

export const resetLoadingAction = (dispatch: React.Dispatch<any>) => {
	dispatch({
		type: 'RESET_LOADER'
	})
}

export const setLoadedAction = (dispatch: React.Dispatch<any>) => {
	dispatch({
		type: 'SET_LOADED'
	})
}
