import React from 'react'
import {
	FormLine,
	Select,
	FormColumns,
	Input
} from '~components/Ticketing/FormToolbox'

const InfosTransportAccessibility = ({ publicTransportField }) => {
	const accessibility = publicTransportField.get('accessibility').value

	return (
		<>
			<FormLine>
				<div>Votre activité est-elle accessible en transport en commun ?</div>
				<div>
					<Select
						style={{
							width: 180
						}}
						{...publicTransportField.get('accessibility')}
						options={[
							{
								label: 'Oui',
								value: true
							},
							{
								label: 'Non',
								value: false
							}
						]}
					/>
				</div>
			</FormLine>

			<div
				style={{
					pointerEvents: accessibility ? 'all' : 'none',
					position: accessibility ? 'static' : 'absolute',
					opacity: accessibility ? 1 : 0
				}}
			>
				<FormColumns>
					<div>
						<div className="transport-column">
							<div className="label">
								<img src="/icons/bus.svg" width={18} />
								Bus
							</div>
							<Input
								placeholder="N°, nom arrêt bus"
								{...publicTransportField.get('informations.bus')}
							/>
						</div>
					</div>
					<div>
						<div className="transport-column">
							<div className="label">
								<img src="/icons/tram.svg" width={13} />
								tram
							</div>
							<Input
								placeholder="N°, nom arrêt tram"
								{...publicTransportField.get('informations.tram')}
							/>
						</div>
					</div>
					<div>
						<div className="transport-column">
							<div className="label">
								<img src="/icons/metro.svg" width={18} />
								Metro
							</div>
							<Input
								placeholder="N°, nom arrêt métro"
								{...publicTransportField.get('informations.subway')}
							/>
						</div>
					</div>
				</FormColumns>
			</div>
		</>
	)
}

export default InfosTransportAccessibility
