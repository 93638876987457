import React, { useState, useRef, useEffect } from 'react'
import { navigate } from 'gatsby'
import { useForm, ErrorMessage } from 'react-hook-form'

import MessageBar from '~components/basics/MessageBar'
import Button from '~components/basics/Button'

import { newLogin } from '~requests/post'
import { useUserData } from '~contexts/userDataContext'
import BigLogo from '~svg/bigLogo.svg'
import { useAppContext } from '~contexts/appContext/appContext'

const complete = () => {
	// ***** State *****
	const { apiUrl, setLogged, setTempMail, setTempPwd } = useUserData()

	const { userId, token } = useAppContext().auth

	const [wrong, setWrong] = useState(false)

	const _id = userId && userId

	useEffect(() => {
		if (!userId) {
			navigate('/')
		}
	}, [])

	// React hook form
	const { register, handleSubmit, errors, watch } = useForm()
	const password = useRef({})
	password.current = watch('new_password', '')

	const PATTERN_ERROR = "L'addresse mail est incorrecte"
	const REQUIRED_ERROR = 'Ce champs est requis'

	// ***** Event handlers *****
	const onSubmit = data => {
		newLogin(data, userId, apiUrl, token).then(result => {
			localStorage.setItem(`temp_mail-${_id}`, 'false')
			setLogged(true)
			setTempMail(false)
			setTempPwd(false)

			document.location.href = '/'
			// navigate('/')
		})
	}

	const messageHandler = params => {
		setWrong(false)
	}

	return (
		<>
			<div className="login switchPwd" onSubmit={handleSubmit(onSubmit)}>
				<MessageBar
					type="danger"
					trigger={wrong}
					onClose={messageHandler}
					message={`Vos identifiants sont incorrects.`}
				/>

				<div className="logoWrapper">
					<BigLogo style={{ height: '92px' }} />
					<h1>Inscription</h1>
				</div>

				<form action="" className="complete add">
					<div className="alignment">
						<label htmlFor="new_mail">Choisissez votre mail de connexion</label>

						<div className="input-group marge">
							<input
								placeholder="Email"
								name="new_mail"
								ref={register({
									required: true,
									pattern: /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/
								})}
								className={errors.new_mail && 'has-error'}
							/>
							<ErrorMessage
								className="has-error info-inner"
								as="small"
								errors={errors}
								name="new_mail"
								message={`${
									errors?.new_mail?.type === 'pattern'
										? PATTERN_ERROR
										: REQUIRED_ERROR
								}`}
							/>
						</div>

						<label htmlFor="new_password">Choisissez votre mot de passe</label>

						<div className="join">
							<div className="input-group">
								<input
									type="password"
									placeholder="Mot de passe"
									name="new_password"
									ref={register({
										required: 'Ce champ est requis',
										minLength: {
											value: 8,
											message: '8 caractères minimum'
										}
									})}
									className={errors.new_password && 'has-error'}
								/>
								<ErrorMessage
									className="has-error info-inner"
									as="small"
									errors={errors}
									name="new_password"
									message={errors.new_password?.message}
								/>
							</div>
							<hr />
							<div className="input-group">
								<input
									type="password"
									placeholder="Répétez le mot de passe"
									name="password_repeat"
									ref={register({
										validate: value =>
											value === password.current ||
											'Le mot de passe ne correspond pas'
									})}
									className={errors.password_repeat && 'has-error'}
								/>
								<ErrorMessage
									className="has-error info-inner"
									as="small"
									errors={errors}
									name="password_repeat"
									message={errors.password_repeat?.message}
								/>
							</div>
						</div>
						<Button
							type="submit"
							theme="blue"
							size="large"
							// onClick={handleClick}
						>
							Valider
						</Button>
					</div>
				</form>
			</div>
		</>
	)
}

export default complete
