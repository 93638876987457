import React from 'react'

export const DeleteIcon = () => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.5658 6.2061L11.1274 6.15442L10.8156 14.4964L12.2539 14.5478L12.5658 6.2061Z"
				fill="#172B49"
			/>
			<rect
				x="8.34399"
				y="6.1803"
				width="1.4393"
				height="8.342"
				fill="#172B49"
			/>
			<path
				d="M7.31222 14.4962L7.00038 6.15417L5.56201 6.20589L5.87389 14.5478L7.31222 14.4962Z"
				fill="#172B49"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.5 4.11229V2.70239H17.6278V4.11229H16.1038L14.914 17.3565C14.8816 17.7206 14.5702 17.9999 14.1972 17.9999H3.90634C3.53335 17.9999 3.22219 17.7208 3.18957 17.3568L1.99977 4.11229H0.5ZM4.56554 16.5903H13.5382L14.6592 4.11259H3.4448L4.56554 16.5903Z"
				fill="#172B49"
			/>
			<path
				d="M11.4146 0H6.71284C6.05148 0 5.51343 0.527059 5.51343 1.17491V3.40729H6.95273V1.4099H11.1747V3.40729H12.614V1.17491C12.614 0.527059 12.076 0 11.4146 0Z"
				fill="#172B49"
			/>
		</svg>
	)
}

export const PenIcon = () => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 12.6663V16.0002H3.33381L13.1708 6.16316L9.83696 2.82935L0 12.6663Z"
				fill="#172B49"
			/>
			<path
				d="M15.7402 2.33588L13.6643 0.260037C13.3176 -0.0866792 12.753 -0.0866792 12.4063 0.260037L10.7794 1.88694L14.1132 5.22075L15.7401 3.59385C16.0869 3.24713 16.0869 2.6826 15.7402 2.33588Z"
				fill="#172B49"
			/>
		</svg>
	)
}

export const CloseIcon = () => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.76221 7.99948L15.7444 2.01704C15.9089 1.85236 15.9997 1.63265 16 1.39837C16 1.16397 15.9092 0.944 15.7444 0.779577L15.2202 0.25548C15.0553 0.0904065 14.8356 0 14.6011 0C14.367 0 14.1473 0.0904065 13.9824 0.25548L8.00026 6.23753L2.01782 0.25548C1.85327 0.0904065 1.63343 0 1.39902 0C1.16488 0 0.945041 0.0904065 0.780488 0.25548L0.256 0.779577C-0.0853333 1.12091 -0.0853333 1.6761 0.256 2.01704L6.23831 7.99948L0.256 13.9817C0.0913171 14.1466 0.000650406 14.3663 0.000650406 14.6006C0.000650406 14.8349 0.0913171 15.0546 0.256 15.2194L0.780358 15.7435C0.944911 15.9084 1.16488 15.999 1.39889 15.999C1.6333 15.999 1.85314 15.9084 2.01769 15.7435L8.00013 9.7613L13.9823 15.7435C14.1471 15.9084 14.3668 15.999 14.601 15.999H14.6012C14.8355 15.999 15.0552 15.9084 15.22 15.7435L15.7443 15.2194C15.9088 15.0547 15.9996 14.8349 15.9996 14.6006C15.9996 14.3663 15.9088 14.1466 15.7443 13.9818L9.76221 7.99948Z"
				fill="#FD517B"
			/>
		</svg>
	)
}

export const CheckIcon = () => {
	return (
		<svg
			width="20"
			height="15"
			viewBox="0 0 20 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.5353 0.464574C18.9165 -0.155053 17.9116 -0.154663 17.292 0.464574L7.19574 10.5612L2.70838 6.07392C2.08875 5.45429 1.08435 5.45429 0.464721 6.07392C-0.154907 6.69355 -0.154907 7.69795 0.464721 8.31758L6.07367 13.9265C6.38329 14.2361 6.78928 14.3913 7.19531 14.3913C7.60134 14.3913 8.00772 14.2365 8.31733 13.9265L19.5353 2.70819C20.1549 2.089 20.1549 1.08416 19.5353 0.464574Z"
				fill="#66A3FF"
			/>
		</svg>
	)
}
