import { text_field, bool_field, number_field } from 'react-super-useform'

const dayHoursFieldValidation = (isPause = false) => (value, get) => {
	const isClosed = get('parent.parent.options.closed').value
	if (isClosed) return true

	const has_pause = get('parent.parent.options.has_pause').value
	if (isPause && !has_pause) return true
	if (value === '' || value === null) return false
	return value >= 0 && value < 24
}

const dayMinutesFieldValidation = (isPause = false) => (value, get) => {
	const isClosed = get('parent.parent.options.closed').value

	if (isClosed) return true

	const has_pause = get('parent.parent.options.has_pause').value

	if (isPause && !has_pause) return true

	if (value === '' || value === null) return false

	return value >= 0 && value < 60
}

const dayTimeSchema = {
	hours: number_field({ validation: dayHoursFieldValidation() }),
	minutes: number_field({ validation: dayMinutesFieldValidation() })
}

const dayPauseSchema = {
	hours: number_field({ validation: dayHoursFieldValidation(true) }),
	minutes: number_field({ validation: dayMinutesFieldValidation(true) })
}

export default {
	root: {
		enabled: bool_field(),
		schedules: {
			type: Array,
			min: 1,
			constrainMinMax: true,
			children: {
				activities: {
					type: Array,
					min: 1,
					children: text_field()
				},
				enabled: bool_field({ default: false }),
				same_schedule: bool_field({ default: true }),
				schedules_table: {
					type: Array,
					min: 1,
					constrainMinMax: true,
					children: {
						// This part is transformed at data saving
						dates: {
							type: Array,
							min: 1,
							constrainMinMax: true,
							children: {
								start_date: text_field({
									validation: (v, get) => {
										// TODO problem in lib, not updated (probably due to __parent Array or Object)
										if (
											get('parent.parent.parent.parent.parent').toJSON()
												.same_schedule === true
										)
											return true

										if (!v || v === '') {
											return false
										}

										return true
									}
								}),
								end_date: text_field({
									validation: (v, get) => {
										// TODO problem in lib, not updated (probably due to __parent Array or Object)
										if (
											get('parent.parent.parent.parent.parent').toJSON()
												.same_schedule === true
										)
											return true
										if (!v || v === '') return false

										return true
									}
								})
							}
						},

						days: {
							type: Array,
							readOnly: true,
							children: {
								start: dayTimeSchema,
								end: dayTimeSchema,
								start_pause: dayPauseSchema,
								end_pause: dayPauseSchema,
								options: {
									closed: bool_field(),
									has_pause: bool_field()
								},
								name: text_field({ required: false })
							},
							constrainMinMax: true,
							min: 7,
							max: 7,
							default: [
								{ name: 'monday' },
								{ name: 'tuesday' },
								{ name: 'wednesday' },
								{ name: 'thursday' },
								{ name: 'friday' },
								{ name: 'saturday' },
								{ name: 'sunday' }
								// { name: 'lundi' },
								// { name: 'mardi' },
								// { name: 'mercredi' },
								// { name: 'jeudi' },
								// { name: 'vendredi' },
								// { name: 'samedi' },
								// { name: 'dimanche' }
							]
						}
					}
				},
				closed_periods: {
					type: Array,
					children: {
						start_date: text_field(),
						end_date: text_field()
					}
				}
			}
		}
	}
}
