import React from 'react'
import {
	Input,
} from '~components/Ticketing/FormToolbox'


const FicheHoursInput = props => (
	<Input
		{...props}
		className="hours-input time-input"
		onFocus={e => {
			e.target.select()
		}}
	/>
)

const MinutesInput = props => (
	<Input
		{...props}
		className="minutes-input time-input"
		onFocus={e => {
			e.target.select()
		}}
	/>
)


export const FicheHoursMinutesInput = ({ timeForm, stopAfterMinutes = false }) => {
	const error = timeForm.get('hours').error || timeForm.get('minutes').error

	//console.log(timeForm.get('hours').value)
	return (
		<div className={`hours-minutes-input ${error ? 'error' : ''}`}>
			<FicheHoursInput
				onBlur={() => {
					if (`${timeForm.get('hours').value}`.length === 1) {
						timeForm.get('hours').setValue(`0${timeForm.get('hours').value}`)
					}
				}}
				value={
					timeForm.get('hours').value === null
						? ''
						: `00${timeForm.get('hours').value}`.substr(-2)
				}
				setValue={(value, e) => {
					if (value.match(/[^0-9]/)) return
					if (value > 23) return
					timeForm.get('hours').setValue(value)
					if (`${value}`.length > 1) {
						const list: any[] = [...Array.from(document.querySelectorAll('.time-input'))]
						const currentIndex = list.indexOf(e.target)
						if (currentIndex + 1 < list.length) {
							setTimeout(() => {
								list[currentIndex + 1].focus()
								list[currentIndex + 1].select()
							}, 100)
						}
					}
				}}
			/>
			<span className="separator">:</span>
			<MinutesInput
				onBlur={() => {
					if (`${timeForm.get('minutes').value}`.length === 1) {
						timeForm
							.get('minutes')
							.setValue(`0${timeForm.get('minutes').value}`)
					}
					if (`${timeForm.get('minutes').value}`.length === 0) {
						timeForm.get('minutes').setValue(`00`)
					}
				}}
				value={timeForm.get('minutes').value}
				setValue={(value, e) => {
					if (value.match(/[^0-9]/)) return
					if (value > 59) return
					if (`${value}`.length > 2) return
					timeForm.get('minutes').setValue(value)
					if (`${value}`.length > 1 && !stopAfterMinutes) {
						const list: any[] = Array.from(document.querySelectorAll('.time-input'))
						const currentIndex = list.indexOf(e.target)
						if (currentIndex + 1 < list.length) {
							setTimeout(() => {
								list[currentIndex + 1].focus()
								list[currentIndex + 1].select()
							}, 100)
						}
					}
				}}
			/>
		</div>
	)
}