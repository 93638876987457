import React, { useEffect } from 'react'
import { FicheHoursMinutesInput } from './FicheHoursMinutesInput'
import Button from '~components/basics/Button'

import {
	CheckBox,
} from '~components/Ticketing/FormToolbox'
import { capitalize } from '../Schedules.utils'
import moment from 'moment'

const ClosedDayLine = () => <div className="closed-day-line"></div>


export const FicheScheduleItemDay = ({
	dayForm,
	i,
	handleCopy,
	copiedDay,
	iSchedule,
	clearCopy
}) => {
	const hasData =
		!!dayForm.get('start.hours').value ||
		!!dayForm.get('start.minutes').value ||
		!!dayForm.get('end.hours').value ||
		!!dayForm.get('end.minutes').value

	useEffect(() => {
		if (copiedDay && copiedDay.i === i && copiedDay.iSchedule === iSchedule) {
			clearCopy()
		}
	}, [
		dayForm.get('start.hours').value,
		dayForm.get('start.minutes').value,
		dayForm.get('end.hours').value,
		dayForm.get('end.minutes').value,
		dayForm.get('start_pause.hours').value,
		dayForm.get('start_pause.minutes').value,
		dayForm.get('end_pause.hours').value,
		dayForm.get('end_pause.minutes').value,
		dayForm.get('options.closed').value,
		dayForm.get('options.has_pause').value
	])

	return (
		<div className="schedule-item-day">
			<div className="left">
				<div className="day-name">
					{capitalize(
						moment()
							.day(i + (1 % 7))
							.format('dddd')
					)}
				</div>

				{!dayForm.get('options.closed').value ? (
					<>
						<div style={{ marginRight: 10, position: 'relative' }}>
							<span className="label">Ouverture</span>
							<FicheHoursMinutesInput timeForm={dayForm.get('start')} />
						</div>
						{dayForm.get('options.has_pause').value && (
							<>
								<div style={{ marginRight: 10, position: 'relative' }}>
									<span className="label">Début pause</span>
									<FicheHoursMinutesInput timeForm={dayForm.get('start_pause')} />
								</div>

								<div style={{ marginRight: 10, position: 'relative' }}>
									<span className="label">Fin pause</span>
									<FicheHoursMinutesInput timeForm={dayForm.get('end_pause')} />
								</div>
							</>
						)}

						<div style={{ marginRight: 10, position: 'relative' }}>
							<span className="label">Fermeture</span>
							<FicheHoursMinutesInput timeForm={dayForm.get('end')} />
						</div>
					</>
				) : (
					<ClosedDayLine />
				)}

				<div className="closed-day">
					<span className="label">Jour fermé</span>
					<div className="checkbox-container">
						<CheckBox
							value={dayForm.get('options.closed').value}
							setValue={dayForm.get('options.closed').setValue}
						/>
					</div>
				</div>
				<div>
					{!dayForm.get('options.has_pause').value &&
						!dayForm.get('options.closed').value && (
							<Button
								theme="colored-action"
								onClick={() => {
									dayForm.get('options.has_pause').setValue(true)
								}}
							>
								+ Ajouter une pause
							</Button>
						)}
				</div>
			</div>
			<div style={{ display: 'flex', paddingTop: 18 }}>
				{dayForm.get('options.has_pause').value &&
					!dayForm.get('options.closed').value && (
						<>
							{' '}
							<Button
								theme="underline-action"
								onClick={() => {
									dayForm.get('options.has_pause').setValue(false)
								}}
							>
								Retirer la pause
							</Button>
							<span style={{ margin: '0 0 0 4px' }}>|</span>
						</>
					)}
				{((copiedDay &&
					(copiedDay.i !== i || copiedDay.iSchedule !== iSchedule)) ||
					!copiedDay) &&
					hasData && (
						<Button
							theme="underline-action"
							onClick={() => {
								handleCopy({
									data: dayForm.toJSON(),
									i,
									iSchedule: iSchedule
								})
							}}
						>
							Copier
						</Button>
					)}
				{copiedDay &&
					(copiedDay.i === i && copiedDay.iSchedule === iSchedule ? (
						<div
							style={{ display: 'flex', alignItems: 'center', marginLeft: 4 }}
						>
							<img
								src="/icons/checked.svg"
								style={{ height: 12, marginRight: 4 }}
							/>
							Copié
						</div>
					) : (
						<>
							{((copiedDay &&
								(copiedDay.i !== i || copiedDay.iSchedule !== iSchedule)) ||
								!copiedDay) &&
								hasData && <span style={{ margin: '0 0 0 4px' }}>|</span>}
							<Button
								theme="underline-action"
								onClick={() => {
									dayForm.set({
										...copiedDay.data,
										name: dayForm.toJSON().name
									})
								}}
							>
								Coller
							</Button>
						</>
					))}
			</div>
		</div>
	)
}