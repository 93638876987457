import React from 'react'

import './CGUV.scss'
import Header from './Layout/Header'
import WaveIllustration from './Layout/WaveIllustration'

const CGUV = ({}) => {
	return (
		<div className="CGUV">
			<header>
				<div>
					<h1>CONDITIONS GÉNÉRALES D’UTILISATION ET DE VENTE</h1>
					<h5>Création de site e-commerce</h5>
					<WaveIllustration />
				</div>
			</header>

			<div className="manifest-container">
				<p>
					L'utilisation du moteur de création de site Internet de coq-trotteur
					(Ci-après « Service ») emporte acceptation pleine et entière des
					présentes Conditions Générales d'Utilisation et de Vente (Ci-après «
					CGUV »).
				</p>
				<p>
					Le fait que FeelCity ne se prévale pas, à un moment donné, d'une des
					dispositions des CGUV ne pourra être interprété comme valant
					renonciation par elle-même à se prévaloir ultérieurement de l'une de
					ces dispositions.
				</p>
				<p>
					Les présentes CGUV sont valables et applicables à compter du
					01/05/2021 et ont pour objet de définir les modalités d’utilisation
					des Services proposés aux Commerçants. FeelCity se réserve le droit de
					les modifier à tout moment.
				</p>

				<h2>I. DÉFINITIONS</h2>

				<p>« FeelCity (ci-après « Nous », « nos », « notre ») »</p>
				<p>
					Désigne la société FeelCity, société SAS au capital de 5 000 €, dont
					le siège social est situé au 11 Rue Sainte Cécile, 13005 Marseille,
					immatriculée sous le n° 850 289 083 RCS Marseille, représentée par
					Nicolas GARCIN, en qualité de Président.
				</p>
				<p>« Service(s) »</p>
				<p>
					Désigne toutes les entités techniques et les données logicielles liées
					au moteur de création de site Internet dont le but est d’aider les
					professionnels à se digitaliser et à vendre sur Internet. Ce service
					est développé et édité par FeelCity qui en détient la propriété.
				</p>
				<p> « Commerçant (ci-après « vous », « vos », « votre ») »</p>
				<p>
					Désigne toute personne physique ou morale qui a la qualité de
					commerçant au sens de l’article L.121-1 du code de commerce et qui
					consulte/utilise les Services de la Plateforme pour promouvoir et/ou
					commercialiser ses produits/prestations
				</p>
				<p>« Partenaire »</p>
				<p>
					Désigne toute personne physique ou morale partenaire de FeelCity
					détenant une URL spécifique liée à la Plateforme de la forme
					www.coq-trotteur.com/fr/nomduPartenaire.
				</p>
				<p>
					Chaque Partenaire dispose d’un périmètre géographique donné, périmètre
					pouvant être partagé entièrement ou partiellement avec un autre
					Partenaire.
				</p>
				<p>« Offre(s) »</p>
				<p>
					Désigne l’ensemble des Offres proposés par les Commerçants sur la
					Plateforme.
				</p>
				<p>« Commande(s) »</p>
				<p>
					Désigne tout acte d’achat ou de réservation sur le site Internet du
					Commerçant.
				</p>

				<h2>II. PREAMBULE</h2>

				<p>
					Nous travaillons avec les territoires pour créer tout un ensemble
					d’outils digitaux à même de décupler l’attractivité de ces derniers et
					la visibilité des Commerçants.
				</p>

				<p>
					De nombreux services sont à disposition du Commerçant pour initier sa
					transition digitale : vente en ligne de ses offres chez le Partenaire,
					vente en ligne de ses offres chez les hébergeurs des territoires et la
					vente en ligne de ses offres sur son propre site Internet.
				</p>

				<p>
					Notre solution bâtie par les territoires, pour les territoires se
					donne pour mission de créer l’écosystème local digital le plus
					puissant possible pour capter les locaux et la clientèle touristique.
				</p>

				<p>Nos Services, sans vouloir être limitatif permettent :</p>
				<ul>
					<li>De créer un site Internet vitrine marchand ;</li>
					<li>
						De proposer un outil innovant qui en fait un atout marketing fort,
						qui stimule l’attractivité du territoire, et protège les spéciﬁcités
						locales dans une démarche responsable.
					</li>
				</ul>

				<p>
					Dans ce cadre, FeelCity ne peut être considéré en tant que vendeur au
					sens de l’article 15 de la Loi pour la Confiance dans l'Économie
					Numérique (LCEN). FeelCity n’est en aucun cas partie prenantes des
					relations existantes entre le Commerçant et l’internaute, ce que le
					Commerçant accepte expressément et sans aucune réserve.
				</p>

				<p>
					Le contrat entre les Parties se forme après l'acceptation des
					présentes CGUV.
				</p>

				<h2>III. DURÉE, COMMANDE & RÈGLEMENT</h2>

				<h3>3.1 Acceptation du contrat</h3>

				<p>
					Toute création d’un site entraîne l’approbation sans réserve des
					présentes CGUV.
				</p>

				<p>
					Le professionnel peut également souscrire à un plan payant. Le contrat
					est conclu pour une durée déterminée de douze (12) mois (ci-après
					“Période Initiale”) commençant à compter de la date de paiement du
					plan. Les Prestations se reconduisent annuellement et sont résiliables
					à tout moment par simple mail, moyennant le respect d’un préavis d’un
					(1) mois.
				</p>

				<h3>3.2 Résiliation du contrat</h3>

				<p>
					Dans le cadre d’une résiliation exprimée par le Commerçant, elle ne
					pourra intervenir qu’à la fin de la Période Initiale. Si toutefois, le
					Commerçant souhaite rompre son contrat avant la fin de la Période
					Initiale, aucun dédommagement ne sera prévu. Passé la Période
					Initiale, elle pourra intervenir trente (30) jours après l'envoi de
					ladite demande adressée par mail.
				</p>

				<p>
					En cas de violation des présentes CGUV, pour tout préjudice constaté
					ou portant atteinte à l’image et à la qualité de la prestation fournie
					par FeelCity, nous nous réservons le droit, à notre entière
					discrétion, de suspendre de façon temporaire ou permanente, de
					résilier immédiatement et sans préavis ou de limiter l’accès et
					l’utilisation des Services. Nous nous réservons le droit, à notre
					seule discrétion, d’annuler les Commandes passées, si cette opération
					est dans l’intérêt des internautes et d’appliquer des frais
					supplémentaires liés audit préjudice et à ladite violation.
				</p>

				<h3>3.3 Règlement</h3>

				<p>
					Le Commerçant pour procéder aux paiements des prestations doit
					obligatoirement renseigner une carte bancaire valide. Ces informations
					transitent ensuite par un service bancaire tiers et sécurisé.
				</p>

				<p>
					En cas de retard ou défaut de paiement, FeelCity se réserve le droit
					de suspendre l’accès aux Services.
				</p>

				<p>
					Dans le cas où le Commerçant est amené à changer de plan et à payer la
					nouvelle souscription annuelle, une régularisation sera faite en
					prenant en compte les sommes déjà versées pour le plan inférieur.
				</p>

				<h2>IV. LES SERVICES</h2>

				<h3>4.1 Accès aux Services</h3>
				<p>
					L’accès aux Services de fait au travers d’une plateforme qui nécessite
					une connexion Internet et un navigateur maintenu et à jour (tels que
					Chrome ou Mozilla), ce que reconnaît expressément le Commerçant.
				</p>

				<p>
					Le Commerçant reconnaît que les Services sont susceptibles d’évoluer
					et qu’à ce titre, les Services pourraient être adaptés, améliorés en
					conséquence.
				</p>

				<p>
					FeelCity ne saurait être tenue pour responsable d’éventuels dommages,
					directs ou indirects, pouvant découler de l’accès et de l’utilisation
					des Services, notamment, un dommage ou virus qui pourrait endommager
					l’ordinateur du Commerçant ou tout autre matériel. Il appartient donc
					à chaque Commerçant de prendre toutes les précautions appropriées de
					façon à protéger ses propres données et/ou logiciels de la
					contamination d’éventuels virus circulant sur Internet.
				</p>

				<h3>4.2 Utilisation des Services</h3>
				<h4>4.2.1 Souscription</h4>

				<p>
					Pour avoir accès aux Services, le Commerçant doit faire partie des
					structures référencées par le Partenaire*.
				</p>

				<p>
					*Par défaut, l’office de tourisme où est implantée l’Activité du
					Commerçant détient le rôle de Partenaire.
				</p>

				<p>
					Un Commerçant peut dans certains cas être sollicité par plusieurs
					Partenaires si ce dernier est présent sur plusieurs destinations.
				</p>
				<p>
					Nous recommandons au Commerçant de se rapprocher du Partenaire* pour
					connaître et suivre les démarches et modalités pour avoir accès aux
					Services.
				</p>

				<h5>
					<i>Accès à la Plateforme</i>
				</h5>

				<p>
					Lorsque le Commerçant est référencé par le Partenaire, nous envoyons
					dans les meilleurs délais au Commerçant les informations nécessaires
					pour assurer une première connexion et avoir ainsi accès à l’ensemble
					des Services.
				</p>

				<p>
					Nous recommandons au Commerçant de vérifier et contrôler attentivement
					ses différents moyens de communication (courrier électronique,
					courrier électronique passé en SPAM, sms etc.) pour s’assurer de la
					bonne réception des informations. Dans le cas contraire, nous vous
					conseillons de nous contacter à l’adresse électronique suivante :
					aide@coq-trotteur.com
				</p>

				<h5>
					<i>Relation entre FeelCity et le Commerçant</i>
				</h5>

				<p>
					Nous n'exerçons aucun contrôle préalable, sans vouloir être limitatif
					: sur les aspects légaux, sur la qualité et la sécurité des
					installations, sur la pertinence et la capacité des Commerçants à
					réaliser leur activité du fait que cela est vérifié par les
					Partenaires.
				</p>

				<p>
					Le Commerçant reconnaît et garantit à titre personnel ou en tant que
					représentant de personne morale être âgé de 18 ans ou plus pour
					pouvoir utiliser nos Services et avoir la capacité juridique de
					respecter et accepter nos présentes CGUV.
				</p>

				<h4>4.2.2 Obligations du Commerçant</h4>
				<p>
					Le Commerçant admet la véracité de toutes les informations saisies et
					fournies lors de la souscription et lors de l’utilisation des Services
					et en certifie l’exactitude et la complétude.
				</p>

				<p>
					L’ensemble des informations mentionnées par le Commerçant ne doivent
					pas enfreindre et violer les droits de propriété, notamment
					intellectuelle et les droits d’auteur d’un tiers ou d’un autre
					Commerçant déjà référencé sur la Plateforme.
				</p>

				<p>
					Le Commerçant reconnaît et s’engage à respecter l’ensemble de ces
					obligations définies par les présentes CGUV. Sans vouloir être
					limitatif :
				</p>
				<ul>
					<li>
						Le Commerçant certifie que pour toute souscription et utilisation
						des Services, l’intégralité des informations transmises sont vraies,
						exactes, complètes, maintenues à jour, non trompeuses et non
						frauduleuses.
					</li>
					<li>
						Le Commerçant est entièrement responsable vis-à-vis du contenu lié à
						son activité sur la Plateforme et de l’utilisation des Services qui
						en découlerait.
					</li>
					<li>
						Nous recommandons au Commerçant de protéger les données liées à
						l’accès à la Plateforme et aux Services. FeelCity ne peut être tenue
						responsable des conséquences d’une utilisation frauduleuse des
						données de connexion par un tiers.
					</li>
					<li>
						Le Commerçant complétera ses informations de facturation pour
						assurer le bon déroulement des Services ;
					</li>
					<li>
						Le Commerçant s'engage à respecter les directives de la Loi pour
						l'Economie Numérique (LEN) ;
					</li>
					<li>
						Le Commerçant peut ajouter certains liens actifs vers d’autres sites
						Internet sans vouloir être limitatif : site web, réseaux sociaux
						etc. Le Commerçant reconnaît, de manière expresse, en être
						entièrement responsable.
					</li>
					<li>
						Toutes les interactions sur ces sites Internet et applications
						tierces sont votre propre responsabilité.
					</li>
					<li>
						Le Commerçant fera ses meilleurs efforts pour présenter, fournir,
						exécuter et réaliser son offre en bonne et due forme conformément
						aux normes relatives et réglementations en vigueur dans le secteur
						du Commerçant notamment liées à la sécurité et à la santé des
						internautes.
					</li>
					<li>
						Le Commerçant disposera de toutes les compétences, de quelque nature
						qu’elle soit, pour fournir et réaliser son activité et disposera à
						tout moment des documents associés pour justifier et prouver
						lesdites compétences.
					</li>
					<li>
						Le Commerçant atteste avoir souscrit l’assurance Responsabilité
						Civile Professionnelle concernant l’ensemble des risques
						susceptibles de faire courir à raison de l’activité dans laquelle il
						est spécialisé.
					</li>
					<li>
						Le Commerçant sera entièrement responsable en cas de recours à un
						sous-traitant et ne pourra en aucun cas transférer ses droits et ses
						obligations audit sous-traitant. La responsabilité du Commerçant
						pourra être engagée en cas de non-respect, manquement ou violation
						des présentes CGUV dudit sous-traitant.
					</li>
					<li>
						Le Commerçant est entièrement responsable des interactions et des
						communications avec l’internaute pour toute action liée à
						l’utilisation de la Plateforme et des Services, qu’elle soit de son
						fait ou d’une autre personne.
					</li>
				</ul>

				<h4>4.2.3 Boutique en ligne</h4>

				<p>
					Nous précisons que pour ce service de boutique en ligne, nous
					intervenons et agissons uniquement en qualité d’intermédiaire des
					Commerçants et des internautes et de ce fait uniquement pour votre
					compte et le compte de ces derniers.
				</p>

				<p>
					Un contrat d’achat se forme donc à chaque Commande entre le Commerçant
					et l’internaute. Nous recommandons au préalable à l’internaute de
					prendre connaissance de vos conditions générales.
				</p>

				<p>
					L’utilisateur mineur ne dispose pas de la capacité juridique pour
					conclure un contrat le liant à un achat sans le consentement de son
					représentant légal (conformément à l’article 1124 du Code civil).
					Toute Commande réalisée par un mineur est, au regard de la loi, nul et
					non avenu.
				</p>

				<p>
					Nous déclinons toute responsabilité concernant tout acte, manquement,
					toute déclaration, erreur, garantie, négligence des Commerçants ou
					tout préjudice corporel, décès, dégât matériel ou autre dommage ou
					frais résultant de vos interactions avec les internautes.
				</p>

				<p>
					Le Commerçant s’engage à respecter et à se conformer aux obligations
					liées à la vente à distance notamment celles issues du Code de la
					consommation et de Commerce. Le Commerçant s’engage à ce que les
					produits proposés soient conformes au droit en vigueur en France. Le
					Commerçant s’interdit de vendre au travers des Services :
				</p>
				<ul>
					<li>
						Des Produits ayant pour but, directement ou indirectement, d’inciter
						au racisme, à la xénophobie, à l'antisémitisme, à la discrimination
						comme par exemple la discrimation raciale, sexuelle ou religieuse,
						violence etc ;
					</li>
					<li>Des Produits volés ou contrefaits ;</li>
					<li>
						Des Produits ayant un caractère diffamatoire, injurieux, mais
						également ceux portant atteinte à la vie privée, à l'honneur ou à la
						réputation d'autrui ;
					</li>
					<li>
						Des Produits n’ayant pas la pleine légitimité des droits de
						propriété intellectuelle (contrefaçon, vidéo, fichiers numériques
						contrefaits, copies d'enregistrements audio etc.) ;
					</li>
					<li>
						Des Produits sur lesquels vous n’avez pas les accréditations ou ne
						remplissez les formalités préalables à la commercialisation au
						niveau national
					</li>
				</ul>

				<p>
					Le Commerçant doit fixer le prix des offres conformément aux lois et
					règlements en vigueur en France notamment, et sans que cette liste
					soit limitative, les dispositions de la loi n°81-766 du 10 août 1981
					sur le prix des livres et celles des Art. L.311-1 et suivants du Code
					de la propriété intellectuelle. Le Commerçant est également tenu de
					respecter la réglementation concernant la revente à perte prévue aux
					Art. L.442-2 à L.442-4 du Code de commerce. Le Commerçant détermine le
					Prix du Produit sous sa seule responsabilité.
				</p>

				<h3>4.3 Moteur de réservation</h3>
				<h4>4.3.1 Principe</h4>

				<p>
					Le Commerçant dispose dans le cadre de la boutique en ligne du module
					de vente en ligne intégré.
				</p>

				<p>
					Le paiement par carte bancaire proposé est réalisé sur les serveurs
					bancaires sécurisés de la BNP PARIBAS. Aucune information bancaire
					transite sur la Plateforme et les coordonnées sont cryptées grâce au
					protocole SSL (Secure Socket Layer) et le paiement sécurisé 3D Secure
					dans sa version 2.
				</p>

				<h4>4.3.2 Politique retour et annulation</h4>

				<p>
					La politique d’annulation et de retour est fixée par chaque Commerçant
					pour chaque Offre. Dans le cas d’une réservation sur une durée de
					validité, l’annulation n’est pas possible.
				</p>

				<h4>4.3.3 Modification et annulation du Commerçant</h4>
				<p>
					<i>Annulation</i>
				</p>
				<p>
					Toute annulation de Commande de votre part entraîne le remboursement
					intégral de l’Utilisateur, pour quelque motif que ce soit.
				</p>
				<p>
					Dans le cas du remboursement d’un client après réception de notre
					virement bancaire, le Commerçant est tenu de rembourser le client de
					son côté, par ses propres moyens.
				</p>
				<p>
					<i>Modification</i>
				</p>
				<p>
					Dans le cas où le Commerçant modifie un tarif, seules les Commandes
					ultérieures à cette date appliqueront ledit tarif : la date et le
					tarif indiqués sur la Commande faisant foi et seront considérés comme
					preuve.
				</p>
				<p>
					Le Commerçant dispose du droit de modifier à tout moment et sans
					préavis sans vouloir être exhaustif : les horaires d’ouverture et de
					fermeture, le lieu de prise en charge etc.{' '}
					<b>
						Dans le cas où de telles modifications impactent des Commandes déjà
						effectuées, le Commerçant aura pour entière responsabilité
						d’informer l’Utilisateur de telles modifications et proposera une
						date de substitution ou un report de la date limite d’utilisation.
					</b>
				</p>
				<p>
					<b>
						Dans le cas où l’Utilisateur refuse ladite modification, le
						Commerçant procédera au remboursement intégral sur la Plateforme.
					</b>
				</p>
				<p>
					Pour limiter tout préjudice, le Commerçant fera ses meilleurs efforts
					pour informer et corriger sur la Plateforme toutes les mises à jour
					apportées, sans vouloir être limitatif, toute modification de
					tarification, d’horaires d’ouverture, de suppression de Produits ou
					toute autre donnée nécessaire pour la bonne exécution des obligations
					du Commerçant. Si le Commerçant ne corrige pas en amont ces
					informations, FeelCity se réserve le droit en cas de remboursement des
					Utilisateurs d’instaurer des frais de gestion.
				</p>

				<h4>4.3.4 Commissionnement</h4>

				<p>
					Lors d’une réservation, nous ne prenons pas de commission. Nous
					appliquons uniquement des frais relatifs aux coûts des opérations
					bancaires (frais bancaires, frais de transactions, frais d’annulations
					etc.)
				</p>
				<p>
					La Commission est un taux fixe pour une durée indéterminée, pensée et
					convenue dans l’intérêt de soutenir l’économie locale et les acteurs
					locaux. Nous nous réservons le droit de modifier à tout moment la
					Commission, ce que comprend et accepte expressément le Commerçant. Le
					Commerçant sera averti par courrier électronique de tout changement.
				</p>
				<p>
					La Commission actuellement pratiquée est de 2.6% + 0.12€ pour les
					cartes européennes et de 3.6% + 0.12€ pour les cartes hors
					européennes.
				</p>

				<h4>4.3.5 Versement</h4>
				<p>
					Le versement du Montant se fera de façon hebdomadaire. Le paiement
					sera effectué par virement bancaire dès lors que le Commerçant aura
					enregistré ses informations bancaires. Dans le cas contraire, le
					montant sera mis de côté en attendant que les informations soient
					complètes.
				</p>
				<p>
					Le Commerçant est tenu de mettre à jour ses coordonnées bancaires et
					fiscales si elles viennent à être modifiées.
				</p>
				<p>
					Lorsque nous vous faisons un virement, nous appliquons des frais de
					0,15€HT, représentant l’exact montant prélevé par notre partenaire
					bancaire, au titre de la transaction bancaire.
				</p>
				<p>
					Le Commerçant disposera dans l’onglet « Mes virements » des
					justificatifs de paiement et du détail des flux.
				</p>
				<p>
					Pour toute incohérence ou anomalie de facturation, le Commerçant peut
					nous contacter à l’adresse suivante : aide@coq-trotteur.com
				</p>

				<h2>V. GARANTIE ET OBLIGATIONS</h2>
				<h3>5.1 Disponibilité et moyens</h3>
				<p>
					FeelCity s’engage à faire ses meilleurs efforts pour assurer aux
					Commerçants une accessibilité aux Services à tout moment. Toutefois,
					FeelCity se réserve le droit de modifier, de suspendre ou
					d'interrompre l'accès à tout ou partie des Services à tout moment,
					pour quelque motif que ce soit et sans avis préalable, le tout sans
					droit à indemnités.
				</p>
				<p>
					FeelCity s’engage à mettre en œuvre tous les moyens opérationnels,
					humains et sécuritaires nécessaires pour la réalisation des Services.
				</p>
				<p>
					Dans le cas d’un non-respect des présentes CGUV, la suspension vaut
					pour la durée nécessaire définie par FeelCity et n’ouvre droit à
					aucune indemnité.
				</p>

				<h3>5.2 Force majeure</h3>

				<p>
					Nous entendons par le terme « Force majeure », tout évènement
					extérieur aux Parties présentant un caractère à la fois imprévisible,
					irrésistible, extérieur et insurmontable qui empêche soit
					l’internaute, soit le Partenaire, soit le Commerçant de réaliser et
					exécuter tout ou partie des obligations prévues et fixées par le
					Contrat.
				</p>
				<p>
					Sans vouloir être limitatif, il en sera ainsi en matière de conditions
					climatiques et catastrophes naturelles (intempéries, incendies,
					tremblement de terre, inondations…), de grèves et manifestations, de
					dysfonctionnements ou de la panne des équipements, des logiciels ou du
					matériel technique, de coupures de courant, d’actes terroristes, de
					crise économique ou sanitaire, de fermeture d’établissements,
					d’accidents graves ou mortels pendant une Activité, d’un comportement
					d’un internaute envers un Commerçant non conforme au Contrat etc.
				</p>
				<p>
					En conséquence, un cas de force majeure, se présentant, suspend
					uniquement les obligations et devoirs de ladite partie, visés et
					affectés par ce dernier et toute responsabilité de ladite partie qui
					aurait dû en découler.
				</p>

				<h2>VI. PROPRIÉTÉ INTELLECTUELLE</h2>
				<p>
					Les Services ainsi que chacun des éléments qui les composent,
					notamment les éléments graphiques, textuels, les images, les bases de
					données, les programmes et développements informatiques associés ainsi
					que les dénominations sociales, les marques et les logos sont la
					propriété exclusive de FeelCity (Ci-après les « Eléments de propriété
					intellectuelle de FeelCity »).
				</p>
				<p>
					Sauf à des fins exclusives d'information pour un usage personnel et
					privé, toute reproduction, diffusion ou utilisation de quelque nature
					que ce soit, des Éléments de propriété intellectuelle de FeelCity sans
					l'autorisation expresse de FeelCity, est interdite.
				</p>
				<p>
					Toute reproduction, modification, imitation, extraction,
					réutilisation, partielle ou intégrale, concession sous licence,
					transfert ou vente de toute information, tout logiciel, tout produit
					ou service à l'aide d'un robot, d'un « scraper » ou de tout autre
					moyen automatisé ou tout processus manuel qui en serait faite sans
					l'accord écrit et préalable de FeelCity constituera une contrefaçon,
					au sens des articles L. 335-2 et suivants du Code de la Propriété
					Intellectuelle, susceptible d’engager la responsabilité civile et
					pénale de son auteur.
				</p>
				<p>
					Toutefois, toutes les données transcrites par vos soins, notamment les
					illustrations et les données textuelles sont votre propriété et ne
					sont pas concernées par le présent article.
				</p>

				<h2>VII. LIMITATION DE RESPONSABILITÉ DE FEELCITY</h2>

				<p>
					FeelCity ne pourra être engagée en cas d’utilisation des Services de
					façon non conforme et ne peut être tenue responsable de tout dommage
					direct ou indirect, de toute perte de profits ou revenus résultant
					et/ou liée à l’interruption des Services.
				</p>
				<p>
					FeelCity ne peut être tenue responsable quant aux contenus
					sauvegardés, publiés et transmis aux internautes par le Commerçant
					étant donné qu’il s’agit du contenu du Commerçant. FeelCity ne saurait
					être tenue pour responsable et aucun recours ne pourra être engagé
					contre elle en cas de faits d’un tiers, d’événements indépendants de
					sa volonté ou de survenance d'événements présentant les caractères de
					force majeure, par la loi et la jurisprudence française, sans que
					cette liste soit exhaustive, empêchant la mise à jour des données
					tarifaires et des horaires d’ouverture, la transmission des
					informations nécessaires au bon déroulement de la vente en ligne,
					obligeant à annuler les Commandes, privant partiellement ou totalement
					les Commerçants d’exercer et fournir leur activité, allongeant le
					délai de traitement des préjudices. FeelCity se dégage de toute
					responsabilité relative à une éventuelle insatisfaction du Commerçant
					concernant les Services.
				</p>
				<p>
					FeelCity n’intervient en aucun cas dans la relation contractuelle et
					commerciale qui unit les Commerçants et les internautes. FeelCity
					invite le Commerçant à la vigilance et à la prudence dans ses échanges
					avec les internautes. La Plateforme facilite la mise en relation entre
					les internautes et les Commerçants mais ne garantit pas la bonne
					compréhension des informations par ces derniers.
				</p>

				<p>
					FeelCity ne saurait voir sa responsabilité engagée dans le cadre de
					différend lié à la commercialisation, à la vente ou à la livraison de
					produits.
				</p>
				<p>
					Dans le cas où la responsabilité de FeelCity est retenue envers un
					Commerçant par la juridiction compétente, celle-ci ne peut excéder le
					montant des sommes versées par le Commerçant lors de la souscription à
					un plan.
				</p>
				<p>
					La clause de non-responsabilité s’appliquera dans toute la mesure
					permise par la législation locale de l’internaute.
				</p>

				<h2>VIII. RESPONSABILITÉ DU COMMERCANT</h2>
				<p>
					Le Commerçant, dans le cadre de l’utilisation des Services, est
					responsable :
				</p>
				<ul>
					<li>
						En cas de manquement et d’omission aux obligatoires définies
						précédemment ;
					</li>
					<li>
						Du respect des présentes CGUV et de la réglementation en vigueur le
						concernant sur son activité ;
					</li>
					<li>
						Du contenu, des pages et de son site Internet de manière générale
					</li>
					<li>
						Des dommages causés à FeelCity quand cela est parfaitement
						explicité.
					</li>
				</ul>
				<p>
					Dans le cadre des présents services, le Commerçant s’engage à
					respecter dans le cadre de son activité de commerce en ligne
					l’ensemble des lois applicables en vigueur pour l’utilisation et le
					traitement des données utilisateurs notamment à la Loi Informatique et
					Libertés du 6 janvier 1978 dans ses versions modifiées, et au
					Règlement UE 2016/679 du 27 avril 2016 relatif à la protection des
					personnes physiques à l'égard du traitement des données à caractère
					personnel et à la libre circulation de ces données.
				</p>
				<p>
					Le Commerçant s’engage à traiter les données utilisateurs uniquement
					pour les seules finalités qui font l’objet du présent Service et à
					garantir la confidentialité.
				</p>
				<p>
					Le Commerçant fera ses meilleurs efforts pour tenir un registre de
					données et effectuer toutes les déclarations obligatoires auprès de la
					CNIL. Le Commerçant précisera la personne en charge des données
					personnelles et précisera l’ensemble des droits reconnus aux
					internautes.
				</p>
				<p>
					Le Commerçant veillera à ce que les personnes autorisées à traiter les
					données personnelles disposent des formations et compétences
					nécessaires en matière de protection de ces dernières et s’engagent à
					respecter le présent article.
				</p>

				<h2>IX. MODIFICATION DES CGUV</h2>

				<p>
					Nous nous réservons le droit de modifier ou compléter les présentes
					CGUV à tout moment. Ces modifications seront rétroactives.
				</p>
				<p>
					Vous comprenez et acceptez que l’accès et l’utilisation des Services
					après lesdites modifications vous engage à respecter les présentes
					CGUV modifiées ou actualisées. Aucune condition particulière ne peut,
					sans notre accord express, prévaloir sur les présentes CGUV.
				</p>
				<p>
					Vous retrouverez en haut de page des présentes CGUV, la date des
					dernières modifications qui prendront effet dès leur publication.
				</p>

				<h2> X. DROIT APPLICABLE ET ATTRIBUTION DE JURIDICTION</h2>

				<p>
					Les présentes CGUV sont soumises à la loi française. Toute
					contestation relative à l’application, l’exécution, à l’interprétation
					et/ou la validité des présentes CGUV non résolue à l’amiable entre les
					parties sera soumise à la juridiction du tribunal de commerce du lieu
					défini par les parties.
				</p>
				<p>
					Si aucun accord n'est trouvé, le tribunal de commerce de Marseille
					sera choisi par défaut.
				</p>
				<p>
					En conséquence, le recours du Commerçant n’est pas limité
					temporellement et ni géographiquement.
				</p>
			</div>
		</div>
	)
}

export default CGUV
