import React, { useEffect } from 'react'

import Button from '~basics/Button'

import { useUserData } from '~contexts/userDataContext'
import BigLogo from '~svg/bigLogo.svg'
import Arrow from '~svg/fleche.svg'
import HelpPanel from './HelpPanel'
import useToggle from '~hooks/useToggle'

import { useFormHandler } from '~contexts/currentFormContext'

import './WelcomeScreen.scss'

const WelcomeScreen = ({
	handleNextStep,
	registerValidationFunction,
	handleChangeCurrentStep
}) => {
	// Context
	const { userData } = useUserData()
	const free = userData?.free
	const type = free ? 'free' : 'pay'
	const [helpPanelIsOpen, toggleHelpPanelIsOpen] = useToggle(false)

	useFormHandler(
		{
			modified: false,
			isValid: true,
			save: () => {
				return true
			}
		},
		() => {
			return true
		}
	)

	const content = {
		pay: {
			title: 'Le coq veille au grain',
			text: () => (
				<>
					Nous oeuvrons à faire rayonner l’écosystème local dans une logique
					vertueuse. En seulement quelques minutes, vous activerez votre
					billetterie en ligne. Rejoignez la communauté coq-trotteur :)
				</>
			)
		},
		free: {
			title: 'Faites rayonner votre activité !',
			text: () => (
				<>
					Nous oeuvrons à faire rayonner l’écosystème local dans une logique
					vertueuse. En seulement 2 minutes, vous complèterez les informations
					essentielles pour la visibilité de votre activité sur la plateforme du
					territoire.
				</>
			)
		}
	}

	return (
		<>
			<HelpPanel
				isOpen={helpPanelIsOpen}
				toggleIsOpen={toggleHelpPanelIsOpen}
			/>
			<div className="welcome-screen-container">
				<div className="welcome-screen">
					<div>
						<BigLogo style={{ height: 80 }} />
					</div>
					<h1>{content[type].title}</h1>

					<p className="subtitle">{content[type].text()}</p>

					<div className="buttons">
						<Button
							className="start-onboarding"
							roundish={'roundish'}
							theme="blue"
							onClick={() => {
								handleChangeCurrentStep(1)
							}}
						>
							<span>C'est parti</span>
							<Arrow />
						</Button>
						<div
							style={{
								marginTop: 24
							}}
						>
							<Button onClick={toggleHelpPanelIsOpen} theme="underline-action">
								En savoir plus
							</Button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default WelcomeScreen
