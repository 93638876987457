import React from 'react'
import { useAlert } from '~components/basics/Alert/Alert'
import SuccessAlert from '~components/basics/Alert/SuccessAlert'

const Success = () => {
  const alert = useAlert()

  return <SuccessAlert {...alert} blocked={true}></SuccessAlert>
}

export default Success
