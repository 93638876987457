import 'url-search-params-polyfill'

export const auth = async (apiUrl, username, password) => {
	const myHeaders = new Headers()
	myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

	const urlencoded = new URLSearchParams()
	urlencoded.append('username', username)
	urlencoded.append('password', password)

	const requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: urlencoded,
		redirect: 'follow'
	}

	let res = await fetch(apiUrl + '/auth/login', requestOptions)
	let data = await res.json()
	return data
}

export const steps = async (data, apiUrl, token, activityId) => {
	const myHeaders = new Headers()
	myHeaders.append('Content-Type', 'application/json')
	myHeaders.append('Accept', 'application/json, text/plain, */*')
	myHeaders.append('x-access-token', token)

	const requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: JSON.stringify(data),
		redirect: 'follow'
	}

	let res = await fetch(
		apiUrl + '/activities/step/' + activityId,
		requestOptions
	)
	let freshData = await res.json()
	return freshData
}

export const postActivity = async (data, apiUrl, token, activityId) => {
	const myHeaders = new Headers()
	myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
	myHeaders.append('x-access-token', token)

	// const urlencoded = new URLSearchParams()
	// urlencoded.append("username", username)
	// urlencoded.append("password", password)

	const requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: JSON.stringify(data),
		redirect: 'follow'
	}

	let res = await fetch(apiUrl + '/activities/' + activityId, requestOptions)
	let freshData = await res.json()
	return freshData
}

export const newLogin = async (
	{ new_mail, new_password },
	userId,
	apiUrl,
	token
) => {
	const myHeaders = new Headers()
	myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
	myHeaders.append('x-access-token', token)

	const urlencoded = new URLSearchParams()
	urlencoded.append('new_mail', new_mail)
	urlencoded.append('new_password', new_password)

	const requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: urlencoded,
		redirect: 'follow'
	}

	let res = await fetch(apiUrl + '/auth/complete/' + userId, requestOptions)
	let data = await res.json()
	return data
}

export const postSchedule = async (data, apiUrl, token) => {
	const myHeaders = new Headers()
	myHeaders.append('Content-Type', 'application/json')
	myHeaders.append('accept', 'application/json')
	myHeaders.append('x-access-token', token)

	const requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: JSON.stringify(data),
		redirect: 'follow'
	}

	let res = await fetch(apiUrl + '/schedules', requestOptions)
	let resp = await res.json()
	return resp
}

export const deleteSchedule = async (apiUrl, token, scheduleId) => {
	const myHeaders = new Headers()
	myHeaders.append('Content-Type', 'application/json')
	myHeaders.append('accept', 'application/json')
	myHeaders.append('x-access-token', token)

	const requestOptions = {
		method: 'DELETE',
		headers: myHeaders,
		redirect: 'follow'
	}

	let res = await fetch(apiUrl + '/schedules/' + scheduleId, requestOptions)
	let resp = await res.json()
	return resp
}

export const postPricings = async (data, apiUrl, token) => {
	const myHeaders = new Headers()
	myHeaders.append('Content-Type', 'application/json')
	myHeaders.append('Accept', 'application/json, text/plain, */*')
	myHeaders.append('x-access-token', token)

	const requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: JSON.stringify(data),
		redirect: 'follow'
	}

	let res = await fetch(apiUrl + '/pricings', requestOptions)
	let freshData = await res.json()
	return freshData
}

export const modifyPricings = async (data, apiUrl, token, pricingId) => {
	const myHeaders = new Headers()
	myHeaders.append('Content-Type', 'application/json')
	myHeaders.append('Accept', 'application/json, text/plain, */*')
	myHeaders.append('x-access-token', token)

	const requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: JSON.stringify(data),
		redirect: 'follow'
	}

	let res = await fetch(apiUrl + '/pricings/' + pricingId, requestOptions)
	let freshData = await res.json()
	return freshData
}

export const deletePricing = async (apiUrl, token, pricingId) => {
	const myHeaders = new Headers()
	myHeaders.append('x-access-token', token)

	const requestOptions = {
		method: 'DELETE',
		headers: myHeaders,
		// body: JSON.stringify(signlePrice),
		redirect: 'follow'
	}

	let res = await fetch(apiUrl + '/pricings/' + pricingId, requestOptions)
	let freshData = await res.json()
	return freshData
}
