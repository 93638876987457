import { Link } from 'gatsby'
import React, { useRef, useState } from 'react'
import Button from '~components/basics/Button'
import Spinner from '~components/basics/Icons/Spinner'
import LZString from 'lz-string'

import './assistance_technique.scss'
import { useUserData } from '~contexts/userDataContext'

const getAllLocalStorage = () =>
	Object.keys(localStorage).reduce((acc, key) => {
		return {
			...acc,
			[key]: localStorage.getItem(key),
		}
	}, {})

const getDebugData = () => {
	const userAgent = navigator.userAgent

	return {
		localStorage: LZString.compressToBase64(
			JSON.stringify(getAllLocalStorage())
		),
		userAgent,
		environment: 'dashboard-activity',
		type: 'client-ticket',
	}
}

const postDebugData = async (token, apiUrl, data) => {
	//frontend-error

	const myHeaders = new Headers()
	myHeaders.append('Content-Type', 'application/json')
	myHeaders.append('Accept', 'application/json, text/plain, */*')
	myHeaders.append('x-access-token', token)

	const requestOptions = {
		method: 'POST',
		headers: myHeaders,
		body: JSON.stringify(data),
	}

	let res = await fetch(apiUrl + '/frontend-error', requestOptions)
	return await res.json()
}

const timeout = ms => new Promise(res => setTimeout(res, ms))
function clearSelection() {
	if (window.getSelection) {
		window.getSelection().removeAllRanges()
	} else if (document.selection) {
		document.selection.empty()
	}
}

const TechnicalAssistanceSendByMail = () => {
	const [copied, setCopied] = useState(false)
	const textareaRef = useRef(null)

	const handleCopy = async () => {
		textareaRef.current.select()
		document.execCommand('copy')
		setCopied(true)

		await timeout(1500)
		setCopied(false)
		clearSelection()
	}

	return (
		<div className="TechnicalAssistanceSendByMail">
			<p>
				{/* Oups, quelque chose n'a pas marché, m */}
				Merci de copier les informations ci dessous et de nous les envoyer par
				mail à <a href="mailto:aide@coq-trotteur.com">aide@coq-trotteur.com</a>.
			</p>

			<Button onClick={handleCopy} theme="primary-dark">
				{copied ? 'copié' : 'copier'}
			</Button>

			<textarea readOnly ref={textareaRef}>
				{JSON.stringify(getDebugData())}
			</textarea>
		</div>
	)
}

const TechnicalAssistance = () => {
	const [reportIsSending, setReportIsSending] = useState(false)
	const [reportSendError, setReportSendError] = useState(false)
	const { token, apiUrl } = useUserData()
	const [reportSent, setReportSent] = useState(false)

	const handleSendReport = async () => {
		if (reportIsSending) return
		setReportIsSending(true)

		postDebugData(token, apiUrl, getDebugData())
			.then(() => {
				setReportIsSending(false)
				setReportSent(true)
			})
			.catch(err => {
				setReportSendError(true)
				setReportIsSending(false)
			})
	}

	return (
		<div className="TechnicalAssistance">
			<div className="content">
				<h1>Assistance technique</h1>

				{reportSent ? (
					<>
						<p style={{ marginBottom: 0 }}>
							Merci pour votre aide ! Nous revenons vers vous au plus vite.
						</p>
					</>
				) : reportSendError ? (
					<TechnicalAssistanceSendByMail />
				) : (
					<>
						<p>
							Merci de cliquer sur ce bouton pour envoyer le rapport de bugs aux
							équipes techniques.
						</p>

						<Button
							onClick={handleSendReport}
							theme="primary-dark"
							style={{
								width: 235,
								height: 42,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								margin: '0 auto',
							}}
						>
							{reportIsSending ? (
								<Spinner color="light" />
							) : (
								<span>Envoyer le rapport de bug</span>
							)}
						</Button>
					</>
				)}
			</div>

			<div className="back">
				<Link to="/">
					<Button>Revenir à l'accueil</Button>
				</Link>
			</div>
		</div>
	)
}
export default TechnicalAssistance
