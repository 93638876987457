import React from 'react'
import './FormGroup.scss'

const FormGroup = ({ trigger, group, children, classes = '', ...leftOver }) => {
  return (
    <div
      className={`formgroup form-${group || 'line'} ${classes} ${
        trigger === false
          ? 'hidden-field'
          : trigger === true
          ? 'hidden-field is-visible'
          : ''
      }`}
      {...leftOver}
    >
      {children}
    </div>
  )
}

export default FormGroup
