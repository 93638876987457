import React from 'react'

import './LineCheck.scss'

const LineCheck = ({ data, name, register, ...leftOver }) => {
  return (
    <div className="line-check">
      <input
        type="checkbox"
        id={name}
        name={name}
        ref={register}
        {...leftOver}
      />
      <label htmlFor={name}>{data}</label> (optionnel)
    </div>
  )
}

export default LineCheck
