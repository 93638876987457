import React from 'react'

export const CopyIcon = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M2.38641 19.9999H11.2502C11.8828 19.9989 12.4893 19.7472 12.9366 19.2999C13.3839 18.8526 13.6356 18.2461 13.6366 17.6135V6.02239C13.6356 5.38977 13.3839 4.78334 12.9366 4.33601C12.4893 3.88868 11.8828 3.63695 11.2502 3.63599H2.38641C1.75379 3.63695 1.14735 3.88868 0.700024 4.33601C0.252695 4.78334 0.000961794 5.38977 0 6.02239V17.6135C0.000961794 18.2461 0.252695 18.8526 0.700024 19.2999C1.14735 19.7472 1.75379 19.9989 2.38641 19.9999ZM1.81821 6.02239C1.81821 5.8717 1.87808 5.72718 1.98463 5.62062C2.09119 5.51406 2.23571 5.4542 2.38641 5.4542H11.2502C11.4009 5.4542 11.5454 5.51406 11.652 5.62062C11.7585 5.72718 11.8184 5.8717 11.8184 6.02239V17.6135C11.8184 17.7642 11.7585 17.9087 11.652 18.0153C11.5454 18.1218 11.4009 18.1817 11.2502 18.1817H2.38641C2.23571 18.1817 2.09119 18.1218 1.98463 18.0153C1.87808 17.9087 1.81821 17.7642 1.81821 17.6135V6.02239Z"
				fill="#172B49"
			/>
			<path
				d="M17.6091 0H8.75437C8.28422 0.000444506 7.82464 0.139544 7.43315 0.39989C7.04166 0.660235 6.73565 1.03026 6.55342 1.46366C6.45939 1.68572 6.45742 1.93605 6.54795 2.15956C6.63848 2.38307 6.81409 2.56147 7.03616 2.6555C7.25822 2.74953 7.50854 2.7515 7.73205 2.66097C7.95557 2.57044 8.13396 2.39483 8.22799 2.17277C8.27101 2.06836 8.34393 1.979 8.43758 1.91592C8.53124 1.85283 8.64145 1.81884 8.75437 1.81821H17.6091C17.7609 1.81845 17.9064 1.87887 18.0138 1.98623C18.1212 2.09359 18.1816 2.23913 18.1818 2.39095V13.973C18.1816 14.1248 18.1212 14.2703 18.0138 14.3777C17.9064 14.4851 17.7609 14.5455 17.6091 14.5457H15.4545C15.2134 14.5457 14.9821 14.6415 14.8117 14.812C14.6412 14.9825 14.5454 15.2137 14.5454 15.4548C14.5454 15.6959 14.6412 15.9272 14.8117 16.0977C14.9821 16.2681 15.2134 16.3639 15.4545 16.3639H17.6091C18.243 16.3632 18.8507 16.1111 19.2989 15.6628C19.7472 15.2146 19.9993 14.6069 20 13.973V2.39095C19.9993 1.75705 19.7472 1.14932 19.2989 0.701091C18.8507 0.252857 18.243 0.000721843 17.6091 0Z"
				fill="#172B49"
			/>
		</svg>
	)
}
