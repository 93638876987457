export const sizingDropdown = data => {
	const maxChars = data.reduce((max, { name }) =>
		max > name.length ? max : name.length
	)
	let size
	switch (true) {
		case maxChars < 10:
			size = 'xsmall'
			break
		case maxChars < 20:
			size = 'small'
			break
		case maxChars < 30:
			size = 'medium'
			break
		case maxChars < 38:
			size = 'large'
			break
		case maxChars < 45:
			size = 'xlarge'
			break
		default:
			size = 'xxlarge'
			break
	}
	return size
}

export const setObject = (data, path, val, index) => {
	const keys = path.split('.')
	const lastKey = keys.pop()
	const lastObj = keys.reduce((obj, key) => (obj[key] = obj[key] || {}), data)
	if (index !== undefined) {
		lastObj[lastKey][index] = val
	} else {
		lastObj[lastKey] = val
	}
	return data
}

export const removeInArray = (data, path, index) => {
	const keys = path.split('.')
	const lastKey = keys.pop()
	const lastObj = keys.reduce((obj, key) => (obj[key] = obj[key] || {}), data)
	lastObj[lastKey].splice(index, 1)
	let newData = { ...data, lastObj }
	return newData
}

export const addInArray = (data, path, max) => {
	const keys = path.split('.')
	const lastKey = keys.pop()
	const lastObj = keys.reduce((obj, key) => (obj[key] = obj[key] || {}), data)

	if (lastObj[lastKey].length >= max) {
		return data
	}
	lastObj[lastKey].push('')

	return data
}

export const setBoolean = data => {
	let formatedData = JSON.stringify(data)

	formatedData = formatedData.replace(/"true"/g, 'true')
	formatedData = formatedData.replace(/"false"/g, 'false')
	return JSON.parse(formatedData)
}

export const setNumeric = (data, fields) => {
	let formatedData = JSON.stringify(data)

	fields.forEach(el => {
		let reComa = new RegExp(`"${el}":"(\d+,?\d{1,2})"`, 'g')
		formatedData = formatedData.replace(/":"(\d+)"/g, '":$1')
		formatedData = formatedData.replace(/":"(\d+),?(\d{1,2})?"/g, '":$1.$2')
		formatedData = formatedData.replace(/":"(\d+)\.?(\d{1,2})?"/g, '":$1.$2')
	})

	return JSON.parse(formatedData)
}
