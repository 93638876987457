import { useState } from 'react'

const useToggle = (defaultValue = true) => {
  const [value, setValue] = useState(defaultValue)

  const toggle = () => {
    setValue(!value)
  }

  return [value, toggle, setValue]
}

export default useToggle
