import React from 'react'

export const FicheRemoveIcon = ({ style = {} }) => (
        <svg
            width="12px"
            height="12px"
            viewBox="0 0 12 12"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
        >
            <title>Remove</title>
            <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
                opacity="0.500379"
            >
                <g
                    id="3bis"
                    transform="translate(-849.000000, -858.000000)"
                    fill="#ffffff"
                    fillRule="nonzero"
                >
                    <g id="Group-10" transform="translate(349.000000, 60.000000)">
                        <g id="Group-5">
                            <g id="Group-3">
                                <g id="Group" transform="translate(30.000000, 731.000000)">
                                    <g id="Group-14" transform="translate(459.000000, 52.000000)">
                                        <path
                                            d="M17.8932316,21.0000044 L22.8150633,16.0781727 C23.0616963,15.8315162 23.0616963,15.4316253 22.8150633,15.1849923 C22.5684069,14.9383359 22.1684926,14.9383359 21.9218596,15.1849923 L17.0000044,20.1068241 L12.0781727,15.1849923 C11.8315162,14.9383359 11.4316253,14.9383359 11.1849923,15.1849923 C10.9383593,15.4316488 10.9383359,15.8315397 11.1849923,16.0781727 L16.1068241,21.0000044 L11.1849923,25.9218361 C10.9383359,26.1684926 10.9383359,26.5683835 11.1849923,26.8150165 C11.4316488,27.0616729 11.8315397,27.0616495 12.0781727,26.8150165 L17.0000044,21.8931847 L21.9218127,26.8150165 C22.1684691,27.0616729 22.5683835,27.0616495 22.8150165,26.8150165 C23.0616495,26.56836 23.0616495,26.1684691 22.8150165,25.9218361 L17.8932316,21.0000044 Z"
                                            id="Shape"
                                        ></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
