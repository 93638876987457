import React, { useEffect, useState } from 'react'
import { useAppContext } from '~contexts/appContext/appContext'
import { addActivityEmails, getActivityEmails } from './FicheMailPage.queries'
import FicheNavbar from '../common/FicheNavbar/FicheNavbar'
import FicheMailItem from './components/FicheMailItem'

import { isEmail } from '~utils/helpers'

import uniqid from 'uniqid'

import './FicheMailPage.scss'

const FicheMailPage: React.FC<any> = ({ id }) => {
	const { token } = useAppContext().auth
	const [contacts, setContacts] = useState([])
	const [updated, setUpdated] = useState(false)

	const [error, setError] = useState(false)
	const showError = () => {
		setError(true)
		setTimeout(() => {
			setError(false)
		}, 3000)
	}

	useEffect(() => {
		getActivityEmails(id, token).then(res => {
			if (Array.isArray(res.contacts?.reservationEmails)) {
				setContacts(res.contacts.reservationEmails)
			}
			// console.log(res.contacts.reservationEmails)
		})
	}, [])

	const handleSave = () => {
		if (contacts.findIndex(x => !isEmail(x.email)) >= 0) {
			showError()
			return Promise.reject()
		}
		return addActivityEmails(id, contacts, token)
	}
	return (
		<>
			<div className="FicheMailPage">
				<div className="header">
					<h3>Mails de réservation</h3>
					<div>
						Ajouter, modifier ou supprimer les mails liés aux réservations
					</div>
				</div>
				<div className="content">
					{contacts.map((contact, i) => (
						<FicheMailItem
							contact={contact}
							canDelete={contacts.length > 1}
							key={i}
							handleRemove={() => {
								let conts = [...contacts]
								conts.splice(i, 1)
								setContacts([...conts])
								if (!updated) setUpdated(true)
							}}
							onUpdate={cont => {
								let conts = [...contacts]
								conts[i] = cont
								setContacts(conts)
								if (!updated) setUpdated(true)
							}}
							isError={error}
						/>
					))}
				</div>
				<div className="center">
					<button
						onClick={() => {
							setContacts([
								...contacts,
								{
									forAllMarketItems: true,
									id: uniqid()
								}
							])
						}}
					>
						+ Nouveau mail
					</button>
				</div>
			</div>
			<FicheNavbar handleSave={handleSave} error={error} unSaved={updated} />
		</>
	)
}

export default FicheMailPage
