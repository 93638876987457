import React, { useRef, useState } from 'react'
import Modal from '~components/basics/Modal/Modal'
import { useUserData } from '~contexts/userDataContext'

import { DecoratedH2 } from '../ReservationsEmpty'

import { CopyToClipboard } from '../../../../utils/misc'

import CheckIcon from '~components/svg/CheckIcon'

import './ShareLinkModal.scss'

const ShareLinkModal = props => {
	const { shareLink } = useUserData()

	return (
		<Modal
			{...props}
			className="ShareLinkModal"
			enableCloseOnClickOverlay={true}
			showCloseButton={true}
		>
			<DecoratedH2>Partagez vos pages</DecoratedH2>

			<div className="activities">
				{props.activities
					?.filter(a => !!a)
					?.map(({ slug, title }) => (
						<ShareLinkActivity
							{...{ url: `https://coq-trotteur.com/fr/a/${slug}`, title }}
						/>
					))}
			</div>
		</Modal>
	)
}

const ShareLinkActivity = ({ url, title }) => {
	const [copied, setCopied] = useState(false)
	const urlValue = useRef(null)

	const handleCopy = () => {
		CopyToClipboard(urlValue.current)
		setCopied(true)
		setTimeout(() => {
			try {
				setCopied(false)
			} catch {}
		}, 2000)
	}

	return (
		<div className="activity">
			<input
				style={{
					position: 'fixed',
					right: '100vw',
					pointerEvents: 'none',
					opacity: 'none'
				}}
				ref={urlValue}
				value={url}
			/>
			<div className="title">{title}</div>

			<div className="button-container">
				<button onClick={handleCopy}>
					{copied ? (
						<span
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}
						>
							<CheckIcon color="#172b49" height={10} />
							<span style={{ marginLeft: 4 }}>Lien copié</span>
						</span>
					) : (
						<span>Copier le lien</span>
					)}
				</button>
			</div>
		</div>
	)
}

export default ShareLinkModal
