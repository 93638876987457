import React from 'react'

export const IndicatorIcon = () => {
	return (
		<svg
			width="29"
			height="18"
			viewBox="0 0 29 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M14.5 0L28.7894 17.25H0.210581L14.5 0Z" fill="white" />
		</svg>
	)
}
