import { useReducer } from 'react'
import { getLocalstorageJSON } from '~utils/helpers'
import { isBrowser } from '~utils/misc'
import StorageManager from '~utils/StorageManager'
import { TAppDataState } from '../appContext.types'
import { TAppDataAction } from './appData.types'

const userId = StorageManager.getStorage('userId')

const initialState: TAppDataState = {
	activities: null,
	activitiesState: 'init',
	websites: null,
	websitesState: 'init',
	shareLink: getLocalstorageJSON(`sharelink-${userId}`),
	activitiesIds: getLocalstorageJSON(`activities-${userId}`) || []
}

const useAppDataReducer = (): [
	TAppDataState,
	React.Dispatch<TAppDataAction>
] => {
	const [dataState, dispatchData] = useReducer(activitiesReducer, initialState)

	function activitiesReducer(
		state: TAppDataState,
		action: TAppDataAction
	): TAppDataState {
		switch (action.type) {
			case 'SET_BASIC_DATA':
				return {
					...state,
					...action.payload
				}
			case 'GET_ACTIVITIES_FETCHING':
				return {
					...state,
					activitiesState: 'fetching'
				}

			case 'GET_ACTIVITIES_SUCCESS':
				return {
					...state,
					activities: action.payload.activities,
					activitiesState: 'success'
				}

			case 'GET_ACTIVITIES_FAIL':
				return {
					...state,
					activitiesState: 'fail'
				}

			case 'GET_WEBSITE_FETCHING':
				return {
					...state,
					activitiesState: 'fetching'
				}

			case 'GET_WEBSITE_SUCCESS':
				return {
					...state,
					websites: action.payload.websites,
					activitiesState: 'success'
				}

			case 'GET_WEBSITE_FAIL':
				return {
					...state,
					activitiesState: 'fail'
				}
		}
	}

	return [dataState, dispatchData]
}

export default useAppDataReducer
