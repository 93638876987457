import React, { useState } from 'react'
import { useModal } from '~components/basics/Modal/Modal'
import { Select } from '~components/Ticketing/FormToolbox'
import { isEmail } from '~utils/helpers'
import DeleteEmailModal from './DeleteEmailModal/DeleteEmailModal'

import './FicheMailItem.scss'
import { CheckIcon, CloseIcon, DeleteIcon, PenIcon } from './Icons'
type IEmail = {
	email: string
	forAllMarketItems: boolean
}
const FicheMailItem = ({
	contact,
	handleRemove,
	onUpdate,
	isError,
	canDelete
}) => {
	const [editMode, setEditMode] = useState(contact.email ? false : true)
	const [email, setEmail] = useState<IEmail>(contact)
	const [error, setError] = useState(false)
	const { isOpen, setIsOpen } = useModal()

	const showError = () => {
		setError(true)
		setTimeout(() => {
			setError(false)
		}, 3000)
	}

	const handleAddEmail = () => {
		if (!isEmail(email.email)) {
			showError()
			return
		}

		onUpdate(email)
		setEditMode(false)
	}

	const handleCancel = () => {
		if (contact.email) {
			setEmail(contact)
			setEditMode(false)
		} else {
			handleRemove()
		}
	}

	return (
		<>
			<div className="FicheMailItem">
				<div>
					{!editMode ? (
						<div className="d-flex">
							<div className="email">{email.email}</div>
							<button
								className="icon"
								onClick={() => {
									setEditMode(true)
								}}
							>
								<PenIcon />
							</button>
							<button className="icon" onClick={() => setIsOpen(true)}>
								<DeleteIcon />
							</button>
						</div>
					) : (
						<div className="d-flex">
							<input
								type="text"
								className={'text_inpt' + (error || isError ? ' err' : '')}
								placeholder="Mail.."
								value={email.email}
								onChange={e =>
									setEmail({
										...email,
										email: e.target.value
									})
								}
							/>

							<button className="icon" onClick={handleAddEmail}>
								<CheckIcon />
							</button>
							<button className="icon" onClick={handleCancel}>
								<CloseIcon />
							</button>
						</div>
					)}
				</div>

				<div>
					<Select
						options={[
							{ value: true, label: 'Valable pour : toutes les offres' },
							{ value: false, label: 'Valable pour : certaines offres' }
						]}
						setValue={forAllMarketItems => {
							const chng = {
								...contact,
								forAllMarketItems
							}
							setEmail(chng)
							onUpdate(chng)
						}}
						value={email.forAllMarketItems}
					/>
				</div>
			</div>
			<DeleteEmailModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				canDelete={canDelete}
				deleteItem={handleRemove}
			/>
		</>
	)
}

export default FicheMailItem
