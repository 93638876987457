import React, { useState, useRef } from 'react'
import { navigate, useStaticQuery, graphql } from 'gatsby'
import { useForm, ErrorMessage } from 'react-hook-form'

import MessageBar from '~components/basics/MessageBar'
import Button from '~components/basics/Button'

import { useUserData } from '~contexts/userDataContext'

import BigLogo from '~svg/bigLogo.svg'
import { forgotMail } from '~requests/back_user'

const PasswordForgotten = () => {
  // ***** State *****
  const { userId, apiUrl, token } = useUserData()
  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState(null)
  const [succeed, setSucceed] = useState(false)
  // React hook form
  const { register, handleSubmit, errors, watch } = useForm()

  const PATTERN_ERROR = "L'addresse mail est incorrecte"
  const REQUIRED_ERROR = 'Ce champs est requis'
  const NOT_IN_DB = 'Cette addresse ne possède pas de compte.'

  // ***** Event handlers *****
  const onSubmit = data => {
    forgotMail(apiUrl, data.mail)
      .then(res => {
        if (res.error) {
          setErrorMsg(NOT_IN_DB)
          setIsError(true)
        } else {
          setSucceed(true)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const messageHandler = params => {
    setIsError(false)
    setErrorMsg(null)
  }

  const handleClick = params => {
    console.log(errors)
    if (errors.mail) {
      if (errors.mail.type === 'required') {
        setErrorMsg(REQUIRED_ERROR)
      } else if (errors.mail.type === 'pattern') {
        setErrorMsg(PATTERN_ERROR)
      }
      setIsError(true)
    }
  }

  return (
    <>
      <div className="login">
        <MessageBar
          type="danger"
          trigger={isError}
          onClose={messageHandler}
          message={errorMsg}
        />

        <div className="logoWrapper">
          <BigLogo style={{ height: '92px' }} />
          <h1>Mot de passe oublié</h1>
        </div>

        {succeed ? (
          <div className="success-message">
            <label>
              Nous avons envoyé un mail avec la procédure de réinitialisation de votre mot de passe. Pensez à vérifier vos spams. Écrivez nous à aide@coq-trotteur.com en cas de problème.
            </label>
          </div>
        ) : (
          <>
            <label htmlFor="mail">
              Renseignez votre adresse mail d’inscription. Nous allons vous
              renvoyer un mail permettant de générer une nouveau mot de passe.
              En cas de problème, écrivez à aide@coq-trotteur.com
            </label>
            <form
              action=""
              className="complete"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="input-group">
                <input
                  placeholder="Email"
                  name="mail"
                  ref={register({
                    required: true,
                    pattern: /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/,
                  })}
                  className={errors.mail && 'has-error'}
                />
                <ErrorMessage
                  className="has-error info-inner"
                  as="small"
                  errors={errors}
                  name="mail"
                  message={`${
                    errors?.mail?.type === 'pattern'
                      ? PATTERN_ERROR
                      : REQUIRED_ERROR
                  }`}
                />
              </div>

              <Button
                type="submit"
                theme="blue"
                size="large"
                onClick={handleClick}
              >
                Envoyer
              </Button>
            </form>
          </>
        )}
      </div>
    </>
  )
}

export default PasswordForgotten
