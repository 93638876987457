import React, { useEffect, useState } from 'react'
import DashboardTemplate from '~components/templates/DashboardTemplate'
import { withAuth } from '~HOC/withAuth'
import { ThemeProvider } from 'styled-components'
import { theme, GlobalStyle } from '~horairesMod/theme'

import ReservationWaitingForValidation from '~components/reservations/reservation-waiting-for-validation'
import ReservationSearchBlock from '~components/reservations/reservation-search-block'
import ReservationCalendar from '~components/reservations/reservation-calendar'
import { providerHasReservation } from '~requests/reservations/services/reservation.service'
import { useInvalidSession, useUserData } from '~contexts/userDataContext'
import MarketReservations from '~components/reservations/market-reservation'
import ReservationsEmpty from '~components/reservations/ReservationsEmpty/ReservationsEmpty'

import ReservationsEmptyHeader from '~components/reservations/ReservationsEmpty/ReservationsEmptyHeader/ReservationsEmptyHeader'

import '~style/reservations.scss'
import useIsMounted from '~hooks/useIsMounted'
import Spinner from '~components/basics/Icons/Spinner'

const Reservations = () => {
	const { apiUrl, token } = useUserData()
	const [hasReservation, setHasReservation] = useState(null)
	const [ticketAccepted, setTicketAccepted] = useState(null)

	const { invalidSession } = useInvalidSession()
	const isMounted = useIsMounted()

	useEffect(() => {
		providerHasReservation(apiUrl, token)
			.then(res => {
				if (!isMounted()) return
				setHasReservation(res)
			})
			.catch(error => {
				console.warn('Error when fetching pending reservations ', error)

				invalidSession(error)
			})
	}, [ticketAccepted])
	function handleAcceptReservation(ticketNumber) {
		setTicketAccepted(ticketNumber)
	}

	if (hasReservation === null)
		return (
			<div className="loader_">
				<Spinner color="dark" />
			</div>
		)
	return (
		<>
			{hasReservation ? (
				<div className="page-content">
					<div
						style={{
							width: '96%'
						}}
					>
						<ReservationsEmptyHeader />
					</div>

					<ReservationWaitingForValidation
						acceptReservation={handleAcceptReservation}
					/>
					<ReservationSearchBlock />
					<MarketReservations />
					<ReservationCalendar ticketAccepted={ticketAccepted} />
				</div>
			) : (
				<div className="reservations-page">
					<ReservationsEmpty />
				</div>
			)}
		</>
	)
}

const BookingPageContainer = props => (
	<DashboardTemplate currentPage="reservations">
		<ThemeProvider theme={theme}>
			<Reservations {...props} />
			<GlobalStyle />
		</ThemeProvider>
	</DashboardTemplate>
)

export default withAuth(BookingPageContainer)
