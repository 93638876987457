import React from 'react'

const WaveIllustration = ({ height = 22, width = 170 }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 170 22"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="waves">
				<path
					d="M19.0154 19.2358C19.1144 19.2358 19.2143 19.235 19.3133 19.2334C26.2352 19.1605 32.7673 16.564 37.7052 11.9232C45.4765 4.33416 58.6634 4.15984 66.4347 11.7489C71.3187 16.4285 77.7636 19.0012 84.5944 19.0012L84.7117 19.0012C91.5908 18.9711 98.0627 16.335 102.935 11.577C104.138 10.4019 104.164 8.47098 102.99 7.26504C101.818 6.0583 99.8914 6.03374 98.6878 7.20878C90.991 14.7249 78.4086 14.7844 70.6397 7.33952C60.527 -2.53545 43.6557 -2.40155 33.543 7.47422C29.7192 11.0675 24.6434 13.0785 19.2491 13.1355C13.8374 13.1902 8.83458 11.2505 5.16603 7.66755C3.96246 6.4925 2.03628 6.51707 0.863609 7.72301C-0.309058 8.92975 -0.284496 10.8607 0.919073 12.0357C5.68026 16.6852 12.0967 19.2358 19.0154 19.2358Z"
					transform="translate(3.051758E-05 0.9047856)"
					id="Shape"
					fill="#A5E6DB"
					stroke="none"
				/>
				<path
					d="M19.0154 16.1508C19.1144 16.1508 19.2143 16.15 19.3133 16.1484C26.2352 16.0755 32.7673 13.479 37.7052 8.83823C45.4765 1.24917 56.2287 -2.95104 64 4.63802C68.884 9.3176 61.1692 4.38923 68 4.38923C68 4.38923 43.6557 -5.48653 33.543 4.38923C29.7191 7.9825 24.6434 9.99347 19.2491 10.0505C13.8374 10.1052 8.83458 8.16553 5.16603 4.58256C3.96246 3.40752 2.03628 3.43208 0.863609 4.63802C-0.309058 5.84476 -0.284496 7.7757 0.919073 8.95075C5.68026 13.6002 12.0967 16.1508 19.0154 16.1508Z"
					transform="matrix(1 0 0 -1 98.00006 17.05555)"
					id="Shape"
					fill="#1DCDFC"
					stroke="none"
				/>
				<path
					d="M19.0154 19.2358C19.1144 19.2358 19.2142 19.235 19.3133 19.2334C26.2352 19.1605 32.7673 16.564 37.7051 11.9232C45.4764 4.33416 58.6634 4.15984 66.4347 11.7489C71.3187 16.4285 77.7636 19.0012 84.5944 19.0012L84.7117 19.0012C91.5908 18.9711 98.0626 16.335 102.935 11.577C104.138 10.4019 104.164 8.47098 102.99 7.26504C101.818 6.0583 99.8914 6.03374 98.6878 7.20878C90.991 14.7249 78.4086 14.7844 70.6397 7.33952C60.527 -2.53545 43.6556 -2.40155 33.543 7.47421C29.7191 11.0675 24.6434 13.0785 19.2491 13.1355C13.8374 13.1902 8.83455 11.2505 5.166 7.66755C3.96243 6.4925 2.03625 6.51706 0.863579 7.72301C-0.309089 8.92975 -0.284526 10.8607 0.919043 12.0357C5.68023 16.6852 12.0966 19.2358 19.0154 19.2358Z"
					transform="matrix(0.9998477 0.01745241 -0.01745241 0.9998477 65.17578 -3.33786E-06)"
					id="Shape"
					fill="#A5E6DB"
					stroke="none"
				/>
			</g>
		</svg>
	)
}
export default WaveIllustration
