import React, { useEffect, useRef, useState } from 'react'
import { useUserData } from '~contexts/userDataContext'
import { DecoratedH2 } from '~components/reservations/ReservationsEmpty/ReservationsEmpty'
import { getActivities } from '~requests/get'

import Spinner from '~components/basics/Icons/Spinner'

import Button from '~components/basics/Button'
import ArrowIcon from '~components/svg/ArrowIcon'

import './WebsiteCreationActivities.scss'

const WebsiteCreationActivities: TWebsiteCreationActivities = ({
	data,
	onChange,
	handlePrevious,
	handleByPass
}) => {
	const activitiesRef = useRef<HTMLDivElement>()
	const { activitiesId, token, apiUrl } = useUserData()
	const [selectedActivities, setSelectedActivities] = useState(
		data.activitiesId
	)
	const [isLoading, setIsLoading] = useState(false)
	const [activities, setActivities] = useState(null)

	const loadActivities = async () => {
		const activities = await getActivities(
			typeof activitiesId === 'object'
				? activitiesId
				: JSON.parse(activitiesId),
			token,
			apiUrl
		)

		if (activities.length === 1) {
			// We can bypass this step

			handleByPass({
				activitiesId: [activities[0]._id]
			})
		} else {
			setActivities(
				activities.map(({ _id, ...activity }) => ({
					id: _id,
					...activity
				}))
			)
			requestAnimationFrame(() => {
				activitiesRef.current.style.height = '22px'
				activitiesRef.current.style.height =
					activitiesRef.current.scrollHeight + 'px'
			})
		}
	}

	useEffect(() => {
		loadActivities()
	}, [])

	return (
		<div className="WebsiteCreationActivities">
			<DecoratedH2>
				Quelle(s) fiche(s) sont concernées par le site ?
			</DecoratedH2>

			<p>
				Les offres de ces activités apparaîtront automatiquement sur votre site.
				Vous pourrez également les catégoriser ou les filtrer.
			</p>

			<div className="activities" ref={activitiesRef}>
				{!activities ? (
					<div>
						<Spinner color="dark" />
					</div>
				) : (
					activities.map((activity: { id: string }) => (
						<ActivityItem
							key={activity.id}
							activity={activity}
							selected={selectedActivities.includes(activity.id)}
							toggleSelected={() => {
								const selected = selectedActivities.includes(activity.id)

								if (selected) {
									setSelectedActivities(
										selectedActivities.filter(id => id !== activity.id)
									)
								} else {
									setSelectedActivities([...selectedActivities, activity.id])
								}
							}}
						/>
					))
				)}
			</div>

			<div className="buttons">
				<Button
					onClick={() => {
						handlePrevious({
							activitiesId: selectedActivities
						})
					}}
					theme="colored-action"
				>
					<ArrowIcon direction="left" color="blue" />
					Retour
				</Button>
				<Button
					theme="blue"
					onClick={() => {
						setIsLoading(true)
						onChange({
							activitiesId: selectedActivities
						})
					}}
					disabled={isLoading || selectedActivities.length === 0}
				>
					{isLoading ? (
						<Spinner color="dark" />
					) : (
						<>
							Suivant
							<ArrowIcon direction="right" color="white" />
						</>
					)}
				</Button>
			</div>
		</div>
	)
}

export default WebsiteCreationActivities

type TWebsiteCreationActivities = React.FC<{
	data: any
	onChange: (data: any) => void
	handlePrevious: (data: any) => void
	handleByPass: (data: any) => void
}>

const ActivityItem = ({ activity, selected, toggleSelected }) => (
	<button
		className={`ActivityItem ${selected ? 'selected' : ''}`}
		onClick={() => {
			toggleSelected()
		}}
	>
		<span className="title">{activity.title}</span>
	</button>
)
