import React, { useEffect } from 'react'
import Resa from './reservations'

import DashboardTemplate from '../components/templates/DashboardTemplate'

import { useUserData } from '~contexts/userDataContext'
import { withAuth } from '~HOC/withAuth'
import { usePricings } from '~contexts/pricingsContext'

const IndexPage = () => {
	const { userData, getLocals } = useUserData()
	const { setPricings } = usePricings()

	const pricings_table = userData.pricings?.pricings_table

	useEffect(() => {
		setPricings({ type: 'getAll', payload: pricings_table })
	}, [userData])

	return <Resa></Resa>
}

const IndexPageContainer = (...props) => {
	return (
		<DashboardTemplate currentPage="reservations">
			<IndexPage {...props} />
		</DashboardTemplate>
	)
}
export default withAuth(IndexPage)
