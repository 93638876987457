import React from 'react'
import { Select, FormLine } from '~components/Ticketing/FormToolbox'

const BookingReservationInformation = ({ BookingForm }) => {
	return (
		<FormLine>
			<div>Informations démandées lors de la réservation</div>
			<div>
				<Select
					{...BookingForm.get('each_person_identity')}
					style={{
						width: 300
					}}
					dropdownStyle={{
						width: 330,
						minHeight: 215,
						height: 215
					}}
					options={[
						{
							label: 'Voyageur principal (recommandé)',
							value: false,
							details: `Le nom, prénom, mail et numéro de téléphone du voyageur principal sont demandés`
						},
						{
							label: 'Tous les voyageurs (déconseillé)',
							value: true,
							details: `Le nom et prénom de tous les voyageurs sont demandés. Ne cochez cette option qu’en cas de réelle nécessité car vous risquez de perdre des réservations avec un processus de réservation plus long.`
						}
					]}
				/>
			</div>
		</FormLine>
	)
}
export default BookingReservationInformation
