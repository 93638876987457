import React, { useEffect, useRef, useState } from 'react'
import Button from '~components/basics/Button'

import ArrowIcon from '~components/svg/ArrowIcon'
import Spinner from '~components/basics/Icons/Spinner'
import { urlIsAvailable } from '~queries/website.queries'
import { useUserData } from '~contexts/userDataContext'
import { DecoratedH2 } from '~components/reservations/ReservationsEmpty/ReservationsEmpty'

import './WebsiteCreationDomain.scss'

const WebsiteCreationDomain: TWebsiteCreationDomain = ({
	data,
	onChange,
	handlePrevious
}) => {
	const { token } = useUserData()
	const domainInputRef = useRef<HTMLInputElement>(null)
	const [domain, setDomain] = useState<string>(
		(data.domain || '').replace('.mavitrine.io', '')
	)
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState(null)

	const inputCooldownTimeoutRef = useRef(null)

	useEffect(() => {
		if (domainInputRef.current) domainInputRef.current.select()
	}, [])

	const verifyUrlAvailable = async () => {
		const isAvailable = await urlIsAvailable(
			`${domainInputRef.current.value.toLowerCase()}.mavitrine.io`,
			token
		)

		return isAvailable
	}

	return (
		<div className="WebsiteCreationDomain">
			<DecoratedH2>Choisissez l'adresse de votre site</DecoratedH2>

			<p>
				Nous vous fournissons gratuitement un nom de domaine sur mavitrine.io.
				Vous pourrez par la suite mettre votre site sur votre propre nom de
				domaine.
			</p>

			<div className="domain-input">
				<input
					ref={domainInputRef}
					className={error ? 'error' : ''}
					value={domain}
					onKeyDown={e => {
						if (!e.key.toLowerCase().match(/[A-Za-z\-]/)) {
							e.preventDefault()
						}
					}}
					onChange={e => {
						setDomain(e.target.value)
					}}
				/>
				<span>.mavitrine.io</span>

				{error && (
					<div className="error-message">
						Cette addresse n'est pas disponible
					</div>
				)}
			</div>

			<div className="legal">
				En appuyant sur "Créer mon site", vous acceptez
				<br />
				les{' '}
				<a href="/cguv" target="_blank">
					conditions générales d'utilisation
				</a>
				.
			</div>

			<div className="buttons">
				<Button
					onClick={() => {
						handlePrevious({
							domain: `${domainInputRef.current.value.toLowerCase()}.mavitrine.io`
						})
					}}
					theme="colored-action"
				>
					<ArrowIcon direction="left" color="blue" />
					Retour
				</Button>
				<Button
					theme="blue"
					style={{
						width: 170
					}}
					onClick={() => {
						setError(false)
						setIsLoading(true)
						verifyUrlAvailable()
							.then(isAvailable => {
								if (isAvailable) {
									onChange({
										domain: `${domainInputRef.current.value.toLowerCase()}.mavitrine.io`
									})
								} else {
									setError(true)
								}
							})
							.catch(() => {
								setError(true)
								setIsLoading(false)
							})
					}}
					disabled={isLoading || domain.length < 3}
				>
					{isLoading ? <Spinner color="light" /> : <>Créer mon site</>}
				</Button>
			</div>
		</div>
	)
}

export default WebsiteCreationDomain

type TWebsiteCreationDomain = React.FC<{
	data: any
	onChange: (data: any) => void
	handlePrevious: (data: any) => void
	handleByPass: () => void
}>
