import React from 'react'

import moment from 'moment'

import { TServiceAvailability, TPeriod } from 'coq-types'
import {
	dateIsInPeriod,
	dayIsOpenInWeekSchedule
} from '~components/core/marketItem/schedule'

const Test = () => {
	const serviceAvailability: TServiceAvailability = {
		global_closed_period: false,
		schedules: [
			{
				closed_periods: [
					{
						start_date: '',
						end_date: ''
					}
				],
				purpose: '',
				unique_week_schedule: true,
				week_schedules: [
					{
						days: [
							{
								open: false
							},
							{
								open: false
							},
							{
								open: false
							},
							{
								open: false
							},
							{
								open: false
							},
							{
								open: false
							},
							{
								open: false
							}
						],
						periods: []
					}
				]
			}
		],
		unique_schedule: false
	}

	console.log(
		dayIsOpenInWeekSchedule('05/03/2021', {
			periods: [
				{
					start_date: '05/01/2021',
					end_date: '06/01/2021'
				}
			],
			days: [
				{
					open: true
				},
				{
					open: false
				},
				{
					open: true
				},
				{
					open: true
				},
				{
					open: true
				},
				{
					open: true
				},
				{
					open: true
				}
			]
		})
	)

	return (
		<div
			className="Test"
			style={{
				padding: 90,
				backgroundColor: 'white'
			}}
		>
			super
		</div>
	)
}

const TestContainer = () => {
	return <Test />
}

export default TestContainer
