import { text_field, bool_field } from 'react-super-useform'

export default {
	activity: {
		story_telling: {
			story: text_field({ required: false })
		},
		gift_coupon: {
			enabled: bool_field(),
			shop_id: text_field({
				validation: (value, get) => {
					if (!get('parent.enabled').value) return true
					return value.length > 0
				}
			})
		},
		about: {
			strong_points: {
				type: Array,
				children: text_field({ validation: () => true }),
				min: 1,
				constrainMinMax: true
			},
			note: {
				description: text_field({ required: false })
			},
			inclusions: {
				enabled: text_field(),
				has: {
					type: Array,
					children: text_field({ validation: () => true }),
					min: 1,
					max: 8,
					constrainMinMax: true
				},
				hasnt: {
					type: Array,
					children: text_field({ validation: () => true }),
					min: 1,
					max: 8,
					constrainMinMax: true
				}
			}
		},
		accessibility: {
			indoor: bool_field({ default: null }),
			restrictions: {
				type: Array,
				children: text_field({ required: false })
			},
			public_transports: {
				accessibility: bool_field({ default: false }),
				informations: {
					bus: text_field({ required: false }),
					tram: text_field({ required: false }),
					subway: text_field({ required: false })
				}
			}
		}
	}
}
