import React from 'react'
import styled from 'styled-components'
import Light from '~horairesMod/theme/icons/light.svg'
import Arrow from '~horairesMod/theme/icons/arrow.svg'
import TarifLogo from '~svg/Group-2.svg'
import { useUserData } from '~contexts/userDataContext'

import './modalPricings.scss'
import Button from '~components/basics/Button'

export const ModalPricings = ({ goPricing, onClickNo = () => {} }) => {
	const { userData, dispatch, userId } = useUserData()

	const handleYes = () => {
		goPricing(true)
		localStorage.setItem(`isPricing-${userId}`, JSON.parse(true))
	}

	return (
		<ModalPricingWrapper className="modal-pricings">
			<div>
				<CustomTarifLogo />
				<h1>Activez votre billetterie en quelques clics</h1>
				<p className="subtitle">
					Le jour où vous souhaiterez vendre des prestations, il vous suffira de
					cliquer ci-dessous. Soyez créatifs ! Paniers gourmands, produits
					locaux, bons d’achats…
				</p>

				<div>
					<Button
						onClick={handleYes}
						theme="primary-dark"
						roundish={true}
						className="create-pricing"
					>
						Ajouter des tarifs
						<CustomArrow />
					</Button>
				</div>

				<Button
					className="bypass"
					onClick={() => {
						onClickNo()
					}}
				>
					Je ne souhaite pas encore activer la billetterie
				</Button>
			</div>
		</ModalPricingWrapper>
	)
}
const ModalPricingWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 4vh 1em;
	max-width: 100%;
	width: 1000px;
	margin: auto;
	text-align: center;
	background: white;
`
const ModalWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	max-width: 600px;
	height: 100%;
	margin: 1em;
`
const CustomTarifLogo = styled(TarifLogo)`
	max-height: 100px;
`
const CustomArrow = styled(Arrow)`
	height: 10px;
	width: 20px;
`
const ModalTitle = styled.h3`
	font-size: 18px;
	color: #323232;
`
const ModalContent = styled.div`
	font-family: 15px;
	color: #323232;
	margin: auto;
`
const ModalButton = styled.button`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	max-width: 335px;
	max-height: 55px;
	width: 100%;
	height: 100%;
	margin: auto;
	background-color: ${props => props.theme.colors.dark};
	color: ${props => props.theme.colors.yellow};
	border: none;
	border-radius: 27.5px;
	font-size: 15px;
	cursor: pointer;
`
const ModalLink = styled.a`
	color: #323232;
`
