import { downloadWeeklyBillUrl } from "../routes/reservations.routes"

export const downloadWeeklyBill = async (apiUrl, token, payload) => {
    const myHeaders = new Headers()
    myHeaders.append('x-access-token', token)
    const value = payload.bill.map((ticket) => ticket._id);
    payload.bill = value
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify(payload),
        'Content-Type': 'application/x-www-url-form-encoded'
    }

    let res = await fetch(apiUrl + `/${downloadWeeklyBillUrl}`, requestOptions)
    let data = await res.json()
    // console.log({resss: data})
    return data.path
}

export const downloadCalendar = async (token, payload) => {
    const myHeaders = new Headers()
    myHeaders.append('x-access-token', token)
    const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify(payload),
        'Content-Type': 'application/x-www-url-form-encoded'
    }

    let res = await fetch(process.env.GATSBY_APIV2_URL + `/pdf-generator/calendar`, requestOptions)
    let data = await res.json()
    // console.log({resss: data})
    return data.path
}
