import React, { useState } from 'react'
import { Router, useLocation, navigate } from '@reach/router'
import { useUserData } from '~contexts/userDataContext'
import { recoverUser } from '~requests/back_user'
import BigLogo from '~svg/bigLogo.svg'
import Button from '~components/basics/Button'
import MessageBar from '~components/basics/MessageBar'
import useForm, { text_field, mail_field } from 'react-super-useform'

const formSchema = {
	password: text_field(),
	confirm: text_field(),
}

const Input = ({ value, setValue, error, ...leftOver }) => (
	<input
		value={value}
		onChange={e => setValue(e.target.value)}
		type="password"
		{...leftOver}
	/>
)

function searchToObject(search) {
	return search
		.substring(1)
		.split('&')
		.reduce(function(result, value) {
			var parts = value.split('=')
			if (parts[0])
				result[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1])
			return result
		}, {})
}

const Recovery = props => {
	const location = useLocation()
	const { apiUrl } = useUserData()

	const PW_NOT_MATCH = 'Les mots de passe ne sont pas identiques'
	const PW_TOO_SHORT = 'Le mot de passe doit contenir au moins 8 caractères'
	const [isError, setIsError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)
	const [succeed, setSucceed] = useState(false)

	const { mail, id } = searchToObject(document.location.search)

	//   const mail = search.mail
	//     typeof window !== 'undefined'
	//       ? location.search.match(/mail=(.+)&id=(.+)$/)[1]
	//       : ''

	//   const id =
	//     typeof window !== 'undefined'
	//       ? location.search.match(/mail=(.+)&id=(.+)$/)[2]
	//       : ''

	const form = useForm(formSchema)

	const handleSubmit = e => {
		e.preventDefault()
		setIsError(false)
		const data = { ...form.toJSON() }

		if (data.password !== data.confirm) {
			setErrorMsg(PW_NOT_MATCH)
			setIsError(true)
		} else {
			if (data.password.length < 8) {
				setErrorMsg(PW_TOO_SHORT)
				setIsError(true)
			} else {
				delete data.confirm
				data.mail = mail
				data.id = id

				recoverUser(apiUrl, data)
					.then(res => {
						setSucceed(true)
					})
					.catch(err => {
						console.log(err)
					})
			}
		}
	}

	return (
		<>
			<div
				className="login switchPwd"
				// onSubmit={handleSubmit(onSubmit)}
			>
				<MessageBar
					type="danger"
					trigger={isError}
					onClose={() => setIsError(false)}
					message={errorMsg}
				/>

				<div className="logoWrapper">
					<BigLogo style={{ height: '92px' }} />
					<h1>Nouveau mot de passe</h1>
				</div>

				{succeed ? (
					<div className="success-message">
						<h2>
							Votre nouveau mot de passe est effectif, connectez-vous pour vous
							authentifier
						</h2>
						<Button
							theme="primary"
							roundish="roundish"
							onClick={() => navigate('/')}
						>
							Je me connecte
						</Button>
					</div>
				) : (
					<form className="complete add" onSubmit={e => handleSubmit(e)}>
						<div className="alignment">
							<label htmlFor="new_password">
								Choisissez votre mot de passe
							</label>

							<div className={`join ${isError ? 'has-error' : ''}`}>
								<div className="input-group">
									<Input {...form.get('password')} placeholder="Mot de passe" />
								</div>

								<hr />

								<div className="input-group">
									<Input {...form.get('confirm')} placeholder="Confimation" />
								</div>
							</div>
							<Button type="submit" theme="blue" size="large">
								Valider
							</Button>
						</div>
					</form>
				)}
			</div>
		</>
	)
}

const RecoveryPage = () => {
	return (
		<Router>
			<Recovery path="/recovery/*" />
		</Router>
	)
}

export default RecoveryPage
