import React, { useEffect, useState } from 'react'
import { Link } from '@reach/router'

import ReservationsEmptyHeader from './ReservationsEmptyHeader/ReservationsEmptyHeader'

import RocketIcon from './icons/RocketIcon'
import PhoneStoreIcon from './icons/PhoneStoreIcon'
import CameraIcon from './icons/CameraIcon'
import HearthIcon from './icons/HearthIcon'

import StatIcon from './icons/StatIcon'

import CheckIcon from '~components/svg/CheckIcon'
import { useUserData } from '~contexts/userDataContext'

import { getUserMarketItemsCount } from '~components/Market/queries/MarketItem.queries'

import { useModalWithPayload } from '~components/basics/Modal/Modal'
import ContactModal from './ContactModal/ContactModal'
import ShareLinkModal from './ShareLinkModal/ShareLinkModal'

import { CopyToClipboard } from '../../../utils/misc'
import { getData } from '~requests/get'

import './ReservationsEmpty.scss'
import { useAppContext } from '~contexts/appContext/appContext'

export const DecoratedH2 = ({ children }) => (
	<h2 className="DecoratedH2">
		<span>{children}</span>
	</h2>
)

const ReservationsEmpty = () => {
	const { userId, token } = useAppContext().auth
	const [itemCount, setItemCount] = useState<number>(null)

	const contactModal = useModalWithPayload()

	useEffect(() => {
		getUserMarketItemsCount(userId, token).then(count => {
			setItemCount(count)
		})
	}, [])

	return (
		<div className="ReservationsEmpty">
			<ReservationsEmptyHeader />
			<ContactModal {...contactModal} />

			<div className="content">
				<header>
					<DecoratedH2>
						{itemCount === null ? (
							<span style={{ opacity: 0 }}>_</span>
						) : itemCount > 0 ? (
							'Vos commandes apparaîtront ici'
						) : (
							'Comment augmenter votre visibilité ?'
						)}
					</DecoratedH2>

					<p className="subtitle">
						{itemCount === null ? null : itemCount > 0 ? (
							'En attendant, quelques astuces pour faire décoller l’activité !'
						) : (
							<>
								Si vous <Link to="/boutique">créez des offres</Link>, vos
								commandes apparaîtront ici. En attendant, vous pouvez quand même
								booster votre visibilité !
							</>
						)}
					</p>
				</header>

				<div>
					<ReservationsEmptyAdvice
						title="1. Communiquez sur internet"
						text="Diffuser la page de votre boutique sur vos réseaux sociaux est essentiel."
						icon={<RocketIcon />}
						button={<CopyLinkButton />}
						link={{
							text: 'Copier le lien de votre page',
							color: '#B2F0FB'
						}}
					/>

					<ReservationsEmptyAdvice
						title="2. Communiquez en physique"
						text="Nous avons créé de superbes affiches et cartes de visites à imprimer qui redirigent directement sur votre page."
						icon={<PhoneStoreIcon />}
						link={{
							text: 'À venir...',
							// text: 'Voir mon affiche',
							color: '#FFEDBD',
							disabled: true
						}}
					/>

					<ReservationsEmptyAdvice
						title="3. Brillez de mille feux"
						text="Il n’y a pas de secret : des photos de qualité, un titre clair, des descriptions détaillées font toute la différence."
						icon={<CameraIcon />}
						link={{
							// text: 'Voir notre tuto',
							text: 'À venir...',
							color: '#B2F0FB',
							disabled: true
						}}
					/>

					<ReservationsEmptyAdvice
						title="4. Rome ne s’est pas fait en un jour"
						text="Créer de nouvelles habitudes prend du temps. Pour initier ce “réflexe local”, il faut communiquer régulièrement. Et n’oubliez pas, ce réflexe commence aussi par vous ! "
						icon={<HearthIcon />}
						link={{
							text: 'Proposer des idées',
							color: '#FFEDBD',
							onClick: () => {
								contactModal.open({
									title: 'On a hâte de vous lire !',
									type: 'suggestion'
								})
							}
						}}
					/>
				</div>

				<ReservationsEmptyHelpSection contactModal={contactModal} />
			</div>
		</div>
	)
}

const CopyLinkButton = () => {
	const [copied, setCopied] = useState(false)
	const { activitiesId, shareLink, apiUrl, token } = useUserData()
	const [activities, setActivities] = useState(null)

	const shareLinkModal = useModalWithPayload()

	useEffect(() => {
		Promise.all(activitiesId.map(id => getData(apiUrl, token, id))).then(
			activities => {
				setActivities(activities)
			}
		)
	}, [])

	const handleClick = () => {
		if (activities.length > 1) {
			shareLinkModal.open(null)
			return
		}
		if (activities.length === 0) return

		CopyToClipboard(shareLink[activitiesId[0]].url)

		setCopied(true)
		setTimeout(() => {
			try {
				setCopied(false)
			} catch {}
		}, 2000)
	}

	return (
		<>
			<ShareLinkModal {...shareLinkModal} activities={activities} />
			<button
				onClick={handleClick}
				style={{
					backgroundColor: '#B2F0FB'
				}}
				className={`CopyLinkButton ${copied ? 'copied' : ''}`}
			>
				{copied ? (
					<span
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						<CheckIcon color="#172b49" height={10} />
						<span style={{ marginLeft: 4 }}>Lien copié</span>
					</span>
				) : (
					<span>Copier le lien de votre page</span>
				)}
			</button>
		</>
	)
}

const ReservationsEmptyHelpSection = ({ contactModal }) => (
	<div className="ReservationsEmptyHelpSection">
		<StatIcon />

		<h2>Le digital au service du local</h2>

		<p>
			Sur une plateforme si locale, le visiteur vient pour découvrir de nouveaux
			professionnels ou trouver des réponses : quel commerçant propose le
			produit/service que je cherche. Ainsi, la plupart ne réserveront pas sur
			le site, mais vous contacteront ou iront directement sur place.{' '}
		</p>
		<p>
			Notre rôle est de vous donner de la visibilité. Et de fournir un
			environnement complet pour que le numérique facilite et améliore votre
			quotifien, sur le long terme, de façon éthique. Notre équipe est à votre
			disposition pour vous conseiller et vous aider à grandir.
		</p>

		<button
			onClick={() => {
				contactModal.open({ title: 'À votre service !', type: 'help' })
			}}
		>
			Demander notre aide
		</button>
	</div>
)

const ReservationsEmptyAdvice = ({
	title,
	text,
	icon,
	link = null,
	button = null
}) => (
	<div className="ReservationsEmptyAdvice">
		<div className="icon-container">{icon}</div>

		<div className="text-container">
			<h3>{title}</h3>

			<p>{text}</p>

			{button ? (
				button
			) : link ? (
				<button
					disabled={link.disabled}
					onClick={link.onClick}
					style={{
						backgroundColor: link.color
					}}
				>
					{link.text}
				</button>
			) : null}
		</div>
	</div>
)

export default ReservationsEmpty
