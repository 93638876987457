import React, { useState, useRef, useEffect } from 'react'

import WelcomeScreen from './WelcomeScreen'

import Success from './Ticketing/Success'
import Validate from './Ticketing/Validate'
import OnboardingNavbar from './Ticketing/OnboardingNavbar'

// Steps
import Infos from './Ticketing/StepInfos'
import Booking from './Ticketing/StepBooking'
import Tarifs from './Ticketing/StepPricings'
import Schedules from './Ticketing/StepSchedules'
import PricingSchedule from './Ticketing/StepPricingSchedule'

import {
	saveCurrentStep,
	saveFormDone,
	getActivity
} from './Ticketing/Common/ticketingCommonQueries'

import { useUserData } from '~contexts/userDataContext'

import { getPricingSchedulePageData } from './Ticketing/StepPricingSchedule/pricingScheduleDataQueries'
import FormModifiedPopup from './Ticketing/FormModifiedPopup'

import { useCurrentFormStateContext } from '~contexts/currentFormContext'

import './OnBoarding.scss'

/**
 *
 * @param {{currentStep: number}} props
 */
const OnboardingStep = ({ currentStep, ...props }) => {
	const StepComponents = [
		WelcomeScreen,
		Infos,
		Booking,
		Tarifs,
		Schedules,
		PricingSchedule,
		Validate,
		Success
	]

	const CurrentStep = StepComponents[currentStep]

	return <CurrentStep {...props} />
}

/**
 *
 * @param {{
 *   initStep?: number
 * }} props
 */
const OnBoardingScreen = ({ initStep }) => {
	const { token, apiUrl, activitiesId } = useUserData()
	const [currentStep, setCurrentStep] = useState(initStep)
	const validationFunctionRef = useRef(null)
	const [modifiedPopupOpen, setModifiedPopupOpen] = useState(false)
	const [pendingStep, setPendingStep] = useState(null)

	const { stateGetterRef } = useCurrentFormStateContext()

	const { currentEnvironmentRef } = useCurrentFormStateContext()

	useEffect(() => {
		currentEnvironmentRef.current = 'onboarding'
	}, [])

	const getNextStep = async step => {
		const direction = step - currentStep

		if (step === 5) {
			const { activity } = await getPricingSchedulePageData(
				activitiesId,
				token,
				apiUrl
			)

			if (
				!activity.pricings.enabled ||
				!activity.pricings.pricings_table.find(
					pricing => pricing.schedule_type === 'time_slots'
				)
			) {
				if (direction < 0) {
					return step - 1
				} else {
					return step + 1
				}
			}
		}

		return step
	}

	const handleChangeCurrentStep = async _step => {
		const step = await getNextStep(_step)

		if (step > 7) return

		if (!stateGetterRef.current) return

		const state = await stateGetterRef.current()

		if (state.disableForcedSave && state.modified) {
			// setModifiedPopupOpen(true)
			// setPendingStep(step)
			await state.save()

			return
		}

		if (
			(await state.save(!state.disableForcedSave && step < currentStep)) ||
			(step < currentStep && !state.disableForcedSave)
		) {
			if (step === 7) {
				await saveFormDone(activitiesId, true, token, apiUrl)
				setCurrentStep(7)
				return
			}

			await saveCurrentStep(step, activitiesId, token, apiUrl)
			setCurrentStep(step)
		}
	}

	const registerValidationFunction = func => {
		validationFunctionRef.current = func
	}

	return (
		<div className="onboarding">
			<div className="onboarding-steps-template">
				<FormModifiedPopup
					isOpen={modifiedPopupOpen}
					onClickAbort={async () => {
						if (!stateGetterRef.current) return

						const state = await stateGetterRef.current()
						setModifiedPopupOpen(false)
						await state.save(true)
						setCurrentStep(pendingStep)
					}}
					onClickCancel={() => {
						setModifiedPopupOpen(false)
					}}
					onClickSave={async () => {
						if (!stateGetterRef.current) return
						const state = await stateGetterRef.current()
						if (await state.save()) {
							await saveCurrentStep(pendingStep, activitiesId, token, apiUrl)
							setCurrentStep(pendingStep)
						}
						setModifiedPopupOpen(false)
					}}
				/>

				<OnboardingNavbar
					currentStep={currentStep}
					handleChangeCurrentStep={handleChangeCurrentStep}
					isOnboarding={true}
					hide={currentStep === 0}
				/>
				<div className="content">
					<OnboardingStep
						currentStep={currentStep}
						registerValidationFunction={registerValidationFunction}
						handleChangeCurrentStep={handleChangeCurrentStep}
					/>
				</div>
			</div>
		</div>
	)
}

const OnBoardingScreenWrapper = ({ ...props }) => {
	const [initStep, setInitStep] = useState(null)

	const { token, apiUrl, activityId } = useUserData()

	useEffect(() => {
		getActivity(activityId, token, apiUrl).then(activity => {
			setInitStep(activity.ticketing.current_step)
		})
	}, [])

	if (initStep === null) return null

	return <OnBoardingScreen initStep={initStep} {...props} />
}

export default OnBoardingScreenWrapper
