import React from 'react'
import styled from 'styled-components'
import Save from '~svg/booking/save.svg'
import SaveRibButton from '~components/basics/virements/Rib/SaveRibButton'

const Header = ({ saveRib, setErrors }) => {

  return (
    <HeaderWrapper>
      <SaveRibButton saveRib={saveRib} setErrors={setErrors} />
    </HeaderWrapper>
  )
}

export default Header

const HeaderWrapper = styled.header`
  position: fixed;
  height: 75px;
  bottom: 0;
  right: 0;
  padding: 1em;
  background-color: white;
  width: calc(100% - 239px);
  display: flex;
  align-items: center;
  justify-content: center;
`
