import gql from 'graphql-tag'
import { doGraphqlMutation, doGraphqlQuery } from '~requests/common'

const contactFragment = `contacts {
	reservationEmails {
		email
		forAllMarketItems
		id
	}
}`
export const getActivityEmails = async (id: string, accessToken: string) => {
	const query = gql`
		query FetchActivity ($id: ID!) {
			fetchActivity (id: $id) {
				${contactFragment}
			}
		}
	`

	const { fetchActivity } = await doGraphqlQuery({
		query,
		accessToken,
		variables: { id }
	})

	return fetchActivity
}

export const addActivityEmails = async (
	activityId: string,
	emails: any[],
	accessToken: string
) => {
	// console.log(emails);

	const mutation = gql`
		mutation addActivityReservationEmails(
			$activityId: ID!
			$emails: [ReservationEmailInput!]!
		) {
			addActivityReservationEmails(activityId: $activityId, emails: $emails) {
				${contactFragment}
			}
		}
	`

	const variables = {
		activityId,
		emails
	}

	const { addActivityReservationEmails } = await doGraphqlMutation({
		mutation,
		variables,
		accessToken
	})

	return addActivityReservationEmails
}
