import React, { useEffect, useRef, useState } from 'react'
import QRCode from 'qrcode.react'
/// @/ts-ignore
import ReactToPdf from 'react-to-pdf'
import './AffichettesPage.scss'
import { UnderlineIcon } from './UnderlineIcon'
import { getMyWebsites } from '~queries/website.queries'
import { useAppContext } from '~contexts/appContext/appContext'
import useQuery from '~hooks/useQuery'
import { Select } from '~components/Ticketing/FormToolbox'
import Spinner from '~components/basics/Icons/Spinner'

const optionsPDF = {
	orientation: 'landscape',
	unit: 'in',
	format: [445, 320]
}

export const AffichettesPage = () => {
	const ref = useRef(null)
	const { token } = useAppContext().auth
	const [websites, isLoading] = useQuery({
		query: () => {
			return getMyWebsites(token)
		}
	})

	const [options, setOptions] = useState([])
	const [url, seturl] = useState('www.coq-trotteur.com')
	const [loading, setLoading] = useState(true)

	useEffect(() => {
    getMyWebsites(token).then(res => {
      // console.log(res);
      if (Array.isArray(res)) {
        const tab = []
        res.map(ws => {
          tab.push({
            value: ws.netlify.url,
            label: ws.name
          })
        })
        seturl(tab[0] ? tab[0].value : 'www.coq-trotteur.com')
        setOptions(tab)
      }
    }).finally(() => setLoading(false))
  }, [])

  if (loading) {
		return (
			<div className="AffichettesPage spinner">
				<Spinner color="dark" />
			</div>
		)
	}

	return (
		<div className="AffichettesPage">
			<div className="buttons">
				<ReactToPdf targetRef={ref} filename="affichette.pdf" options={optionsPDF}>
					{({ toPdf }: any) => (
						<button onClick={toPdf} className="generate_btn">
							Générer le PDF
						</button>
					)}
				</ReactToPdf>
				<Select options={options} value={url} setValue={(val) => seturl(val)} />
			</div>
			<div className="container">
				<div ref={ref} className="the_class">
					<div className="head">
						<span>Bienvenue</span>
						<UnderlineIcon />
					</div>

					<div className="text">
						Retrouvez mes offres en ligne sur la plateforme locale !
					</div>

					<div className="qr_code">
						<QRCode value={url} renderAs="svg" />
					</div>
					<span>{url}</span>
				</div>
			</div>
		</div>
	)
}

export default AffichettesPage
