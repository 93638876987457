import gql from 'graphql-tag'
import { doGraphqlMutation, doGraphqlQuery } from '~requests/common'

export const removeWebsite = async (websiteId: string, accessToken: string) => {
	const mutation = gql`
		mutation CreateWebsite($websiteId: ID!) {
			removeWebsiteById(websiteId: $websiteId)
		}
	`

	const variables = {
		websiteId
	}

	const result = await doGraphqlMutation({
		mutation,
		variables,
		accessToken
	})

	return result
}

export const getMyWebsites = async (accessToken: string) => {
	const query = gql`
		{
			fetchMyWebsites {
				id
				name
				netlify {
					siteId
					previewImageUrl
					url
				}
				activities {
					id
				}
				boutique {
					categories {
						id
						label
						marketItems
						children {
							id
							label
							marketItems
							children {
								id
								label
								marketItems
							}
						}
					}
				}
			}
		}
	`

	const { fetchMyWebsites: websites } = await doGraphqlQuery({
		query,
		accessToken
	})

	return websites
}

export const updateWebsiteById = async (
	websiteInput,
	websiteId,
	accessToken
) => {
	const mutation = gql`
		mutation UpdateWebsiteById($websiteInput: WebsiteInput!, $websiteId: ID!) {
			updateWebsiteById(websiteInput: $websiteInput, websiteId: $websiteId) {
				id
				name
				netlify {
					siteId
					previewImageUrl
					url
				}
				boutique {
					categories {
						id
						label
						children {
							id
							label
							children {
								id
								label
							}
						}
					}
				}
			}
		}
	`

	const variables = {
		websiteInput,
		websiteId
	}

	const { updateWebsite: website } = await doGraphqlMutation({
		mutation,
		variables,
		accessToken
	})

	return website
}

export const updateWebsite = async (websiteInput, accessToken) => {
	const mutation = gql`
		mutation UpdateWebsite($websiteInput: WebsiteInput!) {
			updateWebsite(websiteInput: $websiteInput) {
				id
				name
				netlify {
					siteId
					previewImageUrl
					url
				}
			}
		}
	`

	const variables = {
		websiteInput
	}

	const { updateWebsite: website } = await doGraphqlMutation({
		mutation,
		variables,
		accessToken
	})

	return website
}

export const createWebsite = async (
	websiteInput,
	accessToken
): Promise<{
	id: string
	name: string
	netlify: {
		siteId: string
		previewImageUrl: string
		url: string
	}
}> => {
	const mutation = gql`
		mutation CreateWebsite($websiteInput: WebsiteInput!) {
			createWebsite(websiteInput: $websiteInput) {
				id
				name
				netlify {
					siteId
					previewImageUrl
					url
				}
			}
		}
	`

	const variables = {
		websiteInput
	}

	const { createWebsite: website } = await doGraphqlMutation({
		mutation,
		variables,
		accessToken
	})

	return website
}

export const urlIsAvailable = async (url: string, accessToken: string) => {
	const query = gql`
		query NetlifySiteUrlAvailable($url: String) {
			netlifySiteUrlAvailable(url: $url)
		}
	`

	const variables = {
		url
	}

	return (
		await doGraphqlQuery({
			query,
			accessToken,
			variables
		})
	).netlifySiteUrlAvailable
}

export const getMyActivities = async (accessToken: string) => {
	const query = gql`
		query FetchMyActivities {
			fetchMyActivities {
				_id
				title
				slug
				description
				ht
				hosts {
					id
					name
				}
				offices {
					id
					name
				}
				schedules {
					enabled
					schedules_table {
						days {
							name
						}
					}
				}
				apidae {
					id
					editable
				}
				contacts {
					reservationEmails {
						id
						email
						forAllMarketItems
					}
				}
				miniature {
					public_id
					url
				}
				gallery {
					public_id
					url
				}
			}
		}
	`

	const { fetchMyActivities } = await doGraphqlQuery({
		query,
		accessToken
	})

	return fetchMyActivities
}

export const updateWebsiteDomain = async (
	siteId: string,
	customDomain: string,
	accessToken: string
) => {
	const mutation = gql`
		mutation UpdateNetlifySiteCustomDomain(
			$customDomain: String!
			$siteId: String!
		) {
			updateNetlifySiteCustomDomain(
				customDomain: $customDomain
				siteId: $siteId
			)
		}
	`

	const variables = {
		siteId,
		customDomain
	}

	await doGraphqlMutation({
		mutation,
		variables,
		accessToken
	})
}
