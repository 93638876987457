import { text_field, bool_field, mail_field } from 'react-super-useform'

export default {
	activity: {
		accessibility: {
			starting_location: {
				enabled: text_field({ required: false, default: 'no' }),
				note: text_field({ required: false }),
				address: text_field({ required: false })
			}
		},
		booking: {
			informations: {
				mail: mail_field()
			},
			each_person_identity: bool_field({ default: false })
		},
		administration: {
			phone: text_field(),
			mail: mail_field()
		}
	}
}
