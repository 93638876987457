import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { log } from '~utils/helpers'
import PositiveRows from './PositiveRows'
import NegativeRows from './NegativeRows'
import Charges from './Charges'
import Hr from './Hr'
import {
  getMonth,
  getYear,
  lastDayOfMonth,
  getDaysInMonth,
  getDate,
  isWithinInterval,
  format,
} from 'date-fns'
import Total from './Total'
import { downloadWeeklyBill } from '~requests/reservations/services/pdf-generator.service'
import { useUserData } from '~contexts/userDataContext'
import { downloadWeeklyBillUrl } from '~requests/reservations/routes/reservations.routes'

const Details = ({ bill, visible, month }) => {
  const [weeks, setWeeks] = useState([])
  const { token } = useUserData()
  const [apiUrl, setApiUrl] = useState(process.env.GATSBY_APIV2_URL)
  function downloadBill(weekBill) {
    downloadWeeklyBill(apiUrl, token, weekBill)
    .then(res => {
      const data = res;
      try {
        const blob = data;
        let url = apiUrl+'/'+downloadWeeklyBillUrl+'?id='+data//window.URL.createObjectURL(new Blob(data, {type: "application/pdf"}));
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = weekBill.label+'.pdf';
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
      } catch (error) {
        console.log(error)
        alert("Erreur lors du telechargement ")
        return null;
      }
    })
    .catch(error => {
      console.warn('Error download weekly bill ', error)
    })
  }
  
  const FEE = 3

  useEffect(() => {
    const day = new Date(bill[0].billingDate)//new Date(bill[0].date)
    const monthNum = getMonth(day)
    const year = getYear(day)
    const lastOfMonth = lastDayOfMonth(day)
    const lastNumOfMonth = getDate(lastOfMonth)
    const daysInMonth = getDaysInMonth(day)
    const totalWeeks = Math.ceil(daysInMonth / 8)
    // log(bill)

    const weeks = Array(totalWeeks)
      .fill(null)
      .map((_, i) => {
        const weekNum = i + 1
        let from = weekNum + i * 7
        let to = from + 7 > lastNumOfMonth ? lastNumOfMonth : from + 7
        const fromStr = String(from).length === 1 ? '0' + from : from
        const toStr = String(to).length === 1 ? '0' + to : to

        from = new Date(new Date(year, monthNum, from).setHours(0, 0, 0, 0))

        to = new Date(new Date(year, monthNum, to).setHours(23, 59, 59, 999))
        const weeklyBill = bill.map(obj => {
          let lastUpdateDate = obj.billingDate// obj.date;
          // if(obj.state.canceled) {
          //   lastUpdateDate = obj.state?.canceled_date
          // }
          const day = new Date(lastUpdateDate)
          if (isWithinInterval(day, { start: from, end: to })) {
            return obj
          }
        })

        // log(weeklyBill)

        return {
          label: `Du ${fromStr} ${month} au ${toStr} ${month}`,
          from,
          to,
          bill: weeklyBill.filter(bill => bill !== undefined)?.reverse(),
        }
      })

    setWeeks(weeks.reverse())

    log(weeks)
  }, [])

  return (
    <DetailsWrapper visible={visible}>
      {weeks.map(week => {
        return week.bill.length ? (
          <GreyBloc key={week.label}>
            <WeekRow>
              {week.label} - <Download onClick={() => downloadBill(week)}>Télécharger</Download>
              <Hr />
            </WeekRow>
            <PositiveRows
              rows={week.bill}
              FEE={FEE}
            />
            <NegativeRows rows={week.bill.filter(row => row.state.canceled)} />
            <Charges date={format(week.to, 'dd/MM/yy')} />
            <Total
              FEE={FEE}
              bill={week.bill}
            />
          </GreyBloc>
        ) : null
      })}
    </DetailsWrapper>
  )
}

export default Details

const DetailsWrapper = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};
  margin-top: 29px;
  min-height: 300px;
`

const GreyBloc = styled.div`
  background-color: rgba(245, 246, 250, 0.5);
  padding: 18px 17px;
  margin-bottom: 10px;
`

const WeekRow = styled.div`
  width: 100%;
  text-align: center;
`
const Download = styled.span`
  text-decoration: underline;
  text-align: right;
  cursor: pointer;
`