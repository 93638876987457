import React from 'react'

const LogoIcon = ({ height, color = 'dark' }) => (
  <svg
    height={height}
    viewBox="0 0 144 139"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className="logo"
  >
    <title>Logo</title>
    <defs></defs>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="1"
        fill={color === 'dark' ? '#263244' : 'white'}
        fillRule="nonzero"
      >
        <path
          d="M60.881,111.726 C60.909,110.774 61.918,110.802 61.918,111.699 C62.647,124.081 56.399,133.551 54.886,136.464 C53.373,139.377 55.95,137.752 55.95,137.752 C64.355,131.112 70.322,109.261 63.262,85.646 C56.202,62.03 61.637,57.687 62.983,55.615 C64.327,53.542 63.655,53.261 62.535,53.933 C59.548,55.725 56.148,60.768 55.532,65.139 C54.916,69.51 57.507,85.646 57.57,86.507 C57.633,87.369 57.297,87.537 57.045,87.663 C56.625,88.335 46.203,90.941 37.463,85.856 C24.629,78.025 31.466,65.888 32.817,63.746 C32.952,63.531 33.196,63.619 33.278,63.718 C42.78,75.288 51.302,76.513 51.302,76.513 C53.459,76.807 52.759,75.7 51.672,75.052 C51.617,75.001 51.583,74.972 51.583,74.972 C30.097,62.739 32.844,44.59 33.514,41.417 C33.579,41.103 33.667,41.125 33.837,41.419 C34.434,42.451 36.615,44.763 44.159,44.633 C53.852,44.465 58.783,37.181 69.931,38.414 C81.08,39.646 79.849,48.835 80.073,48.835 C80.297,48.835 82.314,48.891 82.314,48.891 L97.242,40.986 C97.242,40.986 97.426,40.958 97.397,41.101 C97.043,42.845 94.721,54.331 94.444,56.356 C94.425,56.486 94.569,56.488 94.646,56.419 C100.402,51.339 106.713,48.345 107.81,47.845 C107.918,47.795 107.946,47.823 107.89,47.92 C107.007,49.471 99.56,62.575 98.742,64.385 C98.358,65.212 97.722,64.686 97.722,64.686 C97.722,64.686 88.803,58.683 91.055,61.161 C97.478,67.584 120.704,101.326 126.937,114.825 C127.391,115.881 128,116.196 128.916,115.045 C137.93,103.068 143.275,88.175 143.275,72.031 C143.275,32.485 111.216,0.427 71.671,0.427 C32.126,0.427 0.067,32.487 0.067,72.031 C0.067,102.138 18.651,127.903 44.972,138.487 C45.618,138.733 46.235,138.733 46.861,138.183 C58.031,127.593 60.856,112.621 60.881,111.726 Z"
          id="Shape"
        ></path>
        <ellipse
          id="Oval"
          transform="translate(80.284227, 60.466734) rotate(-127.806140) translate(-80.284227, -60.466734) "
          cx="80.2842272"
          cy="60.466734"
          rx="4.79006469"
          ry="2.84603843"
        ></ellipse>
      </g>
    </g>
  </svg>
)
export default LogoIcon
