import React, { useEffect, useRef } from 'react'
import Button from '~components/basics/Button'

import { DecoratedH2 } from '~components/reservations/ReservationsEmpty/ReservationsEmpty'
import ArrowIcon from '~components/svg/ArrowIcon'

import './WebsiteCreationMainInformations.scss'

const WebsiteCreationMainInformations: TWebsiteCreationMainInformations = ({
	data,
	onChange,
	handlePrevious
}) => {
	const inputRef = useRef<HTMLInputElement>(null)

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus()
		}
	}, [])

	return (
		<div className="WebsiteCreationMainInformations">
			<DecoratedH2>Nom de votre site internet</DecoratedH2>

			<p>
				C'est le nom qui apparaîtra sur les mails et en haut
				<br /> de votre site, tant que vous n'avez pas mis de logo.
			</p>

			<input
				ref={inputRef}
				placeholder="Exemple : Les confitures de Lucien"
				defaultValue={data.name}
			/>

			<p></p>
			<div className="buttons">
				<div></div>
				<Button
					onClick={() => {
						onChange({ name: inputRef.current.value })
					}}
					theme="blue"
				>
					Suivant
					<ArrowIcon direction="right" color="white" />
				</Button>
			</div>
		</div>
	)
}

export default WebsiteCreationMainInformations

type TWebsiteCreationMainInformations = React.FC<{
	data: any
	onChange: (data: any) => void
	handlePrevious: () => void
	handleByPass: () => void
}>
