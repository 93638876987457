import { Router } from '@reach/router'
import React from 'react'
import { withAuth } from '~components/HOC/withAuth'
import FicheSchedulePage from '~components/pages/Fiche/FicheSchedulePage/FicheSchedulePage'

const schedules = () => {
	return (
		<Router>
			<FicheSchedulePage path="/fiche/schedules/:id/*" />
		</Router>
	)
}

export default withAuth(schedules)
