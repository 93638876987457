import { gql } from '@apollo/client'
import { doGraphqlMutation, doGraphqlQuery } from '~requests/common'
import { cleanGraphqlResult } from '~utils/helpers'

export const getUserMarketItemsCount = async (userId, token) => {
	const query = gql`
		query getUserMarketItems($userId: ID!) {
			fetchUserMarketItems(userId: $userId) {
				id
			}
		}
	`

	const variables = {
		userId
	}

	const result = Array.from(
		(
			await doGraphqlQuery({
				query,
				variables,
				accessToken: token
			})
		).fetchUserMarketItems
	)

	return result.length
}

/*
 *
 */
export const getUserMarketItems = async (userId, token) => {
	const query = gql`
		query getUserMarketItems($userId: ID!) {
			fetchUserMarketItems(userId: $userId) {
				id
				title
				description
				note
				type
				category {
					id
				}
				pricing {
					unique
					basePrice
					list {
						price
					}
				}
				creation {
					complete
				}

				gallery {
					isCover
					url
				}
				miniature {
					public_id
					url
				}
				enabled
				activities {
					id
				}
				optionsQuotas {
					pricing
					options
					quota
				}
			}
		}
	`

	const variables = {
		userId
	}

	const result = Array.from(
		(
			await doGraphqlQuery({
				query,
				variables,
				accessToken: token
			})
		).fetchUserMarketItems
	)

	return cleanGraphqlResult(result)
}

export const getUserMarketItem = async (marketItemId, accessToken) => {
	const query = gql`
		query getUserMarketItem($marketItemId: ID!) {
			fetchMarketItem(marketItemId: $marketItemId) {
				id
				title
				description
				note
				type
				category {
					id
				}
				service_availability {
					referentActivity
					ephemeral
					type
					unique_schedule
					global_closed_period
					schedules {
						id
						unique_week_schedule
						purpose
						closed_periods {
							id
							start_date
							end_date
						}
						week_schedules {
							id
							periods {
								start_date
								end_date
								id
							}
							days
						}
					}
				}
				service_duration {
					minutes
					hours
					days
				}
				reservation_policy {
					description
					confirmation_method
					weather_cancellation
					reservation_email_ids
					cancellation
					refundPolicy {
						enabled
						delay
					}
					exchangePolicy {
						enabled
						delay
					}
					cancellationPolicy {
						enabled
						delay
					}
					reservationDeadline {
						enabled
						daysDelay
					}
				}
				service_location {
					enabled
					address {
						full_address
					}
					note
					instructions
				}
				private_group {
					enabled
					min_visitor_count
					max_visitor_count
				}
				about {
					inclusions {
						has
						hasnt
					}
					note
				}
				languages {
					enabled
					values
				}
				pricing {
					unique
					basePrice
					note
					list {
						id
						label
						price
						description
					}
					tva {
						unique
						percentage
						list {
							amount
							percentage
						}
					}
				}
				options {
					id
					label
					type
					values {
						id
						label
					}
					choices {
						id
						label
					}
					tva
					price
				}
				time_limit
				delivery {
					ownDelivery {
						note
						enabled
						freeAfterOrderPrice
						preparationDelay
						price
						pricing
					}
					takeAway {
						note
						preparationDelay
						enabled
					}
					shippingOptions {
						enabled
						service
						preparationDelay
						price
						costPerAdditionalProduct
						freeAfterOrderPrice
						weight
						extraCostEnabled
					}
				}
				giftPackage {
					available
					price
				}
				creation {
					complete
					currentStep
				}
				informations {
					moods {
						id
					}
					gender
					who
					labels
				}

				characteristics {
					weight
					length
					height
					width
					volume
				}
				gallery {
					id
					isCover
					url
					public_id
				}
				enabled

				activities {
					id
				}
				optionsQuotas {
					pricing
					options
					quota
				}
				quotas {
					enabled
					type
					quantity
					items {
						language
						pricing
						options
						quantity
					}
				}
			}
		}
	`

	const variables = {
		marketItemId
	}

	const { fetchMarketItem } = await doGraphqlQuery({
		query,
		variables,
		accessToken,
		noCache: true
	})

	return cleanGraphqlResult(fetchMarketItem)
}

export const removeMarketItem = async (marketItemId, accessToken) => {
	const mutation = gql`
		mutation RemoveMarketItem($marketItemId: ID!) {
			removeMarketItem(marketItemId: $marketItemId)
		}
	`
	const variables = {
		marketItemId
	}

	return (await doGraphqlMutation({ mutation, variables, accessToken }))
		.removeMarketItem
}

export const updateMarketItem = async (
	marketItemId,
	marketItemInput,
	accessToken
) => {
	const mutation = gql`
		mutation UpdateMarketItem(
			$marketItemId: ID!
			$marketItemInput: UpdateMarketItemInput!
		) {
			updateMarketItem(
				marketItemInput: $marketItemInput
				marketItemId: $marketItemId
			) {
				enabled
			}
		}
	`
	const variables = {
		marketItemId,
		marketItemInput
	}

	return (await doGraphqlMutation({ mutation, variables, accessToken }))
		.updateMarketItem
}

type TMarketCategory = {
	id: string
	label: string
}

export const getMarketCategories = async (
	accessToken
): Promise<TMarketCategory[]> => {
	const query = gql`
		{
			fetchMarketCategories {
				id
				label
				parent
			}
		}
	`

	return cleanGraphqlResult(
		(
			await doGraphqlQuery({
				query,
				accessToken
			})
		).fetchMarketCategories
	)
}

export const getMarketMoods = async (accessToken): Promise<any[]> => {
	const query = gql`
		query {
			fetchMarketMoods {
				id
				name
			}
		}
	`

	return cleanGraphqlResult(
		(
			await doGraphqlQuery({
				query,
				accessToken
			})
		).fetchMarketMoods
	)
}
