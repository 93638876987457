import React, { useState, useEffect } from 'react';
import './reservation-waiting-for-validation.scss';
import GroupPersonsIcon from '~svg/group-persons.svg';
import { useUserData } from '~contexts/userDataContext';
import { acceptReservationTicket, cancelReservationTicket, getPendingReservations, refusalReservationTicket } from '~requests/reservations/services/reservation.service';
import moment from 'moment'
import ReservationDetailsPopup from './ReservationDetailsPopup';
import ReservationCancellationResult from './ReservationCancellationResult';
import { getTicketDatesString, countTotalTicketPricingsQuantity, getTicketPricings, getTicketOptions } from '@coq-trotteur/coq-core/dist';
import ReservationMarketDetailsPopup from './ReservationMarketDetailsPopup';


const ReservationWaitingForValidation = ({acceptReservation}) => {
    const { apiUrl, token } = useUserData();
    const [ reservations, setReservations ] = useState([]);
    const [hasNoPendingReservation, setHasNoPendingReservation] = useState(false);
    const [popupDetailsData, setPopupDetailsData] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [cancellationResultPopupTitle, setCancellationResultPopupTitle] = useState("");
    const [cancellationResultPopupDescription, setCancellationResultPopupDescription] = useState("");
    const [confirmDialog, setConfirmDialog] = useState(true);
    const [ticketToCancel, setTicketToCancel] = useState(null);
    const onClickAbort = () => {
        setIsOpen(false);
        setConfirmDialog(true);
    }
    const onClickCancel = () => {
        setIsOpen(false);
        setConfirmDialog(true);
    }
    const onClickSave = () => {
        setSaveIsLoading(true)
        refusalReservationTicket(ticketToCancel, apiUrl, token)
            .then(async res => {
                if (res.status !== 200 && res.status !== 201) {
                    const response = await res.json();
                    setCancellationResultPopupTitle('Refus impossible');
                    setCancellationResultPopupDescription(response.error);
                } else {
                    setCancellationResultPopupTitle('Refus confirmé');
                    setCancellationResultPopupDescription('Un mail a été envoyé au client pour le prévenir que sa réservation a été refusée.');
                }
                setConfirmDialog(false);
            }).catch(error => {
                console.warn('Error when fetching pending reservations ', error);
                setCancellationResultPopupTitle('refus impossible');
                setCancellationResultPopupDescription(error?.error);

            }).finally(() => {
                setSaveIsLoading(false);
                fetchPendingReservations();
            });
    }
    const cancelReservation = (ticketNumber) => {
        setCancellationResultPopupTitle('Êtes-vous sûr de vouloir refuser ?');
        setCancellationResultPopupDescription('Si vous confirmez, vous ne pourrez plus changer d’avis, le refus sera définitif.');
        setIsOpen(true);
        setTicketToCancel(ticketNumber);
    }
    useEffect(() => {
        fetchPendingReservations();
    }, [])
    function fetchPendingReservations() {
        getPendingReservations(apiUrl, token)
            .then(res => {
                // console.log({res})
                if (Array.isArray(res)) {
                    setReservations(res);
                    setHasNoPendingReservation(!Boolean(res.length));
                }
            }).catch(error => {
                console.warn('Error when fetching pending reservations ', error);
            })
    }

    const onAcceptReservationTicket = (ticketToAccept) => {
        acceptReservationTicket(ticketToAccept, apiUrl, token)
            .then(async res => {
                if(res.status !== 200) {
                    console.warn(res);
                }
                acceptReservation(ticketToAccept);
            }).catch(error => {
                console.warn('Error when fetching pending reservations ', error);
            }).finally(() => {
                fetchPendingReservations();
            });
    }
    const isOnDate = (items) => {
        let found = false;
        items.map((item) => {
            if (item.schedule_type !== 'time_duration') {
                found = true;
            }
        })
        return found;
    }
    return (
        <>
            <ReservationMarketDetailsPopup reservation={popupDetailsData} onClose={() => {
                setPopupDetailsData(null); fetchPendingReservations();
            }} />
            {
                isOpen
                ?
                <ReservationCancellationResult
                    saveIsLoading={saveIsLoading}
                    isOpen={isOpen}
                    title={cancellationResultPopupTitle}
                    description={cancellationResultPopupDescription}
                    confirmDialog={confirmDialog}
                    onClickAbort={onClickAbort}
                    onClickCancel={onClickCancel}
                    onClickSave={onClickSave}
                />
                :
                <></>
            }
        {
            hasNoPendingReservation
            ?
            <></>
            :
            <div className="block-info">
                <div className="block-title">
                    En attente de validation
                </div>
                <div className="block-container">
                    <table>
                        <tbody>
                            {
                                        reservations?.map((ticket, reservationIndex) => {
                                    return (
                                        <tr key={`reservation_${reservationIndex}`}>
                                            <td onClick={() => {
                                                setPopupDetailsData(ticket)
                                            }}>
                                                { getTicketDatesString(ticket) }
                                            </td>
                                            <td onClick={() => {
                                                setPopupDetailsData(ticket)
                                            }}>
                                                { countTotalTicketPricingsQuantity(ticket) } <span className='group-person-icon'><GroupPersonsIcon className='group-person-icon' /></span>
                                                { getTicketPricings(ticket).map(pricing => {
                                                    return pricing.quantity + ' * ' + pricing.label
                                                }).join('') }
                                                { getTicketOptions(ticket).map(option => {
                                                    return option.quantity + ' * ' + option.label
                                                }).join('') }
                                            </td>
                                            <td>
                                                <button className="btn-resa-action btn-refuse" onClick={() => cancelReservation(ticket._id)}>Refuser</button>
                                                <button className="btn-resa-action btn-accept" onClick={() => onAcceptReservationTicket(ticket._id)}>Accepter</button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            
                        </tbody>
                    </table>
                </div>
            </div>
        }
        </>
    )
};

export default ReservationWaitingForValidation;