import React, { useState } from 'react'
import { getImageUrl } from '~utils/cloudinary'
import './FicheActivityItem.scss'
import { IndicatorIcon } from './icons/IndicatorIcon'
import { SadIcon } from './icons/SadIcon'

const FicheActivityItem = ({ fiche, setSelected, selected }) => {
    const imgUrl = getImageUrl(fiche.miniature)
    const [imgErr, setImgErr] = useState(false)
    return (
        <div
            className={'FicheActivityItem' + (selected ? ' active' : '')}
            onClick={() => setSelected()}
        >
            {(!imgErr && imgUrl) ? <img src={imgUrl} alt="" onError={(erra) => {
                setImgErr(true)
            }} /> : <div className="no_image">
                <div>
                    <SadIcon />
                    Aucune image
                </div>
                </div>}
            <div className="title_box">{fiche.title}</div>
            {selected && <IndicatorIcon />}
        </div>

    )
}

export default FicheActivityItem
