import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { useUserData } from '~contexts/userDataContext'
import useHorizontalScroll from '~hooks/useHorizontalScroll'
import { getMyActivities } from '~queries/website.queries'
import { getImageUrl } from '~utils/cloudinary'
import { BackIcon } from './components/icons/BackIcon'
import FicheActivityItem from './components/FicheActivityItem'
import { ClockIcon } from './components/icons/ClockIcon'

import { MailIcon } from './components/icons/MailIcon'
import Spinner from '~components/basics/Icons/Spinner'
import { isFalse } from '~utils/misc'

import './FichePage.scss'

const FichePage = ({}) => {
	const [selected, setSelected] = useState(0)
	const [fiches, setFiches] = useState([])
	const { token, shareLink } = useUserData()
	const [
		containerRef,
		contentRef,
		onFowardClick,
		onBackClick,
		showBack,
		showFoward
	] = useHorizontalScroll(fiches.length >= 3)

	const [loading, setLoading] = useState(true)

	useEffect(() => {
		getMyActivities(token)
			.then(res => {
				if (Array.isArray(res)) setFiches(res)
			})
			.finally(() => setLoading(false))
	}, [])

	const fiche = fiches.length > 0 ? fiches[selected] : null

	if (loading) {
		return (
			<div className="FichePage spinner">
				<Spinner color="dark" />
			</div>
		)
	}
	return (
		<div className="FichePage">
			<div className="scroller" ref={containerRef}>
				{
					<>
						{showBack && (
							<button className="left" onClick={onBackClick}>
								<BackIcon width={20} height={20} />
							</button>
						)}

						{showFoward && (
							<button className="right" onClick={onFowardClick}>
								<BackIcon width={20} height={20} />
							</button>
						)}
					</>
				}
				<div className="tiles" ref={contentRef}>
					<div className="text_tile">
						Voici les fiches recensées par votre Office de Tourisme. Pour en
						ajouter ou en supprimer, contactez directement votre Office.
					</div>
					{fiches.map((fiche, n) => (
						<FicheActivityItem
							fiche={fiche}
							key={n}
							setSelected={() => setSelected(n)}
							selected={selected === n}
						/>
					))}
				</div>
			</div>
			{!!fiche && (
				<div className="main">
					<div className="header">
						<a
							href={shareLink[fiche._id]?.url}
							target="_blank"
							className="title"
						>
							{fiche.title}
						</a>
						<div className="text">
							Mise en valeur sur{' '}
							<div className="special_txt">
								{fiche.offices?.length} plateforme{' '}
								{fiche.offices?.length > 1 && 's'}
								{fiche.offices?.length > 0 && (
									<div className="hover_show">
										{fiche.offices.map(office => (
											<span key={office.id}>{office.name}</span>
										))}
									</div>
								)}
							</div>{' '}
							du territoire{' '}
							{fiche.hosts?.length > 0 &&
								` et chez ${fiche.hosts?.length} hébergeurs`}
						</div>
					</div>
					<div className="links_container">
						<Link to={'schedules/' + fiche._id}>
							<ClockIcon />
							<div>
								<div className="title">
									<b>Horaires d’ouverture {/* <AlertIcon /> */}</b>
									{isFalse(fiche?.schedules?.enabled) && (
										<>
											- <span>fermé temporairement</span>
										</>
									)}
								</div>
								<div>
									{!(fiche.schedules?.schedules_table?.length > 0)
										? 'Si vous avez des horaires d’ouverture, renseignez-les ici.'
										: 'Modifier ou supprimer vos horaires d’ouverture'}
								</div>
							</div>
						</Link>
						{fiche.apidae.editable ? (
							<Link to={'apidae/' + fiche.apidae.id} className="active">
								<img src="/icons/apidae_update_icon.png" alt="" />
								<div>
									<div className="title">
										<b>
											Modifier votre fiche Apidae (photos, adresse, description,
											etc.)
										</b>
									</div>
									<div>
										Il est important que cette fiche soit bien remplie et donne
										envie ! Elle est utilisée sur les plateformes du territoire
										: office de tourisme, département etc.
									</div>
								</div>
							</Link>
						) : (
							<a style={{ cursor: 'default' }}>
								<img src="/icons/apidae_update_icon.png" alt="" />
								<div>
									<div className="title">
										<b>
											Vous ne pouvez pas modifier cette fiche ici (photo,
											description, adresse). Vous devez contacter votre
											organisme de référence pour la modifier.
										</b>
									</div>
								</div>
							</a>
						)}
						<Link to={'mails/' + fiche._id}>
							<MailIcon />
							<div>
								<div className="title">
									<b>Mails de réservation</b>
								</div>
								<div>
									{fiche.contacts.reservationEmails.map((email, i) => {
										return (
											<div className="email" key={i}>
												{email.email}
											</div>
										)
									})}
								</div>
							</div>
						</Link>
					</div>
				</div>
			)}
		</div>
	)
}

export default FichePage

const AlertIcon = () => (
	<svg
		width="18"
		height="18"
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="9" cy="9" r="9" fill="#F53E7E" />
		<path
			d="M9.092 4C8.6453 4 8.2832 4.3621 8.2832 4.80879V10.0172C8.2832 10.4638 8.6453 10.8259 9.092 10.8259C9.5387 10.8259 9.90079 10.4638 9.90079 10.0172V4.80879C9.90079 4.3621 9.5387 4 9.092 4Z"
			fill="white"
		/>
		<path
			d="M9.09187 14C9.6949 14 10.1837 13.5112 10.1837 12.9082C10.1837 12.3051 9.6949 11.8163 9.09187 11.8163C8.48885 11.8163 8 12.3051 8 12.9082C8 13.5112 8.48885 14 9.09187 14Z"
			fill="white"
		/>
	</svg>
)
