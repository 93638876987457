import React, { useState, useRef, useEffect, useMemo } from 'react'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import fr from 'date-fns/locale/fr'
registerLocale('fr', fr)

import uniqid from 'uniqid'

import './DatePicker.scss'

const moment = extendMoment(Moment)

const displayDate = date => {
	if (moment(date).isValid()) return moment(date).format('DD/MM')

	return null
}

const displayDateRange = (date1, date2) => {
	const dateString1 = displayDate(date1)
	const dateString2 = displayDate(date2)

	return `${dateString1 || 'Début'} - ${dateString2 || 'fin'}`
}

const DatePicker = ({
	dateRangeForm,
	onChange = () => {},
	filterDate = () => true,
	disabledRanges = []
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const [isOpen1, setIsOpen1] = useState(false)
	const [isOpen2, setIsOpen2] = useState(false)
	const pickerIdRef = useRef(uniqid())

	const [leftMargin, setLeftMargin] = useState(0)

	const rootRef = useRef(null)
	const dropdownRef = useRef(null)

	useEffect(() => {
		const clickOutsideEvent = event => {
			let clickIsInside = false
			let _node = event.target
			for (let i = 0; i < 20; i++) {
				if (
					_node.className === `picker-${pickerIdRef.current}` ||
					_node.className === 'react-datepicker' ||
					_node.className === `date-picker date-picker-${pickerIdRef.current}`
				) {
					clickIsInside = true
					break
				}

				if (!_node.parentNode) break
				_node = _node.parentNode
			}

			if (!clickIsInside) {
				setIsOpen1(false)
				setIsOpen2(false)
				setIsOpen(false)
			}
		}

		if (isOpen) {
			window.addEventListener('click', clickOutsideEvent)

			const windowWidth = window.innerWidth
			const { x } = dropdownRef.current.getBoundingClientRect()

			const right = windowWidth - x - 550 - 50

			if (right >= 0) {
				// setLeftMargin(0)
			} else {
				setLeftMargin(right)
			}

			return () => {
				window.removeEventListener('click', clickOutsideEvent)
			}
		}
	}, [isOpen])

	const start_date = dateRangeForm.get('start_date').value

	const startDate =
		!start_date || !moment(start_date).isValid()
			? null
			: new Date(dateRangeForm.get('start_date').value)

	const end_date = dateRangeForm.get('end_date').value

	const endDate =
		!end_date || !moment(end_date).isValid()
			? null
			: new Date(dateRangeForm.get('end_date').value)

	const error =
		dateRangeForm.get('start_date').error || dateRangeForm.get('end_date').error

	const getNextPeriodStartDate = () => {
		if (!startDate) return null

		const sorted = disabledRanges.sort((rangeA, rangeB) => {
			return moment(rangeA.start_date).diff(moment(rangeB.start_date))
		})

		const _startDate = moment(startDate)

		for (let i = 0; i < sorted.length; i++) {
			const range = sorted[i]

			if (_startDate.diff(moment(range.start_date)) < 0) {
				return range.start_date
			}
		}

		return null
	}

	const nextPeriodStartDate = getNextPeriodStartDate()

	return (
		<div
			className={`date-picker date-picker-${pickerIdRef.current}`}
			ref={rootRef}
		>
			<button
				className={`open-button ${error ? 'error' : ''}`}
				type="button"
				onClick={() => {
					if (isOpen1) {
						setIsOpen1(false)
						setIsOpen(false)
					} else if (isOpen2) {
						setIsOpen2(false)
						setIsOpen(false)
					} else {
						setIsOpen1(true)
						setIsOpen(true)
					}
				}}
			>
				{displayDateRange(startDate, endDate)}
			</button>
			<div
				style={{
					position: 'absolute',
					zIndex: 1000,
					left: 0,
					width: 550,
					top: 28,
					marginLeft: leftMargin,
					transition: 'transform 150ms',
					transform: isOpen ? 'translateY(5px)' : 'translateY(0)'
				}}
				ref={dropdownRef}
			>
				<ReactDatePicker
					open={isOpen1}
					className={`picker-${pickerIdRef.current}`}
					onChange={date => {
						setIsOpen1(false)
						setIsOpen2(true)
						dateRangeForm.get('start_date').setValue(date.toISOString())
						onChange()
					}}
					filterDate={date => {
						for (let i = 0; i < disabledRanges.length; i++) {
							const d = disabledRanges[i]

							if (!!d.start_date && !!d.end_date) {
								const range = moment().range(d.start_date, d.end_date)

								if (range.contains(date)) return false
							}
						}

						return true
					}}
					monthsShown={2}
					startDate={startDate ? new Date(startDate) : null}
					endDate={endDate ? new Date(startDate) : null}
					locale="fr"
					openToDate={startDate ? new Date(startDate) : new Date()}
					selectsStart={true}
				/>
				<ReactDatePicker
					key={isOpen2}
					open={isOpen2}
					className={`picker-${pickerIdRef.current}`}
					onChange={date => {
						setIsOpen2(false)

						if (moment(date).diff(startDate) < 0) {
							// Date are inverted

							dateRangeForm.set({
								start_date: moment(date).toISOString(),
								end_date: startDate.toISOString()
							})
						} else dateRangeForm.get('end_date').setValue(date.toISOString())

						// TODO invert if end_date before start_date ?
						onChange()
					}}
					filterDate={date => {
						if (nextPeriodStartDate) {
							if (moment(date).diff(nextPeriodStartDate) > 0) {
								return false
							}
						}

						for (let i = 0; i < disabledRanges.length; i++) {
							const d = disabledRanges[i]

							if (!!d.start_date && !!d.end_date) {
								const range = moment().range(d.start_date, d.end_date)

								if (range.contains(date)) return false
							}
						}

						return true
					}}
					monthsShown={2}
					selectsEnd={true}
					startDate={startDate ? new Date(startDate) : null}
					endDate={endDate ? new Date(startDate) : null}
					openToDate={new Date(startDate)}
					locale="fr"
				/>
			</div>
		</div>
	)
}

export const FormlessDatePicker = ({ onSelect, isOpen, setIsOpen }) => {
	const [isOpen1, setIsOpen1] = useState(false)
	const [isOpen2, setIsOpen2] = useState(false)

	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)

	const rootRef = useRef(null)

	useEffect(() => {
		if (isOpen) {
			setIsOpen1(true)
		} else {
			setStartDate(null)
			setEndDate(null)
			setIsOpen1(false)
			setIsOpen2(false)
		}
	}, [isOpen])

	useEffect(() => {
		const clickOutsideEvent = event => {
			let clickIsInside = false
			let _node = event.target
			for (let i = 0; i < 20; i++) {
				if (
					_node.className === 'react-datepicker' ||
					_node.className === 'date-picker'
				) {
					clickIsInside = true
					break
				}

				if (!_node.parentNode) break
				_node = _node.parentNode
			}

			if (!clickIsInside) {
				setIsOpen1(false)
				setIsOpen2(false)
				setIsOpen(true)
			}
		}

		if (isOpen) {
			window.addEventListener('click', clickOutsideEvent)

			return () => {
				window.removeEventListener('click', clickOutsideEvent)
			}
		}
	}, [isOpen])

	return (
		<div className={`date-picker`} ref={rootRef}>
			<div
				style={{
					position: 'absolute',
					zIndex: 1000,
					left: 0,
					top: 28,
					transition: 'transform 150ms',
					transform: isOpen ? 'translateY(5px)' : 'translateY(0)'
				}}
			>
				<ReactDatePicker
					key={startDate}
					open={isOpen1}
					onChange={date => {
						setStartDate(date.toISOString())
						setIsOpen1(false)
						setIsOpen2(true)
					}}
					monthsShown={2}
					startDate={startDate ? new Date(startDate) : null}
					endDate={endDate ? new Date(startDate) : null}
				/>
				<ReactDatePicker
					key={isOpen2}
					open={isOpen2}
					onChange={date => {
						setIsOpen2(false)
						setIsOpen(false)

						onSelect({
							start_date: startDate,
							end_date: date.toISOString()
						})
					}}
					monthsShown={2}
					startDate={startDate ? new Date(startDate) : null}
					endDate={endDate ? new Date(startDate) : null}
				/>
			</div>
		</div>
	)
}

export default DatePicker
