import { useReducer } from 'react'
import { getLocalstorageJSON } from '~utils/helpers'
import { isBrowser } from '~utils/misc'
import StorageManager from '~utils/StorageManager'
import { TAppAuthState, TFetchingState } from '../appContext.types'
import { TAppAuthAction } from './appAuth.types'

const userId = StorageManager.getStorage('userId')
const token = StorageManager.getStorage(`token-${userId}`)

const initialState: TAppAuthState = {
	isConnected: !!token,
	isLoaded: false,
	loading: 'init',
	user: getLocalstorageJSON(`userData-${userId}`),
	token,
	userId
}

export const useAppAuthReducer = (): [
	TAppAuthState,
	React.Dispatch<TAppAuthAction>
] => {
	const [authState, dispatchAuth] = useReducer(
		//<(a: TAppAuthState, b: TAppAuthAction) => TAppAuthState,TAppAuthState >
		appAuthReducer,
		initialState
	)

	function appAuthReducer(
		state: TAppAuthState,
		action: TAppAuthAction
	): TAppAuthState {
		switch (action.type) {
			case 'RESET_LOADER':
				return {
					...state,
					loading: 'init'
				}
			case `SIGN_IN_FETCHING`:
				console.log('fetching')
				return {
					...state,
					loading: 'fetching'
				}
			case `SIGN_IN_SUCCESS`:
				// console.log('succes---')
				// console.log(`payload`, action.payload)
				return {
					...state,
					...action.payload,
					isConnected: true,
					loading: 'success'
				}

			case `SIGN_IN_FAIL`:
				console.log('fail---')
				return {
					...state,
					loading: 'fail'
				}

			case 'SIGN_IN_DONE':
				return {
					...state,
					isConnected: true
				}

			case 'SIGN_OUT':
				return {
					...state,
					isConnected: false
				}
			case 'SIGN_UP':
				return state
			case 'SET_LOADED':
				return {
					...state,
					isLoaded: true
				}
			default:
				return state
		}
	}

	return [authState, dispatchAuth]
}
