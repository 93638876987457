module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Coq-trotteur Dashboard Activity","short_name":"Dashboard Activities","start_url":"/","background_color":"#172b49","theme_color":"#172b49","display":"standalone","icon":"static/android-chrome-192x192.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0018c079939532b241676dae21d47019"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://8fec04b9306543fc9bc9bf83c2720c55@o930832.ingest.sentry.io/5880340","sampleRate":0.7},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
