const langData = [
  {
    name: 'Allemand',
    value: 'Allemand',
  },
  {
    name: 'Anglais',
    value: 'Anglais',
  },
  {
    name: 'Arabe',
    value: 'Arabe',
  },
  {
    name: 'Chinois',
    value: 'Chinois',
  },
  {
    name: 'Coréen',
    value: 'Coréen',
  },
  {
    name: 'Danois',
    value: 'Danois',
  },
  {
    name: 'Espagnol',
    value: 'Espagnol',
  },
  {
    name: 'Français',
    value: 'Français',
  },
  {
    name: 'Grec',
    value: 'Grec',
  },
  {
    name: 'Italien',
    value: 'Italien',
  },
  {
    name: 'Japonais',
    value: 'Japonais',
  },
  {
    name: 'Néerlandais',
    value: 'Néerlandais',
  },
  {
    name: 'Polonais',
    value: 'Polonais',
  },
  {
    name: 'Portugais',
    value: 'Portugais',
  },
  {
    name: 'Russe',
    value: 'Russe',
  },
  {
    name: 'Tchèque',
    value: 'Tchèque',
  },
]

export { langData }
