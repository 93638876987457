import React from "react";

export const UnderlineIcon = () => {
  return (
    <svg
      width="174"
      height="20"
      viewBox="0 0 174 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          d="M0.123765 11.9504C0.0664934 10.6574 1.0728 9.56361 2.37142 9.50739L17.665 7.40014C66.1807 0.715305 115.259 -0.925361 164.112 2.50441C165.411 2.44818 166.51 3.45078 166.567 4.74379C166.625 6.03679 165.618 7.13055 164.32 7.18678C115.944 3.83549 67.3535 5.42371 19.3003 11.9268L2.57882 14.1898C1.2802 14.246 0.181036 13.2434 0.123765 11.9504Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0.12146"
          y="0.763428"
          width="173.448"
          height="18.4286"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="6" dy="4" />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.8875 0 0 0 0 0.5875 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
