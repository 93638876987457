import React from 'react'

const EyeIcon = ({ color }: { color: 'dark' | 'light' }) => (
	<svg
		width="20"
		height="12"
		viewBox="0 0 20 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M19.858 5.58865C19.6793 5.36065 15.4173 0 10 0C4.58268 0 0.320691 5.36065 0.142024 5.58865C0.0500068 5.70602 0 5.85085 0 5.99999C0 6.14912 0.0500068 6.29395 0.142024 6.41132C0.320691 6.63932 4.58268 12 10 12C15.4173 12 19.6793 6.63932 19.858 6.41132C19.95 6.29395 20 6.14912 20 5.99999C20 5.85085 19.95 5.70602 19.858 5.58865ZM10 9.33331C9.34073 9.33331 8.69627 9.13781 8.1481 8.77154C7.59994 8.40527 7.1727 7.88468 6.92041 7.27559C6.66812 6.66651 6.60211 5.99629 6.73072 5.34969C6.85934 4.70308 7.17681 4.10914 7.64298 3.64297C8.10916 3.17679 8.7031 2.85933 9.3497 2.73071C9.9963 2.60209 10.6665 2.6681 11.2756 2.92039C11.8847 3.17269 12.4053 3.59993 12.7716 4.14809C13.1378 4.69625 13.3333 5.34072 13.3333 5.99999C13.3323 6.88371 12.9807 7.73094 12.3558 8.35583C11.731 8.98072 10.8837 9.33225 10 9.33331Z"
			fill={color === 'dark' ? '#172B49' : 'white'}
		/>
	</svg>
)
export default EyeIcon
