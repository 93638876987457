import React from 'react'
import { withAuth } from '~components/HOC/withAuth'
import AffichettesPage from '~components/pages/AffichettesPage/AffichettesPage'
import DashboardTemplate from '~components/templates/DashboardTemplate'

const affichettes = () => {
	return (
		<DashboardTemplate currentPage="affichettes">
			<AffichettesPage />
		</DashboardTemplate>
	)
}

export default withAuth(affichettes)
