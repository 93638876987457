import moment from 'moment'
import React, { useMemo, useRef } from 'react'
import {
	FormSection,
	FormErrorMessage,
	Select,
	FormContainer,
	FormLine,
	FormGrid,
	Input,
	CheckBox,
	MultipleSelect,
	FormSectionButton
} from '~components/Ticketing/FormToolbox'
import { FicheTimesIcon } from './icons/FicheTimesIcon'
import Button from '~components/basics/Button'
import DatePicker from './DatePicker'
import { FicheScheduleItemDay } from './FicheSheduleItemDay'

export const FicheScheduleItem = ({
	scheduleForm,
	copiedDay,
	setCopiedDay,
	iSchedule,
	same_schedule,
	clearCopy,
	otherPeriodRanges = []
}) => {
	const scheduleDatesListRef = useRef(null)

	const dates = scheduleForm.get('dates').toJSON()

	const allDateArePassed = useMemo(() => {
		const findAfter = dates.find(({ end_date }) => {
			if (!end_date) return true

			if (moment().diff(moment(end_date)) < 0) return true
		})

		return !findAfter
	}, [dates])

	return (
		<>
			<FormContainer>
				<div className="schedule-header">
					<div className="schedule-header-left">
						{!same_schedule && (
							<>
								{allDateArePassed && (
									<div className="color-danger">Période passée</div>
								)}
								<div style={{ display: 'flex' }}>
									<span
										style={{
											marginRight: 8,
											marginBottom: 8,
											paddingTop: 8
										}}
									>
										<div
											style={{
												width: 100
											}}
										>
											Horaires du :
										</div>
									</span>
									<div
										style={{
											paddingRight: 16
										}}
										ref={scheduleDatesListRef}
									>
										{scheduleForm.get('dates').map((date, i) => {
											// console.log('DATE', date.toJSON())
											// console.log(scheduleForm.get('dates').toJSON())
											return (
												<span key={i} className="schedule-date-range">
													<DatePicker
														dateRangeForm={date}
														disabledRanges={[
															...scheduleForm
																.get('dates')
																.toJSON()
																.map(({ start_date, end_date }) => ({
																	start_date: moment(start_date).year(
																		moment().year()
																	),
																	end_date: moment(end_date).year(
																		moment().year()
																	)
																}))
																.filter((_, _i) => _i !== i),
															...otherPeriodRanges
														]}
													/>
													{date.canBeRemoved() && (
														<button
															type="button"
															onClick={() => {
																date.remove()
															}}
															className="remove-button"
														>
															<FicheTimesIcon
																style={{
																	width: 12
																}}
															/>
														</button>
													)}
												</span>
											)
										})}

										<Button
											className="add-period"
											onClick={() => {
												scheduleForm.get('dates').push()

												setTimeout(() => {
													;[
														...scheduleDatesListRef.current.querySelectorAll(
															'.schedule-date-range'
														)
													]
														.slice(-1)[0]
														.querySelector('.open-button')
														.click()
												}, 150)
											}}
										>
											+ Ajouter période avec les mêmes horaires
										</Button>
									</div>
								</div>
							</>
						)}
					</div>
					{scheduleForm.canBeRemoved() && (
						<div>
							<Button
								className="delete-schedule"
								onClick={() => {
									scheduleForm.remove()
								}}
							>
								<FicheTimesIcon style={{ marginRight: 8 }} />
								Supprimer la période
							</Button>
						</div>
					)}
				</div>
			</FormContainer>

			<FormSection>
				{scheduleForm.get('days').map((day, i) => (
					<FicheScheduleItemDay
						dayForm={day}
						i={i}
						iSchedule={iSchedule}
						key={i}
						handleCopy={setCopiedDay}
						copiedDay={copiedDay}
						// same_schedule={same_schedule}
						clearCopy={clearCopy}
					/>
				))}
			</FormSection>
		</>
	)
}
