import {
	TPeriod,
	TServiceAvailabilityScheduleOpeningTimeDay,
	TServiceAvailabilityScheduleWeekSchedule
} from 'coq-types'

import moment from 'moment'
// Make weekday starts monday.
import 'moment/locale/fr'

export const dateIsInPeriod = (
	date: string | Date,
	{ start_date, end_date }: TPeriod
) =>
	moment(date)
		.hour(15)
		.isBetween(moment(start_date).hour(10), moment(end_date).hour(20))

export const dateIsInPeriods = (date: string | Date, periods: TPeriod[]) => {
	for (const period of periods) {
		if (dateIsInPeriod(date, period)) {
			return true
		}
	}

	return false
}

export const dayIsOpenInWeekSchedule = (
	date: string | Date,
	weekSchedule: TServiceAvailabilityScheduleWeekSchedule
) => {
	if (!dateIsInPeriods(date, weekSchedule.periods)) return false

	return (weekSchedule.days[
		moment(date).weekday()
	] as TServiceAvailabilityScheduleOpeningTimeDay).open
}
