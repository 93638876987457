import { getActivities } from '../Common/ticketingCommonQueries'

const getQueryOptions = token => {
	const headers = new Headers()
	headers.append('x-access-token', token)

	return {
		method: 'GET',
		headers,
		redirect: 'follow'
	}
}

const getPostQueryOptions = (activity, token) => {
	const headers = new Headers()
	headers.append('x-access-token', token)
	headers.append('Content-Type', 'application/json')
	headers.append('Accept', 'application/json, text/plain, */*')

	return {
		method: 'POST',
		headers,
		body: JSON.stringify(activity),
		redirect: 'follow'
	}
}

export const getActivity = async (id, token, api) => {
	const activity = await fetch(
		`${api}/activities/${id}`,
		getQueryOptions(token)
	).then(data => data.json())

	return activity
}

export const getSchedulesPageData = async (activitiesId, token, api) => {
	const activities = await getActivities(activitiesId, token, api)
	return {
		activities,
		activitiesNames: activities.reduce((acc, cur) => {
			acc[cur._id] = {
				title: cur.title
			}

			return acc
		}, {})
	}
}

export const createNewSchedule = async (data, token, api) => {
	const result = await fetch(
		`${api}/schedules`,
		getPostQueryOptions(data, token)
	).then(data => {
		return data.json()
	})

	return result
}

export const updateSchedule = async (data, _id, token, api) => {
	return await fetch(
		`${api}/schedules/${_id}`,
		getPostQueryOptions(data, token)
	).then(data => {
		return data.json()
	})
}

export const saveSchedulesData = async (activity, activityID, token, api) => {
	const schedules_id = []
	const schedules = activity.schedules.schedules_table

	for (let i = 0; i < schedules.length; i++) {
		const schedule = schedules[i]

		// TODO useless, now they are generated before.
		if (!schedule._id || schedule._id.length === 0) {
			delete schedule._id
			const returnedData = await createNewSchedule(schedule, token, api)

			schedules_id.push(returnedData._id)
		} else {
			const _id = schedule._id
			delete schedule._id

			await updateSchedule(schedule, _id, token, api)
			schedules_id.push(_id)
		}
	}

	activity.schedules.schedules_table = schedules_id.filter(e => e !== null)

	activity.schedules.enabled = true // Because we don't save when disabled. Just call saveSchedulesEnabled

	delete activity._id

	await fetch(
		`${api}/activities/step/${activityID}`,
		getPostQueryOptions(activity, token)
	).then(data => data.json())
}

export const saveSchedulesEnabled = async (activityID, enabled, token, api) => {
	await fetch(
		`${api}/activities/step/${activityID}`,
		getPostQueryOptions(
			{
				'schedules.enabled': enabled
			},
			token
		)
	)
		.then(data => data.json())
		.then(data => {})
}
