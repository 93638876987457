import React from 'react'

import { UserDataProvider } from '~contexts/userDataContext'
import { PricingsProvider } from '~contexts/pricingsContext'
// import { BookingProvider } from '~contexts/bookingContext'

import {
	HorairesContextProvider,
	useHorairesContextSubscriber
} from '~horairesMod/context/horaires'

import 'typeface-montserrat'

import { CurrentFormStateProvider } from '~contexts/currentFormContext'

import { AppContextProvider } from './src/contexts/appContext/appContext'

import './src/style/global.scss'

const Contexts = ({ element }) => {
	const horairesContext = useHorairesContextSubscriber()

	return (
		<UserDataProvider>
			<AppContextProvider>
				<PricingsProvider>
					<HorairesContextProvider value={horairesContext}>
						<CurrentFormStateProvider>
							{/* <BookingProvider> */}
							{element}
							{/* </BookingProvider> */}
						</CurrentFormStateProvider>
					</HorairesContextProvider>
				</PricingsProvider>
			</AppContextProvider>
		</UserDataProvider>
	)
}

const Wrapper = ({ element }) => {
	return <Contexts element={element} />
}

export const wrapRootElement = Wrapper
