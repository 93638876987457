import React from 'react'

import { useUserData } from '~contexts/userDataContext'
import { FormSection } from '../FormToolbox'

const ActivitiesLinks = ({ activitiesNames }) => {
	const { activitiesId, shareLink } = useUserData()
	// console.log(`shareLink`, shareLink)
	return (
		<FormSection style={{ marginBottom: 24 }}>
			<div style={{ fontWeight: 700, marginBottom: 8 }}>
				Voici la liste des activités concernées par le formulaire et mises en
				avant par coq-trotteur :
			</div>
			{Object.values(shareLink).map(({ url, title }, i) => (
				<div key={i}>
					-{' '}
					<a href={url} target="_blank">
						{title}
					</a>
				</div>
			))}
		</FormSection>
	)
}

export default ActivitiesLinks
