import React from 'react'
import { FormSection } from '~components/Ticketing/FormToolbox'

import './EmptyPricingSchedule.scss'

const EmptyPricingSchedule = ({}) => {
	return (
		<div className="empty-pricing-schedule">
			<FormSection className="body">
				<h2>Aucun créneau à renseigner</h2>
				<p>Vous n’avez aucune prestation commecialisée sur des créneaux</p>
			</FormSection>
		</div>
	)
}
export default EmptyPricingSchedule
