import React from 'react'
import { FormLine, MultipleSelect } from '~components/Ticketing/FormToolbox'

const InfosHealth = ({ restrictionsForm }) => {
	const nRestrictions = restrictionsForm.toJSON().filter(
		value => ['illness', 'pregnant', 'cardiac'].includes(value) // TODO why ?
	).length

	return (
		<FormLine>
			<div>
				Restriction de santé : votre activité est-elle déconseillée à certains
				voyageurs ?
			</div>
			<div>
				<MultipleSelect
					dropdownStyle={{
						width: 360
					}}
					style={{
						width: 180
					}}
					dropdownVerticalPosition="top"
					list={restrictionsForm
						.map(item => item.value)
						.filter(
							value => ['illness', 'pregnant', 'cardiac'].includes(value) // TODO why ?
						)}
					setValue={v => {
						restrictionsForm.set(v)
					}}
					label={
						nRestrictions === 0
							? 'Aucune restriction'
							: `${nRestrictions} restriction${nRestrictions > 1 ? 's' : ''}`
					}
					items={[
						{
							name: 'Déconseillée aux voyageurs avec des problèmes de dos',
							value: 'illness'
						},
						{
							name: 'Déconseillée aux femmes enceintes',
							value: 'pregnant'
						},
						{
							name:
								'Déconseillée aux voyageurs ayant un problème cardiaque ou autre problème médical',
							value: 'cardiac'
						}
					]}
				/>
			</div>
		</FormLine>
	)
}

export default InfosHealth
