import React, { useEffect, useState } from 'react'
import Button from '~components/basics/Button'
import Spinner from '~components/basics/Icons/Spinner'
import Modal from '~components/basics/Modal/Modal'
import { useUserData } from '~contexts/userDataContext'
import { sendAssistanceMail } from '~requests/mails/sendAssistanceMail'

import { DecoratedH2 } from '../ReservationsEmpty'

import './ContactModal.scss'

const ContactModal = ({ payload, ...props }) => {
	const { token, userData } = useUserData()
	const [message, setMessage] = useState('')
	const [isSending, setIsSending] = useState(false)

	useEffect(() => {
		if (!props.isOpen) {
			setMessage('')
		}
	}, [props.isOpen])

	const handleSendMail = () => {
		if (isSending) return
		setIsSending(true)

		const email = userData.administration.mail || '',
			name = userData.title,
			phone = userData.administration.phone || '',
			subject =
				payload.type === 'suggestion'
					? "Proposition d'idée"
					: "Demande d'assistance"

		sendAssistanceMail({
			token,
			message,
			email,
			name,
			phone,
			subject
		}).then(() => {
			setIsSending(false)
			props.close()
		})
	}

	return (
		<Modal
			className="ContactModal"
			{...props}
			allowQuitOnClickOverlay={true}
			showCloseButton={true}
		>
			<DecoratedH2>{payload?.title || 'Contactez-nous'}</DecoratedH2>

			<p className="contact-line">
				Vous pouvez aussi appeler directement
				<br />
				Marie au <a href="tel:0650394129">06 50 39 41 29</a>
			</p>

			<div className="form-section">
				<textarea
					className="Textarea"
					placeholder="Votre message"
					value={message}
					onChange={e => {
						setMessage(e.target.value)
					}}
				></textarea>
			</div>

			<div className="actions">
				<Button
					theme="light"
					outline={'normal'}
					onClick={() => {
						props.close()
					}}
				>
					Retour
				</Button>
				<Button theme="yellow" onClick={handleSendMail}>
					{isSending ? <Spinner color="dark" /> : 'Valider'}
				</Button>
			</div>
		</Modal>
	)
}
export default ContactModal
