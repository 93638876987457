import React from 'react'

import './Dropdown.scss'

const Dropdown = ({ children, style = {} }) => {
	return (
		<div className="Dropdown" style={style}>
			{children}
		</div>
	)
}
export default Dropdown
