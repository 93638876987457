export const getAllReservationTickets = async (apiUrl, token, activityId) => {
  const myHeaders = new Headers()
  myHeaders.append('x-access-token', token)

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  }

  let res = await fetch(process.env.GATSBY_APIV2_URL + '/bills/provider', requestOptions)
  let data = await res.json()
  return data
}

export const updateReservation = async (data, apiUrl, token, resaId) => {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('accept', 'application/json')
  myHeaders.append('x-access-token', token)

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: 'follow',
  }

  let res = await fetch(apiUrl + '/reservations/' + resaId, requestOptions)
  let resp = await res.json()
  return resp
}