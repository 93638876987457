import { MutableRefObject } from 'react'
import { useEffect, useRef, useState } from 'react'

const useHorizontalScroll = (
	defaultShowFoward = false
): [any, any, () => void, () => void, boolean, boolean] => {
	const containerRef = useRef<HTMLElement>(null)
	const contentRef = useRef<HTMLElement>(null)
	const [showBack, setShowBack] = useState(false)
	const [showFoward, setshowFoward] = useState(defaultShowFoward)

	useEffect(() => {
		if (defaultShowFoward) setshowFoward(true)
	}, [defaultShowFoward])

	const onFowardClick = () => {
		sideScroll(contentRef.current, 'right', 25, 200)
	}

	const onBackClick = () => {
		sideScroll(contentRef.current, 'left', 25, 200)
	}

	useEffect(() => {
		if (
			!showFoward &&
			containerRef.current?.offsetWidth < contentRef.current?.scrollWidth
		) {
			setshowFoward(true)
		}
	}, [containerRef.current?.offsetWidth])

	function sideScroll(element, direction, speed, distance) {
		const step = 10
		let scrollAmount = 0
		var slideTimer = setInterval(function() {
			if (direction == 'left') {
				element.scrollLeft -= step
				if (showBack && element.scrollLeft === 0) {
					setShowBack(false)
				}
				if (
					!showFoward &&
					element.scrollWidth > element.scrollLeft + element.offsetWidth
				) {
					setshowFoward(true)
				}
			} else {
				if (!showBack && element.scrollLeft > 0) {
					setShowBack(true)
				}
				element.scrollLeft += step
				if (
					showFoward &&
					element.scrollWidth === element.scrollLeft + element.offsetWidth
				) {
					setshowFoward(false)
				}
			}
			scrollAmount += step
			if (scrollAmount >= distance) {
				window.clearInterval(slideTimer)
			}
		}, speed)
	}

	return [
		containerRef,
		contentRef,
		onFowardClick,
		onBackClick,
		showBack,
		showFoward
	]
}

export default useHorizontalScroll
