import React from 'react'
import CGUV from '~components/pages/CGUV/CGUV'
import Header from '~components/pages/CGUV/Layout/Header'

const CGUVPage = () => {
	return (
		<div className="cguv">
			<Header />
			<CGUV />
		</div>
	)
}

const CGUVPageContainer = props => {
	return (
		// <LocaleContextProvider
		// 	translations={props.pageContext.translations}
		// 	lang={props.pageContext.lang}
		// >
		<CGUVPage {...props} />
		// </LocaleContextProvider>
	)
}

export default CGUVPageContainer
