import React from 'react'

export const SadIcon = () => {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15 30C12.0333 30 9.13319 29.1203 6.66645 27.472C4.19971 25.8238 2.27713 23.4811 1.14181 20.7403C0.00649941 17.9994 -0.290551 14.9834 0.288227 12.0736C0.867006 9.16393 2.29562 6.49119 4.3934 4.3934C6.49119 2.29562 9.16393 0.867006 12.0736 0.288227C14.9834 -0.290551 17.9994 0.00649941 20.7403 1.14181C23.4811 2.27713 25.8238 4.19972 27.472 6.66645C29.1203 9.13319 30 12.0333 30 15C30 18.9783 28.4196 22.7936 25.6066 25.6066C22.7936 28.4196 18.9782 30 15 30ZM15 2.00001C12.4288 2.00001 9.91543 2.76244 7.77759 4.1909C5.63975 5.61936 3.97351 7.64968 2.98957 10.0251C2.00563 12.4006 1.74819 15.0144 2.2498 17.5362C2.75141 20.0579 3.98953 22.3743 5.80762 24.1924C7.6257 26.0105 9.94208 27.2486 12.4638 27.7502C14.9856 28.2518 17.5994 27.9944 19.9749 27.0104C22.3503 26.0265 24.3806 24.3603 25.8091 22.2224C27.2376 20.0846 28 17.5712 28 15C28 11.5522 26.6304 8.24559 24.1924 5.80762C21.7544 3.36964 18.4478 2.00001 15 2.00001Z"
				fill="#172B49"
			/>
			<path
				d="M8.78119 13.4498C9.64275 13.4498 10.3412 12.7514 10.3412 11.8898C10.3412 11.0283 9.64275 10.3298 8.78119 10.3298C7.91963 10.3298 7.22119 11.0283 7.22119 11.8898C7.22119 12.7514 7.91963 13.4498 8.78119 13.4498Z"
				fill="#172B49"
			/>
			<path
				d="M21.2191 13.4498C22.0807 13.4498 22.7791 12.7514 22.7791 11.8898C22.7791 11.0283 22.0807 10.3298 21.2191 10.3298C20.3576 10.3298 19.6591 11.0283 19.6591 11.8898C19.6591 12.7514 20.3576 13.4498 21.2191 13.4498Z"
				fill="#172B49"
			/>
			<path
				d="M20.5007 21.4399C20.369 21.4406 20.2386 21.4154 20.1167 21.3656C19.9949 21.3159 19.8841 21.2426 19.7907 21.1499C18.5195 19.8809 16.7968 19.1682 15.0007 19.1682C13.2045 19.1682 11.4818 19.8809 10.2107 21.1499C10.1177 21.2436 10.0071 21.318 9.88525 21.3688C9.76339 21.4195 9.63268 21.4457 9.50067 21.4457C9.36866 21.4457 9.23795 21.4195 9.11609 21.3688C8.99423 21.318 8.88363 21.2436 8.79067 21.1499C8.60442 20.9625 8.49988 20.7091 8.49988 20.4449C8.49988 20.1807 8.60442 19.9272 8.79067 19.7399C10.44 18.0972 12.6729 17.1749 15.0007 17.1749C17.3284 17.1749 19.5614 18.0972 21.2107 19.7399C21.3969 19.9272 21.5014 20.1807 21.5014 20.4449C21.5014 20.7091 21.3969 20.9625 21.2107 21.1499C21.1172 21.2426 21.0064 21.3159 20.8846 21.3656C20.7627 21.4154 20.6323 21.4406 20.5007 21.4399Z"
				fill="#172B49"
			/>
		</svg>
	)
}
