import React from 'react'

import './BurgerIcon.scss'

const BurgerIcon = ({ open }: { open: boolean }) => {
	return (
		<div className={`BurgerIcon ${open ? 'active' : ''}`}>
			<div className="bar" />
			<div className="middle-bar">
				<div className="bar" />
				<div className="bar" />
			</div>
			<div className="bar" />
		</div>
	)
}
export default BurgerIcon
