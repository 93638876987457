import React, { useEffect, useRef } from 'react'
import Button from '~components/basics/Button'
import Modal from '~components/basics/Modal/Modal'

import './WebsiteChangeNameModal.scss'

const WebsiteChangeNameModal: TWebsiteChangeNameModal = ({
	payload,
	onChange,
	...modalProps
}) => {
	const inputRef = useRef<HTMLInputElement>()

	useEffect(() => {
		if (modalProps.isOpen) {
			inputRef.current.value = payload.name
			inputRef.current.focus()

			return () => {
				inputRef.current.value = ''
			}
		}
	}, [modalProps.isOpen])

	return (
		<Modal {...modalProps} className="WebsiteChangeNameModal">
			<input defaultValue={payload?.name} ref={inputRef} />

			<div className="buttons">
				<Button
					theme="blue"
					onClick={() => {
						onChange(inputRef.current.value)
					}}
				>
					Changer
				</Button>
				<Button
					onClick={() => {
						modalProps.close()
					}}
				>
					Annuler
				</Button>
			</div>
		</Modal>
	)
}

export default WebsiteChangeNameModal

type TWebsiteChangeNameModal = React.FC<any>
