import { useEffect, useState } from 'react'
import { TUseQueryProps, TUseQueryReturns } from './useQuery.types'

function useQuery<T>({ query }: TUseQueryProps<T>): TUseQueryReturns<T> {
	const [data, setData] = useState<T>(null)
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [error, setError] = useState<any>(null)

	const callQry = () => {
		query()
			.then(result => {
				setData(result)
			})
			.catch(err => {
				setError(err)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}
	const refresh = () => {
		setIsLoading(true)
		setError(null)
		callQry()
	}

	useEffect(() => {
		callQry()
	}, [])

	return [data, isLoading, error, refresh]
}

export default useQuery
