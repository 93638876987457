import React from 'react'
import { navigate } from 'gatsby'

import Alert from './Alert'
import Button from '../Button'
import Tick from '~svg/valid.svg'
import { useUserData } from '~contexts/userDataContext'

import './SuccessAlert.scss'

const SuccessAlert = props => {
	const { dispatch, setOnboarding, userId } = useUserData()

	const handleClick = () => {
		dispatch({ type: 'refreshData' })
		navigate('/mouvements')
		setTimeout(() => {
			setOnboarding(false)
		}, 500)
		localStorage.setItem(`onboarding-${userId}`, false)
	}

	return (
		<Alert {...props} className="success-alert">
			<div>
				<div className="circle-illustration">
					<Tick />
				</div>
			</div>

			<h2 className="decorated">
				<span>C'est parfait !</span>
			</h2>

			<p>
				Votre billetterie sera activée d’ici quelques
				<br />
				jours, lors du lancement de la plateforme.
			</p>

			<Button theme="yellow" rounded={true} onClick={handleClick}>
				C'est compris
			</Button>
		</Alert>
	)
}
export default SuccessAlert
