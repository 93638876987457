import React, { useState, useEffect } from 'react'
import { useForm, ErrorMessage } from 'react-hook-form'
import { navigate, Link } from 'gatsby'

import { useUserData } from '~contexts/userDataContext'
import Button from '~components/basics/Button'
import MessageBar from '~components/basics/MessageBar'
import { getData } from '~requests/get'

import { useBooking } from '~contexts/bookingContext'

import BigLogo from '~svg/bigLogo.svg'
import { getAllReservationTickets } from '~requests/booking'
import { useAppContext } from '~contexts/appContext/appContext'

// TODO Make a loader waiting for response 'cause need avoid the login form to render

export const logout = () => {
	localStorage.clear()
	sessionStorage.clear()
	document.location.href = '/'
}

export const withAuth = WrappedComponent => props => {
	const { setBookingData } = useBooking()
	const [, setIsLoaded] = useState(false)

	const appContext = useAppContext()
	const {
		signIn,
		isConnected,
		token,
		loading,
		isLoaded,
		resetLoading
	} = appContext.auth
	// Context
	const {
		activityId,
		userId,
		setLogged,
		apiUrl,
		dispatch,
		tempMail,
		tempPwd
	} = useUserData()

	// Lifecycle
	useEffect(() => {
		if (typeof window.Storage === 'undefined') {
			alert(
				"Votre version du navigateur n'autorise pas le stockage local d'informations, (ce qui est nécéssaire au fonctionnement de l'application) veuillez l'activer: Options -> Avancés -> Sécurité"
			)
		}

		if (isConnected && token) {
			getData(apiUrl, token, activityId)
				.then(result => {
					dispatch({ type: 'get', payload: result })
					dispatch({ type: 'makeShareLink' })

					localStorage.setItem(`userData-${userId}`, JSON.stringify(result))
					setLogged(true)
					setIsLoaded(true)
				})
				.catch(error => {
					console.log('error', error)
					logout()
				})
		}
		setIsLoaded(true)
	}, [])

	// React hook form
	const { register, handleSubmit, errors } = useForm()

	// ***** Events handler *****

	// localStorage.setItem("userId", result.user._id)
	// localStorage.setItem("temp_mail", result.user.temp_mail)
	// localStorage.setItem("pricings", JSON.stringify([pricingsObject]))

	const onSubmit = ({ username, password }) => {
		signIn(username, password)
	}

	useEffect(() => {
		if (appContext.auth?.user?.temp_pwd) {
			navigate('/complete')
		} else if (tempMail === false) {
			setLogged(true)
		}
	}, [appContext.auth?.user?.temp_pwd, tempMail])

	useEffect(() => {
		if (token && activityId) {
			getAllReservationTickets(apiUrl, token, activityId)
				.then(res => {
					if (res.length) {
						setBookingData?.({ type: 'getAll', payload: res })
					}
				})
				.catch(err => {
					console.log(err)
				})
		}
	}, [activityId])

	if (!isLoaded) return null

	if (isConnected)
		return <WrappedComponent data={{ token, userId, activityId }} {...props} />

	return (
		<div className="login">
			<MessageBar
				type="danger"
				trigger={loading === 'fail'}
				onClose={resetLoading}
				message="Vos identifiants sont incorrects."
			/>

			<div className="logoWrapper">
				<BigLogo style={{ height: '92px' }} />
				<h1>Bienvenue sur coq-trotteur !</h1>
			</div>

			<form className="form signin" onSubmit={handleSubmit(onSubmit)}>
				<div className="join">
					<div className="input-group">
						<input
							placeholder="Email"
							name="username"
							defaultValue=""
							ref={register({ required: true })}
							className={errors.username && 'has-error'}
						/>
						<ErrorMessage
							className="has-error info-inner"
							as="small"
							errors={errors}
							name="username"
							message="Ce champs est requis"
						/>
					</div>
					<hr />
					<div className="input-group">
						<input
							type="password"
							placeholder="Mot de passe"
							name="password"
							defaultValue=""
							ref={register({ required: true })}
							className={errors.password && 'has-error'}
						/>
						<ErrorMessage
							className="has-error info-inner"
							as="small"
							errors={errors}
							name="password"
							message="Ce champs est requis"
						/>
					</div>
				</div>

				<div>
					<Button type="submit" theme="blue" size="large">
						Connexion
					</Button>
				</div>
				<small>
					<Link to="/oubli-mdp">Mot de passe oublié ?</Link>
				</small>
			</form>
		</div>
	)
}
