import React, { useState, useEffect } from 'react'
import useForm from 'react-super-useform'

import {
	FormSection,
	FormErrorMessage
} from '~components/Ticketing/FormToolbox'
import { useUserData } from '~contexts/userDataContext'

import 'moment/locale/fr'

// Form schema
import schedulesFormSchema from '../../schedulesFormSchema'

// API Queries deps
import Button from '~components/basics/Button'

import './Schedules.scss'
import { useFormHandler } from '~contexts/currentFormContext'
import { FicheClosePeriods } from '../../components/FicheClosePeriods'
import { FicheOpeningTime } from '../../components/FicheOpeningTime'

const Schedules: React.FC<any> = ({
	schedules,
	handleSave,
	activityId,
	enabled
}) => {
	const [errorNotification] = useState(null)
	console.log(`schedules`, schedules)
	const form = useForm(schedulesFormSchema, schedules)

	const schedulesEnabled = form.get('root.enabled').value === true

	useEffect(() => {
		form.get('root.enabled').setValue(enabled)
	}, [enabled])

	const hasScheduleWithoutActivities =
		form
			.toJSON()
			.root.schedules.filter(({ activities }) => activities.length === 0)
			.length > 0

	useFormHandler(
		schedulesEnabled
			? {
					...form,
					disableForcedSave: hasScheduleWithoutActivities
			  }
			: {
					modified: false,
					isValid: true,
					save: () => true
			  },
		schedulesEnabled ? handleSave : () => true
	)

	const beforeUnload = e => {
		var e = e || window.event

		if (e) {
			e.returnValue = 'Des données ne sont pas sauvegardées'
		}

		return 'Des données ne sont pas sauvegardées'
	}

	useEffect(() => {
		if (form.modified && !schedulesEnabled) {
			window.addEventListener('beforeunload', beforeUnload)

			return () => {
				window.removeEventListener('beforeunload', beforeUnload)
			}
		}
	}, [form.modified, schedulesEnabled])

	// form.logErrors()

	const getTakenActivities = () => {
		const json = form.get('root.schedules').toJSON()

		const activities = new Set()

		json.forEach(schedules => {
			schedules.activities.forEach(id => {
				activities.add(id)
			})
		})

		return activities
	}
	const takenActivities = getTakenActivities()

	return (
		<form id="ticketing-4" className="schedules-form">
			<FormErrorMessage show={errorNotification} />

			{form.get('root.schedules').map((schedulesForm, _, id) => {
				const activities = schedulesForm.get('activities').toJSON()

				// if (activities.includes(activityId))
					return (
						<FormSection key={id}>
							{/* {schedulesForm.canBeRemoved() && (
								<div
									style={{
										textAlign: 'right',
										paddingBottom: 20
									}}
								>
									<Button
										onClick={() => {
											schedulesForm.remove()
										}}
										theme="primary"
										className="delete-prestation-button"
										style={{
											textAlign: 'center'
										}}
									>
										<img
											src="/icons/delete-white.svg"
											style={{
												width: 16
											}}
										/>
									</Button>
								</div>
							)} */}

							<FicheClosePeriods
								closePeriodsForm={schedulesForm.get('closed_periods')}
							/>
							<hr />
							<FicheOpeningTime schedulesForm={schedulesForm} />
						</FormSection>
					)
			})}
		</form>
	)
}

export default Schedules
