import { Router } from '@reach/router'
import React, { useEffect, useState } from 'react'
import { withAuth } from '~components/HOC/withAuth'
import './apidae.scss'

const Apidae = () => {
	return (
		<Router>
			<ScheduleContainer path="/fiche/apidae/:id/*" />
		</Router>
	)
}

export default withAuth(Apidae)

const ScheduleContainer: React.FC<any> = ({ id }) => {
	const [url, setUrl] = useState('')
	useEffect(() => {
		fetch(process.env.GATSBY_APIV2_URL + '/fetch-form-url/' + id)
			.then(res => {
				return res.json()
			})
			.then(res => {
				setUrl(res.url)
			})
	}, [])
	return (
		<div className="Apidae">
			<div className="warning">
				<Icon /> <div>
					<span>Pensez à enregistrer vos modifications tout en bas de la page, sinon elles seront perdues.</span>
					<div>Vos modifications devraient être effectives d’ici 48h</div>
				</div>
			</div>
			{url && <iframe src={url} />}
		</div>
	)
}

const Icon = () => {
	return (
		<svg
			width="38"
			height="34"
			viewBox="0 0 38 34"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.8989 0.000244141C16.2292 0.000244141 13.7527 1.3517 12.3316 3.71517L1.10435 22.387C0.376206 23.5981 -0.00562205 24.9728 6.25588e-05 26.3627C0.010915 29.0262 1.42476 31.5192 3.69646 32.8977C4.88476 33.6188 6.25962 33.9999 7.67229 33.9999H30.1268C31.5396 33.9999 32.9145 33.6188 34.1027 32.8978C36.3753 31.5188 37.788 29.0251 37.7988 26.3625C37.8045 24.9725 37.4227 23.5978 36.6947 22.387L25.4675 3.71517C24.0689 1.38906 21.6134 0.000244141 18.8989 0.000244141Z"
				fill="#FF8188"
			/>
			<path
				d="M3.84079 26.3625C3.8351 24.9726 4.21693 23.5978 4.94508 22.3867L16.1724 3.715C17.2444 1.93217 18.917 0.725636 20.82 0.240156C20.2025 0.0826852 19.5585 0 18.899 0C16.2293 0 13.7528 1.35146 12.3317 3.71493L1.10435 22.3868C0.376206 23.5979 -0.00562205 24.9726 6.25588e-05 26.3625C0.010915 29.026 1.42476 31.5191 3.69646 32.8975C4.88484 33.6187 6.25963 33.9998 7.67236 33.9998H11.5131C10.1004 33.9998 8.72556 33.6186 7.53726 32.8975C5.26556 31.5191 3.85172 29.026 3.84079 26.3625Z"
				fill="#FF656F"
			/>
			<path
				d="M18.8996 3.18201C20.3852 3.18193 21.8705 3.90676 22.7409 5.35449L33.9681 24.0263C35.7659 27.0162 33.6178 30.817 30.1275 30.817H7.67307C4.18494 30.817 2.03372 27.018 3.83234 24.0263L15.0597 5.35449C15.931 3.90558 17.4154 3.18208 18.8996 3.18201Z"
				fill="#FF656F"
			/>
			<path
				d="M7.67308 24.0264L18.9005 5.35466C19.3855 4.54796 20.061 3.9668 20.8203 3.60956C20.2157 3.32518 19.5578 3.18218 18.8995 3.18225C17.4153 3.18232 15.9309 3.90582 15.0597 5.35473L3.83235 24.0264C2.03373 27.0182 4.18487 30.8172 7.67308 30.8172H11.5138C8.0256 30.8172 5.87446 27.0182 7.67308 24.0264Z"
				fill="#FF4756"
			/>
			<path
				d="M18.8996 10.0883C17.1842 10.0883 16.8673 11.6908 16.9625 13.3416L17.2243 19.1838C17.5956 21.0092 20.2046 21.0048 20.575 19.1838L20.8368 13.3416C20.9321 11.5606 20.6156 10.0883 18.8996 10.0883Z"
				fill="#FFEDBD"
			/>
			<path
				d="M18.8991 26.5785C19.9391 26.5785 20.7821 25.7355 20.7821 24.6955C20.7821 23.6555 19.9391 22.8125 18.8991 22.8125C17.8592 22.8125 17.0161 23.6555 17.0161 24.6955C17.0161 25.7355 17.8592 26.5785 18.8991 26.5785Z"
				fill="#FFEDBD"
			/>
			<path
				d="M19.2527 19.1838L18.9909 13.3416C18.9184 12.0835 19.086 10.8542 19.929 10.3402C19.6611 10.1783 19.3231 10.0883 18.8996 10.0883C17.1841 10.0883 16.8673 11.6908 16.9625 13.3416L17.2243 19.1837C17.4994 20.5361 19.002 20.883 19.9133 20.2321C19.5952 20.0047 19.3488 19.656 19.2527 19.1838Z"
				fill="#FFEDBD"
			/>
			<path
				d="M19.0446 24.696C19.0446 24.0296 19.391 23.4446 19.9133 23.1098C19.6205 22.9222 19.2727 22.813 18.8991 22.813C17.8591 22.813 17.0161 23.656 17.0161 24.696C17.0161 25.736 17.8591 26.579 18.8991 26.579C19.2727 26.579 19.6205 26.4697 19.9133 26.2821C19.391 25.9474 19.0446 25.3623 19.0446 24.696Z"
				fill="#FFEDBD"
			/>
		</svg>
	)
}
