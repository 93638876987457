import React, { useState, useReducer } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// import { useUserData } from '~contexts/userDataContext'
import { bookingObject } from '~contexts/bookingObject'
import { getLocalstorageJSON } from '~utils/helpers'

const bookingContext = React.createContext({})

export const useBooking = () =>
	React.useContext<TBookingContext>(bookingContext)

type TBookingContext = {
	bookingData: any
	setBookingData: any
	ribInfos: any
	setRibInfos: any
	apiUrl: any
	emptyBooking: any
}

/*
 * Called in pages/booking.js
 */
export const BookingProvider = ({ children }) => {
	// ***** Query Consts
	const data = useStaticQuery(graphql`
		{
			site {
				siteMetadata {
					apiUrl
				}
			}
		}
	`)
	const { apiUrl } = data.site.siteMetadata

	// ***** Context *****
	// const { token, activityId, userData } = useUserData()

	const [ribInfos, setRibInfos] = useState(ribObject)
	const [emptyBooking, setEmptyBooking] = useState(true)

	// ***** Reducer *****
	const _id = localStorage.getItem('userId')

	const initialState = getLocalstorageJSON(`booking-${_id}`) || bookingObject

	const reducer = (state, { payload, type }) => {
		let newState = []
		let newArr = []

		switch (type) {
			case 'getLocal':
				if (localStorage.getItem(`booking-${_id}`)) {
					try {
						newState = getLocalstorageJSON(`booking-${_id}`)
						newState = newState.sort((a, b) =>
							a.type > b.type ? 1 : b.type > a.type ? -1 : 0
						)
					} catch {}
				} else {
					// localStorage.setItem(`booking-${_id}`, JSON.stringify(newState))
					localStorage.removeItem(`booking-${_id}`)
				}
				return newState

			case 'getAll':
				newState = JSON.parse(JSON.stringify(payload))
				let oneIsPayed = false
				newState.forEach(item => {
					if (item.reservation.state.status === 'payed' || item.reservation.state.status === 'done') {
						oneIsPayed = true
					}
				})
				if (oneIsPayed) {
					setEmptyBooking(false)
				}

				// localStorage.setItem(`booking-${_id}`, JSON.stringify(newState))
				localStorage.removeItem(`booking-${_id}`)
				return newState

			case 'validate':
				let { index, item, newStatus } = payload
				newState = JSON.parse(JSON.stringify(state))
				newState[index].items[item].state.status = newStatus
				// localStorage.setItem(`booking-${_id}`, JSON.stringify(newState))
				localStorage.removeItem(`booking-${_id}`)
				return newState

			case 'reset':
				setEmptyBooking(true)
				setRibInfos(ribObject)
				return bookingObject

			default:
				break
		}
	}

	const [bookingData, setBookingData] = useReducer(reducer, initialState)

	return (
		<bookingContext.Provider
			value={{
				bookingData,
				setBookingData,
				ribInfos,
				setRibInfos,
				apiUrl,
				emptyBooking
			}}
		>
			{children}
		</bookingContext.Provider>
	)
}

const ribObject = {
	rib: {
		name: '',
		bank: '',
		iban: ''
	},
	account: {
		name: '',
		siren: '',
		tva: '',
		address: ''
	},
	billing: {
		enabled: null,
		name: '',
		address: ''
	}
}
