import React from 'react'

import './Cgu.scss'

const Cgu = ({ closer, display }) => {
  return (
    <div className={`cgu ${display ? 'displayed' : 'hidden'}`}>
      <div className="closer" onClick={closer}>
        ×
      </div>
      <p>
        <span>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quia
          repellat laboriosam, aut nihil deserunt culpa magnam, necessitatibus
          quod, facere magni odio deleniti? Sit voluptas quia nulla quis porro.
          Dolor, debitis.
        </span>
        <span>
          Cumque quia officiis, voluptatem laudantium assumenda velit amet
          veniam temporibus nostrum dolores tempora nemo voluptates fuga quae
          unde! Excepturi similique voluptate corrupti eaque reprehenderit.
          Repellat sit necessitatibus perferendis provident distinctio.
        </span>
        <span>
          Iure laudantium cupiditate alias, ipsa consequatur beatae doloremque
          sed veniam reiciendis quaerat aut, voluptatum suscipit error. Ut, vel
          voluptatum! Fugiat repudiandae dignissimos cum suscipit voluptates
          excepturi nulla incidunt quisquam numquam?
        </span>
      </p>
      <p>
        <span>
          Modi ullam neque veritatis dolorem distinctio, corrupti beatae eum
          enim repudiandae placeat temporibus nemo assumenda corporis incidunt
          sit magni facilis voluptates blanditiis non quisquam porro. Facilis
          suscipit nisi mollitia tempora!
        </span>
        <span>
          Deleniti iure reprehenderit natus ullam impedit voluptates nam fuga,
          ipsam dignissimos nisi esse alias quos perspiciatis quod nemo
          temporibus aut architecto illo. Possimus iusto laudantium modi veniam
          magni. Eaque, porro?
        </span>
        <span>
          Dolor dicta adipisci nulla asperiores autem deleniti assumenda ea
          minima similique, voluptatibus repellat explicabo architecto numquam!
          Asperiores error repellat eos odio, incidunt odit ullam voluptatibus
          velit quod sequi optio et.
        </span>
      </p>
      <p>
        <span>
          Eum, laboriosam quos. Eius, esse tempore ipsam et consectetur ratione
          sapiente nulla vero dolor laboriosam corrupti cum ut expedita numquam
          ipsum nostrum, perspiciatis, nobis molestiae officiis natus rerum aut
          sunt.
        </span>
        <span>
          Earum deleniti aliquam accusamus veritatis facere error tempore ut
          fuga veniam minima nihil voluptas enim quidem, ullam reprehenderit
          odit quae, itaque, nostrum ducimus. Iste molestiae perferendis fugiat
          quos id. Ullam.
        </span>
        <span>
          Sapiente sit, voluptatem eius dolore inventore ex eaque in qui atque
          nihil illo sint totam error veniam animi unde ullam soluta? Aliquam
          voluptatem necessitatibus reiciendis aliquid eligendi libero dolores
          molestiae!
        </span>
      </p>
      <p>
        <span>
          Ad cupiditate ducimus, officia aut magni fuga molestiae ea harum omnis
          vel repellendus repellat neque expedita soluta illo corrupti fugit
          deleniti. Commodi autem recusandae quisquam, dignissimos ipsam aliquam
          id ducimus.
        </span>
        <span>
          Eaque similique inventore optio cumque ut fugit? Eius nisi at
          repudiandae exercitationem beatae alias repellat eum mollitia, debitis
          cum sit dignissimos dolorum vero laudantium illum nihil. Totam eaque
          possimus quo.
        </span>
        <span>
          Eius aliquid explicabo nobis quibusdam, id consectetur at ratione quis
          adipisci doloribus voluptatem vero neque molestias soluta error sequi
          repudiandae! Porro quasi beatae cum eius itaque alias eaque, facere
          repellat.
        </span>
      </p>
    </div>
  )
}

export default Cgu
