import React, { useState } from 'react'

import Button from '~components/basics/Button'
import StepBar from '~components/basics/StepBar'

import './OnboardingNavbar.scss'

const CheckIcon = () => (
	<svg
		width="16"
		height="13"
		viewBox="0 0 16 13"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		style={{
			marginTop: -2
		}}
	>
		<path
			id="Shape"
			d="M0 7.01047L5.47907 12.5L15.9999 1.98955L14.4895 0.5L5.47907 9.49995L1.48952 5.51043L0 7.01047Z"
			fill="white"
		/>
	</svg>
)

const ArrowLeft = () => (
	<svg
		width="21"
		height="20"
		viewBox="0 1 21 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M19.8387 10.1935H2.75977L5.58897 7.37806C5.90466 7.06387 5.90587 6.55326 5.59167 6.23758C5.27748 5.92185 4.76683 5.92068 4.45115 6.23484L0.237273 10.4284C0.236991 10.4286 0.23679 10.4289 0.236548 10.4292C-0.0783315 10.7433 -0.0793396 11.2556 0.236467 11.5708C0.236749 11.5711 0.236951 11.5714 0.237193 11.5716L4.45107 15.7652C4.76671 16.0793 5.27736 16.0782 5.59159 15.7624C5.90578 15.4467 5.90458 14.9361 5.58889 14.6219L2.75977 11.8065H19.8387C20.2841 11.8065 20.6452 11.4454 20.6452 11C20.6452 10.5546 20.2841 10.1935 19.8387 10.1935Z"
			fill="#172B49"
		/>
	</svg>
)

const Spinner = ({ color = 'light' }) => (
	<svg
		version="1.1"
		className="spinner"
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		viewBox="0 0 16 16"
	>
		<path
			fill={color === 'light' ? '#ffffff' : '#172b49'}
			d="M12.9 3.1c1.3 1.2 2.1 3 2.1 4.9 0 3.9-3.1 7-7 7s-7-3.1-7-7c0-1.9 0.8-3.7 2.1-4.9l-0.8-0.8c-1.4 1.5-2.3 3.5-2.3 5.7 0 4.4 3.6 8 8 8s8-3.6 8-8c0-2.2-0.9-4.2-2.3-5.7l-0.8 0.8z"
		></path>
	</svg>
)

const ArrowRight = () => (
	<svg width="21" height="20" viewBox="0 1 21 20" x="0px" y="0px">
		<path
			fill="#172B49"
			d="M0.4,10c0,0.4,0.4,0.8,0.8,0.8h17.1l-2.8,2.8c-0.3,0.3-0.3,0.8,0,1.1c0.3,0.3,0.8,0.3,1.1,0l4.2-4.2
	c0,0,0,0,0,0c0.3-0.3,0.3-0.8,0-1.1c0,0,0,0,0,0l-4.2-4.2c-0.3-0.3-0.8-0.3-1.1,0c-0.3,0.3-0.3,0.8,0,1.1l2.8,2.8H1.2
	C0.7,9.2,0.4,9.6,0.4,10z"
		/>
	</svg>

	// <svg
	// 	width="21"
	// 	height="20"
	// 	viewBox="0 1 21 20"
	// 	fill="none"
	// 	xmlns="http://www.w3.org/2000/svg"
	// >
	// 	<path
	// 		d="M19.8387 10.1935H2.75977L5.58897 7.37806C5.90466 7.06387 5.90587 6.55326 5.59167 6.23758C5.27748 5.92185 4.76683 5.92068 4.45115 6.23484L0.237273 10.4284C0.236991 10.4286 0.23679 10.4289 0.236548 10.4292C-0.0783315 10.7433 -0.0793396 11.2556 0.236467 11.5708C0.236749 11.5711 0.236951 11.5714 0.237193 11.5716L4.45107 15.7652C4.76671 16.0793 5.27736 16.0782 5.59159 15.7624C5.90578 15.4467 5.90458 14.9361 5.58889 14.6219L2.75977 11.8065H19.8387C20.2841 11.8065 20.6452 11.4454 20.6452 11C20.6452 10.5546 20.2841 10.1935 19.8387 10.1935Z"
	// 		fill="#172B49"
	// 	/>
	// </svg>
)

const OnboardingNavbar = ({
	currentStep,
	unlocked,
	handleChangeCurrentStep,
	isOnboarding = false,
	hide = false
}) => {
	const [saveButtonState, setSaveButtonState] = useState('init')
	const [previousIsLoading, setPreviousIsLoading] = useState(false)
	const [nextIsLoading, setNextIsLoading] = useState(false)

	return (
		<header
			className={`header-fiche ${unlocked ? 'unlocked' : ''} ${
				hide ? 'hide' : ''
			}`}
		>
			<span>
				<Button
					className="nav-button"
					size="medium"
					theme="transparent"
					style={
						!isOnboarding && currentStep === 1
							? {
									opacity: 0,
									pointerEvents: 'none',
									cursor: 'default'
							  }
							: {}
					}
					onClick={() => {
						if (previousIsLoading) return
						setPreviousIsLoading(true)
						handleChangeCurrentStep(currentStep - 1).then(() => {
							setTimeout(() => {
								setPreviousIsLoading(false)
							}, 200)
						})
					}}
					disabled={currentStep === 0}
				>
					{previousIsLoading ? (
						<Spinner color="dark" />
					) : (
						<>
							<ArrowLeft />
							<span style={{ marginLeft: 8 }}>Précédent</span>
						</>
					)}
				</Button>
			</span>
			<StepBar
				step={currentStep}
				unlocked={!isOnboarding}
				enabledNavLinks={false}
				handleChangeStep={handleChangeCurrentStep}
			></StepBar>
			{!isOnboarding && (
				<span>
					<Button
						className={`save-button ${
							saveButtonState === 'error' ? 'error' : ''
						}`}
						size="medium"
						theme="transparent"
						type="button"
						style={{
							cursor: saveButtonState === 'init' ? 'pointer' : 'not-allowed'
						}}
						onClick={() => {
							// TODO saved state
							if (saveButtonState !== 'init') return

							setSaveButtonState('loading')
							
							handleChangeCurrentStep(currentStep).then(success => {
								if (success)
									setTimeout(() => {
										setSaveButtonState('saved')

										setTimeout(() => {
											setSaveButtonState('init')
										}, 1000)
									}, 400)
								else {
									setTimeout(() => {
										setSaveButtonState('error')

										setTimeout(() => {
											setSaveButtonState('init')
										}, 1000)
									}, 400)
								}
							})
						}}
					>
						{saveButtonState === 'loading' ? (
							<Spinner />
						) : saveButtonState === 'saved' ? (
							<span>
								<CheckIcon />
								<span style={{ marginLeft: 4 }}>Enregistré</span>
							</span>
						) : saveButtonState === 'error' ? (
							'Erreur'
						) : (
							'Enregistrer'
						)}
					</Button>
				</span>
			)}
			<span>
				<Button
					className="nav-button"
					size="medium"
					theme="transparent"
					type="button"
					onClick={() => {
						if (nextIsLoading) return

						setNextIsLoading(true)
						handleChangeCurrentStep(currentStep + 1).then(() => {
							setTimeout(() => {
								setNextIsLoading(false)
							}, 200)
						})
					}}
					style={
						!isOnboarding && currentStep === 5
							? {
									opacity: 0,
									pointerEvents: 'none',
									cursor: 'default'
							  }
							: {}
					}
				>
					{nextIsLoading ? (
						<Spinner color="dark" />
					) : (
						<>
							<span style={{ paddingRight: 8 }}>Suivant</span>
							<ArrowRight />
						</>
					)}
				</Button>
			</span>
		</header>
	)
}

export default OnboardingNavbar
