import React, { useState } from 'react'
import Modal from '~components/basics/Modal/Modal'
import { enableOrDisableAllActivityMarketItems } from '~components/Market/MarketItemEditor/queries/marketItem.queries'
import { Select } from '~components/Ticketing/FormToolbox'
import { useAppContext } from '~contexts/appContext/appContext'
import { FicheClosedIcon } from '../../components/icons/FicheClosedIcon'

import './ScheduleModal.scss'

const ScheduleModal = ({ setIsOpen, isOpen, updateEnabled, activityId }) => {
	const { token } = useAppContext().auth
	const [disable, setDisable] = useState(false)
	const handleclick = async () => {
		if (disable) {
			await enableOrDisableAllActivityMarketItems(false, activityId, token)
		}
		await updateEnabled()
		setIsOpen(false)
	}
	return (
		<Modal
			className="ScheduleModal"
			setIsOpen={setIsOpen}
			isOpen={isOpen}
			close={() => setIsOpen(false)}
		>
			<div className="center">
				<FicheClosedIcon />
				<h3>Fermé temporairement</h3>
				<div>
					La fermeture concerne uniquement cette fiche. Pensez à faire de même
					pour les autres fiches également fermées.
				</div>
			</div>
			<hr />
			<div style={{ marginBottom: '22px' }}>
				Les offres vendues sur des horaires d’ouverture sont arrêtées tant que
				vous êtes fermé. Astuce : placez les sur “Durée de validité” pour
				continuer de les vendre. Les clients auront un temps défini pour
				utiliser leur avoir.
			</div>
			<div>
				Voulez-vous désactiver temporairement toutes vos offres ? En cochant
				“Non”, vous pourrez en laisser certaines actives et en désactiver
				d’autres.
			</div>
			<div className="select_dropdown" style={{ marginBottom: '50px' }}>
				<Select
					value={disable}
					setValue={val => setDisable(val)}
					options={[
						{ value: false, label: 'Non, ne pas désactiver toutes les offres' },
						{ value: true, label: 'Désactiver toutes les offres' }
					]}
				/>
			</div>
			<div className="buttons">
				<button
					onClick={() => {
						setIsOpen(false)
					}}
				>
					Annuler
				</button>
				<button className="confirm" onClick={handleclick}>
					Confirmer
				</button>
			</div>
		</Modal>
	)
}

export default ScheduleModal
