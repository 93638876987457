import React, { useState, useEffect } from 'react'
import moment from 'moment'

import PlusIcon from '~svg/booking/plus.svg'

import styled from 'styled-components'
import ReservationDetailsPopup from '~components/reservations/ReservationDetailsPopup'
import ReservationMarketDetailsPopup from '~components/reservations/ReservationMarketDetailsPopup'
import { getTicketPricings, getTicketDatesString, getTicketOptions, getTicketOptionalOptions } from '@coq-trotteur/coq-core/dist'

/**
 *
 * @param {{
 *
 * }} props
 */
const Row = props => {
  const { infos } = props
  const {
    reservation,
    visitors,
    reservation_ticket_number,
    items,
    date,
  } = infos

  const [popupDetailsData, setPopupDetailsData] = useState(null);
  const [popupDetailsMarketItem, setPopupDetailsMarketItem] = useState(null);

  useEffect(() => {
    if(infos.reservation_ticket_number === "904-F04-AED") {
      console.log({"904-F04-AED": infos})
    }
		if(infos && infos.marketPricings && !infos.marketPricings?.uniquePrice) {
        infos.marketPricings.list = infos?.marketPricings?.list?.map(pricing => {
          let marketPricing = infos?.marketItem?.pricing?.list.find((p) => String(p._id) === String(pricing?.marketPricingId) || String(p.id) === String(pricing?.marketPricingId));
          pricing.marketPricing = marketPricing;
          return pricing;
        })
      
      
    }
	}, [infos])
  function getPrestationTimeLimits() {
    if(infos?.type === 'market_item') {
      return "";
    }
    const timeLimits = [];
    infos?.items?.map((item) => {
      if(item.schedule_type === 'time_duration') {
        if(timeLimits.indexOf(item.time_limit) < 0) {
          timeLimits.push(item.time_limit);
        }
      }
    });
    return timeLimits.map((timeLimit, index) => {
      return `Durée de validité ${timeLimit} mois`;
    })?.join(', ');
  }
  function includePrestationOnDate() {
    if(infos?.type === 'market-item') {
      return true;
    }
    let hasPrestationOnDate = false;
    infos?.items?.map((item) => {
      if(item.schedule_type !== 'time_duration') {
        hasPrestationOnDate = true;
      }
    });
    return hasPrestationOnDate;
  }
  // ***** State / Context *****

  const { firstname, lastname } = reservation?.informations || {};

  let formatedDate = '';
  const prestationDates = [];
  if(includePrestationOnDate()) {
    formatedDate = moment(date).format('DD/MM/YY');
    prestationDates.push(formatedDate);
  }
  const prestationTimeLimits = getPrestationTimeLimits();
  if(prestationTimeLimits) {
    prestationDates.push(prestationTimeLimits);
  }

  // ***** LifeCycle *****
  // useEffect(() => {

  // })

  return (
    <>
    <ReservationDetailsPopup reservation={popupDetailsData} onClose={() => {
          setPopupDetailsData(null);
      }} />
      <ReservationMarketDetailsPopup reservation={popupDetailsMarketItem} onClose={() => {
          setPopupDetailsMarketItem(null);
      }} />
    <RowWrapper onClick={() => { setPopupDetailsMarketItem(infos) }}>
      <div><PlusIcon /></div>
      <div>{infos.ticket_type === 'service' ? getTicketDatesString(infos) : `Le ${moment(infos.date).format('DD/MM/YY')}`}</div>

      <div>
        {reservation_ticket_number}
        {/* <Separator>|</Separator>
        {`${firstname} ${lastname}`} */}
      </div>

      <PriceDetails>
        {
          
          <>
          <React.Fragment>
          {/* <>{!infos.market_item ? console.log({infos}) : ''}</> */}
          {
            
            
            getTicketPricings(infos).map((pricing, i) => (
            //  let marketPricing = infos?.marketItem?.pricing?.list.find((p) => String(p._id) === String(pricing.marketPricingId));
              
                
              
              <MultiRow>
                <div>
                  {`${pricing?.quantity || 1}* ${pricing?.label}`}
                </div>
                
                {
                  !infos?.state?.canceled
                  ?
                  <Prices>
                    <div>{`+${(pricing?.price * (pricing?.quantity || 1))
                      .toFixed(2)
                      .replace('.', ',')}€TTC`}
                    </div>
                    {/* TODO */}
                        {
                          pricing?.tvaList?.map((tvaItem) => {
                            return (
                              <TVA className='tva'>
                              {`(TVA ${tvaItem.tva.toFixed(2)}% : ${tvaItem.tvaAmount.toFixed(2)}€. ${tvaItem.ht.toFixed(2)}€HT)`}
                              </TVA>
                            )
                          })
                        }
                        
                        
                  </Prices>
                  :
                    i === 0?
                      <Prices>
                        <Canceled>Ticket annulé: Montant restant {infos?.total}€</Canceled>
                      </Prices>
                    :<></>
                  }
              </MultiRow>
            ))
            
            // :
            // <MultiRow>
            //   <div>{`${infos?.marketPricings?.quantity}* ${infos?.marketItem?.title}`}</div>
            //   {
            //     !infos?.state?.canceled
            //     ?
            //   <Prices>
            //     <div>{`+${(infos?.marketPricings?.basePrice * infos?.marketPricings?.quantity)
            //       .toFixed(2)
            //       .replace('.', ',')}€TTC`}
            //     </div>
            //         <TVA>{`(TVA ${infos?.marketPricings?.tva || 0}% : ${(((infos?.marketPricings?.basePrice * infos?.marketPricings?.quantity)/(1 + (infos?.marketPricings?.tva || 0) / 100 )) * (infos?.marketPricings?.tva || 0) / 100).toFixed(2).replace('.', ',')}€. ${((infos?.marketPricings?.basePrice * infos?.marketPricings?.quantity)/(1 + (infos?.marketPricings?.tva || 0) / 100 )).toFixed(2).replace('.', ',')}€HT)`}</TVA>
                    
            //   </Prices>
            //   :
            //     <Prices>
            //       <Canceled>Ticket annulé: Montant restant {infos?.total}€</Canceled>
            //     </Prices>
            //   }
            // </MultiRow>
          }
          {
            getTicketOptionalOptions(infos).map((option, i) => (
              <MultiRow>
                <div>
                  {`${option?.quantity || 1}* ${option?.label}`}
                </div>
                
                {
                  !infos?.state?.canceled
                  ?
                  <Prices>
                    <div>{`+${(option?.price * (option?.quantity || 1))
                      .toFixed(2)
                      .replace('.', ',')}€TTC`}
                    </div>
                    {/* TODO */}
                    <TVA className='tva'>
                      {`(TVA ${option.tva.toFixed(2)}% : ${option.tvaAmount.toFixed(2)}€. ${option.ht.toFixed(2)}€HT)`}
                    </TVA>
                        
                        
                  </Prices>
                  :
                    i === 0?
                      <Prices>
                        <Canceled>Ticket annulé: Montant restant {infos?.total}€</Canceled>
                      </Prices>
                    :<></>
                  }
              </MultiRow>
            ))
          }
          
      </React.Fragment>
      <React.Fragment>
        <ShippingFees>
              <div>
                { infos?.delivery?.price ? 'Frais de port' : '' }
              </div>
              {
              !infos?.state?.canceled && infos?.delivery?.price
              ?
              <Prices>
              <div>
              {`+${(infos?.delivery?.price || 0)
                .toFixed(2)
                .replace('.', ',')}€TTC`}
              </div>
              
                
              </Prices>
              :
              <></>
            }
            </ShippingFees>
            
      </React.Fragment>
      </>
        }
      </PriceDetails>

    </RowWrapper>
    </>
  )
}

export default Row

const RowWrapper = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr 3fr 5fr;
  grid-column-gap: 12px;
  grid-row-gap: 20px;
  justify-items: center;
  margin-bottom: 25px;
  cursor: pointer;
`

const Separator = styled.span`
  margin: 0 8px;
`

const PriceDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const MultiRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const ShippingFees = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -17px;
`

const Prices = styled.div`
  justify-self: end;
  margin-bottom: 20px;
  text-align: right;
`
const Canceled = styled.div`
  justify-self: end;
  margin-bottom: 20px;
  color: #fe6388;
`
const TVA = styled.div`
  font-size: 11px;
`