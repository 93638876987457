import React, { useRef } from 'react'
import useToggle from '~hooks/useToggle'
import Button from '~components/basics/Button'
import { Textarea } from '~components/Ticketing/FormToolbox'

const InfosNote = ({ descriptionField }) => {
	const [isOpen, toggleIsOpen] = useToggle(descriptionField.value.length > 0)
	const inputRef = useRef(null)

	const handleOpen = () => {
		toggleIsOpen()
		inputRef.current.focus()
	}
	return (
		<>
			<Button theme="underline-action" onClick={handleOpen}>
				+ Ajouter une note importante / des instructions pour les visiteurs ?
			</Button>{' '}
			(optionnel)
			<div
				style={{
					opacity: isOpen ? 1 : 0,
					pointerEvents: isOpen ? 'all' : 'none',
					position: isOpen ? 'static' : 'absolute'
				}}
			>
				<div style={{ paddingTop: 8 }}>
					<Textarea
						targetRef={inputRef}
						placeholder="Écrivez votre note"
						{...descriptionField}
						style={{ minHeight: 70 }}
					/>
				</div>
				Cette note sera envoyée par mail aux personnes qui réservent votre
				activité
			</div>
		</>
	)
}

export default InfosNote
