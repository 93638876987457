import React, { useRef, useState } from 'react'
import Button from '~components/basics/Button'
import { useModalWithPayload } from '~components/basics/Modal/Modal'
import ModalConfirmation from '~components/basics/Modal/ModalConfirmation/ModalConfirmation'
import { useUserData } from '~contexts/userDataContext'
import CogIcon from '~components/svg/CogIcon'
import ExternalLinkIcon from '~components/svg/ExternalLink.icon'
import useClickOutside from '~hooks/useClickOutside'
import { getTransferToken } from '~queries/auth.queries'
import {
	removeWebsite,
	updateWebsite,
	updateWebsiteById
} from '~queries/website.queries'
import WebsiteChangeNameModal from './WebsiteChangeNameModal/WebsiteChangeNameModal'

import './WebsiteList.scss'

const WebsiteList = ({ websites, handleCreateWebsite, handleRefresh }) => {
	return (
		<div className="WebsiteList">
			<WebsiteListActionBar handleCreateWebsite={handleCreateWebsite} />

			<div className="list">
				{websites.map(website => (
					<WebsiteItem
						key={website.id}
						website={website}
						handleRefresh={handleRefresh}
					/>
				))}
			</div>
		</div>
	)
}

const WebsiteItem = ({ website, handleRefresh }) => {
	const { token } = useUserData()
	const [isLoading, setIsLoading] = useState(false)
	const [editionError, setEditionError] = useState(null)

	const url = website.netlify.url?.replace('http://', 'https://') || null

	const modalConfirmation = useModalWithPayload()
	const websiteChangeNameModal = useModalWithPayload()

	const handleEditWebsite = async () => {
		setIsLoading(true)
		setEditionError(null)

		try {
			const transferToken = await getTransferToken(token)
			const websiteSessionURL = `${url}/session?token=${transferToken}`
			window.open(websiteSessionURL)
			setIsLoading(false)
		} catch (err) {
			setIsLoading(false)
			setEditionError(true)
		}
	}

	const handleVisiteWebsite = () => {
		window.open(url, '_blank').focus()
	}

	const handleRemove = () => {
		removeWebsite(website.id, token).then(() => {
			handleRefresh()
		})
	}

	return (
		<div className="WebsiteItem">
			<ModalConfirmation {...modalConfirmation} />
			<WebsiteChangeNameModal
				{...websiteChangeNameModal}
				onChange={async name => {
					await updateWebsiteById({ name }, website.id, token)
					websiteChangeNameModal.close()
					handleRefresh()
				}}
			/>
			<WebsiteItemParams
				handleRemove={() => {
					modalConfirmation.openWithPayload({
						title: 'Êtes-vous sûr de vouloir supprimer ce site ?',
						leftButtonlabel: 'Annuler',
						rightButtonlabel: 'Supprimer le site',
						handleLeftButtonClick: () => {},
						handleRightButtonClick: () => {
							handleRemove()
						}
					})
				}}
				handleRename={() => {
					websiteChangeNameModal.openWithPayload({ name: website.name })
				}}
			/>

			<button className="miniature" onClick={handleEditWebsite}>
				<div className="hover">
					<span>
						<ExternalLinkIcon /> Editer mon site
					</span>
				</div>

				<img
					src={
						website.netlify.previewImageUrl ||
						'/images/empty-website-preview.svg'
					}
				/>
			</button>

			<div className="text">
				<WebsiteName
					name={website.name}
					onChange={name => {
						updateWebsite(
							{
								...website,
								name
							},
							token
						)
					}}
					onClick={handleEditWebsite}
				/>

				<div className="buttons">
					<Button onClick={handleVisiteWebsite}>
						Voir en tant que visiteur
					</Button>
					<Button theme="blue" onClick={handleEditWebsite}>
						<i className="fas fa-pencil-alt" /> Editer
					</Button>
				</div>
			</div>
		</div>
	)
}

export default WebsiteList

const WebsiteName = ({ name, onChange, onClick }) => {
	const [editMode, setEditMode] = useState(false)
	const inputRef = useRef(null)

	const handleValidate = () => {
		onChange(inputRef.current.value)
		setEditMode(false)
	}

	const handleEnableEdit = () => {
		setEditMode(true)
		window.requestAnimationFrame(() => {
			if (inputRef.current) {
				inputRef.current.select()
			}
		})
	}

	return (
		<div className="WebsiteName">
			<h2
				onClick={() => {
					onClick()
				}}
			>
				{name}
			</h2>
			{/* {editMode ? (
				<input
					defaultValue={name}
					onChange={e => {}}
					ref={inputRef}
					onKeyDown={e => {
						if (e.key === 'Enter') {
							handleValidate()
						}
						if (e.key === 'Escape') {
							setEditMode(false)
						}
					}}
				/>
			) : (
				<h2 onClick={handleEnableEdit} role="button" tabIndex={-1}>
					{name}
				</h2>
			)} */}
			{/* {editMode ? (
				<button className="validate" onClick={handleValidate}>
					Valider
				</button>
			) : (
				<button className="edit" onClick={handleEnableEdit}>
					Changer le nom
				</button>
			)} */}
		</div>
	)
}

const WebsiteListActionBar = ({ handleCreateWebsite }) => (
	<div className="WebsiteListActionBar">
		<Button theme="blue" onClick={handleCreateWebsite}>
			+ Créer un site web
		</Button>
	</div>
)

const WebsiteItemParams = ({ handleRemove, handleRename }) => {
	const [isOpen, setIsOpen] = useState(false)
	const rootRef = useRef<HTMLDivElement>(null)

	useClickOutside(isOpen, setIsOpen, rootRef)

	return (
		<div className="WebsiteItemParams" ref={rootRef}>
			<button
				onClick={() => {
					setIsOpen(!isOpen)
				}}
			>
				<CogIcon />
			</button>
			<div className={`dropdown ${isOpen ? 'active' : ''}`}>
				<button
					onClick={() => {
						handleRename()
					}}
				>
					Modifier le nom du site
				</button>
				<button
					onClick={() => {
						handleRemove()
					}}
				>
					Supprimer
				</button>
			</div>
		</div>
	)
}
