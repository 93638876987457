// const pendingReservationsUrl = "backend_users/pending-reservations";
pendingReservationsUrl = "tickets/manual";
reservationCalendarUrl = "tickets/calendar";
const providerReservationsUrl = "backend_users/reservations";
const providerHasReservationUrl = 'backend_users/has-reservation';
const ticketCancellationUrl = 'provider/cancel';
const ticketAcceptationUrl = 'accept';
const ticketRefusalUrl = 'refuse';
const markPrestationAsReadUrl = "backend_users/mark-prestation-as-read";
const marketReservationsUrl = 'backend_users/market-reservations';
const markTicketAsReadUrl = "backend_users/mark-ticket-as-read";
const downloadWeeklyBillUrl = "pdf-generator/weekly-bill";

module.exports = {
    pendingReservationsUrl,
    providerReservationsUrl,
    providerHasReservationUrl,
    ticketCancellationUrl,
    markPrestationAsReadUrl,
    ticketAcceptationUrl,
    ticketRefusalUrl,
    marketReservationsUrl,
    markTicketAsReadUrl,
    downloadWeeklyBillUrl,
    reservationCalendarUrl
}
