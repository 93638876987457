export const getQueryOptions = token => {
	const headers = new Headers()
	headers.append('x-access-token', token)

	return {
		method: 'GET',
		headers,
		redirect: 'follow'
	}
}

export const getPostQueryOptions = (activity, token) => {
	const headers = new Headers()
	headers.append('x-access-token', token)
	headers.append('Content-Type', 'application/json')
	headers.append('Accept', 'application/json, text/plain, */*')

	return {
		method: 'POST',
		headers,
		body: JSON.stringify(activity),
		redirect: 'follow'
	}
}

export const saveFormDone = async (activitiesId, done, token, api) => {
	const activity = {
		'ticketing.done': done,
		'ticketing.current_step': 0,
		...(done
			? {
					'ticketing.enabled': true // TODO
			  }
			: {})
	}

	for (let i = 0; i < activitiesId.length; i++) {
		await fetch(
			`${api}/activities/step/${activitiesId[i]}`,
			getPostQueryOptions(activity, token)
		).then(data => data.json())
	}
}

export const saveCurrentStep = async (
	current_step,
	activitiesId,
	token,
	api
) => {
	const activity = {
		'ticketing.current_step': current_step
	}

	for (let i = 0; i < activitiesId.length; i++) {
		await fetch(
			`${api}/activities/step/${activitiesId[i]}`,
			getPostQueryOptions(activity, token)
		).then(data => data.json())
	}
}

export const saveTicketingEnabled = async (
	activitiesId,
	enabled,
	token,
	api
) => {
	const activity = {
		'ticketing.enabled': enabled
	}

	for (let i = 0; i < activitiesId.length; i++) {
		await fetch(
			`${api}/activities/step/${activitiesId[i]}`,
			getPostQueryOptions(activity, token)
		).then(data => data.json())
	}
}

export const getActivity = async (id, token, api) => {
	const activity = await fetch(
		`${api}/activities/${id}`,
		getQueryOptions(token)
	).then(data => data.json())

	return activity
}

export const getActivities = async (id_list, token, api) => {
	const activities = []

	for (let i = 0; i < id_list.length; i++) {
		try {
			const activity = await fetch(
				`${api}/activities/${id_list[i]}`,
				getQueryOptions(token)
			).then(data => data.json())

			if (activity) activities.push(activity)
		} catch (err) {
			console.log(`Can't get ${id_list[i]}`)
		}
	}
	return activities.filter(activity => activity !== null)
}

export const saveActivity = async (activity, token, api) => {
	const _activity = {
		...activity
	}

	delete _activity._id

	return await fetch(
		`${api}/activities/step/${activity._id}`,
		getPostQueryOptions(_activity, token)
	).then(data => data.json())
}

export const saveActivities = async (activities, token, api) => {
	for (let i = 0; i < activities.length; i++) {
		await saveActivity(activities[i], token, api)
	}
}
