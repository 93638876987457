import React, { useState, useEffect, useRef, useMemo } from 'react'
import useForm from 'react-super-useform'

import {
	FormSection,
	FormErrorMessage,
	Select,
	FormContainer,
	FormLine,
	FormGrid,
	Input,
	CheckBox,
	MultipleSelect,
	FormSectionButton
} from '../FormToolbox'
import { useInvalidSession, useUserData } from '~contexts/userDataContext'

import moment from 'moment'
import 'moment/locale/fr'

// Form schema
import schedulesFormSchema from './schedulesFormSchema'

// API Queries deps
import {
	getSchedulesPageData,
	createNewSchedule,
	saveSchedulesData,
	saveSchedulesEnabled
} from './schedulesDataQueries'
import InfoTooltip from '~components/basics/InfoTooltip'
import Button from '~components/basics/Button'

import DatePicker from './DatePicker'

import './Schedules.scss'
import EmptySchedulesScreen from './EmptySchedulesScreen'
import { useFormHandler } from '~contexts/currentFormContext'

export const TimesIcon = ({ style = {} }) => (
	<svg
		width="12px"
		height="12px"
		viewBox="0 0 12 12"
		version="1.1"
		style={style}
	>
		<title>Shape</title>
		<g
			id="Page-1"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
			opacity="0.500379"
		>
			<g
				id="3bis"
				transform="translate(-849.000000, -858.000000)"
				fill="#000000"
				fillRule="nonzero"
			>
				<g id="Group-10" transform="translate(349.000000, 60.000000)">
					<g id="Group-5">
						<g id="Group-3">
							<g id="Group" transform="translate(30.000000, 731.000000)">
								<g id="Group-14" transform="translate(459.000000, 52.000000)">
									<path
										d="M17.8932316,21.0000044 L22.8150633,16.0781727 C23.0616963,15.8315162 23.0616963,15.4316253 22.8150633,15.1849923 C22.5684069,14.9383359 22.1684926,14.9383359 21.9218596,15.1849923 L17.0000044,20.1068241 L12.0781727,15.1849923 C11.8315162,14.9383359 11.4316253,14.9383359 11.1849923,15.1849923 C10.9383593,15.4316488 10.9383359,15.8315397 11.1849923,16.0781727 L16.1068241,21.0000044 L11.1849923,25.9218361 C10.9383359,26.1684926 10.9383359,26.5683835 11.1849923,26.8150165 C11.4316488,27.0616729 11.8315397,27.0616495 12.0781727,26.8150165 L17.0000044,21.8931847 L21.9218127,26.8150165 C22.1684691,27.0616729 22.5683835,27.0616495 22.8150165,26.8150165 C23.0616495,26.56836 23.0616495,26.1684691 22.8150165,25.9218361 L17.8932316,21.0000044 Z"
										id="Shape"
									></path>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
)

export const RemoveIcon = ({ style = {} }) => (
	<svg
		width="12px"
		height="12px"
		viewBox="0 0 12 12"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		style={style}
	>
		<title>Remove</title>
		<g
			id="Page-1"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fill-rule="evenodd"
			opacity="0.500379"
		>
			<g
				id="3bis"
				transform="translate(-849.000000, -858.000000)"
				fill="#ffffff"
				fillRule="nonzero"
			>
				<g id="Group-10" transform="translate(349.000000, 60.000000)">
					<g id="Group-5">
						<g id="Group-3">
							<g id="Group" transform="translate(30.000000, 731.000000)">
								<g id="Group-14" transform="translate(459.000000, 52.000000)">
									<path
										d="M17.8932316,21.0000044 L22.8150633,16.0781727 C23.0616963,15.8315162 23.0616963,15.4316253 22.8150633,15.1849923 C22.5684069,14.9383359 22.1684926,14.9383359 21.9218596,15.1849923 L17.0000044,20.1068241 L12.0781727,15.1849923 C11.8315162,14.9383359 11.4316253,14.9383359 11.1849923,15.1849923 C10.9383593,15.4316488 10.9383359,15.8315397 11.1849923,16.0781727 L16.1068241,21.0000044 L11.1849923,25.9218361 C10.9383359,26.1684926 10.9383359,26.5683835 11.1849923,26.8150165 C11.4316488,27.0616729 11.8315397,27.0616495 12.0781727,26.8150165 L17.0000044,21.8931847 L21.9218127,26.8150165 C22.1684691,27.0616729 22.5683835,27.0616495 22.8150165,26.8150165 C23.0616495,26.56836 23.0616495,26.1684691 22.8150165,25.9218361 L17.8932316,21.0000044 Z"
										id="Shape"
									></path>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</g>
	</svg>
)

export const ClosePeriodItem = ({ closePeriodForm, onChange = () => {} }) => {
	return (
		<div className="close-period-item">
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					color: 'white',
					width: 140
				}}
			>
				<div style={{ flex: 1, height: '100%' }}>
					<DatePicker dateRangeForm={closePeriodForm} onChange={onChange} />
				</div>

				<button
					type="button"
					className="remove-button"
					onClick={() => {
						closePeriodForm.remove()
						onChange()
					}}
				>
					<RemoveIcon style={{ marginBottom: 2 }} />
				</button>
			</div>
		</div>
	)
}

const AddClosePeriodButton = ({ closePeriodsForm, onClick }) => {
	return (
		<div
			style={{
				position: 'relative',
				display: 'inline-block'
			}}
		>
			<Button style={{ width: 290 }} theme="colored-action" onClick={onClick}>
				<div
					style={{
						height: 40,
						display: 'flex',
						alignItems: 'center',
						fontWeight: 600,
						position: 'relative'
					}}
				>
					+ Ajouter une période de fermeture
				</div>
			</Button>
		</div>
	)
}

const ClosePeriods = ({ closePeriodsForm }) => {
	const closePeriodsItemsRef = useRef(null)

	return (
		<>
			<FormContainer>
				<div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
					<span className="form-section-title">
						Période de fermeture (vacances, jours fériés)
					</span>
					<InfoTooltip>
						Les périodes de fermeture viennent automatiquement placer des
						périodes en indisponible. Vous devez uniquement indiquer dedans les
						périodes de vacances, de jours fériés, de jours fermés
						exceptionnellement. Si vous êtes fermé·e les samedi et dimanche,
						vous renseignerez cela dans vos horaires.
					</InfoTooltip>
				</div>
			</FormContainer>
			<div className="closed-periods-container">
				{closePeriodsForm.map((closePeriod, i, id) => (
					<div
						key={id}
						style={{
							display: 'inline-block',
							verticalAlign: 'top'
						}}
						ref={closePeriodsItemsRef}
					>
						<ClosePeriodItem closePeriodForm={closePeriod} />
					</div>
				))}

				<AddClosePeriodButton
					onClick={() => {
						closePeriodsForm.push()

						setTimeout(() => {
							closePeriodsItemsRef.current
								.querySelector(':nth-last-child(1) button')
								.click()
						}, 200)
					}}
				/>
			</div>
		</>
	)
}

// TODO move it somewhere else
const capitalize = s => `${s[0].toUpperCase()}${s.slice(1)}`

const HoursInput = props => (
	<Input
		{...props}
		className="hours-input time-input"
		onFocus={e => {
			e.target.select()
		}}
	/>
)
const MinutesInput = props => (
	<Input
		{...props}
		className="minutes-input time-input"
		onFocus={e => {
			e.target.select()
		}}
	/>
)

/**
 *
 * @param {{
 *   timeForm: any
 *   stopAfterMinutes?: boolean
 * }} props
 */
export const HoursMinutesInput = ({ timeForm, stopAfterMinutes = false }) => {
	const error = timeForm.get('hours').error || timeForm.get('minutes').error

	console.log(timeForm.get('hours').value)
	return (
		<div className={`hours-minutes-input ${error ? 'error' : ''}`}>
			<HoursInput
				onBlur={() => {
					if (`${timeForm.get('hours').value}`.length === 1) {
						timeForm.get('hours').setValue(`0${timeForm.get('hours').value}`)
					}
				}}
				value={
					timeForm.get('hours').value === null
						? ''
						: `00${timeForm.get('hours').value}`.substr(-2)
				}
				setValue={(value, e) => {
					if (value.match(/[^0-9]/)) return
					if (value > 23) return
					timeForm.get('hours').setValue(value)
					if (`${value}`.length > 1) {
						const list = [...document.querySelectorAll('.time-input')]
						const currentIndex = list.indexOf(e.target)
						if (currentIndex + 1 < list.length) {
							setTimeout(() => {
								list[currentIndex + 1].focus()
								list[currentIndex + 1].select()
							}, 100)
						}
					}
				}}
			/>
			<span className="separator">:</span>
			<MinutesInput
				onBlur={() => {
					if (`${timeForm.get('minutes').value}`.length === 1) {
						timeForm
							.get('minutes')
							.setValue(`0${timeForm.get('minutes').value}`)
					}
					if (`${timeForm.get('minutes').value}`.length === 0) {
						timeForm.get('minutes').setValue(`00`)
					}
				}}
				value={timeForm.get('minutes').value}
				setValue={(value, e) => {
					if (value.match(/[^0-9]/)) return
					if (value > 59) return
					if (`${value}`.length > 2) return
					timeForm.get('minutes').setValue(value)
					if (`${value}`.length > 1 && !stopAfterMinutes) {
						const list = [...document.querySelectorAll('.time-input')]
						const currentIndex = list.indexOf(e.target)
						if (currentIndex + 1 < list.length) {
							setTimeout(() => {
								list[currentIndex + 1].focus()
								list[currentIndex + 1].select()
							}, 100)
						}
					}
				}}
			/>
		</div>
	)
}

const ClosedDayLine = () => <div className="closed-day-line"></div>

const ScheduleItemDay = ({
	dayForm,
	i,
	handleCopy,
	copiedDay,
	iSchedule,
	clearCopy
}) => {
	const hasData =
		!!dayForm.get('start.hours').value ||
		!!dayForm.get('start.minutes').value ||
		!!dayForm.get('end.hours').value ||
		!!dayForm.get('end.minutes').value

	useEffect(() => {
		if (copiedDay && copiedDay.i === i && copiedDay.iSchedule === iSchedule) {
			clearCopy()
		}
	}, [
		dayForm.get('start.hours').value,
		dayForm.get('start.minutes').value,
		dayForm.get('end.hours').value,
		dayForm.get('end.minutes').value,
		dayForm.get('start_pause.hours').value,
		dayForm.get('start_pause.minutes').value,
		dayForm.get('end_pause.hours').value,
		dayForm.get('end_pause.minutes').value,
		dayForm.get('options.closed').value,
		dayForm.get('options.has_pause').value
	])

	return (
		<div className="schedule-item-day">
			<div className="left">
				<div className="day-name">
					{capitalize(
						moment()
							.day(i + (1 % 7))
							.format('dddd')
					)}
				</div>

				{!dayForm.get('options.closed').value ? (
					<>
						<div style={{ marginRight: 10, position: 'relative' }}>
							<span className="label">Ouverture</span>
							<HoursMinutesInput timeForm={dayForm.get('start')} />
						</div>
						{dayForm.get('options.has_pause').value && (
							<>
								<div style={{ marginRight: 10, position: 'relative' }}>
									<span className="label">Début pause</span>
									<HoursMinutesInput timeForm={dayForm.get('start_pause')} />
								</div>

								<div style={{ marginRight: 10, position: 'relative' }}>
									<span className="label">Fin pause</span>
									<HoursMinutesInput timeForm={dayForm.get('end_pause')} />
								</div>
							</>
						)}

						<div style={{ marginRight: 10, position: 'relative' }}>
							<span className="label">Fermeture</span>
							<HoursMinutesInput timeForm={dayForm.get('end')} />
						</div>
					</>
				) : (
					<ClosedDayLine />
				)}

				<div className="closed-day">
					<span className="label">Jour fermé</span>
					<div className="checkbox-container">
						<CheckBox
							value={dayForm.get('options.closed').value}
							setValue={dayForm.get('options.closed').setValue}
						/>
					</div>
				</div>
				<div>
					{!dayForm.get('options.has_pause').value &&
						!dayForm.get('options.closed').value && (
							<Button
								theme="colored-action"
								onClick={() => {
									dayForm.get('options.has_pause').setValue(true)
								}}
							>
								+ Ajouter une pause
							</Button>
						)}
				</div>
			</div>
			<div style={{ display: 'flex', paddingTop: 18 }}>
				{dayForm.get('options.has_pause').value &&
					!dayForm.get('options.closed').value && (
						<>
							{' '}
							<Button
								theme="underline-action"
								onClick={() => {
									dayForm.get('options.has_pause').setValue(false)
								}}
							>
								Retirer la pause
							</Button>
							<span style={{ margin: '0 0 0 4px' }}>|</span>
						</>
					)}
				{((copiedDay &&
					(copiedDay.i !== i || copiedDay.iSchedule !== iSchedule)) ||
					!copiedDay) &&
					hasData && (
						<Button
							theme="underline-action"
							onClick={() => {
								handleCopy({
									data: dayForm.toJSON(),
									i,
									iSchedule: iSchedule
								})
							}}
						>
							Copier
						</Button>
					)}
				{copiedDay &&
					(copiedDay.i === i && copiedDay.iSchedule === iSchedule ? (
						<div
							style={{ display: 'flex', alignItems: 'center', marginLeft: 4 }}
						>
							<img
								src="/icons/checked.svg"
								style={{ height: 12, marginRight: 4 }}
							/>
							Copié
						</div>
					) : (
						<>
							{((copiedDay &&
								(copiedDay.i !== i || copiedDay.iSchedule !== iSchedule)) ||
								!copiedDay) &&
								hasData && <span style={{ margin: '0 0 0 4px' }}>|</span>}
							<Button
								theme="underline-action"
								onClick={() => {
									dayForm.set({
										...copiedDay.data,
										name: dayForm.toJSON().name
									})
								}}
							>
								Coller
							</Button>
						</>
					))}
			</div>
		</div>
	)
}

const ScheduleItem = ({
	scheduleForm,
	copiedDay,
	setCopiedDay,
	iSchedule,
	same_schedule,
	clearCopy,
	otherPeriodRanges = []
}) => {
	const scheduleDatesListRef = useRef(null)

	const dates = scheduleForm.get('dates').toJSON()

	const allDateArePassed = useMemo(() => {
		const findAfter = dates.find(({ end_date }) => {
			if (!end_date) return true

			if (moment().diff(moment(end_date)) < 0) return true
		})

		return !findAfter
	}, [dates])

	return (
		<>
			<FormContainer>
				<div className="schedule-header">
					<div className="schedule-header-left">
						{!same_schedule && (
							<>
								{allDateArePassed && (
									<div className="color-danger">Période passée</div>
								)}
								<div style={{ display: 'flex' }}>
									<span
										style={{
											marginRight: 8,
											marginBottom: 8,
											paddingTop: 8
										}}
									>
										<div
											style={{
												width: 100
											}}
										>
											Horaires du :
										</div>
									</span>
									<div
										style={{
											paddingRight: 16
										}}
										ref={scheduleDatesListRef}
									>
										{scheduleForm.get('dates').map((date, i) => (
											<span className="schedule-date-range">
												<DatePicker
													dateRangeForm={date}
													disabledRanges={[
														...scheduleForm
															.get('dates')
															.toJSON()
															.filter((_, _i) => _i !== i),
														...otherPeriodRanges
													]}
												/>
												{date.canBeRemoved() && (
													<button
														type="button"
														onClick={() => {
															date.remove()
														}}
														className="remove-button"
													>
														<TimesIcon
															style={{
																width: 12
															}}
														/>
													</button>
												)}
											</span>
										))}

										<Button
											className="add-period"
											onClick={() => {
												scheduleForm.get('dates').push()

												setTimeout(() => {
													;[
														...scheduleDatesListRef.current.querySelectorAll(
															'.schedule-date-range'
														)
													]
														.slice(-1)[0]
														.querySelector('.open-button')
														.click()
												}, 150)
											}}
										>
											+ Ajouter période avec les mêmes horaires
										</Button>
									</div>
								</div>
							</>
						)}
					</div>
					{scheduleForm.canBeRemoved() && (
						<div>
							<Button
								className="delete-schedule"
								onClick={() => {
									scheduleForm.remove()
								}}
							>
								<TimesIcon style={{ marginRight: 8 }} />
								Supprimer la période
							</Button>
						</div>
					)}
				</div>
			</FormContainer>

			<FormSection>
				{scheduleForm.get('days').map((day, i) => (
					<ScheduleItemDay
						dayForm={day}
						i={i}
						iSchedule={iSchedule}
						key={i}
						handleCopy={setCopiedDay}
						copiedDay={copiedDay}
						same_schedule={same_schedule}
						clearCopy={clearCopy}
					/>
				))}
			</FormSection>
		</>
	)
}

const OpeningTime = ({ schedulesForm }) => {
	const same_schedule = schedulesForm.get('same_schedule').value
	const [copiedDay, setCopiedDay] = useState(null)

	const clearCopy = () => {
		setCopiedDay(null)
	}

	return (
		<>
			<span className="form-section-title">Vos horaires d'ouverture</span>
			<FormLine>
				Hormis vos périodes de fermeture, avez-vous les mêmes horaires toute
				l'année ?
				<InfoTooltip>
					Si vous répondez non, vous pourrez enregistrer des horaires
					d’ouvertures différents dans l’année. Sinon, vous aurez les mêmes
					horaires toute l’année.
				</InfoTooltip>
				<div>
					<Select
						value={schedulesForm.get('same_schedule').value}
						setValue={value => {
							if (value === true) {
								if (schedulesForm.get('schedules_table').length === 1) {
									schedulesForm.get('same_schedule').setValue(true)
								} else {
									const json = schedulesForm.toJSON()

									schedulesForm.set({
										...json,
										schedules_table: [json.schedules_table[0]],
										same_schedule: 'true'
									})
								}
							} else {
								schedulesForm.get('same_schedule').setValue(false)
							}
						}}
						emptyLabel="Choisir"
						options={[
							{
								label: 'Oui',
								value: true
							},
							{
								label: 'Non',
								value: false
							}
						]}
					/>
				</div>
			</FormLine>

			{schedulesForm.get('schedules_table').map((schedule, i, id) => (
				<ScheduleItem
					key={id}
					iSchedule={i}
					scheduleForm={schedule}
					copiedDay={copiedDay}
					setCopiedDay={setCopiedDay}
					same_schedule={same_schedule}
					clearCopy={clearCopy}
					otherPeriodRanges={(() => {
						const ranges = []

						schedulesForm
							.get('schedules_table')
							.toJSON()
							.forEach((period, _i) => {
								if (_i === i) return

								period.dates.forEach(({ start_date, end_date }) => {
									if (
										start_date &&
										end_date &&
										start_date !== '' &&
										end_date !== ''
									) {
										ranges.push({
											start_date,
											end_date
										})
									}
								})
							})

						return ranges
					})()}
				/>
			))}

			{!same_schedule && (
				<>
					<FormContainer>
						<div
							role="button"
							tabIndex={0}
							onClick={() => {
								schedulesForm.get('schedules_table').push()
							}}
						>
							<div className="add-schedule-button">
								+ Ajouter une période d'horaire
							</div>
						</div>
					</FormContainer>
				</>
			)}
		</>
	)
}

const transformActivityDataFromForm = activity => {
	const schedules_table = []

	activity.schedules.schedules_table.forEach(({ dates, ...table }) => {
		dates.forEach(date => {
			schedules_table.push({
				...table,
				start_date: date.start_date,
				end_date: date.end_date,
				_id: date._id
			})
		})
	})

	const _activity = JSON.parse(JSON.stringify(activity))

	_activity.schedules.schedules_table = schedules_table

	return _activity
}

/**
 *
 * @param {{
 *   schedules_table: [any]
 * }} schedules
 */
const schedulesEmpty = schedules => {
	return schedules.schedules_table.length === 0
}

const sameSchedules = (schedulesA, schedulesB) => {
	const schedules_tableA = schedulesA.schedules_table.map(({ _id }) => _id)
	const schedules_tableB = schedulesB.schedules_table.map(({ _id }) => _id)

	if (schedulesEmpty(schedulesA)) {
		return false
	}

	return (
		JSON.stringify({
			...schedulesA,
			closed_periods: schedulesA.closed_periods.map(
				({ _id, ...period }) => period
			),
			activities: null,
			schedules_table: schedules_tableA
		}) ===
		JSON.stringify({
			...schedulesB,
			closed_periods: schedulesB.closed_periods.map(
				({ _id, ...period }) => period
			),
			activities: null,
			schedules_table: schedules_tableB
		})
	)
}

const transformSchedulesFromDatabase = activities => {
	const schedules = []

	activities.forEach(activity => {
		const findSame = schedules.find(schedule =>
			sameSchedules(schedule, activity.schedules)
		)

		if (findSame) {
			findSame.activities.push(activity._id)
		} else {
			if (!schedulesEmpty(activity.schedules))
				schedules.push({
					...activity.schedules,
					activities: [activity._id]
				})
		}
	})

	return schedules.map(transformScheduleFromDatabase)
}

const transformScheduleFromDatabase = schedules => {
	const schedules_table = []

	const removeIdMap = ({ _id, ...day }) => day

	schedules.schedules_table.forEach(
		({ _id, start_date, end_date, ...table }) => {
			const find = schedules_table.find(
				s =>
					JSON.stringify(s.days.map(removeIdMap)) ===
					JSON.stringify(table.days.map(removeIdMap))
			)

			if (find) {
				find.dates.push({ _id, start_date, end_date })
			} else {
				schedules_table.push({
					...table,
					dates: [{ _id, start_date, end_date }]
				})
			}
		}
	)

	const transformHoursMinutes = ({ hours, minutes }) => {
		let _hours = hours
		let _minutes = minutes

		if (minutes === 0) {
			_minutes = '00'
		} else if (!_minutes) {
			_minutes = ''
		} else if (_minutes < 10) {
			_minutes = '0' + _minutes
		}

		if (!_hours && _hours !== 0) {
			_hours = ''
		} else if (_hours < 10) {
			_hours = '0' + _hours
		}

		return {
			hours: _hours,
			minutes: _minutes
		}
	}

	const _schedules = JSON.parse(JSON.stringify(schedules))
	_schedules.schedules_table = schedules_table.map((table, i) => {
		return {
			...table,
			days: table.days.map(day => {
				if (day.start) {
					day.start = transformHoursMinutes(day.start)
				}
				if (day.end) {
					day.end = transformHoursMinutes(day.end)
				}
				if (day.start_pause) {
					day.start_pause = transformHoursMinutes(day.start_pause)
				}
				if (day.end_pause) {
					day.end_pause = transformHoursMinutes(day.end_pause)
				}

				return day
			})
		}
	})
	return _schedules
}

const Schedules = ({
	schedules,
	handleSave,
	updateScheduleEnabled,
	handleChangeCurrentStep,
	activitiesNames
}) => {
	const { activitiesId } = useUserData()
	const [errorNotification, setErrorNotification] = useState(null)
	const form = useForm(schedulesFormSchema, schedules)

	const schedulesEnabled = form.get('root.enabled').value === true

	const hasScheduleWithoutActivities =
		form
			.toJSON()
			.root.schedules.filter(({ activities }) => activities.length === 0)
			.length > 0

	useFormHandler(
		schedulesEnabled
			? {
					...form,
					disableForcedSave: hasScheduleWithoutActivities
			  }
			: {
					modified: false,
					isValid: true,
					save: () => true
			  },
		schedulesEnabled ? handleSave : () => true
	)

	const beforeUnload = e => {
		var e = e || window.event

		if (e) {
			e.returnValue = 'Des données ne sont pas sauvegardées'
		}

		return 'Des données ne sont pas sauvegardées'
	}

	useEffect(() => {
		if (form.modified && !schedulesEnabled) {
			window.addEventListener('beforeunload', beforeUnload)

			return () => {
				window.removeEventListener('beforeunload', beforeUnload)
			}
		}
	}, [form.modified, schedulesEnabled])

	form.logErrors()

	const getTakenActivities = () => {
		const json = form.get('root.schedules').toJSON()

		const activities = new Set()

		json.forEach(schedules => {
			schedules.activities.forEach(id => {
				activities.add(id)
			})
		})

		return activities
	}
	const takenActivities = getTakenActivities()

	const activityIsEnabled = id => {
		return !takenActivities.has(id)
	}

	if (!schedulesEnabled) {
		return (
			<>
				<EmptySchedulesScreen
					handleYes={() => {
						form.get('root.enabled').setValue(true)
						updateScheduleEnabled(true)
						setTimeout(() => {
							form.setModified(false)
						}, 300)
					}}
					handleNo={() => {
						form.get('root.enabled').setValue(false)
						handleChangeCurrentStep(5)
					}}
				/>
			</>
		)
	}

	return (
		<form id="ticketing-4" className="schedules-form">
			<FormErrorMessage show={errorNotification} />
			<div
				style={{
					marginBottom: 45,
					textAlign: 'center',
					outline: 'none',
					cursor: 'pointer',
					userSelect: 'none'
				}}
				role="button"
				tabIndex={0}
				onClick={() => {
					form.get('root.enabled').setValue(false)
					updateScheduleEnabled(false)
					setTimeout(() => {
						form.setModified(false)
					}, 300)
				}}
			>
				<FormSection>
					Si vous ne souhaitez plus renseigner d’horaires, cliquez ici
				</FormSection>
			</div>

			{form.get('root.schedules').map((schedulesForm, _, id) => {
				const activities = schedulesForm.get('activities').toJSON()

				return (
					<FormSection key={id}>
						{schedulesForm.canBeRemoved() && (
							<div
								style={{
									textAlign: 'right',
									paddingBottom: 20
								}}
							>
								<Button
									onClick={() => {
										schedulesForm.remove()
									}}
									theme="no-style"
									className="delete-prestation-button"
									style={{
										textAlign: 'center'
									}}
								>
									<img
										src="/icons/delete-white.svg"
										style={{
											width: 16
										}}
									/>
								</Button>
							</div>
						)}

						{activitiesId.length > 1 && (
							<>
								<FormLine>
									Pour quelle(s) fiche(s) d’activité ces horaires d’ouverture
									sont-ils valables ?
									<InfoTooltip>
										Ces horaires d’ouverture seront appliqués sur chaque fiche
										d’activité que vous aurez coché.
									</InfoTooltip>
									<div>
										<MultipleSelect
											dropdownStyle={{
												width: 360
											}}
											style={{
												width: 248
											}}
											error={schedulesForm.get('activities').error}
											list={activities}
											setValue={activitiesId => {
												schedulesForm.get('activities').set(activitiesId)
											}}
											label={
												activities.length === 0
													? 'Sélectionner des fiches'
													: `${activities.length} fiche${
															activities.length > 1 ? 's' : ''
													  }`
											}
											items={Object.keys(activitiesNames).map(id => {
												const disabled =
													!activityIsEnabled(id) && !activities.includes(id)

												return {
													name: activitiesNames[id].title,
													value: id,
													title:
														disabled &&
														'Vous avez déjà des horaires associés à cette activité.',
													disabled
												}
											})}
										/>
									</div>
								</FormLine>

								<hr />
							</>
						)}
						<ClosePeriods
							closePeriodsForm={schedulesForm.get('closed_periods')}
							onChange={() => {}}
						/>
						<hr />
						<OpeningTime schedulesForm={schedulesForm} />
					</FormSection>
				)
			})}

			{activitiesId.length > 1 && // TODO useless ?
				activitiesId.length - takenActivities.size > 0 && (
					<FormSectionButton
						className="add-schedules"
						onClick={() => {
							form.get('root.schedules').push()
						}}
					>
						Voulez-vous ajouter des horaires d’ouverture pour vos autres fiches
						d’activité ?
					</FormSectionButton>
				)}
		</form>
	)
}

const SchedulesWrapper = ({ ...props }) => {
	const { activitiesId, token, apiUrl } = useUserData()
	const [schedules, setSchedules] = useState(null)
	const [activitiesNames, setActivitiesNames] = useState(null)

	const { invalidSession } = useInvalidSession()
	useEffect(() => {
		getSchedulesPageData(activitiesId, token, apiUrl)
			.then(({ activities, activitiesNames }) => {
				const schedules = transformSchedulesFromDatabase(activities)

				if (activitiesId.length === 1) {
					if (schedules.length === 0) {
						schedules.push({
							activities: activitiesId
						})
					} else {
						schedules.forEach(schedule => {
							schedule.activities = activitiesId
						})
					}
				}

				setSchedules(schedules)
				setActivitiesNames(activitiesNames)
			})
			.catch(err => {
				invalidSession(err)
			})
	}, [])

	const handleSave = async formData => {
		const { schedules: list } = formData.root

		if (!formData.root.enabled) return

		const activitiesData = activitiesId.reduce((acc, cur) => {
			acc[cur] = {
				_id: cur,
				schedules: {
					init: true,
					enabled: false,
					same_schedule: true,
					schedules_table: [],
					closed_periods: []
				}
			}
			return acc
		}, {})

		for (let i = 0; i < list.length; i++) {
			const schedule = list[i]

			for (let j = 0; j < schedule.schedules_table.length; j++) {
				const { dates, ...table } = schedule.schedules_table[j]

				for (let k = 0; k < dates.length; k++) {
					const { _id, start_date, end_date } = dates[k]

					if (!_id) {
						const returnedData = await createNewSchedule(
							{
								start_date,
								end_date,
								...table
							},
							token,
							apiUrl
						)

						dates[k]._id = returnedData._id
					}
				}
			}
		}

		for (let i = 0; i < list.length; i++) {
			const { activities, ...schedules } = list[i]

			for (let j = 0; j < activities.length; j++) {
				const activityID = activities[j]

				activitiesData[activityID] = transformActivityDataFromForm({
					_id: activityID,
					schedules
				})
			}
		}

		const activities = Object.values(activitiesData)

		for (let i = 0; i < activities.length; i++) {
			const { _id, schedules } = activities[i]

			await saveSchedulesData({ schedules }, _id, token, apiUrl)
		}
	}

	const handleBypass = async () => {
		props.bypassStep()
	}

	const updateScheduleEnabled = async enabled => {
		for (let i = 0; i < activitiesId.length; i++) {
			const id = activitiesId[i]

			await saveSchedulesEnabled(id, enabled, token, apiUrl)
		}
	}

	if (!schedules || !activitiesNames) return null

	return (
		<Schedules
			schedules={{
				root: {
					enabled: schedules.length === 0 ? false : schedules[0].enabled,
					schedules
				}
			}}
			handleSave={handleSave}
			handleBypass={handleBypass}
			updateScheduleEnabled={updateScheduleEnabled}
			activitiesNames={activitiesNames}
			{...props}
		/>
	)
}

export default SchedulesWrapper
