import { createContext, useContext } from 'react'
import equal from 'deep-equal'
const { v4 } = require('uuid')
import { useSimilitude } from '../hooks/useSimilitudeHooks'
import { usePeriods } from '../hooks/usePeriodHooks'
import { useClosingHooks } from '../hooks/useClosingHooks'
import { useOpeningPeriod } from '../hooks/useOpeningPeriod'

// Create context
const HorairesContext = createContext(null)

// Export provider
export const HorairesContextProvider = HorairesContext.Provider

// Export consumer
export function useHorairesContext() {
	// As long as Horairescontext is wrapping the whole app, we are sure that this is not null (that's why I use !)
	return useContext(HorairesContext)
}

/**
 * Export horaires initializer
 */
export function useHorairesContextSubscriber() {
	const { handleSimilitude, similitude } = useSimilitude()
	const {
		handleAddPeriod,
		handlePaste,
		handleRemovePeriod,
		handleUpdateOptions,
		handleUpdateTime,
		periods,
		resetPeriods,
		handleGetPeriod
	} = usePeriods()
	const {
		closedPeriods,
		handleAddClosingPeriod,
		handleRemoveClosingPeriod,
		handleUpdateClosingPeriod,
		handleGetFromDB,
		resetClosingPeriods
	} = useClosingHooks()
	const {
		openingPeriods,
		setOpeningPeriods,
		handleAddOpeningPeriod,
		handleRemoveOpeningPeriods,
		handleDateChange,
		resetOpeningPeriods
	} = useOpeningPeriod()

	const isSameDay = (arr1, arr2) => {
		const updateArr1 = arr1.map(arr => ({ ...arr, _id: undefined }))
		const updateArr2 = arr2.map(arr => ({ ...arr, _id: undefined }))

		return equal(updateArr1, updateArr2)
	}

	/**
	 *
	 * @param {object[]} db
	 */
	const initPeriod = db => {
		const periodsLength = db.length

		const openingPeriod = []

		db.map((dbEntity, index) => {
			// console.log('INDEX', index)

			if (index < periodsLength - 1) {
				// console.log('IF1')
				// console.log(dbEntity)
				// console.log(db[index + 1])

				if (isSameDay(dbEntity.days, db[index + 1].days)) {
					// console.log('same as after')
					let uuid = v4()

					if (!dbEntity.uuid) {
						dbEntity.uuid = uuid
						db[index + 1].uuid = uuid
					} else {
						uuid = dbEntity.uuid
						db[index + 1].uuid = uuid
					}

					const where = openingPeriod.filter(period => period.uuid === uuid)
						.length

					openingPeriod.push({
						uuid,
						index: where,
						start_date: dbEntity.start_date,
						end_date: dbEntity.end_date
					})

					// else if same that before
				} else if (index >= 1 && isSameDay(dbEntity.days, db[index - 1].days)) {
					// console.log('same as before')
					const where = openingPeriod.filter(
						period => period.uuid === dbEntity.uuid
					).length
					openingPeriod.push({
						uuid: dbEntity.uuid,
						index: where,
						start_date: dbEntity.start_date,
						end_date: dbEntity.end_date
					})
				} else {
					// console.log('else')
					dbEntity.uuid = v4()
					openingPeriod.push({
						uuid: dbEntity.uuid,
						index: 0,
						start_date: dbEntity.start_date,
						end_date: dbEntity.end_date
					})
				}

				// So it's the last element of array
			} else if (dbEntity.uuid) {
				// console.log('last el')
				const where = openingPeriod.filter(
					period => period.uuid === dbEntity.uuid
				).length

				openingPeriod.push({
					uuid: dbEntity.uuid,
					index: where,
					start_date: dbEntity.start_date,
					end_date: dbEntity.end_date
				})
			} else {
				// console.log('else')
				dbEntity.uuid = v4()
				openingPeriod.push({
					uuid: dbEntity.uuid,
					index: 0,
					start_date: dbEntity.start_date,
					end_date: dbEntity.end_date
				})
			}
		})

		db.map((dbEntity, index) => {
			if (index < periodsLength - 1) {
				if (isSameDay(dbEntity.days, db[index + 1].days)) {
					db[index + 1].same = true
				} else {
					db[index + 1].same = false
				}
			}
		})
		const updateSchedule = db.filter(data => data.same !== true)
		handleGetPeriod(updateSchedule)
		return openingPeriod
	}

	return {
		closedPeriods,
		handleAddOpeningPeriod,
		handleAddClosingPeriod,
		handleAddPeriod,
		handleDateChange,
		handlePaste,
		handleRemoveClosingPeriod,
		handleRemoveOpeningPeriods,
		handleRemovePeriod,
		handleSimilitude,
		handleUpdateClosingPeriod,
		handleUpdateOptions,
		handleUpdateTime,
		openingPeriods,
		periods,
		resetPeriods,
		setOpeningPeriods,
		similitude,
		handleGetFromDB,
		handleGetPeriod,
		initPeriod,
		resetClosingPeriods,
		resetOpeningPeriods
	}
}
