import { gql } from '@apollo/client'
import { doGraphqlMutation } from '~requests/common'

export const createMarketItem = async (marketItemInput, accessToken) => {
	const mutation = gql`
		mutation CreateMarketItem($marketItemInput: MarketItemInput!) {
			createMarketItem(marketItemInput: $marketItemInput) {
				id
			}
		}
	`

	return (
		await doGraphqlMutation({
			mutation,
			variables: {
				marketItemInput
			},
			accessToken
		})
	).createMarketItem
}

export const enableOrDisableAllMarketItems = async (
	enable: boolean,
	accessToken
) => {
	let mutation = gql`
		mutation DisableUserMarketItems {
			disableUserMarketItems
		}
	`
	if (enable) {
		mutation = gql`
			mutation EnableUserMarketItems {
				enableUserMarketItems
			}
		`
	}

	return await doGraphqlMutation({
		mutation,
		variables: {},
		accessToken
	})
}

export const enableOrDisableAllActivityMarketItems = async (
	enable: boolean,
	activityId,
	accessToken
) => {
	let mutation = gql`
	mutation DisableUserActivityMarketItems ($activityId: ID!) {
		disableUserActivityMarketItems(activityId: $activityId)
	  }
	`
	if (enable) {
		mutation = gql`
		mutation EnableUserActivityMarketItems ($activityId: ID!) {
			enableUserActivityMarketItems(activityId: $activityId)
		  }
		`
	}

	return await doGraphqlMutation({
		mutation,
		variables: {
			activityId
		},
		accessToken
	})
}


export const duplicatMarketItem = async (marketItemId, accessToken) => {
	const mutation = gql`
		mutation CreateMarketItemFromId($marketItemId: ID!) {
			createMarketItemFromId(marketItemId: $marketItemId) {
				id
				title
				description
				note
				type
				category {
					id
				}
				pricing {
					unique
					basePrice
					list {
						price
					}
				}
				creation {
					complete
				}

				gallery {
					isCover
					url
				}
				miniature {
					public_id
					url
				}
				enabled
				activities {
					id
				}
				optionsQuotas {
					pricing
					options
					quota
				}
			}
		}
	`

	const { createMarketItemFromId } = await doGraphqlMutation({
		mutation,
		variables: {
			marketItemId
		},
		accessToken
	})
	

	return createMarketItemFromId
}
