const getQueryOptions = token => {
  const headers = new Headers()
  headers.append('x-access-token', token)

  return {
    method: 'GET',
    headers,
    redirect: 'follow',
  }
}

export const getActivity = async (id, token, api) => {
  const activity = await fetch(
    `${api}/activities/${id}`,
    getQueryOptions(token)
  ).then(data => data.json())

  return activity
}

export const getBookingPageData = async (activityID, token, api, nextStep) => {
  const activity = await getActivity(activityID, token, api)

  return {
    activity,
  }
}
