const getQueryOptions = token => {
	const headers = new Headers()
	headers.append('x-access-token', token)

	return {
		method: 'GET',
		headers,
		redirect: 'follow'
	}
}

const getPostQueryOptions = (activity, token) => {
	const headers = new Headers()
	headers.append('x-access-token', token)
	headers.append('Content-Type', 'application/json')
	headers.append('Accept', 'application/json, text/plain, */*')

	return {
		method: 'POST',
		headers,
		body: JSON.stringify(activity),
		redirect: 'follow'
	}
}

export const getActivity = async (id, token, api) => {
	const activity = await fetch(
		`${api}/activities/${id}`,
		getQueryOptions(token)
	).then(data => data.json())

	return activity
}

export const getInfosPageData = async (activitiesId, token, api) => {
	const activities = []
	for (let i = 0; i < activitiesId.length; i++) {
		const id = activitiesId[i]
		const activity = await getActivity(id, token, api)
		activities.push(activity)
	}

	return {
		activity: activities[0],
		activitiesNames: activities
			.filter(activity => !!activity)
			.reduce((acc, cur) => {
				acc[cur._id] = {
					title: cur.title
				}

				return acc
			}, {})
	}
}

export const saveActivityData = async (
	{ story_telling, gift_coupon, about, accessibility, booking, administration },
	activitiesId,
	token,
	api
) => {
	for (let i = 0; i < activitiesId.length; i++) {
		const activityID = activitiesId[i]

		await fetch(
			`${api}/activities/step/${activityID}`,
			getPostQueryOptions(
				{
					story_telling,
					gift_coupon,
					about,
					accessibility,
					booking,
					administration
				},
				token
			)
		)
	}
}
