import React from 'react'

import { useUserData } from '~contexts/userDataContext'
import Light from '~horairesMod/theme/icons/light.svg'
import Arrow from '~horairesMod/theme/icons/arrow.svg'
import Button from '~components/basics/Button'

import './EmptySchedulesScreen.scss'

const EmptySchedulesScreen = ({ handleYes, handleNo }) => {
	const { userData, dispatch } = useUserData()
	const { done: ticketingDone } = userData.ticketing

	return (
		<div className="empty-schedules-screen-container">
			<div className="empty-schedules-screen">
				<div>
					<Light height={100} />
				</div>
				<h1>Ajouter vos horaires d’ouverture </h1>

				<p className="subtitle">
					Il est important pour les visiteurs de savoir quand votre activité est
					ouverte, par exemple pour planifier leur venue.{' '}
					<strong>Note covid-19 :</strong> Si vous ne savez pas encore quand
					votre établissement ouvrira, vous pouvez enregistrer vos horaires
					habituels et ajouter une période de fermeture sur les prochains
					jours/semaines.
				</p>

				<div>
					<Button
						onClick={handleYes}
						theme="primary-dark"
						roundish={true}
						className="create-schedules"
					>
						Ajouter des horaires d'ouverture
						<Arrow
							style={{
								height: 10,
								width: 20
							}}
						/>
					</Button>
				</div>

				<Button
					className="bypass"
					onClick={() => {
						handleNo()
					}}
				>
					Mon activité n'a pas d'horaires d'ouverture
				</Button>
			</div>
		</div>
	)
}
export default EmptySchedulesScreen
