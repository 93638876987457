import React, { useEffect, memo, useState } from 'react'
import DashboardTemplate from '~components/templates/DashboardTemplate'
import useQuery from '~hooks/useQuery'

import WebsiteEmpty from './WebsiteEmpty/WebsiteEmpty'
import WebsiteList from './WebsiteList/WebsiteList'

import Spinner from '~components/basics/Icons/Spinner'

import { getMyWebsites } from '~queries/website.queries'
import { useUserData } from '~contexts/userDataContext'

import WebsiteCreation from './WebsiteCreation/WebsiteCreation'

import './Website.scss'
import { withAuth } from '~components/HOC/withAuth'

const Website = ({ websites, handleCreateWebsite, handleRefresh }) => {
	return (
		<div className="Website">
			{websites?.length ? (
				<WebsiteList
					websites={websites}
					handleCreateWebsite={handleCreateWebsite}
					handleRefresh={handleRefresh}
				/>
			) : (
				<WebsiteEmpty handleCreateWebsite={handleCreateWebsite} />
			)}
		</div>
	)
}

const WebsiteContainer = () => {
	const { token } = useUserData()
	const [websites, isLoading, error, refreshWebsitesData] = useQuery({
		query: () => {
			return getMyWebsites(token)
		}
	})
	const [createWebsiteMode, setCreateWebsiteMode] = useState(false)

	const handleCreateWebsite = async () => {
		setCreateWebsiteMode(true)
	}

	const handleRefresh = () => {
		refreshWebsitesData()
	}

	// return null

	const Content = () => {
		if (createWebsiteMode)
			return (
				<WebsiteCreation
					handleCancel={() => {
						setCreateWebsiteMode(false)
					}}
				/>
			)
		if (isLoading) return <LoadingScreen />
		if (error) return <ErrorScreen />
		return (
			<Website
				websites={websites}
				handleCreateWebsite={handleCreateWebsite}
				handleRefresh={handleRefresh}
			/>
		)
	}

	return (
		<DashboardTemplate currentPage="websites">
			<div className="Website">
				<Content />
			</div>
		</DashboardTemplate>
	)
}

export default withAuth(WebsiteContainer)

const LoadingScreen = memo(() => (
	<div className="LoadingScreen">
		<Spinner color="dark" />
	</div>
))

const ErrorScreen = memo(() => <div>Error</div>)
