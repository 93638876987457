import 'moment/locale/fr'
import { apiUrl } from '~utils/misc'
import { createNewSchedule, saveSchedulesData } from './schedulesDataQueries'

export const capitalize = s => `${s[0].toUpperCase()}${s.slice(1)}`

export const transformActivityDataFromForm = activity => {
	const schedules_table = []

	activity.schedules.schedules_table.forEach(({ dates, ...table }) => {
		dates.forEach(date => {
			schedules_table.push({
				...table,
				start_date: date.start_date,
				end_date: date.end_date,
				_id: date._id
			})
		})
	})

	const _activity = JSON.parse(JSON.stringify(activity))

	_activity.schedules.schedules_table = schedules_table

	return _activity
}

const schedulesEmpty = schedules => {
	return schedules.schedules_table.length === 0
}

const sameSchedules = (schedulesA, schedulesB) => {
	const schedules_tableA = schedulesA.schedules_table.map(({ _id }) => _id)
	const schedules_tableB = schedulesB.schedules_table.map(({ _id }) => _id)

	if (schedulesEmpty(schedulesA)) {
		return false
	}

	return (
		JSON.stringify({
			...schedulesA,
			closed_periods: schedulesA.closed_periods.map(
				({ _id, ...period }) => period
			),
			activities: null,
			schedules_table: schedules_tableA
		}) ===
		JSON.stringify({
			...schedulesB,
			closed_periods: schedulesB.closed_periods.map(
				({ _id, ...period }) => period
			),
			activities: null,
			schedules_table: schedules_tableB
		})
	)
}

export const transformSchedulesFromDatabase = activities => {
	const schedules = []

	activities.forEach(activity => {
		const findSame = schedules.find(schedule =>
			sameSchedules(schedule, activity.schedules)
		)

		if (findSame) {
			findSame.activities.push(activity._id)
		} else {
			if (!schedulesEmpty(activity.schedules))
				schedules.push({
					...activity.schedules,
					activities: [activity._id]
				})
		}
	})

	return schedules.map(transformScheduleFromDatabase)
}

const transformScheduleFromDatabase = schedules => {
	const schedules_table = []

	const removeIdMap = ({ _id, ...day }) => day

	schedules.schedules_table.forEach(
		({ _id, start_date, end_date, ...table }) => {
			const find = schedules_table.find(
				s =>
					JSON.stringify(s.days.map(removeIdMap)) ===
					JSON.stringify(table.days.map(removeIdMap))
			)

			if (find) {
				find.dates.push({ _id, start_date, end_date })
			} else {
				schedules_table.push({
					...table,
					dates: [{ _id, start_date, end_date }]
				})
			}
		}
	)

	const transformHoursMinutes = ({ hours, minutes }) => {
		let _hours = hours
		let _minutes = minutes

		if (minutes === 0) {
			_minutes = '00'
		} else if (!_minutes) {
			_minutes = ''
		} else if (_minutes < 10) {
			_minutes = '0' + _minutes
		}

		if (!_hours && _hours !== 0) {
			_hours = ''
		} else if (_hours < 10) {
			_hours = '0' + _hours
		}

		return {
			hours: _hours,
			minutes: _minutes
		}
	}

	const _schedules = JSON.parse(JSON.stringify(schedules))
	_schedules.schedules_table = schedules_table.map(table => {
		return {
			...table,
			days: table.days.map(day => {
				if (day.start) {
					day.start = transformHoursMinutes(day.start)
				}
				if (day.end) {
					day.end = transformHoursMinutes(day.end)
				}
				if (day.start_pause) {
					day.start_pause = transformHoursMinutes(day.start_pause)
				}
				if (day.end_pause) {
					day.end_pause = transformHoursMinutes(day.end_pause)
				}

				return day
			})
		}
	})
	return _schedules
}

type TEnable = {
	enabled: boolean
	activityId: string
}

export const handleSaveUtil = async (
	formData,
	activitiesId,
	token,
	enabled: boolean
) => {
	const { schedules: list } = formData.root

	if (!formData.root.enabled) return
	const activitiesData = activitiesId.reduce((acc, cur) => {
		acc[cur] = {
			_id: cur,
			schedules: {
				init: true,
				enabled: true,
				same_schedule: true,
				schedules_table: [],
				closed_periods: []
			}
		}
		return acc
	}, {})

	for (let i = 0; i < list.length; i++) {
		const schedule = list[i]

		for (let j = 0; j < schedule.schedules_table.length; j++) {
			const { dates, ...table } = schedule.schedules_table[j]

			for (let k = 0; k < dates.length; k++) {
				const { _id, start_date, end_date } = dates[k]

				if (!_id) {
					const returnedData = await createNewSchedule(
						{
							start_date,
							end_date,
							...table
						},
						token,
						apiUrl
					)

					dates[k]._id = returnedData._id
				}
			}
		}
	}

	for (let i = 0; i < list.length; i++) {
		const { activities, ...schedules } = list[i]

		for (let j = 0; j < activities.length; j++) {
			const activityID = activities[j]

			activitiesData[activityID] = transformActivityDataFromForm({
				_id: activityID,
				schedules
			})
		}
	}

	const activities: any = Object.values(activitiesData)

	for (let i = 0; i < activities.length; i++) {
		const { _id, schedules } = activities[i]
		await saveSchedulesData(
			{
				schedules: {
					...schedules
				}
			},
			_id,
			token,
			apiUrl,
			enabled
		)
	}
}

export const ficheChangUtil = (schedules, actiID, activityId) => {
	if (actiID) {
		let remove = -1
		schedules.map((scd, i) => {
			const { activities } = scd
			if (
				scd.activities.includes(actiID) &&
				!scd.activities.includes(activityId)
			) {
				scd.activities.push(activityId)
			} else if (
				!scd.activities.includes(actiID) &&
				scd.activities.includes(activityId)
			) {
				remove = i
			}
		})
		if (remove >= 0) {
			schedules.splice(remove, 1)
		}
	} else {
		schedules.map(scd => {
			const { activities } = scd
			if (scd.activities.includes(activityId)) {
				scd.activities = scd.activities.filter(x => x !== activityId)
			}
		})
		schedules.push({
			activities: [activityId]
		})
	}
	return schedules
}
