import React, { useEffect, useState } from 'react'
import './reservation-search-block.scss'
import {
	getMarketReservations,
	markTicketAsRead
} from '~requests/reservations/services/reservation.service'
import { useUserData } from '~contexts/userDataContext'
import VerifiedCircleIcon from '~svg/verified-circle.svg'
import ReservationMarketDetailsPopup from './ReservationMarketDetailsPopup'
import VerifiedCircleReadIcon from '~svg/verified-circle-read.svg'
import moment from 'moment'
import { getTicketOptions } from '@coq-trotteur/coq-core/dist'

const MarketReservations = () => {
	const { apiUrl, token } = useUserData()
	const [reservations, setReservations] = useState([])
	const [popupDetailsData, setPopupDetailsData] = useState(null)
	const [search, setSearch] = useState('')
	useEffect(() => {
		getMarketReservations(apiUrl, token, search)
			.then(res => {
				if (Array.isArray(res)) {
					setReservations(res)
				}
				
			})
			.catch(error => {
				console.warn('Error when fetching pending reservations ', error)
			})
	}, [])
	const markAsRead = (payload, index) => {
		markTicketAsRead(apiUrl, token, payload)
			.then(res => {
				const resa = reservations.slice()
				resa[index].isUsed = res
				setReservations(resa)
			})
			.catch(error => {
				console.warn('Error when mark prestation as read ', error)
			})
	}

	return (
		<div className="block-info">
			<ReservationMarketDetailsPopup
				reservation={popupDetailsData}
				onClose={() => {
					setPopupDetailsData(null)
				}}
			/>
			<div className="block-title">Dernières commandes sur votre boutique</div>

			<div className="reservation search-reservation">
				<div className="block-container">
					<table>
						<tbody>
							{reservations?.map((ticket, index) => {
								let options = ''
								ticket?.options?.map(
									option => (options += ' - ' + option.value)
								)
								return (
									<tr
										className={ticket.isUsed ? 'read' : ''}
										key={`market_product_${index}`}
									>
										<td>
											{ticket.isUsed ? (
												<VerifiedCircleReadIcon
													onClick={() => {
														markAsRead(
															{ ticketId: ticket._id, isUsed: false },
															index
														)
													}}
													className="check-icon"
												/>
											) : (
												<VerifiedCircleIcon
													onClick={() => {
														markAsRead(
															{ ticketId: ticket._id, isUsed: true },
															index
														)
													}}
													className="check-icon"
												/>
											)}
											<span
												onClick={() => {
													setPopupDetailsData(ticket)
												}}
											>
												{ticket.reservation.informations.firstname} {ticket.reservation.informations.lastname} - Le {moment(ticket.createdAt).format('DD/MM/YY HH:mm')} -{' '}
												{ticket.total.toFixed(2)}€
											</span>
										</td>
										<td
											onClick={() => {
												setPopupDetailsData(ticket)
											}}
										>
											{ticket?.delivery?.type === 'takeaway'
												? `
                                                    Retrait sur place ${
																											ticket?.giftPackage
																												?.selected
																												? ' + paquet cadeau - '
																												: ' - '
																										}
                                                        `
												: ''}

											{ticket?.delivery?.type === 'owndelivery'
												? `
                                                    Livraison par le vendeur ${
																											ticket?.giftPackage
																												?.selected
																												? ' + paquet cadeau - '
																												: ' - '
																										}
                                                        `
												: ''}
											{ticket?.delivery?.type === 'shippingoption'|| ticket?.delivery?.type === 'service'
												? `
                                                    Livraison par ${
																											ticket?.delivery
																												?.shippingService || ticket?.delivery
																												?.service
																										} ${
														ticket?.giftPackage?.selected
															? ' + paquet cadeau - '
															: ' - '
												  }
                                                        `
												: ''}
											{ticket?.marketPricings?.uniquePrice
												? `${ticket?.marketPricings?.quantity || 1}* ${
														ticket?.marketItem?.title
												  } ${getTicketOptions(ticket).map(t => `${t.quantity || 1} * ${t.label}`)}`
												: ticket?.marketPricings?.list?.map(p => {
														const marketPricing = ticket?.marketItem?.pricing?.list.find(
															pr => {
																return (
																	String(pr.id) === String(p.marketPricingId) ||
																	String(pr._id) === String(p.marketPricingId)
																)
															}
														)

														return ` ${p.quantity || 1}* ${marketPricing?.label} ${getTicketOptions(ticket).map(t => `${t.quantity || 1} * ${t.label}`)}`
												  })}
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}

export default MarketReservations
