import { useState, useEffect } from 'react'
import moment from 'moment'
const { v4 } = require('uuid')

export function useOpeningPeriod() {
	const [openingPeriods, setOpeningPeriods] = useState([])

	const handleAddOpeningPeriod = (uuid, clearError) => () => {
		const isAlready = openingPeriods.filter(open => open.uuid === uuid)

		setOpeningPeriods(prev => [
			...prev,
			{
				uuid: uuid,
				index: isAlready.length,
				start_date: moment().toDate(),
				end_date: moment().toDate()
			}
		])
	}

	const handleRemoveOpeningPeriods = uuid => index => () => {
		// console.log('REMV', index, uuid)
		const oldPeriod = openingPeriods.filter(
			open => (open.uuid === uuid && open.index !== index) || open.uuid !== uuid
		)
		oldPeriod.forEach((open, id) => {
			open.index = id
		})
		setOpeningPeriods(oldPeriod)
	}

	const handleDateChange = uuid => index => date => {
		if (date === null) {
			return
		}
		let oldPeriod = openingPeriods.filter(
			open => open.uuid === uuid && open.index === index
		)
		oldPeriod = {
			...oldPeriod[0],
			start_date: date[0],
			end_date: date[1]
		}

		const newPeriods = JSON.parse(JSON.stringify(openingPeriods))
		newPeriods.splice(index, 1, oldPeriod)

		setOpeningPeriods(newPeriods)
	}

	const resetOpeningPeriods = () => {
		setOpeningPeriods([])
	}

	useEffect(() => {}, [openingPeriods])

	return {
		openingPeriods,
		setOpeningPeriods,
		handleAddOpeningPeriod,
		handleRemoveOpeningPeriods,
		handleDateChange,
		resetOpeningPeriods
	}
}

const defaultState = [
	{
		index: 0,
		uuid: v4(),
		start_date: '',
		end_date: ''
	}
]
