import React, { useEffect, useMemo, useRef, useState } from 'react';
import './ReservationDetailsPopup.scss';
import ParameterIcon from '~svg/parameter.svg';
import CloseIcon from '~svg/close.svg';
import InformationIcon from '~svg/information.svg';
import RedCircleTrash from '~svg/redCircleTrash.svg';
import PersonIcon from '~svg/person.svg';
import GroupPersonsIcon from '~svg/group-persons.svg';
import GroupPersonsCircleIcon from '~svg/group-persons-circle.svg';
import InformationSymbolIcon from '~svg/information-symbol.svg';
import CalendarIcon from '~svg/calendar-circle.svg';
import CommentIcon from '~svg/comment.svg';
import Moment from 'moment';
import 'moment/locale/fr';
import PrestationBenefits from './prestation-benefits';
import PrestationOptions from './prestation-options';
import ReservationCancellationResult from './ReservationCancellationResult';
import { cancelReservationTicket } from '~requests/reservations/services/reservation.service';
import { set } from 'date-fns';
import { useUserData } from '~contexts/userDataContext';
import { TicketPricingsCancellationRefund } from './ticket-pricings-refund';

function useOutsideAlerter(ref, setter, dropdownRef) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
                event.stopPropagation()
                return;
            }
            else if ((ref.current && !ref.current.contains(event.target))) {
                setter(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}

const ReservationDetailsPopup = ({ reservation, onClose }) => {
    const { apiUrl, token } = useUserData();
    const [openDropdown, setOpenDropdown] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [saveIsLoading, setSaveIsLoading] = useState(false);
    const [cancellationResultPopupTitle, setCancellationResultPopupTitle] = useState("");
    const [cancellationResultPopupDescription, setCancellationResultPopupDescription] = useState("");
    const [confirmDialog, setConfirmDialog] = useState(true);
    const cancelBtnRef = useRef(null);
    const cancelDropdownBtnRef = useRef(null);
    useOutsideAlerter(cancelBtnRef, setOpenDropdown, cancelDropdownBtnRef);

    const onClickAbort = () => {
        setIsOpen(false);
        setConfirmDialog(true);
    }
    const onClickCancel = () => {
        setIsOpen(false);
        setConfirmDialog(true);
    }
    const onClickSave = () => {
        setSaveIsLoading(true)
        cancelReservationTicket(reservation.reservation_ticket_number, apiUrl, token)
            .then(async res => {
                if(res.status !== 200 && res.status !== 201) {
                    const response = await res.json();
                    setCancellationResultPopupTitle('Annulation impossible');
                    setCancellationResultPopupDescription(response.error);
                } else {
                    setCancellationResultPopupTitle('Annulation confirmée');
                    setCancellationResultPopupDescription('Un mail a été envoyé au client pour le prévenir que sa réservation a été annulée.');
                }
                setConfirmDialog(false);
            }).catch(error => {
                console.warn('Error when fetching pending reservations ', error);
                setCancellationResultPopupTitle('Annulation impossible');
                setCancellationResultPopupDescription(error?.error);
                
            }).finally(() => {
                setSaveIsLoading(false);
            });
    }
    const cancelReservation = () => {
        setCancellationResultPopupTitle('Êtes-vous sûr de vouloir annuler ?');
        setCancellationResultPopupDescription('Si vous confirmez, vous ne pourrez plus changer d’avis, l’annulation sera définitive.');
        setIsOpen(true);
    }
    useEffect(() => {
        if (reservation) {
            document.body.classList.add('no-scroll')

            return () => {
                document.body.classList.remove('no-scroll')
            }
        }
    }, [reservation]);

    // To move in an utils file
    const sumReducer = (a, b) => a + b;

    const [prestations, reservationMoment, numberOfPrestations, phoneNumber] = useMemo(() => {
        const reservationPrestations = reservation?.items;

        const numberOfReservationPrestations = reservationPrestations?.map((item) => {
            const numberOfBenefits = item.benefits?.map((benefit) => benefit.count).reduce(sumReducer, 0);
            // const numberOfOptions = item.options?.map((option) => option.count).reduce(sumReducer, 0);
            return numberOfBenefits;
        }).reduce(sumReducer, 0);
        let phone = reservation?.reservation?.informations?.phone;
        phone = phone?.replace('+', '')?.replace(' ', '');
        phone = phone?.match(/.{1,2}/g);
        phone = phone?.join(' ');
        return [reservationPrestations, Moment(reservation?.reservation?.date), numberOfReservationPrestations, phone];
    }, [reservation]);

    const Visitors = () => {
        if (reservation?.visitors?.length) {
            return (
                <div className="details-group">
                    <GroupPersonsCircleIcon className="details-icon" />
                    <div className="details">
                        <div>
                            <span className="detail-title">Nom des autres participants : </span>
                        </div>
                        <div>
                            <span>{
                                reservation?.visitors?.map((visitor, index) => {
                                    return (<span key={'visitor_' + index}>{visitor.firstname} {visitor.lastname} {index < reservation?.visitors?.length - 2 ? ',' : ''}</span>)
                                })
                            }</span>
                        </div>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }


    return (
        isOpen
        ?
            <ReservationCancellationResult
                saveIsLoading={saveIsLoading}
                isOpen={isOpen}
                title={cancellationResultPopupTitle}
                description={cancellationResultPopupDescription}
                confirmDialog={confirmDialog}
                onClickAbort={onClickAbort}
                onClickCancel={onClickCancel}
                onClickSave={onClickSave}
            />
        :
        <div className={`reservation-details-popup ${reservation ? 'active' : ''}`}>
            <div className="overlay" />
            <div className="popup-content">

                <div className="popup-header">
                    <div className="btn-group">
                            <div className="btn" ref={cancelDropdownBtnRef}>
                    <ParameterIcon onClick={() => setOpenDropdown(!openDropdown)}/>
                    </div>
                        <CloseIcon className="btn" onClick={onClose} />
                        </div>
                        {
                            openDropdown
                            ?
                                <div className="line" ref={cancelBtnRef}>
                                <div className="cancellation-btn" onClick={() => cancelReservation()}><RedCircleTrash className="icon" /> Annuler et rembourser la réservation</div>
                            </div>
                            :
                            <></>
                        }
                        
                    <div className="separator" />
                </div>


                <div className="popup-body first-popup-body-elem">
                    <div className="note-form">
                        <div className='label'>
                            Ajouter une note interne à cette réservation <InformationIcon className="information-icon" />
                            <textarea className="note-control" />
                        </div>
                    </div>
                </div>

                <div className="separator" />

                <div className="reservation-details popup-body">
                    <div className="details-group">
                        <PersonIcon className="details-icon" />
                        <div className="details">
                            <div>
                                <span className="detail-title">Nom : </span>
                                <span>
                                    {reservation?.reservation?.informations?.firstname} {reservation?.reservation?.informations?.lastname} | {reservation?.reservation_ticket_number}</span>
                            </div>
                            <div>
                                <span className="detail-title">Contact : </span>
                                <span>{reservation?.reservation?.informations?.email} ou +{phoneNumber}</span>
                            </div>
                            <div>
                                <span className="detail-title">Satut : </span>
                                <span>{reservation?.reservation?.state?.status}</span>
                            </div>
                        </div>
                    </div>

                    <div className="details-group">
                        <InformationSymbolIcon className="details-icon" />
                        <div className="details">
                            <div>
                                <span className="detail-title">Prestations sélectionnées, {numberOfPrestations} <GroupPersonsIcon /> </span>
                            </div>
                            {
                            !reservation?.state?.canceled
                            ?
                            <ul>
                                {
                                    prestations?.map((prestation) => {
                                        return (
                                            <div key={prestation._id}>
                                                <PrestationBenefits key={prestation._id + 'forBenefits'} ticket={reservation} prestation={prestation} benefits={prestation.benefits} />
                                                <PrestationOptions key={prestation._id + 'forOptions'} ticket={reservation} prestation={prestation} options={prestation.options} />
                                            </div>
                                        )
                                    })
                                }
                                
                            </ul>
                            :
                            <div>
                                <TicketPricingsCancellationRefund ticket={reservation} />
                            </div>
                            }
                        </div>
                    </div>

                    <div className="details-group">
                        <CalendarIcon className="details-icon" />
                        <div className="details">
                            <div>
                                <span className="detail-title">Commandé le {reservationMoment.format('DD/MM')} à {reservationMoment.format('HH:mm')} pour un montant total de {reservation?.total?.toFixed(2)}€. </span>
                                <span></span>
                            </div>
                        </div>
                    </div>

                    <div className="details-group">
                        <CommentIcon className="details-icon" />
                        <div className="details">
                            <div className="comment-label">
                                <span className="detail-title">Commentaire du client :</span>
                                <span className='mark-comment-as-read float-right'>Ce message a été traité</span>
                            </div>
                            <div className="comment-box">
                            </div>
                        </div>
                    </div>
                    <Visitors />

                </div>

            </div>
        </div>
    );
}

export default ReservationDetailsPopup;