import React, { useState, useEffect } from 'react'

import { useRef } from 'react'
import { useMemo } from 'react'

import './SuperSelect.scss'

const Close = () => (
	<svg width={15} height={15} viewBox="0 0 30 30" version="1.1">
		<path
			d="M17.233 15L29.5376 2.69542C30.1541 2.07878 30.1541 1.07906 29.5376 0.462479C28.9209 -0.15416 27.9211 -0.15416 27.3046 0.462479L15 12.767L2.69542 0.462479C2.07878 -0.15416 1.07906 -0.15416 0.462479 0.462479C-0.154101 1.07912 -0.15416 2.07884 0.462479 2.69542L12.767 15L0.462479 27.3045C-0.15416 27.9211 -0.15416 28.9209 0.462479 29.5374C1.07912 30.1541 2.07884 30.154 2.69542 29.5374L15 17.2329L27.3044 29.5374C27.9211 30.1541 28.9209 30.154 29.5374 29.5374C30.154 28.9208 30.154 27.9211 29.5374 27.3045L17.233 15Z"
			id="Shape"
			fill="#000000"
			stroke="none"
		/>
	</svg>
)

export const CaretDownIcon = () => (
	<svg
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		x="0px"
		y="0px"
		width={8}
		height={8}
		viewBox="0 0 292.362 292.362"
	>
		<g>
			<path
				fill={'#FD517B'}
				d="M286.935,69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952,0-9.233,1.807-12.85,5.424
		C1.807,72.998,0,77.279,0,82.228c0,4.948,1.807,9.229,5.424,12.847l127.907,127.907c3.621,3.617,7.902,5.428,12.85,5.428
		s9.233-1.811,12.847-5.428L286.935,95.074c3.613-3.617,5.427-7.898,5.427-12.847C292.362,77.279,290.548,72.998,286.935,69.377z"
			/>
		</g>
	</svg>
)

const SuperSelectOption = ({
	label,
	onClick,
	selected,
	onMouseOver,
	disabled,
	style = {}
}) => (
	<button
		type="button"
		style={style}
		onClick={e => {
			e.preventDefault()
			onClick()
		}}
		disabled={disabled}
		className={`super-select-option ${selected ? 'selected' : ''}`}
		onMouseMove={onMouseOver}
	>
		{label}
	</button>
)

/**
 *
 * @param {{
 *  children:JSX.Element[] | JSX.Element
 *  value:any
 *  onChange: (value:any) => void
 *  emptyLabel?: string
 *  className?: string
 *  style?: React.CSSProperties
 *  dropdownClassName?: string
 *  dropdownStyle?: React.CSSProperties
 *  optionsStyle?: React.CSSProperties
 *  theme?: 'default'
 *  disabled?: boolean
 *  error?: boolean
 *  caretColor?: string,
 *  mobileFullscreen?: boolean
 *  mobileFullscreenLabel?: string
 *  rootClassName?: string
 *  dropdownVerticalPosition?: 'top'|'bottom'
 *  dropdownHorizontalPosition?:'left'|'right'
 *  readOnly?: boolean
 *  buttonTitle?: string
 * }} props
 */
const SuperSelect = ({
	children,
	value,
	onChange,
	emptyLabel = 'Sélectionnez',
	className = '',
	style = {},
	dropdownClassName = '',
	dropdownStyle = {},
	optionsStyle = {},
	theme = 'default',
	disabled = false,
	error = false,
	caretColor = null,
	mobileFullscreen = false,
	mobileFullscreenLabel = '',
	rootClassName = '',
	rootStyle = {},
	noWrap = false,
	dropdownVerticalPosition = 'bottom',
	dropdownHorizontalPosition = 'left',
	readOnly = false,
	buttonTitle = null
}) => {
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [currentSelected, setCurrentSelected] = useState(findValueIndex())

	const selectRef = useRef(null)
	const dropdownRef = useRef(null)
	useEffect(() => {
		const clickOutsideEvent = event => {
			if (!selectRef.current.contains(event.target)) setDropdownIsOpen(false)
		}

		const closeDropdown = () => {
			setDropdownIsOpen(false)
		}

		if (dropdownIsOpen) {
			setCurrentSelected(findValueIndex())
			window.addEventListener('click', clickOutsideEvent)
			window.addEventListener('blur', closeDropdown)

			return () => {
				window.removeEventListener('click', clickOutsideEvent)
				window.removeEventListener('blur', closeDropdown)
			}
		}
	}, [dropdownIsOpen, setDropdownIsOpen])

	useEffect(() => {
		if (currentSelected === null) return

		scrollToElement(currentSelected)
	}, [currentSelected])

	function findValueIndex() {
		const _ = children.filter(child => child.type === 'option')

		for (let i = 0; i < _.length; i++) {
			const child = _[i]

			if (child.props.value === value) return i
		}
		return undefined
	}

	const options = useMemo(
		() =>
			children
				.filter(elem => elem.type === 'option')
				.map(({ props }) => ({
					value: props.value,
					label: props.children
				}))
				.reduce((acc, cur) => {
					acc[cur.value] = cur.label
					return acc
				}, {}),
		[children]
	)

	const toggleDropdownIsOpen = () => {
		if (disabled) return
		setCurrentSelected(dropdownIsOpen ? null : 0)
		if (dropdownIsOpen && dropdownRef.current.scrollTo) {
			dropdownRef.current.scrollTo(0, 0)
		}
		setDropdownIsOpen(!dropdownIsOpen)
	}

	const handleSelect = value => () => {
		setDropdownIsOpen(false)
		onChange(value)
	}

	function scrollToElement(i) {
		if (i === undefined) return
		const selectedElem = dropdownRef.current.querySelector(
			`.super-select-option:nth-child(${i + 1})`
		)

		if (!selectedElem) return

		const top = selectedElem.offsetTop
		const height = selectedElem.offsetHeight
		const scroll = dropdownRef.current.scrollTop
		const dropHeight = dropdownRef.current.offsetHeight

		if (!dropdownRef.current.scrollTo) return

		if (top + height > dropHeight + scroll) {
			dropdownRef.current.scrollTo(0, top + height - dropHeight)
		}

		if (top < scroll) {
			dropdownRef.current.scrollTo(0, top)
		}
	}

	const handleKeyDown = e => {
		if (!dropdownIsOpen) return

		e.preventDefault()

		if (e.keyCode === 27) {
			// escape

			setDropdownIsOpen(false)
		} else if (e.keyCode === 38) {
			// up

			setCurrentSelected(current => {
				return Math.max(current - 1, 0)
			})
		} else if (e.keyCode === 40) {
			// down

			setCurrentSelected(current => {
				return Math.min(current + 1, Object.keys(options).length - 1)
			})
		} else if (e.keyCode === 13) {
			// enter

			const findOption = children.filter(elem => elem.type === 'option')[
				currentSelected
			]

			if (!findOption) return

			handleSelect(findOption.props.value)()
		}
	}

	return (
		<div
			className={`super-select positionv-${dropdownVerticalPosition} positionh-${dropdownHorizontalPosition} theme-${theme} ${
				dropdownIsOpen ? 'active' : ''
			} ${rootClassName} ${disabled ? 'disabled' : ''} ${
				error ? 'error' : ''
			} ${mobileFullscreen ? 'mobile-fullscreeen' : ''}`}
			ref={selectRef}
			onKeyDown={handleKeyDown}
			style={rootStyle}
		>
			<button
				type="button"
				className={`super-select-button ${className} ${
					readOnly ? 'readOnly' : ''
				}`}
				title={buttonTitle}
				onClick={e => {
					e.preventDefault()

					if (readOnly) return

					toggleDropdownIsOpen()
				}}
				style={{
					...style
				}}
			>
				<span
					style={{
						display: 'block',
						width: '100%',
						...(noWrap
							? {
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis'
							  }
							: {})
					}}
				>
					{options[value] || emptyLabel}
				</span>

				<span className="caret">
					<CaretDownIcon color={caretColor} />
				</span>
			</button>

			{mobileFullscreen && (
				<div className="overlay" onClick={toggleDropdownIsOpen}></div>
			)}
			<div
				className={`super-select-dropdown ${dropdownClassName} `}
				style={dropdownStyle}
				ref={dropdownRef}
			>
				{mobileFullscreen && (
					<div className="super-select-dropdown-header">
						<div className="close-button">
							<button
								type="button"
								onClick={e => {
									e.preventDefault()
									toggleDropdownIsOpen()
								}}
							>
								<Close />
							</button>
						</div>
						{mobileFullscreenLabel}
					</div>
				)}

				<div className="options">
					{children.map((option, i) => {
						if (!option?.props) return null
						const value = option.props.value
						const label = option.props.children

						return (
							<SuperSelectOption
								key={i}
								style={optionsStyle}
								defaultValue={value}
								disabled={!!option.props.disabled}
								label={label}
								onClick={handleSelect(value)}
								selected={currentSelected === i}
								onMouseOver={() => {
									setCurrentSelected(i)
								}}
							/>
						)
					})}
				</div>
			</div>
		</div>
	)
}
export default SuperSelect
