import React from 'react'
import Button from '~components/basics/Button'
import Modal from '../Modal'

import './ModalConfirmation.scss'

const ModalConfirmation = ({ payload = {}, ...props }) => {
	return (
		<Modal {...props}>
			{props.isOpen && (
				<div className="ModalConfirmation">
					<button
						className="close-button"
						onClick={() => {
							props.close()
						}}
					>
						<img src="/icons/cross.svg" alt="" />
					</button>
					<div>
						<h2>
							<span>{payload?.title || 'Une erreur est survenue'}</span>
						</h2>

						<p>{payload?.subtitle}</p>
					</div>
					<div className="buttons">
						<Button
							theme={payload?.leftButtonTheme || ''}
							onClick={() => {
								payload.handleLeftButtonClick?.()
								props.close()
							}}
						>
							{payload?.leftButtonlabel || 'Oui'}
						</Button>
						<Button
							theme={payload?.rightButtonTheme || 'yellow'}
							onClick={() => {
								payload?.handleRightButtonClick?.()
								props.close()
							}}
						>
							{payload?.rightButtonlabel || 'Non'}
						</Button>
					</div>
				</div>
			)}
		</Modal>
	)
}
export default ModalConfirmation
