import React, { useEffect } from 'react'

import Button from '~components/basics/Button'

import './FormModifiedPopup.scss'
import Spinner from '~components/basics/Icons/Spinner'

const FormModifiedPopup = ({
	isOpen,
	onClickAbort,
	onClickCancel,
	onClickSave,
	saveIsLoading
}) => {
	useEffect(() => {
		if (isOpen) {
			document.body.classList.add('no-scroll')

			return () => {
				document.body.classList.remove('no-scroll')
			}
		}
	}, [isOpen])

	return (
		<div className={`form-modified-popup ${isOpen ? 'active' : ''}`}>
			<div className="overlay" />

			<div className="content">
				<Button
					theme="underline-action"
					className="close-button"
					disabled={saveIsLoading}
					onClick={saveIsLoading ? () => {} : onClickCancel}
				>
					<img src="/icons/cross.svg" />
				</Button>
				<h2>
					<span>Enregistrer les modifications ?</span>
				</h2>

				<p>
					Vous avez effectué des modifications. Voulez-vous les enregistrer ou
					annuler ces modifications ?
				</p>

				<div className="buttons">
					<div>
						<Button
							disabled={saveIsLoading}
							className="cancel"
							style={{ marginRight: 6 }}
							roundish={'roundish'}
							onClick={saveIsLoading ? () => {} : onClickAbort}
						>
							Supprimer les modifs
						</Button>
					</div>

					<div>
						<Button
							onClick={saveIsLoading ? () => {} : onClickSave}
							roundish={'roundish'}
							theme="yellow"
							style={{ marginLeft: 6 }}
						>
							{saveIsLoading ? <Spinner color="dark" /> : `Enregistrer`}
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}
export default FormModifiedPopup
