 const isBrowser = () => typeof window !== 'undefined'

class StorageManager {
	static setStorage = (key: string, value) => {
		if (!isBrowser()) return
		localStorage.setItem(key, value)
	}

	static getStorage = (key: string) => {
		if (!isBrowser()) return null
		return localStorage.getItem(key)
	}

	static setSession = () => {
		// Session storage
	}

	static getSession = () => {
		// Session Storage
	}
}

export default StorageManager
