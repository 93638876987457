import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Chevron from '~svg/chevron-down.svg'
import { log } from '~utils/helpers'
import Details from './Details'

const Block = ({ bill, month }) => {
	const [isDeployed, setIsDeployed] = useState(false)

	const handleCollapser = () => {
		setIsDeployed(prev=>!prev)
	}
	

	return (
		<BlocWrapper>
			<MainRow>
				<Title>{month?.charAt(0)?.toUpperCase() + month?.slice(1)}</Title>
				<Buttons>
					{/* <Download href='/mouvements/factures'>Télécharger</Download> */}
					{/* <Separator>|</Separator> */}
					<Collapser onClick={handleCollapser}>
						{isDeployed ? 'Masquer' : 'Afficher'} <Arrow isDeployed={isDeployed}/>
					</Collapser>
				</Buttons>
			</MainRow>
			<Details bill={bill} visible={isDeployed} month={month.split(' ')[0]}/>
		</BlocWrapper>
	)
}

const BlocWrapper = styled.div`
	height: 100%;
	background-color: white;
	line-height: 19px;
	padding: 30px 36px 29px;
`

const MainRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`

const Title = styled.div`
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
`

const Buttons = styled.div`
	font-style: normal;
	font-size: 16px;
	line-height: 19px;
`

const Download = styled.a`
	font-style: normal;
	font-size: 16px;
	line-height: 19px;
`

const Separator = styled.span`
	font-weight: 600;
	margin: 0 30px;
`

const Collapser = styled.button`
	border: none;
	background-color: inherit;
	font-style: normal;
	font-size: 16px;
	line-height: 19px;
	cursor: pointer;
`

const Arrow = styled(Chevron)`
	height: 17px;
	margin-left: 8px;
	color: inherit;
	transform: ${props=>props.isDeployed ? 'rotate(180deg)': 'rotate(0deg)'};
	transition: transform 200ms;
`

export default Block
