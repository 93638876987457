import React from 'react'
import Button from '~components/basics/Button'
import InfoTooltip from '~components/basics/InfoTooltip'
import { FormGrid } from '~components/Ticketing/FormToolbox'
import useToggle from '~hooks/useToggle'

const StrongPointItem = ({ value, setValue, error, remove, onClickDelete }) => {
	return (
		<div className={`strong-point-item ${error ? 'error' : ''}`}>
			<span className="plus">+</span>
			<input
				autoComplete="off"
				value={value}
				onChange={e => {
					setValue(e.target.value)
				}}
				name="strong-point"
				className="strongpoint"
			/>
			<button
				className="delete-button"
				onClick={e => {
					e.preventDefault()
					remove()
					onClickDelete()
				}}
			>
				<img src="/icons/delete.svg" />
			</button>
		</div>
	)
}

const InfosStrongPoint = ({ strongPointForm }) => {
	const [isOpen, toggleIsOpen, setIsOpen] = useToggle(
		!(
			strongPointForm.length === 0 ||
			(strongPointForm.length === 1 &&
				strongPointForm.get(0).value.length === 0)
		)
	)

	return (
		<>
			<div
				style={{
					display: 'flex',
					alignItems: 'center'
				}}
			>
				<Button
					theme="underline-action"
					onClick={() => {
						if (isOpen) return
						;[...document.querySelectorAll('.strongpoint')][0].focus()

						setIsOpen(true)
					}}
				>
					+ Ajouter des points forts à propos de votre activité
				</Button>

				<span style={{ marginLeft: 4 }}>(optionnel)</span>
				<InfoTooltip>
					Vous pouvez écrire quelques points forts sur votre activité pour
					donner envie. Formulez des phrases simples et courtes.
				</InfoTooltip>
			</div>
			<div
				style={{
					paddingTop: 8,
					opacity: isOpen ? 1 : 0,
					pointerEvents: isOpen ? 'all' : 'none',
					position: isOpen ? 'static' : 'absolute'
				}}
			>
				<FormGrid>
					{strongPointForm.map((strongPoint, i) => (
						<StrongPointItem
						key={i}
							{...strongPoint}
							onClickDelete={() => {
								if (strongPointForm.length === 1) {
									strongPoint.setValue('')
									toggleIsOpen()
								}
							}}
						/>
					))}
					<Button
						theme="colored-action"
						style={{
							textAlign: 'left'
						}}
						onClick={() => {
							strongPointForm.push()
							setTimeout(() => {
								const inputs = document.querySelectorAll('.strongpoint')
								if (inputs.length > 0) {
									;[...inputs].slice(-1)[0].focus()
								}
							}, 100)
						}}
					>
						+ Ajouter un point fort
					</Button>
				</FormGrid>
			</div>
		</>
	)
}

export default InfosStrongPoint
