import React, { useState } from 'react'
import {
	Select,
	FormContainer,
	FormLine
} from '~components/Ticketing/FormToolbox'
import InfoTooltip from '~components/basics/InfoTooltip'
import { FicheScheduleItem } from './FicheScheduleItem'
import moment from 'moment'

export const FicheOpeningTime = ({ schedulesForm }) => {
	const same_schedule = schedulesForm.get('same_schedule').value
	const [copiedDay, setCopiedDay] = useState(null)

	const clearCopy = () => {
		setCopiedDay(null)
	}

	return (
		<>
			<span className="form-section-title">Vos horaires d'ouverture</span>
			<FormLine>
				Hormis vos périodes de fermeture, avez-vous les mêmes horaires toute
				l'année ?
				<InfoTooltip>
					Si vous répondez non, vous pourrez enregistrer des horaires
					d’ouvertures différents dans l’année. Sinon, vous aurez les mêmes
					horaires toute l’année.
				</InfoTooltip>
				<div>
					<Select
						value={schedulesForm.get('same_schedule').value}
						setValue={value => {
							if (value === true) {
								if (schedulesForm.get('schedules_table').length === 1) {
									schedulesForm.get('same_schedule').setValue(true)
								} else {
									const json = schedulesForm.toJSON()

									schedulesForm.set({
										...json,
										schedules_table: [json.schedules_table[0]],
										same_schedule: 'true'
									})
								}
							} else {
								schedulesForm.get('same_schedule').setValue(false)
							}
						}}
						emptyLabel="Choisir"
						options={[
							{
								label: 'Oui',
								value: true
							},
							{
								label: 'Non',
								value: false
							}
						]}
					/>
				</div>
			</FormLine>
			{schedulesForm.get('schedules_table').map((schedule, i, id) => (
				<FicheScheduleItem
					key={id}
					iSchedule={i}
					scheduleForm={schedule}
					copiedDay={copiedDay}
					setCopiedDay={setCopiedDay}
					same_schedule={same_schedule}
					clearCopy={clearCopy}
					otherPeriodRanges={(() => {
						const ranges = []

						schedulesForm
							.get('schedules_table')
							.toJSON()
							.forEach((period, _i) => {
								if (_i === i) return

								period.dates.forEach(({ start_date, end_date }) => {
									if (
										start_date &&
										end_date &&
										start_date !== '' &&
										end_date !== ''
									) {
										ranges.push({
											start_date: moment(start_date).year(moment().year()),
											end_date: moment(end_date).year(moment().year())
										})
									}
								})
							})

						return ranges
					})()}
				/>
			))}
			{!same_schedule && (
				<>
					<FormContainer>
						<div
							role="button"
							tabIndex={0}
							onClick={() => {
								schedulesForm.get('schedules_table').push()
							}}
						>
							<div className="add-schedule-button">
								+ Ajouter une période d'horaire
							</div>
						</div>
					</FormContainer>
				</>
			)}
		</>
	)
}
