import React from 'react'

const EuroIcon = () => (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
			fill="#F0F0F0"
		/>
		<path
			d="M11.9299 15.1813C10.3152 15.1813 8.91934 14.2678 8.22461 12.9298H11.9299V11.6433H7.80005C7.76788 11.431 7.74858 11.2187 7.74858 11C7.74858 10.7813 7.76788 10.569 7.80005 10.3567H11.9299V9.07017H8.22461C8.91934 7.73216 10.3217 6.81871 11.9299 6.81871C12.9655 6.81871 13.9176 7.19824 14.6509 7.82865L15.7895 6.69006C14.7667 5.77017 13.4094 5.21053 11.9299 5.21053C9.40823 5.21053 7.27256 6.82515 6.4749 9.07017H4.21057V10.3567H6.17899C6.15326 10.569 6.1404 10.7813 6.1404 11C6.1404 11.2187 6.15326 11.431 6.17899 11.6433H4.21057V12.9298H6.4749C7.27256 15.1749 9.40823 16.7895 11.9299 16.7895C13.4158 16.7895 14.7667 16.2298 15.7895 15.3099L14.6445 14.1713C13.9176 14.8018 12.972 15.1813 11.9299 15.1813Z"
			fill="black"
		/>
	</svg>
)
export default EuroIcon
