import React, { useEffect, useState } from 'react'
import { useAppAuthReducer } from './appAuthReducer/appAuth.reducer'
import {
	loadActivitiesAction,
	loadActivityAction
} from './appDataReducer/appData.actions'

import useAppDataReducer from './appDataReducer/appData.reducer'

import { TAppContext } from './appContext.types'
import {
	resetLoadingAction,
	setLoadedAction,
	signInAction,
	signOutAction
} from './appAuthReducer/appAuth.actions'
import { useUserData } from '~contexts/userDataContext'
import { getLocalstorageJSON } from '~utils/helpers'
import { TUserData } from '~contexts/userDataContext.types'
import { getData } from '~requests/get'
import { isBrowser } from '~utils/misc'

const appContext = React.createContext<TAppContext>(null)

export const AppContextProvider = ({ children }) => {
	if (!isBrowser()) return <></>

	const userUserData = useUserData()
	const [authState, dispatchAuth] = useAppAuthReducer()
	const [dataState, dispatchData] = useAppDataReducer()

	useEffect(() => {
		loadAndSetLegacyData(userUserData, () => {
			setLoadedAction(dispatchAuth)
		})
	}, [])

	return (
		<appContext.Provider
			value={{
				auth: {
					...authState,
					signIn: (email, password) => {
						signInAction(
							email,
							password,
							dispatchAuth,
							dispatchData,
							userUserData
						)
					},
					signOut: () => signOutAction(dispatchAuth),
					resetLoading: () => resetLoadingAction(dispatchAuth)
				},
				data: {
					...dataState,
					loadActivities: () => loadActivitiesAction(dispatchData),
					loadActivity: (id: string) => loadActivityAction(id, dispatchData)
				}
			}}
		>
			{children}
		</appContext.Provider>
	)
}

export const useAppContext = () => React.useContext<TAppContext>(appContext)

const loadAndSetLegacyData = (useUserData: TUserData, cb) => {
	if (!isBrowser()) return

	const {
		userId: _id,
		setName,
		activityId,
		setId,
		setLogged,
		token,
		setToken,
		apiUrl,
		dispatch,
		setActivitiesId,
		setShareLink,
		setOnboarding
	} = useUserData

	const activity = localStorage.getItem(`activity-${_id}`)
	const activities = getLocalstorageJSON(`activities-${_id}`) || []
	const localUserData = JSON.parse(localStorage.getItem(`userData-${_id}`))
	const name = localStorage.getItem(`name-${_id}`)
	const _token = localStorage.getItem(`token-${_id}`)
	const _shareLink = localStorage.getItem(`sharelink-${_id}`)
	const _onboarding = localStorage.getItem(`onboarding-${_id}`) === 'true'

	// if (localUserData && _token) {
	if (_token) {
		// dispatch({ type: 'getFromLocal', payload: localUserData })
		setId(activity)
		setActivitiesId(activities)
		setToken(_token)
		setName(name)
		setLogged(true)
		setShareLink(JSON.parse(_shareLink))
		setOnboarding(_onboarding)

		getData(apiUrl, token, activityId)
			.then(result => {
				setOnboarding(!result.ticketing.done)
				dispatch({ type: 'get', payload: result })
				dispatch({ type: 'makeShareLink' })

				localStorage.setItem(`userData-${_id}`, JSON.stringify(result))
				setLogged(true)
			})
			.catch(error => {
				console.log('error', error)
			})
			.finally(() => cb())
	} else cb()
}
